import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import { ViewDetailGasto } from './Components/MenuContent/ViewDetailGasto';
import { ViewModalApproval } from './Components/MenuContent/ViewModalApproval';
import { ViewModalNote } from './Components/MenuContent/ViewModalNote';


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        // eslint-disable-next-line
    }, []);

    return (
    <>
        <ViewMain
            languageTranslate={controller.languageTranslate}
            configForm={controller.configForm}
            openModalDetail={controller.openModalDetail}
            onSubmitSearch={controller.onSubmitSearch}
            onChangeSearch={controller.onChangeSearch}
            configItems={controller.configItems}
            activeModeSelectItem={controller.activeModeSelectItem}
            disabledModeSelectItem={controller.disabledModeSelectItem}
            onSelectAllItem={controller.onSelectAllItem}
            onSelectItem={controller.onSelectItem}
            onSubmitApproval={controller.onSubmitApproval}
            openModalNotes={controller.openModalNotes}
        />
        <ViewDetailGasto
            configModalDetail={controller.configModalDetail}
            languageTranslate={controller.languageTranslate}
            listGasto={controller.configItems.items}
            configForm={controller.configForm}
            nextGasto={controller.nextGastoMD}
            prevGasto={controller.prevGastoMD}
            closeModalDetail={controller.closeModalDetail}
            onSubmitApproval={controller.onSubmitApproval}
        />
        <ViewModalApproval
            configModalApproval={controller.configModalApproval}
            languageTranslate={controller.languageTranslate}
            closeModalApproval={controller.closeModalApproval}
            onChangeMA={controller.onChangeMA}
            onSubmitMA={controller.onSubmitMA}
        />
        <ViewModalNote
            configModalNote={controller.configModalNotes}
            closeModalNotes={controller.closeModalNotes}
            languageTranslate={controller.languageTranslate}
            onChangeMN={controller.onChangeMN}
            onSubmitMN={controller.onSubmitMN}
        />
    </>
    );
};

export default ManagerEntity;