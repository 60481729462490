import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { DtoReqChangePassword } from "../Domain/DtoReqChangePassword";

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async changePassword(params: DtoReqChangePassword): Promise<void> {
        try {
            const newParams = JSON.stringify({ IdProceso: 7, data: params });
            await this.service.call('POST', `${this.urlBase}/administracion/insertUser`, newParams, 'bearer', "json", "json", {}, 0);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}