import { ElementCollapseCostume } from "../../../../shared/Components/ElementCollapseCostume"
import { ElementTableCostume } from "../../../../shared/Components/ElementTableCostume";
import { EntityFactura } from "../../../../shared/Domain/EntityFactura";
import { AdapterGenerico } from "../../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { EntityListContent } from "../../Domain/EntityListContent";
import { EntityRequestDownloadFile } from "../../Domain/EntityRequestDownloadFile";
import { keyModal } from "../../Domain/EntityUtils";
import { TableGenericDoc } from "../generic/TableGenericDoc";

interface ContainerProps {
    listContent: EntityListContent<EntityFactura>;
    viewDocument: (payload: EntityRequestDownloadFile, code: string) => Promise<any>;
    openModal: (key: keyModal, payload?: any) => void;
    show: boolean;
    copyCodeItem: Function;
}

export const ViewFactura = (props: ContainerProps) => {
    if (!props.show) return null;
    const languageTranslate = LanguageTranslate();

    return (
        <>
            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemDetail.textTitle}
                isOpenDefault
                persistsOpen
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <div className='d-flex' onClick={(evt) => props.copyCodeItem(props.listContent.detail.codigo)}>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textCodigo}:</span>
                        <span className='text-muted'> {props.listContent.detail.codigo} </span>
                        <button className='icon-copy-custom'><i className="fa-solid fa-copy" /></button>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textPais}:</span>
                        <span className='text-muted'> {props.listContent.detail.pais} </span>
                        <span> {" "}|{" "} </span>
                        <span> {languageTranslate.moduloGenericDetail.itemDetail.textGrupo}: </span>
                        <span className='text-muted'> {props.listContent.detail.grupo} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textEmpresa}:</span>
                        <span className='text-muted'> {props.listContent.detail.empresa} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textProveedor}:</span>
                        <span className='text-muted'> {props.listContent.detail.proveedor} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textOT}:</span>
                        <span className='text-muted'> {props.listContent.detail.ot} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textFechaRecepcion}:</span>
                        <span className='text-muted'> {props.listContent.detail.fechaRecepcion} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textFormaPago}:</span>
                        <span className='text-muted'> {props.listContent.detail.formaPago} </span>
                        <span> {" "}|{" "} </span>
                        <span> {languageTranslate.moduloGenericDetail.itemDetail.textTerminoPago}: </span>
                        <span className='text-muted'> {props.listContent.detail.terminoPago} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textImporte}: </span>
                        <span className='text-danger'> {AdapterGenerico.formatoDinero(props.listContent.detail.importe)} ({props.listContent.detail.moneda}) </span>
                    </div>
                    {
                        (props.listContent.detail.isFacturaAsociada) ? 
                            <div>
                                <span>{languageTranslate.moduloGenericDetail.itemDetail.textImporteFacturaAsociado} {props.listContent.detail.tipoFacturaAsociada}:</span>
                                <span className='text-muted'> {AdapterGenerico.formatoDinero(props.listContent.detail.importeFacturaAsociada)} </span>
                            </div>
                        : null
                    }
                    <span>{ languageTranslate.moduloGenericDetail.itemDetail.textPedidoCompra }:</span>
                    <br />
                    <span className='text-muted'>
                        {
                            props.listContent.detail.pedidoCompra.map((row, index) =>
                                <>
                                    -
                                    <i className="fa-solid fa-eye mx-2" style={{ fontSize: 12 }} onClick={() => props.openModal('resume-factura', props.listContent.dataComplete.Contrato[index] || {})}/>
                                    { row }
                                    <br />
                                </>
                            )
                        }
                    </span>
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemValorizaciones.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <ElementTableCostume
                        listHeader={props.listContent.detail.tipoFacturaAsociada === '(Recepciones)' ? languageTranslate.moduloGenericDetail.itemValorizaciones.headerTablePedidoCompra : languageTranslate.moduloGenericDetail.itemValorizaciones.headerTableContrato }
                        listData={props.listContent.valorizacion}
                        className='TableNoWrap'
                    />

                    <div className='d-flex w-100'>
                        <span className='ms-auto'>{languageTranslate.moduloGenericDetail.itemValorizaciones.textMontoTotal}:</span>
                        <span className='text-muted'> {AdapterGenerico.formatoDinero(props.listContent.detail.importeAsociado || props.listContent.valorizacion.reduce((prev, current) => prev + current.subTotalTypeNumber, 0))} </span>
                    </div>
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={
                    props.listContent.distribuccion.length === 0 ?
                        languageTranslate.moduloGenericDetail.itemDistribuccion.textTitleEmpty :
                        languageTranslate.moduloGenericDetail.itemDistribuccion.textTitle
                }
                className={`my-3 ${props.listContent.distribuccion.length === 0 ? 'CardEmpty' : ''}`}
            >
                <div className='ContentItemCollapse'>
                    <div className='d-flex w-100'>
                        <span>{languageTranslate.moduloGenericDetail.itemDistribuccion.textProveedor}:</span>
                        <span className='text-muted'> {props.listContent.detail.proveedor} </span>
                    </div>
                    <div className='d-flex w-100'>
                        <span>{languageTranslate.moduloGenericDetail.itemDistribuccion.textMoneda}:</span>
                        <span className='text-muted'> {props.listContent.detail.moneda} </span>
                    </div>
                        
                    <ElementTableCostume
                        listHeader={languageTranslate.moduloGenericDetail.itemDistribuccion.headerTable}
                        listData={props.listContent.distribuccion}
                    />
                    
                    <div className='d-flex w-100'>
                        <span>{languageTranslate.moduloGenericDetail.itemDistribuccion.textImporteTotal}:</span>
                        <span className='text-muted'> {AdapterGenerico.formatoDinero(props.listContent.detail.importe)} </span>
                    </div>
                    <div className='d-flex w-100'>
                        <span>{languageTranslate.moduloGenericDetail.itemDistribuccion.textImporteDistribuido}:</span>
                        <span className='text-muted'> {AdapterGenerico.formatoDinero(props.listContent.detail.sumaImporteDetalle)} </span>
                    </div>
                    <div className='d-flex w-100'>
                        <span>{languageTranslate.moduloGenericDetail.itemDistribuccion.textImportePendiente}:</span>
                        <span className='text-muted'> {AdapterGenerico.formatoDinero((props.listContent.detail.importe)-(props.listContent.detail.sumaImporteDetalle))} </span>
                    </div>
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemNotas.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <div className="w-100 mb-2" style={{ textAlign: 'end' }}>
                        <button className="btn btn-primary" onClick={() => props.openModal('notes')}>Agregar</button>
                    </div>
                    <ElementTableCostume
                        listHeader={languageTranslate.moduloGenericDetail.itemNotas.headerTable}
                        listData={props.listContent.notas}
                        className="CustomTableNota"
                    />
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemBitacora.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <ElementTableCostume
                        listHeader={languageTranslate.moduloGenericDetail.itemBitacora.headerTable}
                        listData={props.listContent.bitacora}
                    />
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemDocumentos.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <TableGenericDoc
                        payload={props.listContent.documento}
                        viewDocument={props.viewDocument}
                    />
                </div>
            </ElementCollapseCostume>
        </>
    )
}