import { ElementCollapseCostume } from "../../../../shared/Components/ElementCollapseCostume"
import { ElementTableCostume } from "../../../../shared/Components/ElementTableCostume";
import { EntityComparative } from "../../../../shared/Domain/EntityComparative";
import { AdapterGenerico } from "../../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { EntityListContent } from "../../Domain/EntityListContent";
import { EntityRequestDownloadFile } from "../../Domain/EntityRequestDownloadFile";
import { keyModal } from "../../Domain/EntityUtils";
import { TableGenericDoc } from "../generic/TableGenericDoc";

interface ContainerProps {
    listContent: EntityListContent<EntityComparative>;
    viewDocument: (payload: EntityRequestDownloadFile, code: string) => Promise<any>;
    openModal: (key: keyModal, dataComplete?: any) => void;
    show: boolean;
    copyCodeItem: Function;
}
const languageTranslate = LanguageTranslate();

export const ViewComparativo = (props: ContainerProps) => {
    if (!props.show) return null;

    return (
        <>
            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemDetail.textTitle}
                isOpenDefault
                persistsOpen
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <div className='d-flex' onClick={(evt) => props.copyCodeItem(props.listContent.detail.codigo)}>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textCodigo}:</span>
                        <span className='text-muted'> {props.listContent.detail.codigo} </span>
                        <button className='icon-copy-custom'><i className="fa-solid fa-copy" /></button>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textPais}:</span>
                        <span className='text-muted'> {props.listContent.detail.pais} </span>
                        <span> {" "}|{" "} </span>
                        <span> {languageTranslate.moduloGenericDetail.itemDetail.textGrupo}: </span>
                        <span className='text-muted'> {props.listContent.detail.grupo} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textEmpresa}:</span>
                        <span className='text-muted'> {props.listContent.detail.empresa} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textProveedor}:</span>
                        <span className='text-muted'> {props.listContent.detail.proveedor} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textOT}:</span>
                        <span className='text-muted'> {props.listContent.detail.ot} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textTipoPago}:</span>
                        <span className='text-muted'> {props.listContent.detail.tipoPago} </span>
                        <span> {" "}|{" "} </span>
                        <span> {languageTranslate.moduloGenericDetail.itemDetail.textTerminoPago}: </span>
                        <span className='text-muted'> {props.listContent.detail.terminoPago} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textImporte}: </span>
                        <span className='text-danger'> {AdapterGenerico.formatoDinero(props.listContent.detail.importe)} ({props.listContent.detail.moneda}) </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textComentario}:</span>
                        <span className='text-muted'> {props.listContent.detail.comentario} </span>
                    </div>
                    {
                        (props.listContent.detail.global || props.listContent.detail.multiProveedor) ?
                            <div className='d-flex GroupBadge'>
                                { props.listContent.detail.global ? <div className="badgeCustom badgeCustom-global">{languageTranslate.moduloGenericDetail.itemDetail.textContratoMarco} <i className="fa-solid fa-file-contract" /> </div> : null }
                                { props.listContent.detail.multiProveedor ? <div className="badgeCustom badgeCustom-multiproveedor">{languageTranslate.moduloGenericDetail.itemDetail.textMultiProveedor} <i className="fa-solid fa-users" ></i></div> : null }
                            </div>
                        : null
                    }
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemProducto.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <ElementTableCostume
                        listHeader={
                            (languageTranslate.moduloGenericDetail.itemProducto.headerTable || []).map(row => {
                                if (row.key === 'descripcion') {
                                    row = {
                                        ...row,
                                        render: (currentValue: string, dataComplete: any) => <p className="d-flex gap-2 mb-0" onClick={() => props.openModal('product', dataComplete)}> < i className="fa-solid fa-eye" style={{ height: 18 }} /> { currentValue } </p>
                                    }
                                }
                                return row;
                            })
                        }
                        listData={props.listContent.producto}
                    />

                    <div className='d-flex w-100'>
                        <span className='ms-auto'>{languageTranslate.moduloGenericDetail.itemProducto.textMontoTotal}:</span>
                        <span className='text-muted'> {AdapterGenerico.formatoDinero(props.listContent.producto.reduce((prev, current) => prev + current.subTotalTypeNumber, 0))} </span>
                    </div>
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemNotas.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <div className="w-100 mb-2" style={{ textAlign: 'end' }}>
                        <button className="btn btn-primary" onClick={() => props.openModal('notes')}>Agregar</button>
                    </div>
                    <ElementTableCostume
                        listHeader={languageTranslate.moduloGenericDetail.itemNotas.headerTable}
                        listData={props.listContent.notas}
                        className="CustomTableNota"
                    />
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemBitacora.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <ElementTableCostume
                        listHeader={languageTranslate.moduloGenericDetail.itemBitacora.headerTable}
                        listData={props.listContent.bitacora}
                    />
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemDocumentos.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <TableGenericDoc
                        payload={props.listContent.documento}
                        viewDocument={props.viewDocument}
                    />
                </div>
            </ElementCollapseCostume>
        </>
    )
}