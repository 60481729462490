export interface EntityStatsMyAccount {
    show: boolean;
    loading: boolean;
    Anticipos: Array<{
        Codigo: string;
        OT: string;
        Importe: number;
    }>
    AnticiposAprobacion: Array<{
        Codigo: string;
        OT: string;
        Importe: number;
    }>
    AnticiposPendientePago: Array<{
        Codigo: string;
        OT: string;
        Importe: number;
    }>
    Rendiciones: Array<{
        Codigo: string;
        OT: string;
        Importe: number;
    }>
    Monto: {
        PendientePago: number
        Pendiente: number
        Total: number
    }
}

export const initEntityStatsMyAccount: EntityStatsMyAccount = {
    show: true,
    loading: true,
    Anticipos: [],
    AnticiposAprobacion: [],
    AnticiposPendientePago: [],
    Rendiciones: [],
    Monto: {
        PendientePago: 0,
        Pendiente: 0,
        Total: 0
    }
}