import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        // controller.init();
        return () => {
        }
        // eslint-disable-next-line
    }, []);

    return (<ViewMain
        form={controller.form}
        onChange={controller.onChange}
        onSubmit={controller.onSubmit}
        onClickLogin={controller.onClickLogin}
    />);
};

export default ManagerEntity;