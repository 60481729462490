import React from 'react';

export const ItemOptionMenu = (props: any) => {
    return (
        <div className="row pb-3" key={props.row.id}>
            <div className="col-12">
                <div className='shadow-lg' style={{ width: '100%', padding: 5, margin: 0, borderRadius: '10px', cursor: 'pointer' }} onClick={() => props.onClickMenu(props.row)}>
                    <div className="row">
                        <div className="col-2">
                            <button className='btn btn-primary btn-icon-first' style={{ marginTop: '5px' }}>
                                <i className={`text-white ${props.row.icono}`} />
                            </button>
                        </div>
                        <div className="col-7" style={{ placeSelf: 'center' }}>
                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{props.languageTranslate.menu[props.row.codigo as keyof typeof props.languageTranslate.menu]?.titulo}</span>
                        </div>
                        <div className="col-3 d-flex">
                            <p className='btn btn-lg btn-primary text-white text-center mb-0 ms-auto'>
                                {">"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}