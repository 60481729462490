// Library
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useState } from "react";

// Infraestructure
import { addLoading, changeSaludo, removeLoading } from "../../../../../shared/Infraestructure/SliceGenerico";
import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";
import { RootState } from "../../../../../shared/Infraestructure/AdapterStore";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { AdapterConfigure } from "./AdapterConfigure";

// Domain
import { EntityConfigApproval, EntityConfigForm, EntityConfigItems, EntityConfigModalApproval, EntityConfigModalDetail, EntityConfigModalNotes, EntityNavBar, ModeForm, initEntityConfigForm, initEntityConfigItems, initEntityConfigModalApproval, initEntityConfigModalDetail, initEntityConfigModalNotes, keyModule, nameModule } from "../Domain/EntityUtils";
import { EntityRendicionGastoAprobacion } from "../../../../../shared/Domain/EntityRendicionGastoAprobacion";
import { AdapterGenerico } from "../../../../../shared/Infraestructure/AdapterGenerico";

// Use Case
import { UseCaseFind } from "../Application/UseCaseFind";
import { EntityGasto } from "../../../../../shared/Domain/EntityGasto";
import { EntityRequestApproval, EntityRequestNote } from "../Domain/EntityRequest";

const languageTranslate = LanguageTranslate();
let nameStore: keyModule = '';
let modeForm: ModeForm = '';

export const Controller = () => {

    const { generico: { websocket, dbLocal }, auth: { user } } = useSelector((state: RootState) => state);
    const [configForm, setConfigForm] = useState<EntityConfigForm>(initEntityConfigForm);
    const [configModalDetail, setConfigModalDetail] = useState<EntityConfigModalDetail>(initEntityConfigModalDetail);
    const [configItems, setConfigItems] = useState<EntityConfigItems>(initEntityConfigItems);
    const [configModalApproval, setConfigModalApproval] = useState<EntityConfigModalApproval>(initEntityConfigModalApproval);
    const [configModalNotes, setConfigModalNotes] = useState<EntityConfigModalNotes>(initEntityConfigModalNotes);
    const dispatch: Dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const init = () => {
        dispatch(changeSaludo(false));
        configModeType();
    };

    /* ==================================Principal=============================================== */

    const configModeType = async () => {

        // Variables
        let navBar: EntityNavBar[] = [];
        let findRegister: EntityRendicionGastoAprobacion | null = null;
        const arrPathname = window.location.pathname.split('/');
        const validationPathName = (module: string) => arrPathname.some(item => item === module);
        
        // Valida la ruta para activar el formulario
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_APROBADOR_GASTO_APROBACION}`)) {
            navBar = languageTranslate.moduloGastoAprobacion.navBar;
            nameStore = 'GastoAprobacion';
            modeForm = 'approval';

            findRegister = await new UseCaseFind(repository).exec(`${params.id}`, nameStore);
            if (!findRegister) {
                AdapterGenerico.createMessage('Error', languageTranslate.moduloGastoAprobacion.validate.errorDetail, 'error').then(res => navigate(AdapterConfigure.LIST_GASTO_APROBACION)); 
                return;
            }
        } else if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_APROBADOR_HISTORICO_GASTOS}`)) {
            navBar = languageTranslate.moduloGastoAprobacion.navBarHistorico;
            nameStore = 'HistoricoGasto';
            modeForm = 'preview';

            findRegister = await new UseCaseFind(repository).exec(`${params.id}`, nameStore);
            if (!findRegister) {
                AdapterGenerico.createMessage('Error', languageTranslate.moduloGastoAprobacion.validate.errorDetail, 'error').then(res => navigate(AdapterConfigure.LIST_HISTORICO_GASTO)); 
                return;
            }
        } else {}
        

        // Modifica el navbar para el lenguaje
        if (navBar.length >= 3) navBar[2].text = nameModule[modeForm];

        // Actualizar Items del gasto
        setConfigItems((prev) => ({
            ...prev,
            filterItems: findRegister?.Gastos || [],
            items: findRegister?.Gastos || []
        }))

        // Guarda la información general y la del usuario
        setConfigForm(() => ({
            ...configForm,
            loadingForm: false,
            data: findRegister as any,
            key: nameStore,
            navBar: navBar,
            mode: modeForm,
        }));
    };

    /* ============================Config Modal Detail=========================================== */

    const openModalDetail = async (row: EntityGasto, position: number) => {
        dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
        if (!row.FileUrl) {
            const responseFile = await repository.downloadFile(row.Files[0])
            if (responseFile) {
                const indexFinded = configItems.items.findIndex(_row => _row.Codigo === row.Codigo)
                const data = configItems.items;
                data[indexFinded].FileUrl = (window.URL ? URL : webkitURL).createObjectURL(responseFile);
                setConfigItems((prev) => ({...prev, items: data}));
            }
        }
        setConfigModalDetail((prev) => ({
            show: true,
            currentPosition: position,
            gastoSelect: row
        }))
        dispatch(removeLoading());
    }

    const closeModalDetail = () => {
        setConfigModalDetail((prev) => ({
            show: false,
            currentPosition: -1,
            gastoSelect: {} as any
        }));
    }

    const nextGastoMD = () => {
        let findPosition = configItems.items.findIndex(row => row.Codigo === configModalDetail.gastoSelect.Codigo);
        if (findPosition === -1) return;
        findPosition = ((findPosition + 1) === configItems.items.length) ? 0 : (findPosition + 1);

        const gastoSelect = configItems.items[findPosition];
        openModalDetail(gastoSelect, findPosition);
    }

    const prevGastoMD = () => {
        let findPosition = configItems.items.findIndex(row => row.Codigo === configModalDetail.gastoSelect.Codigo);
        if (findPosition === -1) return;
        findPosition = (findPosition === 0) ? (configItems.items.length - 1) : (findPosition - 1);

        const gastoSelect = configItems.items[findPosition];
        openModalDetail(gastoSelect, findPosition);
    }

    /* ===============================ConfigItems/ModeSelect===================================== */

    const activeModeSelectItem = () => {
        setConfigItems((prev) => ({
            ...prev,
            active: true,
            filterItems: configItems.items.filter(row => row.Status.IdStatus === 5),
            textSearch: '',
            selectItems: []
        }))
    }

    const disabledModeSelectItem = () => {
        setConfigItems((prev) => ({
            ...prev,
            active: false,
            selectItems: [],
            textSearch: '',
            filterItems: configForm.data.Gastos
        }))
    }

    const onSelectAllItem = () => {
        const selectItems = configItems.items.length === configItems.selectItems.length ? [] : configItems.items;
        setConfigItems(prev => ({...prev, selectItems }));
    }

    const onSelectItem = (gasto: EntityGasto) => {
        let listGasto = configItems.selectItems;
        let findedIndex = listGasto.findIndex(row => row.Codigo === gasto.Codigo);
        findedIndex === -1 ? listGasto.push(gasto) : listGasto.splice(findedIndex, 1);
        setConfigItems(prev => ({...prev, selectItems: listGasto}));
    }

    /* =================================ConfigItems/Search====================================== */

    const onChangeSearch = (textSearch: string) => {
        setConfigItems((prev) => ({
            ...prev,
            textSearch
        }))
    }

    const onSubmitSearch = () => {
        let itemFiltered = configItems.items.filter((row) =>
            row.DatosEmpresa.RazonSocial.toLocaleLowerCase().search(configItems.textSearch.toLocaleLowerCase()) >= 0 ||
            row.DatosServicio.Servicio.toLocaleLowerCase().search(configItems.textSearch.toLocaleLowerCase()) >= 0 ||
            `${row.DatosImporte.Importe}`.toLocaleLowerCase().search(configItems.textSearch.toLocaleLowerCase()) >= 0
        )

        if (configItems.active) itemFiltered = itemFiltered.filter(row => row.Status.IdStatus === 5);

        setConfigItems((prev) => ({
            ...prev,
            filterItems: itemFiltered
        }))
    }

    /* =================================Config Approval========================================= */

    const onSubmitApproval = async (props: EntityConfigApproval) => {
        let items: EntityGasto[]
        if (props.mode === 'group') {
            items = configItems.selectItems.length === 0 ? configItems.items.filter(row => row.Status.IdStatus === 5) : configItems.selectItems;
        } else {
            items = [configModalDetail.gastoSelect];
        }

        const itemsMoreDays30 = items.filter(row => (AdapterGenerico.calculoDias(AdapterGenerico.convertStringToDate(row.FechaEmision.Fecha)) || 0) >= 30);
        if (itemsMoreDays30.length > 0)
            AdapterGenerico.createMessage(
                languageTranslate.textoAlerta, 
                `Tiene ${itemsMoreDays30.length} gasto(s) con mas de 30 días <br/> ${itemsMoreDays30.map((row, index) => `${index + 1}) ${row.DatosEmpresa.RazonSocial} - ${row.FechaEmision.Fecha} <br/>`)}`,
                'info',
                false
            );


        setConfigModalApproval((prev) => ({
            comentario: "",
            description: props.type === 'approve' ? languageTranslate.moduloGastoAprobacion.question.questApprove : languageTranslate.moduloGastoAprobacion.question.questReject,
            items: items,
            type: props.type,
            show: true,
        }))
    }

    const closeModalApproval = () => {
        setConfigModalApproval((prev) => ({
            comentario: "",
            description: '',
            items: [],
            type: "approve",
            show: false,
        }))
    }

    const onChangeMA = (value: string) => {
        setConfigModalApproval((prev) => ({
            ...prev,
            comentario: value
        }))
    }

    const onSubmitMA = async (evt: Event) => {
        evt.preventDefault();
        evt.stopPropagation();

        try {
            // Validar Comentario
            if (!configModalApproval.comentario && configModalApproval.type === 'reject') {
                AdapterGenerico.createMessage(languageTranslate.textoIncompleto, languageTranslate.moduloGastoAprobacion.validate.errorComentario, 'warning', false);
                return null;
            }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const payload: EntityRequestApproval = {
                codigoGastos: configModalApproval.items.map(row => row.Codigo),
                codigoInforme: configForm.data.Codigo,
                nota: configModalApproval.comentario,
                user: {
                    lastname: `${user.LastNameFather} ${user.LastNameMother}`,
                    name: user.Name,
                    user: user.User
                },
                type: configModalApproval.type,
                keyModule: nameStore
            };

            const response = await repository.approval(payload);
            if (!response) return;
            if (response.Gastos.length === 0 || response.Status.Rendicion.IdStatus === 1) return navigate(AdapterConfigure.LIST_GASTO_APROBACION, { replace: true });

            // Actualiza la información
            setConfigForm((prev) => ({
                ...prev,
                data: response
            }))

            setConfigItems((prev) => ({
                ...prev,
                items: response.Gastos || [],
                filterItems: response.Gastos || [],
            }));

            // Cierra los modales dependiendo el modo
            if (configModalDetail.show)
                closeModalDetail();
            else
                disabledModeSelectItem()

            // Cierra el modal de confirmación
            closeModalApproval();
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    /** ====================================Config Notas======================================= */

    const openModalNotes = () => {
        setConfigModalNotes((prev) => ({
            ...prev,
            show: true,
            titulo: '',
            comentario: ''
        }))
    }

    const closeModalNotes = () => {
        setConfigModalNotes((prev) => ({
            ...prev,
            show: false,
            titulo: '',
            comentario: ''
        }))
    }

    const onChangeMN = (name: 'comentario' | 'titulo', value: string) => {
        setConfigModalNotes((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const onSubmitMN = async (evt: Event) => {
        evt.preventDefault();
        evt.stopPropagation();

        // Validaciones
        /*if (!configModalNotes.titulo) {
            AdapterGenerico.createMessage(languageTranslate.textoIncompleto, languageTranslate.moduloGastoAprobacion.validate.errorTitulo, 'warning', false);
            return null;
        }*/
        if (!configModalNotes.comentario) {
            AdapterGenerico.createMessage(languageTranslate.textoIncompleto, languageTranslate.moduloGastoAprobacion.validate.errorDescripcion, 'warning', false);
            return null;
        }

        try {

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const payload: EntityRequestNote = {
                codigo: configForm.data.Codigo,
                nota: {
                    titulo: configModalNotes.titulo,
                    descripcion: configModalNotes.comentario
                },
                user: {
                    user: user.User,
                    lastname: `${user.LastNameFather} ${user.LastNameMother}`,
                    name: user.Name
                }
            }

            const response = await repository.addNote(payload);
            if (!response) return;

            // Actualiza la información
            setConfigForm((prev) => ({
                ...prev,
                data: response
            }))

            // Cierra el modal de notas
            closeModalNotes();
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    return ({
        init,

        // Config Form
        languageTranslate,
        configForm,

        // Config Modal Detail
        configModalDetail,
        openModalDetail,
        closeModalDetail,
        nextGastoMD,
        prevGastoMD,

        // Config Search
        configItems,
        onChangeSearch,
        onSubmitSearch,

        // Config Mode Select
        onSelectAllItem,
        activeModeSelectItem,
        disabledModeSelectItem,
        onSelectItem,

        // Config Approval
        onSubmitApproval,
        closeModalApproval,
        onChangeMA,
        onSubmitMA,
        configModalApproval,

        // Config Notes
        closeModalNotes,
        openModalNotes,
        onChangeMN,
        onSubmitMN,
        configModalNotes,
    });
}