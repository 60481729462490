import { InputSelect } from "../../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../../shared/Components/ElementModalCostume";
import { EntityModalFiltro } from "../../Domain/EntityUtils";

interface ContainerProps {
    ModalFiltroConfig: EntityModalFiltro;
    closeModal(): void;
    onChangeModalFiltro(name: string, value: any): void;
    onSubmitModalFiltro(): Promise<void>;
}

const OptionsSelect = [
    { label: 'Abierto / En Aprobación', value: 'pending' },
    { label: 'Aprobados', value: 'history' },
]

export const ViewModalFiltro = (props: ContainerProps) => {
    if (!props.ModalFiltroConfig.show) return null;

    return (
        <ElementModalCostume
            show={props.ModalFiltroConfig.show}
            title={'Filtros'}
            closeModal={props.closeModal}
            height="100px"
        >
            <div>
                <InputSelect
                    label="Estado"
                    name="statusSelected"
                    onChange={props.onChangeModalFiltro}
                    values={props.ModalFiltroConfig}
                    options={OptionsSelect}
                />
                
                <button className="btn btn-primary w-100" onClick={() => props.onSubmitModalFiltro()}> Buscar </button>
            </div>
        </ElementModalCostume>
    )
}