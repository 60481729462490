import React from 'react';
import ElementModalCostume from '../../../../../shared/Components/ElementModalCostume';
import { InputCheck, InputDate, InputSelect, InputText } from '../../../../../shared/Components/ElementInputsCostume';
import { EntityConfigForm, EntityConfigModalAutocomplete, EntityFormModalAutocomplete } from '../../Domain/EntityUtilts';
import { EntityGenericDetailForm } from '../../../../../shared/Domain/EntityGenericDetailForm';
import { EntityRequestGenericForm } from '../../Domain/EntityRequest';
import { AdapterGenerico } from '../../../../../shared/Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../../../../../shared/Infraestructure/LanguageTranslate';
import { AdapterConfigure } from '../../Infraestructure/AdapterConfigure';

interface ContainerProps {
    configModalAutocomplete: EntityConfigModalAutocomplete;
    formModalAutocomplete: EntityGenericDetailForm<EntityFormModalAutocomplete>;
    closeModalAutocompletar: () => void;
    onChangeMA: (name: string, value: string) => void;
    onSubmitMD: (evt: Event) => void;
    configForm: EntityConfigForm;
    formikForm: EntityGenericDetailForm<EntityRequestGenericForm>;
}

const languageTranslate = LanguageTranslate();

export const ModalAutocompletar = (props: ContainerProps) => {
    const minDate = AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(props.formikForm.values.Periodo?.FechaInicio), 6);
    const maxDate = AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(props.formikForm.values.Periodo?.FechaFinal), 6);

    return (
        <ElementModalCostume
            closeModal={props.closeModalAutocompletar}
            show={props.configModalAutocomplete.show}
            title={languageTranslate.moduloTareo.detail.textTitleModalAutocompletar}
        >
            <div className='ModalAutocompletar p-2'>
                <InputSelect
                    label={languageTranslate.moduloTareo.detail.textOT}
                    name='OT'
                    onChange={props.onChangeMA}
                    values={props.formModalAutocomplete.values}
                    isRequired
                    options={props.configForm.dataOptions.ot}
                />

                <InputSelect
                    label={languageTranslate.moduloTareo.detail.textDisciplina}
                    name='Disciplina'
                    onChange={props.onChangeMA}
                    values={props.formModalAutocomplete.values}
                    isRequired
                    options={AdapterConfigure.LIST_OT_BISA.includes(props.formModalAutocomplete.values.OT?.dataComplete.Code || '') ?
                        props.configForm.dataOptions.disciplina.filter(row => AdapterConfigure.KEY_DISCIPLINA_GENERAL !== row.dataComplete.Key)
                        :
                        props.configForm.dataOptions.disciplina.filter(row => AdapterConfigure.KEY_DISCIPLINA_GENERAL === row.dataComplete.Key)
                    }
                />

                <InputText
                    label={languageTranslate.moduloTareo.detail.textHoras}
                    name="Horas"
                    onChange={props.onChangeMA}
                    values={props.formModalAutocomplete.values}
                    type="time"
                    isRequired
                />

                <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <InputDate
                        label={languageTranslate.moduloTareo.detail.textFechaInicio}
                        name='FechaInicio'
                        onChange={props.onChangeMA}
                        values={props.formModalAutocomplete.values}
                        isRequired
                        minDate={minDate}
                        maxDate={maxDate}
                    />

                    <InputDate
                        label={languageTranslate.moduloTareo.detail.textFechaFinal}
                        name='FechaFinal'
                        onChange={props.onChangeMA}
                        values={props.formModalAutocomplete.values}
                        isRequired
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </div>

                <InputCheck
                    label={languageTranslate.moduloTareo.detail.textExcluyeSabado}
                    name='ExcluyeSabado'
                    onChange={props.onChangeMA}
                    values={props.formModalAutocomplete.values}
                    isHorizontal
                />

                <InputCheck
                    label={languageTranslate.moduloTareo.detail.textExcluyeDomingo}
                    name='ExcluyeDomingo'
                    onChange={props.onChangeMA}
                    values={props.formModalAutocomplete.values}
                    isHorizontal
                />

                <InputCheck
                    label={languageTranslate.moduloTareo.detail.textExcluyeFeriado}
                    name='ExcluyeFeriado'
                    onChange={props.onChangeMA}
                    values={props.formModalAutocomplete.values}
                    isHorizontal
                />

                <div className='d-flex' style={{ gap: 10 }}>
                    <button className='btn btn-dark w-50' onClick={props.closeModalAutocompletar}>{languageTranslate.btnCancelar}</button>
                    <button className='btn btn-primary w-50' onClick={(evt: any) => props.onSubmitMD(evt)}>{languageTranslate.btnGuardar}</button>
                </div>
            </div>
        </ElementModalCostume>
    )
}