import "./Style.scss";
import { ElementBreadCrumb } from "../../../shared/Components/ElementBreadCrumb";
import { ElementEmptyList } from "../../../shared/Components/ElementEmptyList";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntityMain } from "../Domain/EntityMain";
import ElementModalCostume from "../../../shared/Components/ElementModalCostume";
import { InputSelect, } from "../../../shared/Components/ElementInputsCostume";
import { Carousel, FormControl } from "react-bootstrap";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import ElementModalPDF from "../../../shared/Components/ElementModalPDF";

interface PropsViewMain {
  data: EntityMain[];
  dataPais: Array<{ _id: string; codigo: string; nombre: string }>;
  navBar: Array<{ text: string; navigate: boolean; path: string }>;

  buscarText: string;
  setBuscarText: (value: string) => void;

  onClickItem: (item: any) => void;

  modalItem: { item: EntityMain | null; showModal: boolean };
  onCloseModalItem: () => void;

  onClickAbrirResumen: (item: EntityMain | null) => void;
  onClickAbrirDocumento: (item: EntityMain | null) => void;
  onClickVerRelacionados: () => void;

  modalPDF: {
    type: string | null;
    item: EntityMain | null;
    src: string | null;
    showModal: boolean;
  };
  onCloseModalPDF: () => void;

  modalRelacionados: {
    data: EntityMain[];
    indexCarousel: number;
    showModal: boolean;
  };
  onCloseModalRelacionados: () => void;
  onChangeIndexCarouselModalRelacionados: (index: number) => void;

  modalFiltros: { showModal: boolean };
  formFiltros: { values: any; touched: any; errors: any; handleBlur: Function };
  onShowModalFiltros: (showModal: boolean) => void;
  onChangeValueFormFiltros: (name: string, value: any) => void;
  onClickBuscarFiltros: () => void;
}

export const ViewMain = (props: PropsViewMain) => {
  const languageTranslate = LanguageTranslate();

  return (
    <div className="ViewGenericList">
      <div className="container-fluid">
        {/** BLOQUE DE BREADCRUMB */}
        <ElementBreadCrumb
          list={[...languageTranslate.moduloDocucobra.navBar, ...props.navBar]}
        />
        <div className="row">
          <div className="col-12 pb-3">
            <FormControl
              size={"sm"}
              placeholder={languageTranslate.moduloDocucobra.textoInputBuscar}
              value={props.buscarText}
              onChange={(evt) => props.setBuscarText(evt.target.value)}
            />
          </div>
        </div>
        {props.data.length === 0 && (<ElementEmptyList className="data-empty" text={languageTranslate.moduloGenericList.item.textoListaVacia} />)}

        <div className="row">
          {props.data
            .filter((row) => !!row.Name.toUpperCase().includes(props.buscarText.toUpperCase()))
            .map((row) => (
              <div className="col-4" key={row._id}>
                <div
                  className="card text-center shadow"
                  style={{ height: "100px", cursor: "pointer", marginBottom: "20px", }}
                  onClick={() => { props.onClickItem(row); }}
                >
                  <div className="card-body" style={{ padding: "5px" }}>
                    <div className="card-text">
                      {row.Type === "FOLDER"
                        ? (<i className="fa-solid fa-folder-open" style={{ fontSize: "40px", paddingBottom: "10px", paddingTop: "10px", color: "wheat", }} />)
                        : (<i className="fa-solid fa-file" style={{ fontSize: "40px", paddingBottom: "10px", paddingTop: "10px", color: "red", }} />
                        )}
                      <div className="row">
                        <div className="col-12 text-truncate" style={{ fontSize: "8px", fontWeight: "bold" }}>
                          {row.Name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <ElementModalCostume
          show={props.modalItem.showModal}
          title={`${languageTranslate.moduloDocucobra.textoTituloArchivo}: ${props.modalItem.item?.Name}`}
          closeModal={() => {
            props.onCloseModalItem();
          }}
          height="42%"
        >
          <div className="row">
            <div className="col-12">
              <p
                style={{
                  display: "-webkit-box",
                  maxWidth: "100%",
                  WebkitLineClamp: "4",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {props.modalItem.item?.Description || ""}
              </p>
            </div>
            <div className="col-12">
              <p>
                {new Date(
                  props.modalItem.item?.DateRegistry || "01/01/1900"
                ).toLocaleDateString("en-GB")}{" "}
                {new Date(
                  props.modalItem.item?.DateRegistry || "01/01/1900"
                ).toLocaleTimeString("en-GB")}
              </p>
            </div>
            <button
              className="btn btn-secondary text-white col-12"
              onClick={() => props.onClickAbrirResumen(props.modalItem.item)}
            >
              {languageTranslate.moduloDocucobra.textoAbrirResumen}
            </button>
            <button
              className="btn btn-primary col-12 mt-3"
              onClick={() => props.onClickAbrirDocumento(props.modalItem.item)}
            >
              {languageTranslate.moduloDocucobra.textoAbrirDocumento}
            </button>
            <button
              className="btn btn-success text-white col-12 mt-3"
              onClick={() => props.onClickVerRelacionados()}
            >
              {languageTranslate.moduloDocucobra.textoVerRelacionados}
            </button>
          </div>
        </ElementModalCostume>

        <ElementModalCostume
          show={props.modalRelacionados.showModal}
          title={`${languageTranslate.moduloDocucobra.textoTituloArchivosRelacionados} - ${props.modalItem.item?.Name}`}
          closeModal={() => {
            props.onCloseModalRelacionados();
          }}
          height="100"
        >
          <Carousel
            activeIndex={props.modalRelacionados.indexCarousel}
            onSelect={(index) =>
              props.onChangeIndexCarouselModalRelacionados(index)
            }
            variant="dark"
            fade
            style={{ height: "300px" }}
            controls={false}
          >
            {AdapterGenerico.chunkedArray<EntityMain>(
              props.modalRelacionados.data,
              5
            ).map((row) => (
              <Carousel.Item>
                {row.map((subrow) => (
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="card shadow"
                            style={{ paddingLeft: "5px", cursor: "pointer" }}
                            key={subrow.Code}
                          >
                            <div className="row">
                              <div className="col-9">
                                <span
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {subrow.Code} - {subrow.Name}
                                </span>
                              </div>
                              <div className="col-3 text-center">
                                <button
                                  className="btn btn-sm btn-light"
                                  onClick={() =>
                                    props.onClickAbrirDocumento(subrow)
                                  }
                                >
                                  <i className="fa-solid fa-eye"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-2"></div>
                  </div>
                ))}
              </Carousel.Item>
            ))}
          </Carousel>
        </ElementModalCostume>

        <ElementModalPDF
          show={props.modalPDF.showModal}
          title={`${props.modalPDF.type} - ${props.modalPDF.item?.Name}`}
          onCloseModal={() => {
            props.onCloseModalPDF();
          }}
          src={props.modalPDF.src}
        />

        <ElementModalCostume
          show={props.modalFiltros.showModal}
          title={languageTranslate.moduloDocucobra.textoTituloFiltros}
          closeModal={() => {
            props.onShowModalFiltros(false);
          }}
          height="50px"
        >
          <div className="row pb-3 ViewInspeccionListar">
            <div className="col-12">
              <InputSelect
                label={languageTranslate.moduloDocucobra.textoFiltroPais}
                name={"pais"}
                isMulti={true}
                onChange={props.onChangeValueFormFiltros}
                values={props.formFiltros.values}
                options={props.dataPais.map((row) => ({
                  label: row.nombre,
                  value: row,
                }))}
              />
            </div>
          </div>
          <div className="row">
            <button
              className="btn btn-primary text-white col-12"
              onClick={() => props.onClickBuscarFiltros()}
            >
              {languageTranslate.moduloDocucobra.textoBuscar}
            </button>
          </div>
        </ElementModalCostume>

        <div className="position-fixed search bg-primary">
          <i
            className="fa-solid fa-filter"
            style={{ fontSize: 20 }}
            onClick={() => props.onShowModalFiltros(true)}
          />
        </div>

        <div
          className="position-fixed reload bg-primary"
          onClick={() => props.onClickBuscarFiltros()}
        >
          <i
            className="fa-sharp fa-solid fa-arrow-rotate-right"
            style={{ fontSize: 20 }}
          />
        </div>
      </div>
    </div>
  );
};
