import { LIST_LANGUAGE } from "../../../Language";
import { EntityLanguage } from "../Domain/EntityLanguage";
import { AdapterStorage } from './AdapterStorage';

const DEFAULT_LANGUAGE = 'es';
const LANGUAGE_NAME_FROM_LOCAL_STORAGE = 'language';

export const LanguageTranslate = ():EntityLanguage => {
    const { language } = AdapterStorage.get([LANGUAGE_NAME_FROM_LOCAL_STORAGE]);
    const languageTranslate: EntityLanguage = LIST_LANGUAGE[language || DEFAULT_LANGUAGE];
    return languageTranslate;
};