import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { EntityMenu } from '../../../shared/Domain/EntityMenu';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { changeSaludo, hideIconMenu } from '../../../shared/Infraestructure/SliceGenerico';

export const Controller = () => {
    let { auth: { menu } } = useSelector((state: RootState) => state);
    const languageTranslate = LanguageTranslate();
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();

    menu = menu.filter(row => row.esOpcion && !row.padre);

    const init = async () => {
        dispatch(hideIconMenu());
        dispatch(changeSaludo(true));
    };

    const onClickAyuda = async () => {
        let confirmacion: boolean = await AdapterGenerico.createMessage(languageTranslate.moduloMain.textoTituloPreguntaAyuda, languageTranslate.moduloMain.textoDescripcionPreguntaAyuda, 'question', true);
        if (!confirmacion) return;
        window.open('https://www.cobraperu.com.pe/helpcenter/sistema-sgso/', '_blank');
    };

    const onClickReport = async () => {
        let confirmacion: boolean = await AdapterGenerico.createMessage(languageTranslate.moduloMain.textoTituloPreguntaReporte, languageTranslate.moduloMain.textoDescripcionPreguntaReporte, 'question', true);
        if (!confirmacion) return;
        window.open('https://www.cobraperu.com.pe/glpi/', '_blank')
    };

    const onClickMenu = (element: EntityMenu) => {
        navigate(`/${element.ruta}`, { replace: true });
    };

    return {
        init,
        menu,

        onClickAyuda,
        onClickReport,
        onClickMenu,
    };
}