const SCHEMA: string = process.env.REACT_APP_MASTER_MAIN_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MASTER_MAIN_ENTITY || '';
const LIST_GASTO_APROBACION: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_APROBADOR_GASTO_APROBACION}`;
const LIST_HISTORICO_GASTO: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_CAJACHICA}/${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_APROBADOR_HISTORICO_GASTOS}`;
// /main/approvals/gasto-aprobacion
export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    LIST_GASTO_APROBACION,
    LIST_HISTORICO_GASTO
};