import { EntityOT } from "../../../../../shared/Domain/Catalogos/EntityOT";
import { EntitySelectBase } from "../../../../../shared/Domain/Catalogos/EntitySelectBase"
import { EntityDatosTarea, EntityProyectos } from "../../../../../shared/Domain/Catalogos/EntityProyectos";

export type EntityRequestGenericForm =  {
  Codigo: string;
  OT: EntitySelectBase<EntityOT>;
  Proyectos: EntitySelectBase<EntityProyectos>;
  Tarea: EntitySelectBase<EntityDatosTarea>
  Empresa: {
    CDEmpresa: string;
    Empresa: string;
    Grupo: string;
  };
  Delegacion: {
    Code: string;
    Codigo: string;
    Name: string;
  };
  Pais: {
    CDPais: string;
    Code: string;
    Name: string;
  };
  Personal: {
    TipoDocumento: string;
    Identificacion: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    Nombres: string;
  };
  // Divisa: EntitySelectBase<EntityMoneda>;
  Importe: number;
  Banco: string;
  CuentaBancaria: string;
  CuentaInterbancaria: string;
  Nota: string;
  User: {
    IdUser: number;
    User: string;
    LastName: string;
    Name: string;
    Perfil: string;
  };
  Estado: {
    IdStatus: number;
    Status: string;
  };
  EstadoNav: {
    IdStatus: number;
    Status: string;
  };
  Flujo: {
    Id: number
    Codigo: string
    Code: string
    Name: string
    Descripcion: string
    Aprobacion: Array<{
      IdNivel: number
      Nivel: number
      Monto: number
      Status: {
        IdStatus: number
        Status: string
      }
      Perfil: {
        IdPerfil: number
        Perfil: string
        Initial: string
      }
      Aprobador: {
        LastNameFather: string
        LastNameMother: string
        Name: string
        Identificacion: string
        Status: {
          IdStatus: number
          Status: string
        }
        mail: string
        name: string
        FullName: string
      }
      StatusSendMail: boolean
    }>
  };
  Aprobacion: {
    Nivel: number
  };
  Notas: any[];
  Bitacora: any[];
  Files: {
    value: any;
    type: 'local' | 'server';
    valueToShow: string;
  }[];
  Descripcion: string;
}

export const initEntityRequestGenericForm: EntityRequestGenericForm = {
  Codigo: '',
  OT: null as any,
  Proyectos: null as any,
  Tarea: null as any,
  Empresa: {
    CDEmpresa: "",
    Empresa: "",
    Grupo: ""
  },
  Delegacion: {
    Code: "",
    Codigo: "",
    Name: ""
  },
  Pais: {
    CDPais: "",
    Code: "",
    Name: ""
  },
  Personal: {
    TipoDocumento: '',
    Identificacion: '',
    ApellidoPaterno: '',
    ApellidoMaterno: '',
    Nombres: ''
  },
  Importe: 0,
  Banco: "",
  CuentaBancaria: "",
  CuentaInterbancaria: "",
  Nota: "",
  User: {
    IdUser: 0,
    User: "",
    LastName: "",
    Name: "",
    Perfil: ""
  },
  Estado: {
    IdStatus: 0,
    Status: 'Creacion'
  },
  EstadoNav: {
    IdStatus: 0,
    Status: ""
  },
  Flujo: {
    Id: 0,
    Codigo: "",
    Code: "",
    Name: "",
    Descripcion: "",
    Aprobacion: []
  },
  Aprobacion: {
    Nivel: 0
  },
  Notas: [],
  Bitacora: [],
  Files: [],
  Descripcion: ''
}

export type EntityRequestFormService = {
  Codigo: string;
  DatosPersonal: {
    TipoDocumento: string
    Identificacion: string
    ApellidoPaterno: string
    ApellidoMaterno: string
    Nombres: string
  }
  DatosEmpresa: {
    CodEmpresa: string
    CDEmpresa: string
    Empresa: string
    Grupo: string
  }
  DatosImporte: {
    Moneda: {
      Codigo: string
      Moneda: string
      Name: string
      Descripcion: string
    }
    Solicitud: {
      Importe: number
      IGV: number
      ImporteIGV: number
      ImporteTotal: number
    }
    Inicial: {
      Importe: number
      ImportePendiente: number
    }
  }
  Descripcion: string
  DatosBanco: {
    Banco: string
    NroCuenta: string
    CuentaInterbancaria: string
  }
  DatosTrabajo: {
    Delegacion: {
      Code: string
      Codigo: string
      Delegacion: string
    }
    OT: {
      Code: string
      Codigo: string
      OT: string
    }
  }
  DatosPais: {
    CDPais: string
    Code: string
    Name: string
  }
  Users: {
    IdUser: number
    User: string
    LastName: string
    Name: string
    Perfil: string
  }
  Files: {
    value: any;
    type: 'local' | 'server';
    valueToShow: string;
  }[];
}

export type EntityRequestFormServiceSendApproval = {
  paramsSend: {
    idSolicitud: number;
    flujo: {
      Id: number;
      Code: string;
      Codigo: string;
      Name: string;
      Descripcion: string;
      Aprobacion: any[];
    }
    user: {
      IdUser: number;
      User: string;
      LastName: string;
      Name: string;
      Perfil: string;
    }
  },
  extraData: {
    Codigo: string;
  }
}

export interface EntityRequestAddNote {
  paramsSend: {
    codigo: string;
    nota: string;
  }
  extraConfig: {
    nameStore: 'SolicitudAnticipo' | ''
  }
}

export type EntityRequestCancelFlow = {
  codigo: string;
  user: {
    IdUser: number;
    User: string;
    LastName: string;
    Name: string;
    Perfil: string;
  };
}