import { InputTextArea } from "../../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../../shared/Components/ElementModalCostume"
import { ElementTableCostume } from "../../../../shared/Components/ElementTableCostume";
import { AdapterGenerico } from "../../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { EntityConfigModal } from "../../Domain/EntityUtils";

interface ContainerProps {
    closeModal(): void;
    onSubmit(evt: Event): void;
    onChange(name: string, value: string): void; 
    configModal: EntityConfigModal;
}

const languageTranslate = LanguageTranslate();

export const ViewModal = (props: ContainerProps) => {

    return (
        <ElementModalCostume
            closeModal={props.closeModal}
            show={props.configModal.show}
            title={props.configModal.title}
            height={ props.configModal.key === 'reject' ? '380px' : ( props.configModal.key === 'notes' ? "320px" : '')}
        >
            {
                ['reject', 'notes'].includes(props.configModal.key) ?
                <>
                    <div>
                        <p className="text-danger">{props.configModal.description}</p>
                        <InputTextArea
                            label="Comentario"
                            name="comment"
                            onChange={props.onChange}
                            values={props.configModal}
                            isRequired
                        />
                    </div>
                    <div className="w-100 d-flex" style={{ gap: 10 }}>
                        <button className="btn btn-primary" style={{ width: '50%' }} onClick={props.closeModal}>{languageTranslate.btnCancelar}</button>
                        <button className={`btn ${props.configModal.key === 'reject' ? 'btn-danger' : 'btn-primary'}`} style={{ width: '50%' }} onClick={(evt: any) => props.onSubmit(evt)}>{props.configModal.key === 'reject' ? languageTranslate.moduloGenericDetail.btnTextDevolver : languageTranslate.btnGuardar}</button>
                    </div>
                </>
                : null
            }
            {
                ['product'].includes(props.configModal.key) ?
                <>
                    <div className="group-detail">
                        <label>Requerimiento</label>
                        <p>
                            <span className="font-normal">Número: </span>
                            <span className="text-muted">{ props.configModal.payload?.dataComplete.Requerimiento.Codigo }</span>
                        </p>
                        <p>
                            <span className="font-normal">OT: </span>
                            <span className="text-muted">{ props.configModal.payload?.dataComplete.Requerimiento.DatosTrabajo?.OT.Codigo } - { props.configModal.payload?.dataComplete.Requerimiento.DatosTrabajo?.OT.OT }</span>
                        </p>
                    </div>
                    <div className="group-detail">
                        <label>Producto</label>
                        <p>
                            <span className="font-normal">Descripción: </span>
                            <span className="text-muted">{ props.configModal.payload?.descripcion }</span>
                        </p>
                        <p>
                            <span className="font-normal">Cantidad: </span>
                            <span className="text-muted">{ props.configModal.payload?.cantidad }</span>
                        </p>
                        <p>
                            <span className="font-normal">Unidad: </span>
                            <span className="text-muted">{ props.configModal.payload?.unidad }</span>
                        </p>
                        <p>
                            <span className="font-normal">Precio Unitario: </span>
                            <span className="text-muted">{ props.configModal.payload?.precioUnitario }</span>
                        </p>
                        <p>
                            <span className="font-normal">Sub total: </span>
                            <span className="text-muted">{ props.configModal.payload?.subTotal }</span>
                        </p>

                    </div>
                    <div className="w-100 d-flex">
                        <button className="btn btn-primary" style={{ width: '100%' }} onClick={props.closeModal}>{languageTranslate.btnRegresar}</button>
                    </div>
                </>
                : null
            }

            {
                ['resume-factura'].includes(props.configModal.key) ?
                <>
                    <label className="label-custom-modal">
                        Valor ejecutado ({
                            AdapterGenerico.formatoDinero((props.configModal.payload?.Valorizaciones || []).reduce((prev: number, current: any) => {
                                prev += (current?.Total || 0);
                                return prev;
                            }, 0))
                        })
                    </label>
                    <div className="mb-4" style={{ height: 200, overflowY: 'scroll' }}>
                        <ElementTableCostume
                            className="TableNoWrap overflow-y-scroll"
                            listHeader={[
                                { key: 'CodeValorizacion', label: 'Código' },
                                { key: 'Periodo.dato', label: 'Periodo' },
                                {
                                    key: 'Importe', label: 'Importe', render: (text: any, data, index) => AdapterGenerico.formatoDinero(text)
                                },
                                {
                                    key: 'Descuento', label: 'Descuento', render: (text: any, data, index) => AdapterGenerico.formatoDinero(text)
                                },
                                {
                                    key: 'Total', label: 'Total', render: (text: any, data, index) => AdapterGenerico.formatoDinero(text)
                                },
                                { key: 'Observacion', label: 'Observación' }
                            ]}
                            listData={(props.configModal.payload?.Valorizaciones || [])}
                        />
                    </div>

                    <label className="label-custom-modal">
                        Valor facturado ({
                            AdapterGenerico.formatoDinero(((props.configModal.payload?.Valorizaciones || []).flatMap((row: any) => row.FacturasAsociados)).reduce((prev: number, current: any) => {
                                prev += (current?.importeAsociado || 0);
                                return prev;
                            }, 0))
                        })
                    </label>
                    <div style={{ height: 200, overflowY: 'scroll' }}>
                        <ElementTableCostume
                            className="TableNoWrap overflow-y-scroll"
                            listHeader={[
                                { key: 'Factura', label: 'Factura' },
                                { key: 'NroDocumento', label: 'NroDocumento' },
                                {
                                    key: 'importeAsociado', label: 'Importe asociado', render: (text: any, data, index) => AdapterGenerico.formatoDinero(text)
                                }
                            ]}
                            listData={((props.configModal.payload?.Valorizaciones || []).flatMap((row: any) => row.FacturasAsociados))}
                        />
                    </div>
                </>
                : null
            }
        </ElementModalCostume>
    )
}