import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityPendingWorkflow, initEntityPendingWorkflow } from "../Domain/EntityPendingWorkflow";
import { EntityRequestPendingWorkflow } from "../Domain/EntityRequest";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async getPendingWorkflow(params: EntityRequestPendingWorkflow): Promise<EntityPendingWorkflow> {
        let url: string = `${this.urlBase}/navision/selectWorkFlowPendiente`;
        let urlRendicion: string = `${this.urlBaseGastos}/Rendicion/Informe/find`;

        const paramsRendicion = {
            "filter": {
                "Flujo.Aprobacion": { "$elemMatch": { "Aprobador.Identificacion": params.identificacion } },
                "$expr": {
                    "$eq": [{ "$sum": ["$Aprobacion.Nivel", 1]}, { "$arrayElemAt": ["$Flujo.Aprobacion.Nivel", { "$indexOfArray": ["$Flujo.Aprobacion.Aprobador.Identificacion", params.identificacion] }] }]
                },
                "Status.Rendicion.IdStatus": 5 
            },
            "fields": {},
            "count": "Codigo"
        }

        let [response, responseRendicion] = await Promise.allSettled([
            this.service.call<any>("POST", url, JSON.stringify(params), 'bearer', "json", 'json', {}, 0),
            this.service.call<any>("POST", urlRendicion, JSON.stringify(paramsRendicion), 'bearer', "json", 'json', { "request-decrypt-response": true }, 0)
        ])
        
        if (response.status === 'fulfilled')
            response = response.value;

        const entriesObject = Object.entries(response);
        let newResult = initEntityPendingWorkflow;


        if (responseRendicion.status === 'fulfilled' && responseRendicion.value.length > 0)
            newResult.GIAAPP_APPROVALS_GASTOAPROBACCION = responseRendicion.value[0]?.Codigo || 0;

        entriesObject.forEach(row => {
            switch (row[0]) {
                case 'rqSistema': newResult.GIAAPP_APPROVALS_SYSTEM_REQUIREMENT = row[1] as number; break;
                case 'rqCompra': newResult.GIAAPP_APPROVALS_PURCHA_SEREQUIREMENT = row[1] as number; break;
                case 'preOrden': newResult.GIAAPP_APPROVALS_COMPARATIVE = row[1] as number; break;
                case 'preRegistro': newResult.GIAAPP_APPROVALS_INVOICE = row[1] as number; break;
                case 'solicitudCajaChica': newResult.GIAAPP_APPROVALS_PETTY_CASH_REQUEST = row[1] as number; break;
                case 'rendicionCajaChicaReembolso': newResult.GIAAPP_APPROVALS_SURRENDER_PETTY_CASH_EXPENSES = row[1] as number; break;
                case 'hojaPeticion': newResult.GIAAPP_APPROVALS_REQUEST_FORM = row[1] as number; break;
                case 'condicionLaboral': newResult.GIAAPP_APPROVALS_WORKING_CONDITIONS = row[1] as number; break;
                case 'tareo': newResult.GIAAPP_APPROVALS_TAREO = row[1] as number; break;
                case 'ausencia': newResult.GIAAPP_APPROVALS_AUSENCIA = row[1] as number; break;
            }
        })
        return newResult;
    }

}