import { Buffer } from 'buffer';
import { AdapterStorage } from './AdapterStorage';

type TypeMethodService = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
type TypeRequestService = 'json' | 'text' | 'form';
type TypeResponseService = 'json' | 'text' | 'blob';
type TypeAuthService = 'basic' | 'basicFiles' | 'bearer';

export class AdapterService {

    public async call<T>(method: TypeMethodService = 'GET', url: string, body: string | FormData | undefined, auth: TypeAuthService = 'bearer', typeRequest: TypeRequestService = 'json', typeResponse: TypeResponseService = 'json', adicionalHeaders: Object, tries: number = 2): Promise<Array<T> | T | null> {
        try {
            if (!window.navigator.onLine) { throw Error('No posee conexión a internet'); }
            // if (actualizandoToken && !authBasic) { throw Error('Se esta actualizando el token de seguridad, vuelva a ejecutar su proceso en unos segundos'); }

            let { token }: { token: string; } = AdapterStorage.get('token');

            let headers = new Headers({
                'Authorization': auth === 'basic' ?
                    `Basic ${Buffer.from(process.env.REACT_APP_AUTH_BASIC_USER + ':' + process.env.REACT_APP_AUTH_BASIC_PASS).toString('base64')}` :
                    (
                        auth === 'bearer' ?
                        `Bearer ${token}` :
                        `Basic ${Buffer.from(process.env.REACT_APP_AUTH_BASIC_USER_FILES + ':' + process.env.REACT_APP_AUTH_BASIC_PASS_FILES).toString('base64')}`
                    ),
            });

            switch (typeRequest) {
                case 'json':
                    headers.append('Content-Type', 'application/json');
                    break;
                case 'text':
                    headers.append('Content-Type', 'text/plain');
                    break;
                default:
                    break;
            }

            if (typeof adicionalHeaders === 'object') { if (!Array.isArray(adicionalHeaders)) { for (let row of Object.entries(adicionalHeaders)) { headers.set(row[0], row[1]); } } }

            let options: RequestInit = { method, headers };
            if (method !== 'GET') { Object.assign(options, { body }); }

            let data = await this.__exec(url, typeResponse, options, tries);
            return data;
        } catch (error) {
            throw error;
        }
    }

    public bgCall<T>(method: TypeMethodService = 'GET', url: string, body: string | FormData, auth: TypeAuthService = 'bearer', typeRequest: TypeRequestService = 'json', typeResponse: TypeResponseService = 'json', adicionalHeaders: Object, tries: number = 2): Promise<Array<T> | T | null> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!window.navigator.onLine) { reject(new Error('No posee conexión a internet')); return null; }
                // if (actualizandoToken && !authBasic) { reject('Se esta actualizando el token de seguridad, vuelva a ejecutar su proceso en unos segundos'); return null; }

                let { token }: { token: string; } = AdapterStorage.get('token');

                let headers = {
                    'Authorization': auth === 'basic' ? 
                        `Basic ${Buffer.from(process.env.REACT_APP_AUTH_BASIC_USER + ':' + process.env.REACT_APP_AUTH_BASIC_PASS).toString('base64')}` :
                        (
                            auth === 'bearer' ?
                            `Bearer ${token}` :
                            `Basic ${Buffer.from(process.env.REACT_APP_AUTH_BASIC_USER_FILES + ':' + process.env.REACT_APP_AUTH_BASIC_PASS_FILES).toString('base64')}`
                        ),
                };

                switch (typeRequest) {
                    case 'json':
                        Object.assign(headers, { 'Content-Type': 'application/json' });
                        break;
                    case 'text':
                        Object.assign(headers, { 'Content-Type': 'text/plain' });
                        break;
                    default:
                        break;
                }

                if (typeof adicionalHeaders === 'object') { if (!Array.isArray(adicionalHeaders)) { Object.assign(headers, { ...adicionalHeaders }); } }

                let options: RequestInit = { method, headers };
                if (method !== 'GET') { Object.assign(options, { body }); }

                let workerBg: Worker = new Worker(new URL('./WorkerService.ts', import.meta.url));

                workerBg.onmessage = async (evt) => {
                    workerBg.terminate();
                    if (evt.data.error) { reject(new Error(evt.data.error)); }
                    else if (evt.data.logout || evt.data.refresh) {
                        resolve(null);
                    }
                    else if (evt.data.response) { resolve(evt.data.response); }
                    else { resolve(null); }

                };
                workerBg.onerror = (evt) => { workerBg.terminate(); reject(evt.error); };
                workerBg.onmessageerror = (evt) => { workerBg.terminate(); reject(evt.data); }

                workerBg.postMessage(JSON.parse(JSON.stringify({ url, typeRequest, typeResponse, options, tries })));
            } catch (error) {
                reject(error);
            }
        });
    }

    private async __exec(url: string, type: TypeResponseService, options: RequestInit, tries: number): Promise<any> {
        try {
            let result: any = null;
            let res: Response = await fetch(url, options);
            if (!res.ok) {
                try { result = await res.json(); }
                catch (error) { throw new Error(res.statusText); }
                if (Reflect.has(result, "Error")) {
                    switch (res.status) {
                        case 400:
                        case 500:
                            tries = 0;
                            throw Error(result.Error.error);
                        case 401:
                            tries = 0;
                            throw Error(result.errorDescription);
                        case 412:
                            tries = 0;
                            throw new Error('Cambio de Token');
                        case 403:
                            tries = 0;
                            return null;
                        default:
                            break;
                    }
                    throw new Error(result.errorDescription);
                }
                else if (Reflect.has(result, "errorDescription")) { throw new Error(result.errorDescription) }
                else if (Reflect.has(result, "message")) { throw new Error(result.message) }
                else { throw new Error('Servicio Web no accesible'); }
            }
            result = await res[type]();
            return result;
        } catch (error) {
            console.error("error: ", error);
            if (tries > 0) { tries--; return await this.__exec(url, type, options, tries); }
            if (error instanceof TypeError) { throw new Error('Existe inconveniente para comunicarse con el servicio, verificar su conexión a internet y volver a intentar la acción deseada'); }
            else { throw error; }
        }
    }
}