export interface EntityConfigModal {
    show: boolean;
    title: string;
    description: string;
    comment: string;
    key: keyModal;
}

export const initEntityConfigModal: EntityConfigModal = {
    show: false,
    title: "",
    description: "",
    comment: "",
    key: "reject"
}

export type keyModal = 'reject';