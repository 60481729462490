// React
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";

// Domain
import { ErrorCostume } from "../../../shared/Domain/ErrorCostume";
import { EntityTypeModule, KeyBDModule } from "../Domain/EntityTypeModule";
import { EntityModalFiltro, initEntityModalFiltro } from "../Domain/EntityUtils";
import { EntityMain } from "../Domain/EntityMain";

// Adapters
import { AdapterCopyText } from "../../../shared/Infraestructure/AdapterCopyText";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { AdapterConfigure } from "./AdapterConfigure";

// Infraestructure
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { addLoading, changeSaludo, removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { RepositoryImplMain } from "./RepositoryImplMain";

// Use Case
import { UseCaseReloadList } from "../Application/UseCaseReloadList";
import { EntityRendicionGasto } from "../../../shared/Domain/EntityRendicionGasto";
import { EntityAnticipo } from "../../../shared/Domain/EntityAnticipo";


let nameStore: KeyBDModule = '';

export const Controller = () => {
    const { generico: { websocket, dbLocal, countProcess }, auth: { user } } = useSelector((state: RootState) => state);
    const [data, setData] = useState<Array<EntityMain<any>>>([]);
    const [modeTypeModule, setModeTypeModule] = useState<EntityTypeModule>({ key: '', navBar: [] });
    const [modalFiltroConfig, setModalFiltroConfig] = useState<EntityModalFiltro>(initEntityModalFiltro);

    const languageTranslate = LanguageTranslate();
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const init = async () => {
        dispatch(changeSaludo(false));
        configModeType();
    };

    const configModeType = async () => {
        const arrPathname = window.location.pathname.split('/');
        const validationPathName = (module: string) => arrPathname.some(item => item === module);
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_TAREO_PERSONAL}`)) initModeTareoPersonal();
        else if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_TAREO_GRUPAL}`)) initModeTareoGrupal();
        else {};

        if (navigator.onLine) await loadData();
    }

    const initModeTareoPersonal = async () => {
        setModeTypeModule(() => ({ key: 'TareoPersonal', navBar: languageTranslate.moduloGenericList.navBarTareoPersonal }));
        nameStore = "TareoPersonal";
        if (!navigator.onLine) {
            const response: EntityRendicionGasto[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatToGenericList<EntityRendicionGasto>(response, 'TareoPersonal')));
        }
    }

    const initModeTareoGrupal = async () => {
        setModeTypeModule(() => ({ key: 'TareoGrupal', navBar: languageTranslate.moduloGenericList.navBarTareoGrupal }));
        nameStore = "TareoGrupal";
        if (!navigator.onLine) {
            const response: EntityAnticipo[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatToGenericList<EntityAnticipo>(response, 'TareoGrupal')));
        }
    }

    const loadData = async () => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const response = await (new UseCaseReloadList(repository).exec(user, nameStore));
            if (response === null) return;
            await dbLocal.clearStore(nameStore);
            await dbLocal.insertDataStore([{ nameStore, data: response.data }]);
            setData(() => (response.dataFormatted));
        } catch (err) {
            let error: ErrorCostume = new ErrorCostume((err as Error).message);
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    };

    const copyCodeItem = async (code: string) => {
        await AdapterCopyText.copyText(code);
        AdapterGenerico.createToast(`${languageTranslate.textoCopiar}`, 'success');
    }

    const onRedirectForm = (codigo: string) => {
        navigate(`${codigo}`)
    };

    // ================================== Modal Filtros ==========================================================
    const openModalFiltro = () => {
        setModalFiltroConfig((prev) => ({ ...prev, show: true }));
    }
    
    const closeModalFiltro = () => {
        setModalFiltroConfig((prev) => ({ ...prev, show: false }));
    }

    const onChangeModalFiltro = (name: string, value: any) => {
        setModalFiltroConfig((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    const onSubmitModalFiltro = async () => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            if (!modalFiltroConfig.statusSelected.value) throw new Error('Seleccione un estado');

            const response = await (new UseCaseReloadList(repository).exec(user, nameStore, modalFiltroConfig.statusSelected));
            if (response === null) return;
            await dbLocal.clearStore(nameStore);
            await dbLocal.insertDataStore([{ nameStore, data: response.data }]);

            setData(() => (response.dataFormatted));
            closeModalFiltro();
        } catch (err) {
            let error: ErrorCostume = new ErrorCostume((err as Error).message);
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    return {
        data,
        countProcess,
        modeTypeModule,

        init,
        onRedirectForm,
        loadData,
        copyCodeItem,

        modalFiltroConfig,
        openModalFiltro,
        closeModalFiltro,
        onChangeModalFiltro,
        onSubmitModalFiltro,
    };
}