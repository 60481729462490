// Dependencies
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { useFormik } from "formik";
import * as Yup from 'yup';
// @ts-ignore
import templateExcel from './../../../../../../assets/utils/formato-solicitud-caja-chica.xlsx';

// Infraestructure
import { addLoading, changeSaludo, removeLoading } from "../../../../../shared/Infraestructure/SliceGenerico";
import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";
import { AdapterValidator } from "../../../../../shared/Infraestructure/AdapterValidator";
import { AdapterGenerico } from "../../../../../shared/Infraestructure/AdapterGenerico";
import { RootState } from "../../../../../shared/Infraestructure/AdapterStore";

// Domain
import { EntityConfigForm, EntityConfigModalFlujo, EntityConfigModalNotas, EntityNavBar, ModeForm, initEntityConfigForm, initEntityConfigModalFlujo, initEntityConfigModalNotas, keyModule, nameModule } from "../Domain/EntityUtils";
import { EntityDatosTarea, EntityProyectos } from "../../../../../shared/Domain/Catalogos/EntityProyectos";
import { EntitySelectBase } from "../../../../../shared/Domain/Catalogos/EntitySelectBase";
import { EntityOT } from "../../../../../shared/Domain/Catalogos/EntityOT";
import { EntityRequestAddNote, EntityRequestCancelFlow, EntityRequestFormServiceSendApproval, initEntityRequestGenericForm } from "../Domain/EntityRequest";

// Adapter
import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";

// Application
import { UseCaseLoadInitialData } from "../Application/UseCaseLoadInitialData";
import { UseCaseFind } from "../Application/UseCaseFind";
import { EntityFlujoOT } from "../../../../../shared/Domain/Catalogos/EntityFlujoOT";
import { UseCaseAddNote } from "../Application/UseCaseAddNote";
import { EntityAnticipo } from "../../../../../shared/Domain/EntityAnticipo";

const languageTranslate = LanguageTranslate();
let nameStore: keyModule = '';
let modeForm: ModeForm = '';
let dataComplete: EntityAnticipo;

export const Controller = () => {
    const [configForm, setConfigForm] = useState<EntityConfigForm>(initEntityConfigForm)
    const [configModalFlujo, setConfigModalFlujo] = useState<EntityConfigModalFlujo>(initEntityConfigModalFlujo);
    const [configModalNotas, setConfigModalNotas] = useState<EntityConfigModalNotas>(initEntityConfigModalNotas);
    const { generico: { websocket, dbLocal }, auth: { user } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const formikForm = useFormik({
        initialValues: initEntityRequestGenericForm,
        onSubmit: () => {},
        validationSchema: Yup.object({
            OT: Yup.object().required(languageTranslate.moduloAnticipo.validate.errorOT).nullable(),
            Proyectos: Yup.object().when(['OT'], {
                is: (OT: EntitySelectBase<EntityOT>) => (!!OT?.dataComplete?.Restringido && configForm.dataOptions.proyecto.some(row => row.dataComplete.DatosTrabajo.OT.Codigo === OT?.dataComplete.Codigo)),
                then: Yup.object().required(languageTranslate.moduloAnticipo.validate.errorProyectos).nullable()
            }).nullable(),
            Personal: Yup.object().required(languageTranslate.moduloAnticipo.validate.errorPersonal).nullable(),
            // Divisa: Yup.object().required(languageTranslate.moduloAnticipo.validate.errorDivisa).nullable(),
            Importe: Yup.number().min(1, languageTranslate.moduloAnticipo.validate.errorImporte).required(languageTranslate.moduloAnticipo.validate.errorImporte).nullable().test('ValidarImporte', languageTranslate.moduloAnticipo.validate.errorImporteValido, (value, context) => {
                if (!context.parent.Tarea) return true;
                const Tarea = context.parent.Tarea.dataComplete as EntityDatosTarea;
                const importeDisponible = Tarea.importe - Tarea.importeFacturado;
                if (importeDisponible < parseInt(`${value}`)) return false;
                return true;
            }),
            Banco: Yup.string().nullable(), //.required(languageTranslate.moduloAnticipo.validate.errorBanco).nullable(),
            CuentaBancaria: Yup.string().nullable(), //.required(languageTranslate.moduloAnticipo.validate.errorCuentaBancaria).nullable(),
            Descripcion: Yup.string().required(languageTranslate.moduloAnticipo.validate.errorNota).nullable(),
            File: Yup.array().min(1, 'Ingrese un Archivo').nullable()
        })
    });

    const init = () => {
        dispatch(changeSaludo(false));
        configModeType();
        AdapterGenerico.scrollTopByClassName('ViewAnticipoForm');
    };

    const configModeType = async () => {
        // Carga la información de los select
        const dataOptions = await new UseCaseLoadInitialData(repository).exec();

        // Variables
        let navBar: EntityNavBar[] = languageTranslate.moduloAnticipo.navBar;
        nameStore = 'SolicitudAnticipo';

        if (params.id) {
            // Modo editar
            modeForm = 'edit';
            const findForm = await new UseCaseFind(repository).exec(`${params.id}`, nameStore, dataOptions);
            if (!findForm) {
                AdapterGenerico.createMessage('Error', `No se encontró el detalle para: ${params.id}`, 'error').then(res => navigate(AdapterConfigure.LIST_ANTICIPO)); 
                return;
            }
            if (findForm.data.Estado.IdStatus !== 2) modeForm = 'preview';
            dataComplete = findForm.dataComplete;
            formikForm.setValues(findForm.data);
        } else {
            // Modo crear
            modeForm = 'create';
            formikForm.setFieldValue('Codigo', `${Date.now()}`);
            // Seleccionar OT Default
            if (user.DatosTrabajo.OT) {
                const resultOT = dataOptions.ot.find(row => row.dataComplete.Code === user.DatosTrabajo.OT)
                formikForm.setFieldValue('OT', resultOT || null);
                formikForm.setFieldValue('Pais', resultOT?.dataComplete.DatosPais || null);
                formikForm.setFieldValue('Empresa', resultOT?.dataComplete.DatosEmpresa || null);
                formikForm.setFieldValue('Delegacion', resultOT?.dataComplete.DatosTrabajo.Delegacion || null);
                // const Divisa = dataOptions.moneda.find(row => row.dataComplete.Country.Code === resultOT?.dataComplete.DatosPais?.Code && !row.dataComplete.Key);
                // formikForm.setFieldValue('Divisa', Divisa || null)

                // Seleccionar Personal Default
                const resultPersonal = {
                    TipoDocumento: user.TipoDocumento.TipoDocumento,
                    Identificacion: user.Identificacion,
                    ApellidoPaterno: user.LastNameFather,
                    ApellidoMaterno: user.LastNameMother,
                    Nombres: user.FullName
                }
                formikForm.setFieldValue('Personal', resultPersonal)

                // Verifica el personal para obtener los datos del banco
                const result = await repository.findPersonal(user.Identificacion);
                if (!result) {
                    AdapterGenerico.createMessage('Error', `No se encontró el usuario: ${user.Identificacion}`, 'error').then(res => navigate(AdapterConfigure.LIST_ANTICIPO)); 
                    return;
                }

                /*if (!result.DatosBanco.CuentaCorriente) {
                    AdapterGenerico.createMessage('Error', `El usuario no tiene configurado su Cuenta Bancaria`, 'error').then(res => navigate(AdapterConfigure.LIST_ANTICIPO)); 
                    return;
                }*/

                formikForm.setFieldValue('Banco', result.DatosBanco?.Banco?.Name || result.DatosBanco?.Banco?.Key || '');
                formikForm.setFieldValue('CuentaBancaria', result.DatosBanco?.CuentaCorriente || '');
                formikForm.setFieldValue('CuentaInterbancaria', result.DatosBanco?.CuentaInterbancaria || '');
            }
        }

        // Modifica el navbar para el lenguaje
        if (navBar.length >= 3) navBar[2].text = nameModule[modeForm];

        // Guarda la información general y la del usuario
        setConfigForm(() => ({
            ...configForm,
            loadingForm: false,
            dataOptions: dataOptions,
            key: nameStore,
            navBar: navBar,
            mode: modeForm,
        }));

        formikForm.setFieldValue("User", { IdUser: user.IdUser, User: user.User, LastName: `${user.LastNameFather} ${user.LastNameMother}`, Name: user.Name, Perfil: user.Profile.find(row => row.Principal === 'SI')?.Perfil || '' })
    };

    const onChange = (name: string, value: any) => {

        switch (name) {
            case 'OT':
                if (!!value) {
                    const otSelected: EntityOT = value.dataComplete;
                    formikForm.setFieldValue('Pais', otSelected.DatosPais);
                    formikForm.setFieldValue('Empresa', otSelected.DatosEmpresa);
                    formikForm.setFieldValue('Delegacion', otSelected.DatosTrabajo.Delegacion);
                    // const Divisa = configForm.dataOptions.moneda.find(row => row.dataComplete.Country.Code === otSelected.DatosPais?.Code && !row.dataComplete.Key);
                    // formikForm.setFieldValue('Divisa', Divisa || null)
                } else {
                    formikForm.setFieldValue('Pais', null);
                    formikForm.setFieldValue('Empresa', null);
                    formikForm.setFieldValue('Delegacion', null);
                    formikForm.setFieldValue('Divisa', null)
                }
                formikForm.setFieldValue('Proyectos', null)
                formikForm.setFieldValue('Tarea', null)
                break;
            case 'Proyectos':
                if (value) {
                    const proyectoSelected: EntityProyectos = value.dataComplete;
                    const searchTarea = proyectoSelected.DatosTareas.find(row => row.Code === 'TF-CAJACHICA');
                    if (searchTarea) {
                        const Tarea: EntitySelectBase<EntityDatosTarea> = {
                            label: `${searchTarea.Name}`,
                            value: `${searchTarea.Code}`,
                            dataComplete: searchTarea
                        }
                        formikForm.setFieldValue('Tarea', Tarea);
                    }
                } else {
                    formikForm.setFieldValue('Tarea', null);
                }
                break;
            case 'Importe':
                formikForm.setFieldValue('Importe', AdapterGenerico.removerCeroIzquierda(value))
                return;
        }
        formikForm.setFieldValue(name, value);
    };

    const onSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formikForm.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formikForm.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const dataFormatted = await repository.formatDataToSave(formikForm.values);
            await repository.save(dataFormatted, configForm.mode)

            dispatch(removeLoading());
            navigate(AdapterConfigure.LIST_ANTICIPO, { replace: true });
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        };
    };

    const onCancelForm = () => {
        navigate(AdapterConfigure.LIST_ANTICIPO);
    };

    /* =======================================CONFIG MODAL FLUJO======================================================== */

    const openModalFlujo = () => {
        const flujoOT = configForm.dataOptions.flujoOT.filter(row =>
            row.OT.Code === formikForm.values.OT?.dataComplete.Code && 
            (row.TipoOperacion.Codigo === 'O01')
        );

        const flujoDelegacion: any[] = configForm.dataOptions.flujoDelegacion.filter(row =>
            row.Delegacion.Code === formikForm.values.Delegacion?.Code &&
            row.TipoOperacion.Codigo === 'O01'
        );

        setConfigModalFlujo((prev) => ({
            show: true,
            flujo: flujoOT.length > 0 ? flujoOT : flujoDelegacion,
            flujoSelected: undefined
        }));
    }
    
    const closeModalFlujo = () => {
        setConfigModalFlujo(() => ({
            show: false,
            flujo: [],
            flujoSelected: undefined
        }));
    }

    const onChangeMFL = (flujo: EntityFlujoOT) => {
        setConfigModalFlujo((prev) => ({
            ...prev,
            flujoSelected: flujo
        }));
    }

    const onSubmitMFL = async (evt: Event) => {
        if (!configModalFlujo.flujoSelected) return AdapterGenerico.createMessage(languageTranslate.textoIncompleto, 'Seleccione un Flujo', 'warning', false);
        try {

            // Guardar Rendición
            try { await formikForm.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formikForm.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const dataFormatted = await repository.formatDataToSave(formikForm.values);
            const responseSave = await repository.save(dataFormatted, configForm.mode)
            if (!responseSave) return;
            
            // Enviar a aprobación
            const dataApproval: EntityRequestFormServiceSendApproval = {
                paramsSend: {
                    idSolicitud: responseSave.IdSolicitud,
                    flujo: {
                        Id: configModalFlujo.flujoSelected.Id,
                        Codigo: configModalFlujo.flujoSelected.Codigo,
                        Code: configModalFlujo.flujoSelected.Code,
                        Name: configModalFlujo.flujoSelected.Name,
                        Descripcion: configModalFlujo.flujoSelected.Descripcion,
                        Aprobacion: configModalFlujo.flujoSelected.Aprobacion
                    },
                    user: {
                        IdUser: formikForm.values.User.IdUser,
                        LastName: formikForm.values.User.LastName,
                        Name: formikForm.values.User.Name,
                        User: formikForm.values.User.User,
                        Perfil: formikForm.values.User.Perfil
                    }
                },
                extraData: {
                    Codigo: responseSave.Codigo
                }
            }
            await repository.sendApproval(dataApproval);

            dispatch(removeLoading());
            navigate(AdapterConfigure.LIST_ANTICIPO, { replace: true });
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        };
    }

    const onCancelFlow = async () => {
        try {
            const result = await AdapterGenerico.createMessage('', '¿Desea cancelar el Flujo?', 'question', true);
            if (!result) return;
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const requestCancelFlow: EntityRequestCancelFlow = {
                codigo: formikForm.values.Codigo,
                user: {
                    IdUser: formikForm.values.User.IdUser,
                    LastName: formikForm.values.User.LastName,
                    Name: formikForm.values.User.Name,
                    Perfil: formikForm.values.User.Perfil,
                    User: formikForm.values.User.User
                }
            }
            const response = await repository.cancelarFlow(requestCancelFlow);
            navigate(AdapterConfigure.LIST_ANTICIPO, { replace: true });
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    /* =======================================CONFIG MODAL NOTAS======================================================== */

    const openModalNotas = () => {
        setConfigModalNotas((prev) => ({
            nota: '',
            show: true,
            title: 'Notas'
        }))
    }

    const closeModalNotas = () => {
        setConfigModalNotas((prev) => ({
            nota: '',
            show: false,
            title: 'Notas'
        }))
    }

    const onChangeMNT = (_: string, value: string) => {
        setConfigModalNotas((prev) => ({
            ...prev,
            nota: value
        }))
    }

    const onSubmitMNT = async () => {
        try {
            if (configModalNotas.nota === '') return AdapterGenerico.createMessage('Incompleto', 'Ingrese un comentario', 'warning', false);
            
            dispatch(addLoading({ textLoading: languageTranslate.textoEnviando }))
            const payload: EntityRequestAddNote = {
                paramsSend: {
                    codigo: formikForm.values.Codigo || '',
                    nota: configModalNotas.nota
                },
                extraConfig: {
                    nameStore: nameStore
                }
            }

            let response = await new UseCaseAddNote(repository).exec(payload);

            if (response) {
                dataComplete.Notas = [
                    ...(dataComplete?.Notas || []),
                    response.data,
                ];
                (dataComplete.Dates as any) = { ...(dataComplete.Dates as any || {}), Update: response.data.Date };
                let templateNewNote = {
                    observacion: response.data.Description,
                    fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(response.data.Date.Date), 3),
                    usuario: `${response.data.User.Name} ${response.data.User.LastName}`,
                    action: "NUEVA NOTA"
                }
                formikForm.setFieldValue('Notas', [templateNewNote, ...formikForm.values.Notas]);
                await dbLocal.updateByIndexStore({ nameStore, value: dataComplete })
            }

            closeModalNotas()
        }  catch(error) {
            let msg = (error as Error).message;
            if (msg === 'error-date-update') {
                msg = `No se puede ${languageTranslate.moduloApprovals.question.textoDevolver}, el registro actualmente cuenta con modificaciones`
            }
            msg = `${msg} - ${formikForm.values?.Codigo}`
            AdapterGenerico.createMessage('Error', msg, 'error', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    /* ======================================= Descargar Plantilla ======================================================= */
    const onDownload = () => {
        AdapterGenerico.downloadDocument(templateExcel, 'Formato Solicitud Caja Chica.xlsx');
    }

    return ({
        init,
        onSubmit,
        onCancelForm,
        onChange,
        configModeType,

        languageTranslate,
        configForm,
        formikForm,

        // Modal Flujo
        configModalFlujo,
        openModalFlujo,
        closeModalFlujo,
        onSubmitMFL,
        onChangeMFL,
        onCancelFlow,

        // Modal Notas
        configModalNotas,
        openModalNotas,
        closeModalNotas,
        onChangeMNT,
        onSubmitMNT,

        // Utils
        onDownload
    });
}