import { EntityGasto } from "../../../../../shared/Domain/EntityGasto";
import { EntityRendicionGastoAprobacion, initEntityRendicionGastoAprobacion } from "../../../../../shared/Domain/EntityRendicionGastoAprobacion";
import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";

export interface EntityConfigForm {
    key: keyModule;
    mode: ModeForm;
    navBar: EntityNavBar[];
    loadingForm: boolean;
    data: EntityRendicionGastoAprobacion;
}

export const initEntityConfigForm: EntityConfigForm = {
    key: "",
    mode: "",
    navBar: [],
    loadingForm: false,
    data: initEntityRendicionGastoAprobacion
}

export interface EntityNavBar {
    text: string;
    navigate: boolean;
    path: string;
}

export type EntityModeSelectGastos = {
    active: boolean;
    select: EntityGasto[];
    showConfirm: boolean;
    comentario: string;
}

export const initEntityModeSelectGastos: EntityModeSelectGastos = {
    active: false,
    select: [],
    showConfirm: false,
    comentario: ""
}

export type EntityConfigModalDetail = {
    show: boolean;
    gastoSelect: EntityGasto;
    currentPosition: number;
}

export type EntityConfigItems = {
    active: boolean;
    items: EntityGasto[];
    filterItems: EntityGasto[];
    selectItems: EntityGasto[];
    textSearch: string;
}

export const initEntityConfigItems: EntityConfigItems = {
    active: false,
    items: [],
    filterItems: [],
    selectItems: [],
    textSearch: ''
}

export const initEntityConfigModalDetail: EntityConfigModalDetail = {
    show: false,
    gastoSelect: {} as any,
    currentPosition: 0
}

export type EntityConfigApproval = {
    mode: 'group' | 'individual';
    type: 'approve' | 'reject';
}

export type EntityConfigModalApproval = {
    show: boolean;
    items: EntityGasto[];
    comentario: string;
    type: 'approve' | 'reject';
    description: string;
}

export const initEntityConfigModalApproval: EntityConfigModalApproval = {
    show: false,
    items: [],
    comentario: "",
    type: "approve",
    description: ""
}

export type EntityConfigModalNotes = {
    show: boolean;
    comentario: string;
    titulo: string;
}

export const initEntityConfigModalNotes: EntityConfigModalNotes = {
    show: false,
    comentario: "",
    titulo: ""
}

const languageTranslate = LanguageTranslate();
export const nameModule = {
    'preview': languageTranslate.textoModoVisualizar,
    'create': languageTranslate.textoModoCrear,
    'edit': languageTranslate.textoModoEditar,
    'approval': 'Detalle',
    '': 'No Found'
}

export type keyModule = 'GastoAprobacion' | 'HistoricoGasto' | '';
export type ModeForm = 'preview' | 'approval' | '';