import { EntityTareoAprobacion } from "../../../../shared/Domain/Tareo/EntityTareoAprobacion";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseFind {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(code: string): Promise<EntityTareoAprobacion | null> {
        return await this._exec(code);
    }

    private async _exec(code: string): Promise<EntityTareoAprobacion | null> {
        return await this.repository.findForm(code);
    }
}