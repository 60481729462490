import React, { useState } from 'react';
import { Document, Page, pdfjs } from "react-pdf";

import { ElementEmptyList } from './ElementEmptyList';
import ElementModalCostume from './ElementModalCostume';
import { ElementInputFileImage } from './ElementInputFileImage';

import { LanguageTranslate } from '../Infraestructure/LanguageTranslate';

import './ElementViewDocumentsCostume.scss'
import { AdapterGenerico } from '../Infraestructure/AdapterGenerico';

interface DocumentFile {
  type: 'local' | 'server';
  value: File | { directory: string[]; fileName: string; };
  valueToShow: string;
}

interface IElementViewImagesCostume {
  title?: string;
  disabled?: boolean;
  isMulti?: boolean;
  maxFile?: number;
  onChange(name: string, data: DocumentFile[]): any;
  documents: DocumentFile[];
  name: string;
}

const languageTranslate = LanguageTranslate();

const ElementViewDocumentsCostume = (props: IElementViewImagesCostume) => {
    const [indexImage, selectImage] = useState(0);
    const [show, setShow] = useState(false);

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages }: any) => setNumPages(numPages);
    const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
    const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1,);

    const openModal = () => {
      setShow(() => true);
    }

    const closeModal = () => {
      selectImage(0);
      setShow(false);
    }

    const prevDelete = () => {
      let focusImage = indexImage === 0 ? 0 : indexImage - 1;
      selectImage(focusImage);

      const refDocuments: DocumentFile[] = props.documents;
      refDocuments.splice(indexImage, 1);
      
      props.onChange(props.name, refDocuments);
    }

    const onChangeNewDocument = (file: FileList) => {
      let newResult: DocumentFile[] = [...props.documents];

      if (props.maxFile && (Array.from(file).length + newResult.length) > props.maxFile) {
        AdapterGenerico.createMessage(languageTranslate.textoAlerta, `La cantidad máxima de archivos es de ${props.maxFile}`, 'warning', false);
        return;
      }

      Array.from(file).forEach(row => {
        newResult.push({
          type: 'local',
          value: row,
          valueToShow: (window.URL ? URL : webkitURL).createObjectURL(row)
        })
      });

      props.onChange(props.name, newResult)
    }

    const isDocumentOnServer = props.documents[indexImage]?.type === 'server';
    const isPDF = isDocumentOnServer ?
      ((props.documents[indexImage]?.value as any)?.fileName || '').toLocaleLowerCase().match('pdf')
      :
      ((props.documents[indexImage]?.value as File)?.name || '').toLocaleLowerCase().match('pdf');

    return (
      <>
        <ElementModalCostume
            show={show}
            closeModal={closeModal}
            title={props.title}
        >
          {
            props.documents.length === 0 ? (
              <>
                <ElementEmptyList className="top-50 start-50 translate-middle position-absolute" text="Sin Documentos" />
              </>
            ) : (
              <div className="ElementViewDocumentsCostume">
                <div className="main-image">
                  {
                    isPDF ?
                      <div className="row">
                        <div className="col-12" style={{ minHeight: 200 }}>
                            {
                              !!props.documents[indexImage]?.valueToShow
                                  ? <div style={{ height: '100%' }}>
                                      <Document file={props.documents[indexImage]?.valueToShow} onLoadSuccess={onDocumentLoadSuccess} >
                                          <Page pageNumber={pageNumber} />
                                      </Document>
                                  </div>
                                  : <></>
                            }
                        </div>
                        <div className="col-12">
                            <div className="divPaginado">
                                <button className="btn btn-primary" onClick={goToPrevPage}>{`<`}</button>
                                <p>
                                    {languageTranslate.textoPagina} {pageNumber} {languageTranslate.textoPaginaDe} {numPages}
                                </p>
                                <button className="btn btn-primary" onClick={goToNextPage}>{`>`}</button>
                            </div>
                        </div>
                      </div>
                    : 
                      <img
                        className={`shadow`}
                        height={330}
                        width="100%"
                        src={props.documents[indexImage]?.valueToShow}
                        alt="not source"
                      />
                  }
                  {
                    props.disabled ?
                      null
                      :
                      <button style={{ bottom: isPDF ? 70 : 20 }} className="btn btn-dark btn-delete" onClick={prevDelete}> Eliminar documento </button>
                  }
                </div>
                {
                  props.documents.length > 1 ?
                    <div className="group-image">
                      {props.documents?.map((image, index) => (
                        image.type === 'server' ? 
                          <>
                          </>
                          :
                          (
                            (image.type === 'local' ? (image.value as File).name : (image.value as any).fileName).toLocaleLowerCase().match('pdf') ? 
                            <div
                              className={`item-pdf ${indexImage === index ? "img-selected" : ""}`}
                              onClick={() => selectImage(index)}
                            >
                              <i
                                className={`fa-solid fa-file-pdf`}
                              />
                            </div>
                            :
                            <img
                              key={index}
                              onClick={() => selectImage(index)}
                              className={indexImage === index ? "img-selected" : ""}
                              height={50}
                              width={50}
                              src={image?.valueToShow}
                              alt="not source"
                            />
                          )
                      ))}
                    </div>
                  : null
                }
              </div>
            )
          }
        </ElementModalCostume>

        <div className='GroupBtnDocument'>
          <div className='GroupButtonsPicture'>
            {
              props.disabled ? null :
              <ElementInputFileImage
                accept='image/*, .pdf'
                icon='fa-solid fa-camera'
                onChange={onChangeNewDocument}
                multiple={props.isMulti}
              />
            }
            {
              props.disabled ? null :
              <ElementInputFileImage
                accept='image/*, .pdf'
                icon='fa-solid fa-image'
                onChange={onChangeNewDocument}
                multiple={props.isMulti}
                capture
              />
            }
          </div>

          {
            props.documents.length > 0 ?
              <button
                className='btn btn-dark custom-btn-dark'
                onClick={openModal}
              >
                Visualizar &nbsp;
                <i className="fa-solid fa-eye" />
              </button>
            : null
          }
        </div>
      </>
    )
}

export default ElementViewDocumentsCostume;