// Library
import React from 'react';

// Domain
import { EntityLanguage } from '../../../../../shared/Domain/EntityLanguage';
import { EntityGasto } from '../../../../../shared/Domain/EntityGasto';
import { EntityConfigApproval, EntityConfigForm, EntityConfigItems } from '../Domain/EntityUtils';

// Components
import { ElementBreadCrumb } from '../../../../../shared/Components/ElementBreadCrumb';
import { ViewReport } from './MenuContent/ViewReport';

// Style
import './Style.scss';

interface ContainerProps {
    languageTranslate: EntityLanguage;
    configForm: EntityConfigForm;
    configItems: EntityConfigItems;
    openModalDetail: (row: EntityGasto, position: number) => void;
    onSubmitSearch: () => void;
    onChangeSearch: (textSearch: string) => void;
    activeModeSelectItem: () => void;
    disabledModeSelectItem: () => void;
    onSelectAllItem: () => void;
    onSelectItem: (gasto: EntityGasto) => void;
    onSubmitApproval: (props: EntityConfigApproval) => Promise<void>;
    openModalNotes(): void;
}

export const ViewMain = (props: ContainerProps) => {

    return (
        <div className='ViewRendicionGastoAprobacion'>
            <div className="container-fluid">
                <ElementBreadCrumb list={props.configForm.navBar} />
                <div className="container" style={{ marginBottom: 80 }}>
                    <ViewReport
                        languageTranslate={props.languageTranslate}
                        configForm={props.configForm}
                        openModalDetail={props.openModalDetail}
                        onChangeSearch={props.onChangeSearch}
                        onSubmitSearch={props.onSubmitSearch}
                        configItems={props.configItems}
                        activeModeSelectItem={props.activeModeSelectItem}
                        disabledModeSelectItem={props.disabledModeSelectItem}
                        onSelectAllItem={props.onSelectAllItem}
                        onSelectItem={props.onSelectItem}
                        onSubmitApproval={props.onSubmitApproval}
                        openModalComment={props.openModalNotes}
                    />
                    {
                        /*
                            <ElementMenuTab
                                MenuList={[
                                    {
                                        key: '#Informe',
                                        title: props.languageTranslate.moduloGastoAprobacion.viewReport.title,
                                        content: <ViewReport
                                            languageTranslate={props.languageTranslate}
                                            configForm={props.configForm}
                                            openModalDetail={props.openModalDetail}
                                            onChangeSearch={props.onChangeSearch}
                                            onSubmitSearch={props.onSubmitSearch}
                                            configItems={props.configItems}
                                            activeModeSelectItem={props.activeModeSelectItem}
                                            disabledModeSelectItem={props.disabledModeSelectItem}
                                            onSelectAllItem={props.onSelectAllItem}
                                            onSelectItem={props.onSelectItem}
                                            onSubmitApproval={props.onSubmitApproval}
                                            openModalComment={props.openModalNotes}
                                        />
                                    },
                                    {
                                        key: '#Historial',
                                        title: props.languageTranslate.moduloGastoAprobacion.viewHistory.title,
                                        content: <ViewHistory
                                            notes={props.configForm.data.Notas}
                                            openModalComment={props.openModalNotes}
                                            languageTranslate={props.languageTranslate}
                                            configForm={props.configForm}
                                        />
                                    } 
                                ]}
                                defaultKey={window.location.hash || ''}
                            />
                        */
                    }
                    {/* <button className='btn btn-dark w-100'>{props.languageTranslate.btnRegresar}</button> */ }
                </div>
            </div>
        </div>
    )
}