import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {
    public async logout(accessToken: string): Promise<void> {
        await this._logout(accessToken);
    }

    private async _logout(accessToken: string): Promise<void> {
        // let url: string = `${this.urlBase}/${AdapterConfigure.SCHEMA}/${AdapterConfigure.ENTITY}/logout`;
        // await this.service.bgCall<void>("POST", url, JSON.stringify({ accessToken }), "basic", "json", 'json', {}, 0);
        await this.dbLocal.clearStore(['Comparativo', 'Factura', 'RequerimientoSistema', 'RequerimientoCompra', 'SolicitudCajaChica', 'RendicionGastosCajaChica', 'CTAnticipo', 'CTFlujosOT', 'CTFlujosDelegacion', 'CTMoneda', 'CTOT', 'CTPersonal', 'CTProveedor', 'CTProyectos', 'CTServicio', 'CTTipoCambio', 'CTTipoDocumento', 'CTTipoDocumentoTributario', 'CTVAT']);
    }
}