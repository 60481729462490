import { EntityResponseFormattedMyAccount } from "../Domain/EntityReponse";
import { EntityRequestMyAccount } from "../Domain/EntityRequest";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseStatsMyAccount {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: EntityRequestMyAccount): Promise<EntityResponseFormattedMyAccount | null> {
        return await this._exec(params);
    }

    private async _exec(params: EntityRequestMyAccount): Promise<EntityResponseFormattedMyAccount | null> {
        return await this.repository.getStatusMyAccount(params);
    }
}