// Library
import React from 'react';

// Components
import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { EntityLanguage } from '../../../shared/Domain/EntityLanguage';
import { ItemOptionMenu } from './Items/ItemOptionMenu';

// Domain
import { EntityMenu } from '../../../shared/Domain/EntityMenu';

// Styles
import './Style.scss';

interface ContainerProps {
    listMenu: EntityMenu[];
    onClickMenu(row: EntityMenu): void;
    languageTranslate: EntityLanguage;
}

export const ViewMain = (props: ContainerProps) => {

    return (
        <div className="ViewMainApprovals">
            <div className="container-fluid">
                <ElementBreadCrumb list={props.languageTranslate.moduloTareo.navBar} />
                <div className="container">
                    {
                        props.listMenu.map(row =>
                            <ItemOptionMenu
                                row={row}
                                onClickMenu={props.onClickMenu}
                                languageTranslate={props.languageTranslate}
                                key={row.id}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}