import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntityMain } from "../Domain/EntityMain";
import { KeyBDModule } from "../Domain/EntityTypeModule";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseReloadList {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: EntityDataUsuario, keyModule: KeyBDModule, filters: any  = null): Promise<{ dataFormatted: EntityMain<any>[], data: any[] } | null> {
        return await this._exec(params, keyModule, filters);
    }

    private async _exec(params: EntityDataUsuario, keyModule: KeyBDModule, filters: any = null): Promise<{ dataFormatted: EntityMain<any>[], data: any[] } | null> {
        return await this.repository.getList(params, keyModule, filters);
    }
}