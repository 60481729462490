import React from 'react';
import './Style.scss';

// Domain
import { EntityLanguage } from '../../../../../shared/Domain/EntityLanguage';
import { EntityGenericDetailForm } from '../../../../../shared/Domain/EntityGenericDetailForm';
import { EntityRequestGenericForm } from '../Domain/EntityRequest';
import { EntityConfigForm } from '../Domain/EntityUtils';

// Component
import { ElementBreadCrumb } from '../../../../../shared/Components/ElementBreadCrumb';
import ElementStepperContent from '../../../../../shared/Components/ElementStepperContent';
import { InputSelect, InputText, InputTextArea } from '../../../../../shared/Components/ElementInputsCostume';
import { AdapterGenerico } from '../../../../../shared/Infraestructure/AdapterGenerico';
import { AdapterFilterOption } from '../../../../../shared/Infraestructure/AdapterFilterOptions';
import ElementStepperHeader from '../../../../../shared/Components/ElementStepperHeader';
import { ElementCollapseCostume } from '../../../../../shared/Components/ElementCollapseCostume';
import { ElementTableCostume } from '../../../../../shared/Components/ElementTableCostume';
import ElementViewDocumentsCostume from '../../../../../shared/Components/ElementViewDocumentsCostume';

interface ContainerProps {
    configForm: EntityConfigForm;
    languageTranslate: EntityLanguage;
    formikForm: EntityGenericDetailForm<EntityRequestGenericForm>;
    openModalNotas: () => void
    openModalFlujo: () => void;
    onChange: (name: string, value: any) => void;
    onSubmit: (e: any) => Promise<any>;
    onCancelForm: () => void;
    onCancelFlow: () => Promise<void>;
    onDownload: Function;
}

const itemStatusSolicitud: any = {
    1: {
        color: "",
        text: "Aprobado"
    },
    2: {
        color: "",
        text: "Abierto"
    },
    3: {
        color: "",
        text: 'En Aprobación'
    },
    5: {
        color: "",
        text: 'En Aprobación'
    },
    8: {
        color: "",
        text: "Pagado"
    },
    11: {
        color: "",
        text: 'Anulado'
    }
}

const itemStatusNavision: any = {
    "0": {
        color: "",
        text: "Pendiente Pago" //"Pendiente Navisión"
    },
    "5": {
        color: "",
        text: "Pendiente Pago" // Contabilizado
    },
    "6": {
        color: "",
        text: "Pendiente Pago" // Programado
    },
    "7": {
        color: "",
        text: "Depositado"//"Depositado"
    },
    "8": {
        color: "",
        text: "Depositado"
    },
}

export const ViewMain = (props: ContainerProps) => {

    return (
        <div className='ViewAnticipoForm'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={props.configForm.navBar} />
            </div>
            <div className="container-form">
                <ElementStepperContent
                    step={1}
                    currentStep={1}
                    title={props.languageTranslate.moduloAnticipo.detail.textTitle}
                    isLastStep
                    removeButtonSubmit
                    onSubmit={() => {}}
                >
                    <div className="form-row mb-0">

                        {
                            props.configForm.loadingForm ?
                                <>
                                    {props.languageTranslate.textoCargando}
                                </>
                            :
                                <>
                                    {
                                        props.formikForm.values.Flujo.Aprobacion.length > 0 ?
                                        <>
                                            <label style={{ fontSize: 14 }}>Aprobadores</label>
                                            <ElementStepperHeader
                                                data={props.formikForm.values.Flujo.Aprobacion.map(row => ({ label: row.Aprobador.FullName, value: row.Aprobador.Identificacion }))}
                                                onChange={() => {}}
                                                stepPosition={props.formikForm.values.Estado.IdStatus === 1 ? 999 : props.formikForm.values.Aprobacion.Nivel + 1}
                                            />
                                            <div className='mb-1'>
                                                <label style={{ fontSize: 14 }}>Código Solicitud:&nbsp;</label>
                                                <span className='text-muted' style={{ fontSize: 14 }}>{props.formikForm.values.Codigo}</span>
                                            </div>
                                            <div className='mb-3'>
                                                <label style={{ fontSize: 14 }}>Estado:&nbsp;</label>
                                                {
                                                    props.formikForm.values.Estado.IdStatus !== 1 ?
                                                    <span className='text-muted' style={{ fontSize: 14 }}>{itemStatusSolicitud[props.formikForm.values.Estado.IdStatus]?.text}</span>
                                                    : null
                                                }
                                                {
                                                    props.formikForm.values.Estado.IdStatus === 1 ?
                                                    <span className='text-muted' style={{ fontSize: 14 }}>{itemStatusNavision[props.formikForm.values.EstadoNav.IdStatus]?.text}</span>
                                                    : null
                                                }
                                            </div>
                                        </> : null
                                    }

                                    {
                                        props.formikForm.values.Estado.IdStatus !== 0 ?
                                            <div>
                                                <ElementCollapseCostume
                                                    textTitle={props.languageTranslate.moduloGenericDetail.itemNotas.textTitle}
                                                    className='my-3'
                                                >
                                                    <div className='ContentItemCollapse'>
                                                        {
                                                            props.formikForm.values.Estado.IdStatus !== 1 ? 
                                                                <div className="w-100 mb-2" style={{ textAlign: 'end' }}>
                                                                    <button className="btn btn-primary" onClick={props.openModalNotas}>Agregar</button>
                                                                </div>
                                                            : null
                                                        }
                                                        <ElementTableCostume
                                                            listHeader={props.languageTranslate.moduloGenericDetail.itemNotas.headerTable}
                                                            listData={props.formikForm.values.Notas}
                                                            className="CustomTableNota"
                                                        />
                                                    </div>
                                                </ElementCollapseCostume>

                                                <ElementCollapseCostume
                                                    textTitle={props.languageTranslate.moduloGenericDetail.itemBitacora.textTitle}
                                                    className='mt-3 mb-5'
                                                >
                                                    <div className='ContentItemCollapse'>
                                                        <ElementTableCostume
                                                            listHeader={props.languageTranslate.moduloGenericDetail.itemBitacora.headerTable}
                                                            listData={props.formikForm.values.Bitacora}
                                                        />
                                                    </div>
                                                </ElementCollapseCostume>
                                            </div>
                                            : null
                                    }

                                    {
                                    (props.formikForm.values.Flujo.Aprobacion.length > 0 || props.formikForm.values.Estado.IdStatus !== 0) ? <hr/> : null
                                    }

                                    {
                                        props.formikForm.values.Estado.IdStatus === 0 ?
                                            <div className='MessageHelp'>
                                                <span>{props.languageTranslate.moduloAnticipo.detail.textHelp}</span>
                                                <br/>
                                                <span className='DownloadTemplate' onClick={() => props.onDownload()}>{props.languageTranslate.btnDownloadTemplate}</span>
                                            </div>
                                        : null
                                    }

                                    <InputSelect
                                        label={props.languageTranslate.moduloAnticipo.detail.textOT}
                                        options={props.configForm.dataOptions.ot}
                                        values={props.formikForm.values}
                                        onChange={props.onChange}
                                        loading={props.configForm.loadingForm}
                                        disabled
                                        className='form-group w-100'
                                        name='OT'
                                        isRequired
                                        arrFilterOption={AdapterFilterOption.OT}
                                        isClearable
                                    />

                                    <InputText
                                        label={props.languageTranslate.moduloAnticipo.detail.textBanco}
                                        values={props.formikForm.values}
                                        name='Banco'
                                        onChange={props.onChange}
                                        disabled
                                    />

                                    <InputText
                                        label={props.languageTranslate.moduloAnticipo.detail.textCuentaBancaria}
                                        values={props.formikForm.values}
                                        name='CuentaBancaria'
                                        onChange={props.onChange}
                                        disabled
                                    />

                                    <InputText
                                        label={props.languageTranslate.moduloAnticipo.detail.textCuentaInterbancaria}
                                        values={props.formikForm.values}
                                        name='CuentaInterbancaria'
                                        onChange={props.onChange}
                                        disabled
                                    />

                                    {
                                        props.formikForm.values.OT?.dataComplete.Restringido ?
                                            <InputSelect
                                                label={props.languageTranslate.moduloAnticipo.detail.textProyectos}
                                                options={
                                                    props.configForm.dataOptions.proyecto.filter(row =>
                                                        row.dataComplete.DatosTrabajo.OT.Codigo ===
                                                        props.formikForm.values.OT?.dataComplete.Codigo
                                                    )
                                                }
                                                values={props.formikForm.values}
                                                onChange={props.onChange}
                                                loading={props.configForm.loadingForm}
                                                disabled={props.configForm.mode === 'preview'}
                                                className='form-group w-100'
                                                name='Proyectos'
                                                isRequired
                                                isClearable
                                            />
                                        : null
                                    }

                                    {/* <InputSelect
                                        label={props.languageTranslate.moduloAnticipo.detail.textDivisa}
                                        options={
                                            props.configForm.dataOptions.moneda.filter(row => 
                                                row.dataComplete.Country.Code === props.formikForm.values.Pais?.Code 
                                            )
                                        }
                                        values={props.formikForm.values}
                                        onChange={props.onChange}
                                        loading={props.configForm.loadingForm}
                                        disabled={props.configForm.mode === 'preview'}
                                        className='form-group w-100'
                                        name='Divisa'
                                        isRequired
                                        isClearable
                                    /> */}

                                    <InputText
                                        label={props.languageTranslate.moduloAnticipo.detail.textImporte}
                                        values={props.formikForm.values}
                                        onChange={props.onChange}
                                        disabled={props.configForm.mode === 'preview'}
                                        type='number'
                                        className='form-group w-100'
                                        name='Importe'
                                        isRequired
                                        max={
                                            props.formikForm.values.Tarea?.dataComplete ?
                                            props.formikForm.values.Tarea.dataComplete.importe - props.formikForm.values.Tarea.dataComplete.importeFacturado 
                                            : undefined
                                        }
                                        // text={props.formikForm.values.Divisa?.dataComplete.Symbol || '-'}
                                    >
                                        {
                                            props.formikForm.values.Tarea?.dataComplete ? 
                                                <span style={{ fontSize: 12, color: 'red' }}>
                                                    {props.languageTranslate.moduloAnticipo.detail.textoImporteMaximo}:{/*props.formikForm.values.Divisa?.dataComplete.Symbol*/}{AdapterGenerico.formatoDinero(props.formikForm.values.Tarea?.dataComplete ?
                                                    props.formikForm.values.Tarea.dataComplete.importe - props.formikForm.values.Tarea.dataComplete.importeFacturado 
                                                    : 0)}
                                                </span>
                                            : null
                                        }
                                    </InputText>

                                    <InputTextArea
                                        label={props.languageTranslate.moduloAnticipo.detail.textNota}
                                        values={props.formikForm.values}
                                        onChange={props.onChange}
                                        disabled={props.configForm.mode === 'preview'}
                                        className='form-group w-100'
                                        name='Descripcion'
                                        isRequired
                                    />

                                    <label className='mb-2'>{props.languageTranslate.moduloGasto.detail.textDigitalizacionFactura} <span className='text-danger'>*</span></label>
                                    <ElementViewDocumentsCostume
                                        name='Files'
                                        title={props.languageTranslate.moduloGasto.detail.textDigitalizacionFactura}
                                        documents={props.formikForm.values['Files']}
                                        onChange={props.onChange}
                                        disabled={props.configForm.mode === 'preview'}
                                        maxFile={1}
                                    />

                                    <div className='group-button d-flex flex-column' style={{ gap: 10 }}>
                                        {   
                                            props.configForm.mode !== 'preview' ?
                                                <button className='btn btn-primary w-100' onClick={props.openModalFlujo}> {props.languageTranslate.moduloRendicion.detail.btnAprobacion} </button>
                                            : null
                                        }
                                        {
                                            props.formikForm.values.Estado.IdStatus === 3 ?
                                            <button className='btn btn-danger w-100' onClick={props.onCancelFlow}> {props.languageTranslate.btnCancelarFlujo} </button>
                                            : null
                                        }
                                    </div>
                                </>
                        }
                        
                    </div>

                </ElementStepperContent>
            </div>
        </div>
    )
}