// Library
import * as React from 'react';

// Controller
import { Controller } from "./Infraestructure/Controller";

// Components
import { ViewMain } from "./Components/View";
import { ModalAutocompletar } from './Components/Modals/ModalAutocompletar';
import { ModalDetalle } from './Components/Modals/ModalDetalle';
import { ModalAgregarItem } from './Components/Modals/ModalAgregarItem';
import { ModalNotas } from './Components/Modals/ModalNotas';
import { ModalFormAusencia } from './Components/Modals/ModalFormAusencia';

const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ViewMain
                openModalAutocompletar={controller.openModalAutocompletar}
                openModalDetalle={controller.openModalDetalle}
                openModalNote={controller.openModalNote}
                openModalAusencia={controller.openModalAusencia}
                configForm={controller.configForm}
                formikForm={controller.formikForm}
                onClearTareo={controller.onClearTareo}
                onSubmit={controller.onSubmit}
                onSubmitApproval={controller.onSubmitApproval}
                cancelWorkFlow={controller.cancelWorkFlow}
            />
            <ModalAutocompletar
                closeModalAutocompletar={controller.closeModalAutocompletar}
                configModalAutocomplete={controller.configModalAutocompletar}
                formModalAutocomplete={controller.formModalAutocompletar}
                onChangeMA={controller.onChangeMA}
                onSubmitMD={controller.onSubmitMA}
                configForm={controller.configForm}
                formikForm={controller.formikForm}
            />
            <ModalDetalle
                closeModalDetalle={controller.closeModalDetalle}
                configModalDetalle={controller.configModalDetalle}
                formModalDetalle={controller.formModalDetalle}
                onChangeMD={controller.onChangeMD}
                onSubmitMD={controller.onSubmitMD}
                openModalItem={controller.openModalItem}
                formikForm={controller.formikForm}
            />
            <ModalAgregarItem
                closeModalItem={controller.closeModalItem}
                configModalItem={controller.configModalItem}
                formModalItem={controller.formModalItem}
                onChangeMI={controller.onChangeMI}
                onSubmitMI={controller.onSubmitMI}
                configForm={controller.configForm}
            />
            <ModalNotas
                closeModalNotas={controller.closeModalNote}
                configModalNotas={controller.configModalNote}
                onChangeMN={controller.onChangeMN}
                onSubmitMN={controller.onSubmitMN}
            />
            <ModalFormAusencia
                closeModalAusencia={controller.closeModalAusencia}
                configForm={controller.configForm}
                configModalAusencia={controller.configModalAusencia}
                formModalAusencia={controller.formModalAusencia}
                onChangeModAUS={controller.onChangeModAUS}
                onSubmitModAUS={controller.onSubmitModAUS}
                formikForm={controller.formikForm}
            />
        </>
    );
};

export default ManagerEntity;