import { DtoRequestList } from "../Domain/DtoRequestList";
import { EntityMain } from "../Domain/EntityMain";
import { KeyTypeModule } from "../Domain/EntityTypeModule";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseReloadList {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: DtoRequestList, keyModule: KeyTypeModule): Promise<{ dataFormatted: EntityMain<any>[], data: any[] } | null> {
        return await this._exec(params, keyModule);
    }

    private async _exec(params: DtoRequestList, keyModule: KeyTypeModule): Promise<{ dataFormatted: EntityMain<any>[], data: any[] } | null> {
        return await this.repository.getList(params, keyModule);
    }
}