import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import './ElementCollapseCostume.scss';

interface ContainerProps {
    textTitle: string;
    isOpenDefault?: boolean;
    children?: JSX.Element;
    className?: string;
    ocult?: boolean;
    persistsOpen?: boolean;
}

export const ElementCollapseCostume = (props: ContainerProps) => {
    const [showContent, setShowContent] = useState(props.isOpenDefault);
    if (!!props.ocult) return <></>;

    return (
        <div className={`card ElementCollapseCostume shadow ${props.className || ''}`}>
            <div className="card-header fondoSeccion" onClick={() => props.persistsOpen ? null : setShowContent(!showContent)}>
                <h6 className='mb-0'> {props.textTitle} </h6>
                { props.persistsOpen ? null : <i className={`fa-solid fa-play ${showContent ? 'iconShow' : 'iconNotShow'}`} /> }
            </div>
            <Collapse in={showContent}>
                <div className="card-body">
                    { props.children }
                </div>
            </Collapse>
        </div>
    )
}