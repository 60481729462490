import { AdapterConfigure } from './AdapterConfigure';
import { RepositoryImplMain } from './RepositoryImplMain';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { changeSaludo, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { useState } from 'react';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';
import { UseCaseChangePassword } from '../Application/UseCaseChangePassword';
import { DtoReqChangePassword } from '../Domain/DtoReqChangePassword';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';

export const Controller = () => {
    const { auth, user } = useSelector((state: RootState) => state.auth);
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const languageTranslate = LanguageTranslate();

    const [showLanguage, setShowLanguage] = useState<boolean>(false);
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);

    const { language } = AdapterStorage.get('language');

    const formChangePassword = useFormik({
        initialValues: {
            newPassword: '',
            reNewPassword: '',
        } as any,
        validationSchema: Yup.object({
            newPassword: Yup.string().required('Ingresar Nueva Contraseña'),
            reNewPassword: Yup.string().required('Ingresar Repetir Nueva Contraseña').oneOf([Yup.ref('newPassword'), null], 'La contraseña deben coincidir')
        }),

        onSubmit: (values, formikHelpers) => { },
    });


    const init = async () => {
        try {
            dispatch(changeSaludo(false));
            dispatch(hideIconMenu());
            // dispatch(addLoading('Cargando datos...'));
            await loadData();
            // dispatch(removeLoading())
        } catch (error) {
            dispatch(removeLoading())
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning');
        }
    };

    const loadData = async () => {
    };

    const onChangeLanguage = async (code: string) => {
        AdapterStorage.set('language', code);
        window.location.reload();
    };

    const onChangePassword = async (name: string, value: any) => {
        formChangePassword.setFieldValue(name, value);
    };

    const onSubmitChangePassword = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formChangePassword.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formChangePassword.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            const param: DtoReqChangePassword = {
                IdUser: user.IdUser,
                Password1: formChangePassword.values.newPassword,
                Email: user.Email,
                Identificacion: user.Identificacion,
                TypeLogin: user.TypeLogin,
                idSocket: null,
                allData: null,
                Users: {
                    General: {
                        IdUser: user.IdUser,
                        User: user.User,
                        LastName: `${user.LastNameFather} ${user.LastNameMother}`,
                        Name: user.Name,
                        Perfil: user.Profile.filter(row => row.Principal.toLocaleLowerCase() === 'si')[0]?.Perfil ?? '',
                    }
                }
            }

            await (new UseCaseChangePassword(repository)).exec(param);
            formChangePassword.resetForm();
            AdapterGenerico.createMessage(languageTranslate.textoExitoso, languageTranslate.textoExitosoCambioPassword, 'success');
        } catch (error) {
            console.error(error);

            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        }
    }

    return {
        init,
        auth,
        user,

        showLanguage,
        setShowLanguage,
        language,
        onChangeLanguage,

        showChangePassword,
        setShowChangePassword,
        formChangePassword,
        onChangePassword,
        onSubmitChangePassword,
    };
}