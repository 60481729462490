import { EntityRequestDetail } from "../Domain/EntityRequestDetail";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseSave {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(data: EntityRequestDetail): Promise<boolean | null> {
        return await this._exec(data);
    }

    private async _exec(data: EntityRequestDetail): Promise<boolean | null> {
        return await this.repository.save(data);
    }
}