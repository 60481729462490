import { EntityGasto } from "./EntityGasto"

export type EntityRendicionGastoAprobacion = {
    _id: string
    Codigo: string
    Anticipo: {
      Codigo?: string;
      Nav?: string;
      DatosImporte?: {
        Moneda: {
          Codigo: string;
          Moneda: string;
          Nombre: string;
          Descripcion: string;
        },
        Solicitud: {
          Importe: number;
          IGV: number;
          ImporteIGV: number;
          ImporteTotal: number;
        },
        Inicial: {
          Importe: number;
          ImportePendiente: number;
        }
      }
    }
    DatosPersonal: {
      ApellidoMaterno: string
      ApellidoPaterno: string
      Identificacion: string
      Nombres: string
      TipoDocumento: string
    }
    DatosBanco: {
      Banco: string
      CuentaBancaria: string
      CuentaInterbancaria: string
      TipoCuenta: string
    }
    DatosEmpresa: {
      Codigo: string
      Empresa: string
      Grupo: string
    }
    DatosImporte: {
      Moneda: {
        Codigo: string
        Descripcion: string
        Moneda: string
        Nombre: string
      }
      Importe: number
      MontoIVA: number
      Total: number
    }
    DatosPais: {
      CDPais: string
      Codigo: string
      Nombre: string
    }
    DatosTrabajo: {
      Delegacion: {
        Codigo: string
        Delegacion: string
      }
      OT: {
        Codigo: string
        OT: string
      }
    }
    FechaEmision: {
      Date: string
      IdDate: number
    }
    GastoAsociado: Array<string>
    User: {
      LastName: string
      Name: string
      User: string
      IdUser: number
      Perfil: string
    }
    Notas: Array<{
      Titulo: string;
      Descripcion: string;
      Dates: {
        IdFecha: number;
        Fecha: string;
      }
      Users: {
        User: string;
        LastName: string;
        Name: string;
      }
    }>
    Users: {
      Registry: {
        User: string
        IdUser: number
        Perfil: string
        LastName: string
        Name: string
      }
      Review: {
        User: string
        LastName: string
        Name: string
      }
    }
    Status: {
      Nav: {
        IdStatus: number
        Status: string
      }
      Rendicion: {
        IdStatus: number
        Status: string
      }
      GIA: {
        IdStatus: number
        Status: string
        Codigo: string
      }
    }
    Dates: {
      Registry: {
        IdDate: number
        Date: string
      }
      Review: {
        IdFecha: number
        Fecha: string
      }
    }
    Aprobacion: {
      Nivel: number
    }
    Bitacora: Array<{
      Action: string
      Date: {
        IdDate: number
        Date: string
      }
      User: {
        User: string
        LastName: string
        Name: string
      }
      Description: string
    }>
    Flujo: {
      Id: number
      Codigo: string
      Code: string
      Name: string
      Descripcion: string
      Aprobacion: Array<{
        Nivel: number
        Monto: number
        Perfil: {
          IdPerfil: number
          Perfil: string
          Initial: string
        }
        IdNivel: number
        Status: {
          IdStatus: number
          Status: string
        }
        Aprobador: {
          LastNameFather: string
          LastNameMother: string
          Name: string
          Identificacion: string
          name: string
          FullName: string
        }
      }>
    }
    FlujoFormatted: {
      currentNivel: number;
      data: any[];
      stepNumberStart: number;
    }
    Gastos: Array<EntityGasto>
}

export const initEntityRendicionGastoAprobacion: EntityRendicionGastoAprobacion = {
  _id: "",
  Codigo: "",
  Anticipo: {
    Codigo: "",
    DatosImporte: undefined,
    Nav: "",
  },
  DatosPersonal: {
      ApellidoMaterno: "",
      ApellidoPaterno: "",
      Identificacion: "",
      Nombres: "",
      TipoDocumento: ""
  },
  DatosBanco: {
      Banco: "",
      CuentaBancaria: "",
      CuentaInterbancaria: "",
      TipoCuenta: ""
  },
  DatosEmpresa: {
      Codigo: "",
      Empresa: "",
      Grupo: ""
  },
  FlujoFormatted: {
    currentNivel: 0,
    data: [],
    stepNumberStart: 0
  },
  DatosImporte: {
      Moneda: {
          Codigo: "",
          Descripcion: "",
          Moneda: "",
          Nombre: ""
      },
      Importe: 0,
      MontoIVA: 0,
      Total: 0
  },
  DatosPais: {
      CDPais: "",
      Codigo: "",
      Nombre: ""
  },
  DatosTrabajo: {
      Delegacion: {
          Codigo: "",
          Delegacion: ""
      },
      OT: {
          Codigo: "",
          OT: ""
      }
  },
  FechaEmision: {
      Date: "",
      IdDate: 0
  },
  GastoAsociado: [],
  User: {
      LastName: "",
      Name: "",
      User: "",
      IdUser: 0,
      Perfil: ""
  },
  Notas: [],
  Users: {
      Registry: {
          User: "",
          IdUser: 0,
          Perfil: "",
          LastName: "",
          Name: ""
      },
      Review: {
          User: "",
          LastName: "",
          Name: ""
      }
  },
  Status: {
      Nav: {
          IdStatus: 0,
          Status: ""
      },
      Rendicion: {
          IdStatus: 0,
          Status: ""
      },
      GIA: {
          IdStatus: 0,
          Status: "",
          Codigo: ""
      }
  },
  Dates: {
      Registry: {
          IdDate: 0,
          Date: ""
      },
      Review: {
          IdFecha: 0,
          Fecha: ""
      }
  },
  Aprobacion: {
      Nivel: 0
  },
  Bitacora: [],
  Flujo: {
      Id: 0,
      Codigo: "",
      Code: "",
      Name: "",
      Descripcion: "",
      Aprobacion: []
  },
  Gastos: []
}