export interface EntityOptionsPDF {
    show: boolean;
    src: string;
    title: string;
}

export const initEntityOptionsPDF: EntityOptionsPDF = {
    show: false,
    src: "",
    title: ""
}