// Library
import * as React from 'react';

// Controller
import { Controller } from "./Infraestructure/Controller";

// Components
import { ViewMain } from "./Components/View";
import { ModalDetalle } from './Components/Modals/ModalDetalle';
import { ModalMessageReject } from './Components/Modals/ModalMessageReject';
import { ModalNotas } from './Components/Modals/ModalNotas';

const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ViewMain
                configForm={controller.configForm}
                openModalDetalle={controller.openModalDetalle}
                openModalMessageReject={controller.openModalMessageReject}
                confirmDetail={controller.confirmDetail}
                openModalNote={controller.openModalNote}
            />
            <ModalDetalle
                closeModalDetalle={controller.closeModalDetalle}
                configModalDetalle={controller.configModalDetalle}
            />
            <ModalMessageReject
                closeModalMessageReject={controller.closeModalMessageReject}
                configModalApproval={controller.configModalMessageReject}
                onChangeMMS={controller.onChangeMMS}
                onSubmitMMS={controller.onSubmitMMS}
            />
            <ModalNotas
                closeModalNotas={controller.closeModalNote}
                configModalNotas={controller.configModalNote}
                onChangeMN={controller.onChangeMN}
                onSubmitMN={controller.onSubmitMN}
            />
        </>
    );
};

export default ManagerEntity;