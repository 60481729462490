// Library
import React from 'react';

// Components
import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { ElementEmptyList } from '../../../shared/Components/ElementEmptyList';
import { ViewRendicionGastosCajaChica } from './module/ViewRendicionGastosCajaChica';
import { ViewRequerimientoCompra } from './module/ViewRequerimientoCompra';
import { ViewRequerimientoSistema } from './module/ViewRequerimientoSistema';
import { ViewSolicitudCajaChica } from './module/ViewSolicitudCajaChica';
import { ViewCondicionLaboral } from './module/ViewHojaCondicionLaboral';
import { ViewHojaPeticion } from './module/ViewHojaPeticion';
import { ViewComparativo } from './module/ViewComparativo';
import { ViewFactura } from './module/ViewFactura';
import { ViewTareo } from './module/ViewTareo';

// Infraestructure
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';

// Domain
import { EntityModeMultipleSelect } from '../Domain/EntityModeMultipleSelect';
import { EntityTypeModule } from '../Domain/EntityTypeModule';
import { EntityMain } from '../Domain/EntityMain';

// Style
import './Style.scss';
import { ViewAusencia } from './module/ViewAusencia';

interface PropsViewMain {
    data: EntityMain<any>[];
    onRedirectForm: (_id: string) => void;
    reloadData: Function;
    modeTypeModule: EntityTypeModule;
    copyCodeItem: (code: string) => Promise<void>;
    activeModeMultipleSelect: (active?: boolean) => void;
    onSelectItem: (code: string) => void;
    onSelectAllItem: () => void;
    modeMultipleSelect: EntityModeMultipleSelect;
    confirmDetail: (type: 'approve' | 'return') => Promise<any>;
    openModal: (key: "reject") => void
};

export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();
    const genericProps= (module: string) => ({
        data: props.data,
        onRedirectForm: props.onRedirectForm,
        copyCodeItem: props.copyCodeItem,
        modeMultipleSelect: props.modeMultipleSelect,
        onSelectItem: props.onSelectItem,
        show: props.modeTypeModule.key === module
    })

    return (
        <div className='ViewGenericList'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={props.modeTypeModule.navBar} />
                {
                    props.data.length > 0 ?
                    <div className='d-flex group-btn-option'>
                        { props.modeMultipleSelect.active ?
                        <>
                            <button onClick={props.onSelectAllItem} className='btn btn-outline-primary btn-seleccionar'>{ props.modeMultipleSelect.listCode.length === props.data.length ? languageTranslate.btnDescartar : languageTranslate.btnSeleccionar } {languageTranslate.btnTodo}</button>
                            <button onClick={() => props.activeModeMultipleSelect(false)} className='btn btn-danger btn-seleccionar'> <i className='fa-solid fa-xmark' style={{ fontSize: 14 }}/></button>
                        </>
                        : <button className='btn btn-primary btn-seleccionar' onClick={() => props.activeModeMultipleSelect(true)}>{languageTranslate.btnSeleccionar}</button> }
                    </div>
                    : null
                }

                <ViewComparativo {...genericProps('comparative')} />
                <ViewFactura {...genericProps('invoice')} />
                <ViewRequerimientoSistema {...genericProps('system-requirement')} />
                <ViewRequerimientoCompra {...genericProps('purchase-requirement')} />
                <ViewSolicitudCajaChica {...genericProps('petty-cash-request')} />
                <ViewRendicionGastosCajaChica {...genericProps('surrender-petty-cash-expenses')} />
                <ViewHojaPeticion {...genericProps('request-form')} />
                <ViewCondicionLaboral {...genericProps('working-conditions')} />
                <ViewTareo {...genericProps('tareo')} />
                <ViewAusencia {...genericProps('ausencia')} />

                {
                    props.data.length === 0 && ( 
                        <ElementEmptyList className="data-empty" text={languageTranslate.moduloGenericList.item.textoListaVacia} />
                    )
                }

                <div className="position-fixed reload bg-primary" onClick={() => props.reloadData()}>
                    <i className="fa-sharp fa-solid fa-arrow-rotate-right" style={{ fontSize: 20 }} />
                </div>

            </div>
            {
                props.modeMultipleSelect.active ?
                <div className='container_group_button shadow-lg'>
                    <div className='group_button'>
                        <button className='btn btn-danger' onClick={() => props.openModal('reject')} disabled={props.modeMultipleSelect.listCode.length === 0}>
                            <i className="fa-solid fa-arrow-rotate-left" style={{ fontSize: 14, marginRight: 12 }} />
                            {languageTranslate.moduloGenericDetail.btnTextDevolver} ({props.modeMultipleSelect.listCode.length})
                        </button>
                        <button className='btn btn-success' onClick={() => props.confirmDetail('approve')} disabled={props.modeMultipleSelect.listCode.length === 0}>
                            <i className="fa-solid fa-check" style={{ fontSize: 14, marginRight: 12 }} />
                            {languageTranslate.moduloGenericDetail.btnTextAprobar} ({props.modeMultipleSelect.listCode.length})
                        </button>
                    </div>
                </div>
                : null
            }
        </div >
    )
};