import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => {
        }
        // eslint-disable-next-line
    }, []);

    return (<ViewMain
        auth={controller.auth}
        user={controller.user}
        language={controller.language}

        showLanguage={controller.showLanguage}
        setShowLanguage={controller.setShowLanguage}
        onChangeLanguage={controller.onChangeLanguage}

        showChangePassword={controller.showChangePassword}
        setShowChangePassword={controller.setShowChangePassword}
        formChangePassword={controller.formChangePassword}
        onChangePassword={controller.onChangePassword}
        onSubmitChangePassword={controller.onSubmitChangePassword}
    />);
};

export default ManagerEntity;