import React from 'react';
import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { EntityLanguage } from '../../../shared/Domain/EntityLanguage';
import { EntityMenu } from '../../../shared/Domain/EntityMenu';
import './Style.scss';

interface ContainerProps {
    listMenu: EntityMenu[];
    onClickMenu(row: EntityMenu): void;
    languageTranslate: EntityLanguage;
    WorkflowPending: any;
}

export const ViewMain = (props: ContainerProps) => {
    return (
        <div className="ViewMainApprovals">
            <div className="container-fluid">
                <ElementBreadCrumb list={props.languageTranslate.moduloApprovals.navBar} />
                <div className="container">
                    {
                        props.listMenu.map(row => (
                            <div className="row pb-3" key={row.id}>
                                <div className="col-12">
                                    <div className='shadow-lg' style={{ width: '100%', padding: 5, margin: 0, borderRadius: '10px', cursor: 'pointer' }} onClick={() => props.onClickMenu(row)}>
                                        <div className="row">
                                            <div className="col-2">
                                                <button className='GroupCount btn btn-primary btn-icon-first' style={{ marginTop: '5px' }}>
                                                    <i className={`text-white ${row.icono}`} />
                                                    { props.WorkflowPending[row.sigla] ? <div>{ props.WorkflowPending[row.sigla] }</div> : null }
                                                </button>
                                            </div>
                                            <div className="col-7" style={{ placeSelf: 'center' }}>
                                                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{props.languageTranslate.menu[row.codigo as keyof typeof props.languageTranslate.menu]?.titulo}</span>
                                            </div>
                                            <div className="col-3 d-flex">
                                                <p className='btn btn-lg btn-primary text-white text-center mb-0 ms-auto'>
                                                    {">"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}