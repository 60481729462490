import { ElementCollapseCostume } from "../../../../shared/Components/ElementCollapseCostume"
import { ElementTableCostume } from "../../../../shared/Components/ElementTableCostume";
import { EntityRequestForm } from "../../../../shared/Domain/EntityRequestForm";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { EntityListContent } from "../../Domain/EntityListContent";
import { EntityRequestDownloadFile } from "../../Domain/EntityRequestDownloadFile";
import { TableGenericDoc } from "../generic/TableGenericDoc";

interface ContainerProps {
    listContent: EntityListContent<EntityRequestForm>;
    viewDocument: (payload: EntityRequestDownloadFile, code: string) => Promise<any>;
    show: boolean;
    copyCodeItem: Function;
}

export const ViewHojaPeticion = (props: ContainerProps) => {
    if (!props.show) return null;
    const languageTranslate = LanguageTranslate();

    return (
        <>
            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemDetail.textTitle}
                isOpenDefault
                persistsOpen
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <div className='d-flex' onClick={(evt) => props.copyCodeItem(props.listContent.detail.codigo)}>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textCodigo}:</span>
                        <span className='text-muted'> {props.listContent.detail.codigo} </span>
                        <button className='icon-copy-custom'><i className="fa-solid fa-copy" /></button>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textPais}:</span>
                        <span className='text-muted'> {props.listContent.detail.pais} </span>
                        <span> {" "}|{" "} </span>
                        <span> {languageTranslate.moduloGenericDetail.itemDetail.textGrupo}: </span>
                        <span className='text-muted'> {props.listContent.detail.grupo} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textEmpresa}:</span>
                        <span className='text-muted'> {props.listContent.detail.empresa} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textOT}:</span>
                        <span className='text-muted'> {props.listContent.detail.ot} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textSolicitante}:</span>
                        <span className='text-muted'> {props.listContent.detail.solicitante} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textCargoSolicitante}:</span>
                        <span className='text-muted'> {props.listContent.detail.cargoSolicitante} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textObservaciones}:</span>
                        <span className='text-muted'> {props.listContent.detail.observaciones} </span>
                    </div>
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemPersonal.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textModalidadTrabajo}:</span>
                        <span className='text-muted'> {props.listContent.personal?.modalidadTrabajo} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textMotivoSolicitud}:</span>
                        <span className='text-muted'> {props.listContent.personal?.motivoSolicitud} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textTipoContrato}:</span>
                        <span className='text-muted'> {props.listContent.personal?.tipoContrato} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textPuesto}:</span>
                        <span className='text-muted'> {props.listContent.personal?.puesto} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textTipoDocumento}:</span>
                        <span className='text-muted'> {props.listContent.personal?.tipoDocumento.trim()} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textIdentificacion}:</span>
                        <span className='text-muted'> {props.listContent.personal?.identificacion} {props.listContent.personal?.nuevoIngreso ? <span className="text-danger">Nuevo Ingreso</span> : ''}</span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textNombreCompleto}:</span>
                        <span className='text-muted'> {props.listContent.personal?.nombreCompleto} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textFechaAlta}:</span>
                        <span className='text-muted'> {props.listContent.personal?.fechaAlta} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textFechaBaja}:</span>
                        <span className='text-muted'> {props.listContent.personal?.fechaBaja} </span>
                    </div>
                    <hr />
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textVehiculoPropio}:</span>
                        <span className='text-muted'> {props.listContent.personal?.vehiculoPropio} </span>
                        <span> {" "}|{" "} </span>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textVehiculoEmpresa}:</span>
                        <span className='text-muted'> {props.listContent.personal?.vehiculoEmpresa} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textHoraExtra}:</span>
                        <span className='text-muted'> {props.listContent.personal?.horaExtra} </span>
                        <span> {" "}|{" "} </span>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textPrimaProduccion}:</span>
                        <span className='text-muted'> {props.listContent.personal?.primaProduccion} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textBonoTurnoNoche}:</span>
                        <span className='text-muted'> {props.listContent.personal?.bonoTurnoNoche} </span>
                        <span> {" "}|{" "} </span>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textBonoTurnos}:</span>
                        <span className='text-muted'> {props.listContent.personal?.bonoTurnos} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textRetribucionMensual}:</span>
                        <span className='text-danger'> {props.listContent.personal?.retribucionMensual} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textRetribucionAnual}:</span>
                        <span className='text-danger'> {props.listContent.personal?.retribucionAnual} </span>
                    </div>
                    <hr />
                    <div className='d-flex flex-wrap'>
                        <span>{languageTranslate.moduloGenericDetail.itemPersonal.textFunciones }:</span>
                        <span className='text-muted'> {props.listContent.personal?.funciones} </span>
                    </div>
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemBitacora.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <ElementTableCostume
                        listHeader={languageTranslate.moduloGenericDetail.itemBitacora.headerTable}
                        listData={props.listContent.bitacora}
                    />
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemDocumentos.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <TableGenericDoc
                        payload={props.listContent.documento}
                        viewDocument={props.viewDocument}
                    />
                </div>
            </ElementCollapseCostume>
        </>
    )
}