import React, { useEffect, useState } from 'react';
import './ElementMenuTab.scss';

interface ContainerProps {
    MenuList: {
        key: string;
        title: string;
        content: React.Component | any | null;
    }[];
    defaultKey?: string;
}

export const ElementMenuTab = (props: ContainerProps) => {
    const [currentKey, setCurrentKey] = useState<string>('');

    useEffect(() => {
        if (!currentKey && props.MenuList.length > 0) setCurrentKey(props.MenuList[0].key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.defaultKey) setCurrentKey(props.defaultKey);
    }, [props.defaultKey]);

    return (
        <div className='ElementMenuTab'>
            <div className='ContentHeaderTab'>
                <div className='ContainerItems'>
                    {
                        props.MenuList.map(row => 
                            <div key={row.key} className={`ItemHeader ${ currentKey === row.key ? 'ItemHeaderSelect' : '' }`} onClick={() => setCurrentKey(() => row.key)}>
                                { row.title }
                            </div>
                        )
                    }
                </div>
            </div>
            <div className='ContentBodyTab'>
                { props.MenuList.find(row => row.key === currentKey)?.content }
            </div>
        </div>
    )
}