import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityResponseMyAccount, EntityResponseFormattedMyAccount } from "../Domain/EntityReponse";
import { EntityRequestMyAccount } from "../Domain/EntityRequest";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async getStatusMyAccount(params: EntityRequestMyAccount): Promise<EntityResponseFormattedMyAccount | null> {
        try {
            const url = `${this.urlBaseGastos}/Rendicion/Informe/statusAccountUser`;
            const response: EntityResponseMyAccount = await this.service.call<any>("POST", url, JSON.stringify(params), "bearer", "json", 'json',  { "request-decrypt-response": true }, 0);
            if (!response) return null;

            return ({
                Rendiciones: response.Rendiciones.map(row => ({
                    Codigo: `${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_RENDICION_GASTOS}/${row.Codigo}`,
                    OT: row.DatosTrabajo.OT.OT,
                    Importe: row.DatosImporte.Importe,
                })),
                Anticipos: response.Anticipos.map(row => ({
                    Codigo: `${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_SOLICITUD_ANTICIPO}/${row.Codigo}`,
                    OT: row.DatosTrabajo.OT.OT,
                    Importe: row.DatosImporte.Solicitud.Importe,
                })),
                AnticiposAprobacion: response.AnticiposAprobacion.map(row => ({
                    Codigo: `${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_SOLICITUD_ANTICIPO}/${row.Codigo}`,
                    OT: row.DatosTrabajo.OT.OT,
                    Importe: row.DatosImporte.Solicitud.Importe,
                })),
                AnticiposPendientePago: response.RegistrosPendientePago.map(row => ({
                    Codigo: `${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_SOLICITUD_ANTICIPO}/${row.Codigo}`,
                    OT: row.DatosTrabajo?.OT?.OT || '',
                    Importe: row.DatosImporte?.Solicitud?.Importe | 0,
                })),
                Monto: {
                    Pendiente: response.Monto.Pendiente,
                    PendientePago: response.Monto.PendientePago,
                    Total: (response.Monto.PendientePago + response.Monto.Pendiente)
                }
            });
        } catch(err) {
            return null
        }
    }
}