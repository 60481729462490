export interface EntityUserAccess {
    _id: string;
    IdUser: number
    Code: number
    icon: string
    Description: string
    Access: {
      Perfil: {
        IdPerfil: number
        Perfil: string
      }
      Global: Array<EntityGlobal>;
      Globals: Array<EntityGlobal>;
    }
    Padre: Array<{
      IdPadre: number
      Padre: string
    }>
    Mostrar: string
}

interface EntityGlobal {
    Code: string
    Child?: Array<EntityGlobal>
    Status: number
    Check: boolean
}

export interface EntityUserAccessData {
    Code: string
    Codigo: string
    Name: string
    Mostrar: string
    Child: Array<EntityUserAccessData>
    Status: number
    Check: boolean
    DataAdicional: {
      Open: boolean
      type: string
    }
}

export interface EntityUserAccessDataFormatted {
    OT: string[];
    Delegacion: string[];
    Pais: string[];
    Grupo: string[];
}

export const deleteDuplicatePermiss = (data: EntityUserAccessData[]): EntityUserAccessData[] => {
  const uniqueObjects = new Set(data.map((row) => JSON.stringify(row)));
  return Array.from(uniqueObjects).map((row) => JSON.parse(row));
}

export const GetDataForUser = (data: EntityUserAccessData[]): EntityUserAccessDataFormatted => {
  return data.reduce((prev, current, index) => {
    let key = current.DataAdicional.type.toLocaleLowerCase();
    switch(key) {
      case 'pais': prev.Pais.push(current.Codigo); break;
      case 'delegacion': prev.Delegacion.push(current.Code); break;
      case 'ot': prev.OT.push(current.Code); break;
      default: prev.Grupo.push(current.Codigo);
    }
    if (current.Child && current.Child.length > 0) {
      const result = GetDataForUser(current.Child);
      prev.OT = prev.OT.concat(result.OT).filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);
      prev.Delegacion = prev.Delegacion.concat(result.Delegacion).filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);
      prev.Pais = prev.Pais.concat(result.Pais).filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);
      prev.Grupo = prev.Grupo.concat(result.Grupo).filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);
    }
    return prev;
  }, { OT: [], Delegacion: [], Pais: [], Grupo: []} as EntityUserAccessDataFormatted)
}