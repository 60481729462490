import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import { ViewModalFiltro } from './Components/modal/ViewModalFiltro';


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        // eslint-disable-next-line
    }, [controller.countProcess]);

    return (
    <>
        <ViewMain
            data={controller.data}
            onRedirectForm={controller.onRedirectForm}
            reloadData={controller.loadData}
            modeTypeModule={controller.modeTypeModule}
            copyCodeItem={controller.copyCodeItem}
            openModalFiltro={controller.openModalFiltro}
        />
        <ViewModalFiltro
            ModalFiltroConfig={controller.modalFiltroConfig}
            closeModal={controller.closeModalFiltro}
            onChangeModalFiltro={controller.onChangeModalFiltro}
            onSubmitModalFiltro={controller.onSubmitModalFiltro}
        />
    </>
    );
};

export default ManagerEntity;