import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import { ViewModal } from './Components/module/ViewModal';


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        // eslint-disable-next-line
    }, [controller.countProcess]);

    return (
    <>
        <ViewMain
            data={controller.data}
            onRedirectForm={controller.onRedirectForm}
            reloadData={controller.loadData}
            modeTypeModule={controller.modeTypeModule}
            copyCodeItem={controller.copyCodeItem}
            modeMultipleSelect={controller.modeMultipleSelect}
            activeModeMultipleSelect={controller.activeModeMultipleSelect}
            onSelectItem={controller.onSelectItem}
            onSelectAllItem={controller.onSelectAllItem}
            confirmDetail={controller.confirmDetail}
            openModal={controller.openModal}
        />
        <ViewModal
            configModal={controller.configModal}
            closeModal={controller.closeModal}
            onChange={controller.onChange}
            onSubmit={controller.onSubmitM}
        />
    </>
    );
};

export default ManagerEntity;