import { InputTextArea } from "../../../../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../../../../shared/Components/ElementModalCostume";
import { LanguageTranslate } from "../../../../../../shared/Infraestructure/LanguageTranslate";
import { EntityConfigModalNotas } from "../../Domain/EntityUtils";

interface ContainerProps {
    closeModal(): void;
    onSubmit(evt: Event): void;
    onChange(name: string, value: string): void; 
    configModal: EntityConfigModalNotas;
}

const languageTranslate = LanguageTranslate();

export const ViewModalNota = (props: ContainerProps) => {
    return (
        <ElementModalCostume
            closeModal={props.closeModal}
            show={props.configModal.show}
            title={props.configModal.title}
            disabledButtonClose
            height={"320px"}
        >
            <div>
                <InputTextArea
                    label="Comentario"
                    name="comment"
                    onChange={props.onChange}
                    values={props.configModal}
                    isRequired
                />
            </div>
            <div className="w-100 d-flex" style={{ gap: 10 }}>
                <button className="btn btn-dark" style={{ width: '50%' }} onClick={props.closeModal}>{languageTranslate.btnCancelar}</button>
                <button className={`btn btn-primary`} style={{ width: '50%' }} onClick={(evt: any) => props.onSubmit(evt)}>{languageTranslate.btnGuardar}</button>
            </div>
        </ElementModalCostume>
    )
}