import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { EntityComparative } from "../../../shared/Domain/EntityComparative";
import { EntityFactura } from "../../../shared/Domain/EntityFactura";
import { EntityPettyCashRequest } from "../../../shared/Domain/EntityPettyCashRequest";
import { EntityPurchaseRequirement } from "../../../shared/Domain/EntityPurchaseRequirement";
import { EntitySurrenderPettyCashExpenses } from "../../../shared/Domain/EntitySurrenderPettyCashExpenses";
import { EntitySystemRequirement } from "../../../shared/Domain/EntitySystemRequirement";
import { ErrorCostume } from "../../../shared/Domain/ErrorCostume";
import { AdapterCopyText } from "../../../shared/Infraestructure/AdapterCopyText";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { addLoading, changeSaludo, removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { UseCaseReloadList } from "../Application/UseCaseReloadList";
import { UseCaseSave } from "../Application/UseCaseSave";
import { EntityMain } from "../Domain/EntityMain";
import { EntityModeMultipleSelect, EntityModeMultipleSelectInit } from "../Domain/EntityModeMultipleSelect";
import { EntityRequestDetail } from "../Domain/EntityRequestDetail";
import { EntityTypeModule, KeyTypeModule } from "../Domain/EntityTypeModule";
import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { EntityRequestForm } from "../../../shared/Domain/EntityRequestForm";
import { EntityConfigModal, initEntityConfigModal, keyModal } from "../Domain/EntityUtils";

let nameStore: "Comparativo" | "Factura" | "RequerimientoSistema" | "RequerimientoCompra" | "SolicitudCajaChica" | "RendicionGastosCajaChica" | "HojaPeticion" | "CondicionLaboral" | "TareoAprobacion" | "AusenciaAprobacion" | '';
let moduleKey: KeyTypeModule = '';

export const Controller = () => {
    const { generico: { websocket, dbLocal, countProcess }, auth: { user } } = useSelector((state: RootState) => state);
    const [data, setData] = useState<Array<EntityMain<any>>>([]);
    const [modeTypeModule, setModeTypeModule] = useState<EntityTypeModule>({ key: '', navBar: [] });
    const [modeMultipleSelect, setModeMultipleSelect] = useState<EntityModeMultipleSelect>(EntityModeMultipleSelectInit)
    const [configModal, setConfigModal] = useState<EntityConfigModal>(initEntityConfigModal);

    const languageTranslate = LanguageTranslate();
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const init = async () => {
        dispatch(changeSaludo(false));
        configModeType();
    };

    const configModeType = async () => {
        const arrPathname = window.location.pathname.split('/');
        const validationPathName = (module: string) => arrPathname.some(item => item === module);
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_COMPARATIVO}`)) initModeComparative();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_FACTURA}`)) initModeInvoice();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_SISTEMA}`)) initModeSystemRequirement();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_COMPRA}`)) initModePurcharseRequirement();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_SOLICITUD_CAJA_CHICA}`)) initModePettyCashRequest();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_RENDICION_GASTOS_CAJA_CHICA}`)) initModeSurrenderPettyCashExpenses();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_HOJA_PETICION}`)) initModeRequestForm();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_CONDICION_LABORALES}`)) initModeWorkingConditions();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_TAREO}`)) initModeTareo();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_AUSENCIA}`)) initModeAusencia();

        if (navigator.onLine) loadData();
    }

    const initModeComparative = async () => {
        setModeTypeModule(() => ({ key: 'comparative', navBar: languageTranslate.moduloGenericList.navBarComparativo }));
        moduleKey = 'comparative';
        nameStore = "Comparativo";
        if (!navigator.onLine) {
            const response: EntityComparative[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatComparativeToGenericList(response)));
        }
    }

    const initModeInvoice = async () => {
        setModeTypeModule(() => ({ key: 'invoice', navBar: languageTranslate.moduloGenericList.navBarFactura }));
        moduleKey = 'invoice';
        nameStore = "Factura";
        if (!navigator.onLine) {
            const response: EntityFactura[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatInvoiceToGenericList(response)));
        }
    }

    const initModeSystemRequirement = async () => {
        setModeTypeModule(() => ({ key: 'system-requirement', navBar: languageTranslate.moduloGenericList.navBarRequerimientoSistema }));
        moduleKey = 'system-requirement';
        nameStore = "RequerimientoSistema";
        if (!navigator.onLine) {
            const response: EntitySystemRequirement[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatSystemRequirementToGenericList(response)));
        }
    }

    const initModePurcharseRequirement = async () => {
        setModeTypeModule(() => ({ key: 'purchase-requirement', navBar: languageTranslate.moduloGenericList.navBarRequerimientoCompra }));
        moduleKey = 'purchase-requirement';
        nameStore = "RequerimientoCompra";
        if (!navigator.onLine) {
            const response: EntityPurchaseRequirement[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatPurcharseRequirementToGenericList(response)));
        }
    }

    const initModePettyCashRequest = async () => {
        setModeTypeModule(() => ({ key: 'petty-cash-request', navBar: languageTranslate.moduloGenericList.navBarSolicitudCajaChica }));
        moduleKey = 'petty-cash-request';
        nameStore = "SolicitudCajaChica";
        if (!navigator.onLine) {
            const response: EntityPettyCashRequest[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatPettyCashRequestToGenericList(response)));
        }
    }

    const initModeSurrenderPettyCashExpenses = async () => {
        setModeTypeModule(() => ({ key: 'surrender-petty-cash-expenses', navBar: languageTranslate.moduloGenericList.navBarRendicionGastosCajaChica }));
        moduleKey = 'surrender-petty-cash-expenses';
        nameStore = "RendicionGastosCajaChica";
        if (!navigator.onLine) {
            const response: EntitySurrenderPettyCashExpenses[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatSurrenderPettyCashExpensesToGenericList(response)));
        }
    }

    const initModeRequestForm = async () => {
        setModeTypeModule(() => ({ key: 'request-form', navBar: languageTranslate.moduloGenericList.navBarHojaPeticion }));
        moduleKey = 'request-form';
        nameStore = "HojaPeticion";
        if (!navigator.onLine) {
            const response: EntityRequestForm[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatRequestFormToGenericList(response)));
        }
    }

    const initModeWorkingConditions = async () => {
        setModeTypeModule(() => ({ key: 'working-conditions', navBar: languageTranslate.moduloGenericList.navBarWorkingConditions }));
        moduleKey = 'working-conditions';
        nameStore = "CondicionLaboral";
        if (!navigator.onLine) {
            const response: EntityRequestForm[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatRequestFormToGenericList(response)));
        }
    }

    const initModeTareo = async () => {
        setModeTypeModule({ key: 'tareo', navBar: languageTranslate.moduloGenericList.navBarTareo });
        moduleKey = 'tareo';
        nameStore = 'TareoAprobacion';
        if (!navigator.onLine) {
            const response: EntityRequestForm[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatRequestFormToGenericList(response)));
        }
    }

    const initModeAusencia = async () => {
        setModeTypeModule({ key: 'ausencia', navBar: languageTranslate.moduloGenericList.navBarAusencia });
        moduleKey = 'ausencia';
        nameStore = 'AusenciaAprobacion';
        if (!navigator.onLine) {
            const response: EntityRequestForm[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatRequestFormToGenericList(response)));
        }
    }

    const loadData = async () => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const response = await (new UseCaseReloadList(repository).exec({ identificacion: user.Identificacion }, moduleKey));
            if (response === null) return;
            await dbLocal.clearStore(nameStore);
            await dbLocal.insertDataStore([{ nameStore, data: response.data }]);
            setData(() => (response.dataFormatted));
        } catch (err) {
            let error: ErrorCostume = new ErrorCostume((err as Error).message);
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    };

    const copyCodeItem = async (code: string) => {
        await AdapterCopyText.copyText(code);
        AdapterGenerico.createToast(`${languageTranslate.textoCopiar}`, 'success');
    }

    const onRedirectForm = (_id: string) => {
        switch(modeTypeModule.key) {
            case "comparative": navigate(`${AdapterConfigure.ROUTE_PREVIEW_COMPARATIVE}/${_id}`, { replace: true }); break;
            case "invoice": navigate(`${AdapterConfigure.ROUTE_PREVIEW_INVOICE}/${_id}`, { replace: true }); break;
            case "system-requirement": navigate(`${AdapterConfigure.ROUTE_PREVIEW_SYSTEM_REQUIREMENT}/${_id}`, { replace: true }); break;
            case "purchase-requirement": navigate(`${AdapterConfigure.ROUTE_PREVIEW_PURCHARSE_REQUIREMENT}/${_id}`, { replace: true }); break;
            case "petty-cash-request": navigate(`${AdapterConfigure.ROUTE_PREVIEW_PETTY_CASH_REQUEST}/${_id}`, { replace: true }); break;
            case "surrender-petty-cash-expenses": navigate(`${AdapterConfigure.ROUTE_PREVIEW_SURRENDER_PETTY_CASH_EXPENSES}/${_id}`, { replace: true }); break;
            case "request-form": navigate(`${AdapterConfigure.ROUTE_PREVIEW_REQUEST_FORM}/${_id}`, { replace: true }); break;
            case "working-conditions": navigate(`${AdapterConfigure.ROUTE_PREVIEW_WORKING_CONDITIONS}/${_id}`, { replace: true }); break;
            case "tareo": navigate(`${AdapterConfigure.ROUTE_PREVIEW_TAREO}/${_id}`, { replace: true }); break;
            case "ausencia": navigate(`${AdapterConfigure.ROUTE_PREVIEW_AUSENCIA}/${_id}`, { replace: true }); break;
        };
    };

    const activeModeMultipleSelect = (active: boolean = true) => setModeMultipleSelect(() => ({ active, listCode: [] }));
    const onSelectItem = (code: string) => {
        let listCode = modeMultipleSelect.listCode;
        let findedIndex = listCode.findIndex(row => row === code);
        findedIndex === -1 ? listCode.push(code) : listCode.splice(findedIndex, 1);
        setModeMultipleSelect(prev => ({...prev, listCode}));
    }
    const onSelectAllItem = () => {
        const listCode = modeMultipleSelect.listCode.length === data.length ? [] : data.map(row => row.Codigo);
        setModeMultipleSelect(prev => ({...prev, listCode}));
    }
    const confirmDetail = async (type: 'approve' | 'return') => {
        let listNotAsociated = modeMultipleSelect.listCode.filter(code => (data.filter(row => row.Codigo === code && row.FaltaAsociacion).length > 0 && modeTypeModule.key === 'invoice' && type === 'approve'));
        if (listNotAsociated.length > 0) return AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.facturaNoAsociada, 'error', false)

        let moduleText: string = '';
        switch(modeTypeModule.key) {
            case 'comparative': moduleText = languageTranslate.moduloApprovals.question.textoComparativo; break;
            case 'invoice': moduleText = languageTranslate.moduloApprovals.question.textoFactura; break;
            case 'system-requirement': moduleText = languageTranslate.moduloApprovals.question.textoRequerimientoSistema; break;
            case 'purchase-requirement': moduleText = languageTranslate.moduloApprovals.question.textoRequerimientoCompra; break;
            case 'petty-cash-request': moduleText = languageTranslate.moduloApprovals.question.textoSolicitudCajaChica; break;
            case 'surrender-petty-cash-expenses': moduleText = languageTranslate.moduloApprovals.question.textoRendicionGastoCajaChica; break;
            case 'request-form': moduleText = languageTranslate.moduloApprovals.question.textoHojaPeticion; break;
        };

        const message = `${languageTranslate.moduloApprovals.question.textoDesea} ${type === 'approve' ? languageTranslate.moduloApprovals.question.textoAprobar : languageTranslate.moduloApprovals.question.textoDevolver} ${moduleText}`;
        const isAccept = await AdapterGenerico.createMessage('', message, 'info', true);
        if (!isAccept) return;
        
        dispatch(addLoading({ textLoading: languageTranslate.textoEnviando }))
        let result = await saveBatch(type);
        dispatch(removeLoading());
        if (result !== 0) await AdapterGenerico.createMessage('', type === 'approve' ? languageTranslate.moduloGenericDetail.errors.aproveBatch : languageTranslate.moduloGenericDetail.errors.returnBatch, 'error', true);
        else {
            setModeMultipleSelect({active: false, listCode: []});
            AdapterGenerico.createToast(languageTranslate.moduloGenericDetail.successBatch, 'success');
        }
        init();
    }

    const saveBatch = async (type: 'approve' | 'return') => {
        const list = data.filter(row => modeMultipleSelect.listCode.includes(row.Codigo));
        let countServicesSuccess = list.length;

        for (let row of list) {
            const updateDate = (row.dataComplete.Dates as any)?.Update?.Date;
            const bitacora = row.dataComplete.Bitacora || [];
            let paramDate = !!updateDate ? updateDate : (bitacora.length === 0 ? '' : bitacora[(bitacora.length - 1)].Date.Date);

            const payload: EntityRequestDetail = {
                paramsSend: {
                    codigo: row.Codigo,
                    identificacion: user.Identificacion,
                    fecha: paramDate
                },
                extraConfig: {
                    dataComplete: row.dataComplete,
                    moduleKey: modeTypeModule.key,
                    nameStore: nameStore,
                    typeSave: type
                }
            }

            // Asignar parámetro comentario
            if (type === 'return') payload.paramsSend.comentario = configModal.comment
    
            try {
                let response = await new UseCaseSave(repository).exec(payload);
                if (response) countServicesSuccess-= 1;
            }
            catch (error) { }
        }

        return countServicesSuccess;
    }

     // Config Modal
     const openModal = (key: keyModal) => {
        setConfigModal(prev => ({
            ...prev,
            title: 'Rechazar',
            comment: '',
            description: 'Por favor confirme esta acción y agregue un comentario explicando por qué está devolviendo este registro.',
            key,
            show: true
        }))
    }

    const closeModal = () => {
        setConfigModal(prev => ({
            ...prev,
            title: '',
            comment: '',
            description: '',
            key: 'reject',
            show: false
        }))
    }

    const onChange = (name: string, value: string) => {
        setConfigModal(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const onSubmitM = async (evt: Event) => {
        evt.preventDefault();
        dispatch(addLoading({ textLoading: languageTranslate.textoEnviando }))
        let result = await saveBatch('return');
        dispatch(removeLoading());
        if (result !== 0) await AdapterGenerico.createMessage('', languageTranslate.moduloGenericDetail.errors.returnBatch, 'error', true);
        else {
            closeModal();
            setModeMultipleSelect({active: false, listCode: []});
            AdapterGenerico.createToast(languageTranslate.moduloGenericDetail.successBatch, 'success');
        }
        init();
    }

    return {
        data,
        countProcess,
        modeTypeModule,
        modeMultipleSelect,

        init,
        onRedirectForm,
        loadData,
        copyCodeItem,
        activeModeMultipleSelect,
        onSelectItem,
        onSelectAllItem,
        confirmDetail,

        // Config Modal
        onSubmitM,
        onChange,
        openModal,
        closeModal,
        configModal
    };
}