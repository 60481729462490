import { EntityMenu } from '../../../shared/Domain/EntityMenu';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import './Style.scss';

interface PropsView {
    menu: Array<EntityMenu>;
    onClickAyuda: Function;
    onClickReport: Function;
    onClickMenu: Function;
};

const styleItem: any = {
    "010": "ItemModuleHome",
    "020": "ItemModuleDocucobra",
    "030": "ItemModuleCajaChica",
    "040": "ItemModuleTareo",
}

export const ViewMain = (props: PropsView) => {
    const languageTranslate = LanguageTranslate();

    return (
        <div className="ViewMasterHome">
            <div className="container">
                {
                    props.menu.map(row => (
                        <div className="row pb-3" key={row.id}>
                            <div className="col-12">
                                <div className={`shadow-lg ${styleItem[row.codigo]} ItemModuleGeneric`} onClick={() => props.onClickMenu(row)}>
                                    <div className='content position-relative'>
                                        <div className='position-absolute d-flex' style={{ bottom: 10, left: 20 }}>
                                            <span className='fw-semibold' style={{ fontSize: 14, marginRight: 8 }}>{languageTranslate.menu[row.codigo as keyof typeof languageTranslate.menu].titulo}</span>
                                            <i style={{ fontSize: 20 }} className="fa-solid fa-circle-chevron-right" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
};