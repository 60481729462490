import { EntityMoneda } from "../../../../../shared/Domain/Catalogos/EntityMoneda";
import { EntityOT } from "../../../../../shared/Domain/Catalogos/EntityOT";
import { EntityProveedor } from "../../../../../shared/Domain/Catalogos/EntityProveedor";
import { EntitySelectBase } from "../../../../../shared/Domain/Catalogos/EntitySelectBase";
import { EntityTipoDocumento } from "../../../../../shared/Domain/Catalogos/EntityTipoDocumento";
import { EntityTipoDocumentoTributario } from "../../../../../shared/Domain/Catalogos/EntityTipoDocumentoTributario";
import { EntityVAT } from "../../../../../shared/Domain/Catalogos/EntityVAT";
import { EntityServicioCustom } from "./EntityUtils";

export type EntityRequestGenericForm =  {
    Codigo: string;
    OT: EntitySelectBase<EntityOT>;
    Proveedor: EntitySelectBase<EntityProveedor>;
    TipoDocumentoProveedor?: EntitySelectBase<EntityTipoDocumento>;
    NumeroDocumentoProveedor?: string;
    RazonSocialProveedor?: string;
    FechaEmision: string;
    TipoDocumento: EntitySelectBase<EntityTipoDocumentoTributario>;
    NumeroDocumento: string;
    Servicio: EntitySelectBase<EntityServicioCustom>;
    Importe: number;
    TipoImpuesto: EntitySelectBase<EntityVAT>;
    ImporteImpuesto?: number;
    ImporteTotal: number;
    Observacion: string;
    Files: DocumentFile[];
    // Divisa?: EntitySelectBase<EntityMoneda>;
    Pais: {
        CDPais: string;
        Codigo: string;
        Nombre: string;
    };
    Delegacion: {
        Code: string;
        Codigo: string;
        Delegacion: string;
    }
    OtroProveedor: boolean;
    OtroImpuesto: boolean;
    User: {
        User: string;
        LastName: string;
        Name: string;
    }
}

export const initEntityRequestGenericForm: EntityRequestGenericForm = {
    Codigo: "",
    OT: null as any,
    Proveedor: null  as any,
    FechaEmision: "",
    TipoDocumento: null as any,
    NumeroDocumento: "",
    Servicio: null as any,
    Importe: 0,
    TipoImpuesto: null as any,
    ImporteTotal: 0,
    Observacion: "",
    Files: [],
    Pais: { CDPais: "", Codigo: "", Nombre: "" },
    Delegacion: { Code: "", Codigo: "", Delegacion: "" },
    OtroProveedor: false,
    OtroImpuesto: false,
    User: { LastName: "", Name: "", User: "" },
    // Divisa: undefined
}

export type EntityRequestFormService = {
    Codigo: string;
    DatosDocumento: {
        NroDocumento: string
        TipoDocumento: {
            Codigo: string
            Descripcion: string
        }
    }
    FechaEmision: {
        IdFecha: number
        Fecha: string
    }
    DatosEmpresa: {
        RazonSocial: string
        NroDocumento: string
        TipoDocumento: {
            Codigo: string
            Descripcion: string
        }
    }
    DatosImporte: {
        Moneda: {
            Codigo: string
            Moneda: string
            Nombre: string
            Descripcion: string
        }
        Importe: number
        CodigoIVA: string
        MontoIVA: number
        Total: number
    }
    DatosPais: {
        CDPais: string
        Codigo: string
        Nombre: string
    }
    DatosTrabajo: {
        Delegacion: {
            Code: string
            Codigo: string
            Delegacion: string
        }
        OT: {
            Code: string
            Codigo: string
            OT: string
        }
    }
    DatosServicio: {
        Code: string
        Codigo: string
        Servicio: string
    }
    Observacion: string;
    Files: Array<DocumentFile>
    Users: {
        Registry: {
            User: string
            LastName: string
            Name: string
        }
    }
} 

export type DocumentFile = {
    type: 'local' | 'server';
    value: File;
    valueToShow: string;
}