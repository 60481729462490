import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import { ViewFlujoOT } from './Components/Modal/ViewFlujoOT';
import { ViewModalNota } from './Components/Modal/ViewModalNota';


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ViewMain
                configForm={controller.configForm}
                formikForm={controller.formikForm}
                languageTranslate={controller.languageTranslate}
                onCancelForm={controller.onCancelForm}
                onChange={controller.onChange}
                onSubmit={controller.onSubmit}
                openModalFlujo={controller.openModalFlujo}
                openModalNotas={controller.openModalNotas}
                onCancelFlow={controller.onCancelFlow}
                onDownload={controller.onDownload}
            />
            <ViewFlujoOT
                closeModalFlujo={controller.closeModalFlujo}
                configModalFlujo={controller.configModalFlujo}
                formikForm={controller.formikForm}
                onSubmitMFL={controller.onSubmitMFL}
                onChangeMFL={controller.onChangeMFL}
                languageTranslate={controller.languageTranslate}
            />
            <ViewModalNota
                closeModal={controller.closeModalNotas}
                configModal={controller.configModalNotas}
                onChange={controller.onChangeMNT}
                onSubmit={controller.onSubmitMNT}
            />
        </>
    );
};

export default ManagerEntity;