export type EntityRequestGenericForm = {
    Codigo: string;
    OTBase: {
        Code: string
        Codigo: string
        OT: string
    };
    Periodo: {
        Codigo: string;
        Criterio: string;
        FechaFinal: string;
        FechaInicio: string;
    };
    DelegacionBase: {
        Code: string;
        Codigo: string;
        Name: string;
    };
    Pais: {
        CDPais: string;
        Code: string;
        Name: string;
    };
    Grupo: {
        Codigo: string
    };
    Empresa: {
        CDEmpresa: string;
        Empresa: string;
        Grupo: string;
    };
    Semanas: number
    Observacion: string
    Dias: Array<EntityDias>;
    Tareas: Array<EntityRequestTareas>;
    Firma: any;
    User: {
        IdUser: number
        User: string
        LastFather: string
        LastMother: string
        Name: string
        Perfil: string
    };
    Estado: {
        IdStatus: number;
        Status: string;
    };
    Flujo?: {
        Id: number
        Codigo: string
        Code: string
        Name: string
        Descripcion: string
        Aprobacion: Array<{
            IdNivel: number
            Nivel: number
            Monto: number
            Status: {
                IdStatus: number
                Status: string
            }
            Perfil: {
                IdPerfil: number
                Perfil: string
                Initial: string
            }
            Aprobador: {
                LastNameFather: string
                LastNameMother: string
                Name: string
                Identificacion: string
                Status: {
                    IdStatus: number
                    Status: string
                }
                mail: string
                name: string
                FullName: string
            }
            StatusSendMail: boolean
        }>
    };
    Aprobacion?: {
        Nivel: number
    };
    Notas: any[];
    Bitacora: any[];
}

export const initEntityRequestGenericForm: EntityRequestGenericForm = {
    Codigo: "",
    OTBase: null as any,
    Periodo: null as any,
    DelegacionBase: {
        Code: "",
        Codigo: "",
        Name: ""
    },
    Pais: {
        CDPais: "",
        Code: "",
        Name: ""
    },
    Grupo: {
        Codigo: ""
    },
    Empresa: {
        CDEmpresa: "",
        Empresa: "",
        Grupo: ""
    },
    Semanas: 0,
    Observacion: "",
    Tareas: [],
    Dias: [],
    Firma: undefined,
    User: {
        IdUser: 0,
        User: "",
        LastFather: "",
        LastMother: "",
        Name: "",
        Perfil: ""
    },
    Estado: {
        IdStatus: 0,
        Status: "Abierto"
    },
    Notas: [],
    Bitacora: [],
}

export interface EntityRequestTareas {
    Proyecto: {
        Delegacion: {
            Code: string
            Codigo: string
            Delegacion: string
        }
        OT: {
            Code: string
            Codigo: string
            OT: string
        }
        DatosEmpresa: {
            CDEmpresa: string;
            Empresa: string;
        }
    }
    Disciplina: {
        Codigo: string
        Name: string
    }
    Observacion: string
    Horas: Array<EntityDias>
}

export interface EntityDias {
    Id: number;
    Descripcion: string
    DiaMes: number
    IdDia: number
    Mes: number
    Nombre: string
    Semana: number
    Tipo: number
    HorasTrabajador: number
    Anio?: number
    Ausencia?: {
        Codigo: string;
        TipoAusencia: string;
    }
    Bloqueado?: {
        Motivo: string;
    }
}

export interface EntityRequestAddNote {
    codigo: string;
    nota: {
        descripcion: string;
    },
    user: {
        IdUser: number;
        User: string;
        LastFather: string;
        LastMother: string;
        Name: string;
        Perfil: string;
    }
}

export interface EntityRequestAddAusencia {
    TipoAusencia: {
        IdTipoAusencia: number;
        Codigo: string;
        TipoAusencia: string;
    };
    DatosTrabajo: {
        Delegacion: {
            Code: string;
            Codigo: string;
            Delegacion: string;
        }
        OT: {
            Code: string;
            Codigo: string;
            OT: string;
        }
    };
    FechaInicio: string;
    FechaFinal: string;
    Observacion: string;
    Documento: {
        value: any;
        type: 'local' | 'server';
        valueToShow: string;
    }[];
    User: {
        IdUser: number;
        Identificacion: string;
        User: string;
        LastFather: string;
        LastMother: string;
        Name: string;
        Perfil: string;
    }
}

export interface EntityRequestUpdateAusencia {
    filter: {
        Codigo: string;
    }
    update: {
        TipoAusencia: {
            IdTipoAusencia: number;
            Codigo: string;
            TipoAusencia: string;
        },
        FechaInicio: string;
        FechaFinal: string;
        Observacion: string;
        Documento: {
            value: any;
            type: 'local' | 'server';
            valueToShow: string;
        }[];
    }
    user: {
        IdUser: number;
        Identificacion: string;
        User: string;
        LastFather: string;
        LastMother: string;
        Name: string;
        Perfil: string;
    }
}

export interface EntityRequestCancelReport {
    codigo: string[];
    user: {
        IdUser: number;
        User: string;
        LastFather: string;
        LastMother: string;
        Name: string;
        Perfil: string;
    }
}