import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";
import { LanguageTranslate } from "./LanguageTranslate";

export class AdapterGenerico {
    public static async createMessage(title: string = '', html: string = '', icon: SweetAlertIcon = 'info', buttonCancel: boolean = false, target: string = 'body') {
        let language = LanguageTranslate();
        let config: SweetAlertOptions = {
            title: title,
            html: html,
            icon,
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: true,
            confirmButtonText: language.code === 'es' ? 'Aceptar' : language.code === 'en' ? 'Accept' : 'Accept',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            backdrop: true,
            reverseButtons: false,
            focusConfirm: true,
            target: target,
        };

        if (buttonCancel === true) { Object.assign(config, { showCancelButton: true, cancelButtonColor: '#f44336', cancelButtonText: 'Cancelar', }); }

        let Message = await Swal.fire(config);

        return Reflect.has(Message, 'dismiss') ? false : true;
    }

    public static createToast(html: string = '', icon: SweetAlertIcon = 'info', target: string = 'body') {
        let Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            padding: 6,
            timerProgressBar: true,
            target,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });

        Toast.fire({ icon, html });
    }

    public static randomString(longitud: number = 11) {
        let caracteres = "abcdefghijkmnpqrtuvwxyz2346789";
        let cadena = "";
        for (let i = 0; i < longitud; i++) cadena += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        return cadena;
    }

    public static isJSON(str: string) {
        try {
            str = JSON.parse(str);
            return true;
        } catch (error) {
            return false;
        }
    }

    public static calcularEdad(fecha: string) {
        var hoy = new Date();
        var cumpleanos = new Date(fecha);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }

        return edad;
    }

    public static toBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    public static convertStringToDate(stringDate: string = '01/01/1900') {
        try {
            let i = 0;
            let f = 0;
            let y: any = '';
            let m: any = '';
            let d: any = '';
            let h: any = '';
            let mi: any = '';
            let s: any = '';
            let Tipo = 0;

            Tipo = stringDate.indexOf('-') !== -1
                ? 1
                : stringDate.indexOf('/') !== -1
                    ? 2
                    : 0;
            switch (Tipo) {
                case 1:
                    f = 4;
                    y = stringDate.slice(i, f);
                    if (y.indexOf('-') !== -1) { f--; y = stringDate.slice(i, f); }
                    else { y = y === '' ? 1 : parseInt(y); }
                    i = f + 1;
                    f = i + 2;

                    m = stringDate.slice(i, f);
                    if (m.indexOf('-') !== -1) { f -= 1; m = stringDate.slice(i, f); }
                    else { m = m === '' ? 0 : parseInt(m) - 1; }
                    i = f + 1;
                    f = i + 2;

                    d = stringDate.slice(i, f);
                    if (d.indexOf('/') !== -1) { f -= 1; d = stringDate.slice(i, f); }
                    else { d = d === '' ? 1 : parseInt(d); }
                    i = f + 1;
                    f = i + 2;

                    h = stringDate.slice(i, f);
                    h = h === '' ? 0 : parseInt(h);
                    i = f + 1;
                    f = i + 2;

                    mi = stringDate.slice(i, f);
                    mi = mi === '' ? 0 : parseInt(mi);
                    i = f + 1;
                    f = i + 2;

                    s = stringDate.slice(i, f);
                    s = s === '' ? 0 : parseInt(s);
                    break;
                case 2:
                    f = 2;
                    d = stringDate.slice(i, f);
                    if (d.indexOf('/') !== -1) { f--; d = stringDate.slice(i, f); }
                    else { d = d === '' ? 1 : parseInt(d); }
                    i = f + 1;
                    f = i + 2;

                    m = stringDate.slice(i, f);
                    if (m.indexOf('/') !== -1) { f -= 1; m = stringDate.slice(i, f); }
                    else { m = m === '' ? 0 : parseInt(m) - 1; }
                    i = f + 1;
                    f = i + 4;

                    y = stringDate.slice(i, f);
                    if (y.indexOf('/') !== -1) { f--; y = stringDate.slice(i, f); }
                    else { y = y === '' ? 1 : parseInt(y); }
                    i = f + 1;
                    f = i + 2;

                    h = stringDate.slice(i, f);
                    h = h === '' ? 0 : parseInt(h);
                    i = f + 1;
                    f = i + 2;

                    mi = stringDate.slice(i, f);
                    mi = mi === '' ? 0 : parseInt(mi);
                    i = f + 1;
                    f = i + 2;

                    s = stringDate.slice(i, f);
                    s = s === '' ? 0 : parseInt(s);
                    break;
                default:
                    throw new Error('formato desconocido');
            }
            let resp: Date = new Date(y, m, d, h, mi, s, 0);
            if (Number.isNaN(resp.getTime())) {
                throw Error('Fecha no es Válida')
            }
            return resp;
        } catch (error) {
            throw error;
        }
    }

    public static convertDateToString(Fecha: Date = new Date(), Tipo: number = 1) {

        try {
            Fecha = typeof Fecha === 'string' ? new Date(Fecha) : Fecha;
            let meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
            let y = Fecha.getFullYear();
            let m = Fecha.getMonth() + 1;
            let d = Fecha.getDate();

            let h = Fecha.getHours();
            let mi = Fecha.getMinutes();
            let s = Fecha.getSeconds();

            let am = h > 11 ? false : true;

            let h2 = h > 12 ? h - 12 : h;

            let result = '';

            switch (Tipo) {
                case 1:
                    result = Fecha.toISOString();
                    break;
                case 2:
                    result = `${(d < 10 ? '0' : '') + d}/${(m < 10 ? '0' : '') + m}/${y} ${(h < 10 ? '0' : '') + h}:${(mi < 10 ? '0' : '') + mi}:${(s < 10 ? '0' : '') + s}`;
                    break;
                case 3:
                    result = `${(d < 10 ? '0' : '') + d}/${(m < 10 ? '0' : '') + m}/${y}`;
                    break;
                case 4:
                    result = `${(d < 10 ? '0' : '') + d} de ${meses[m - 1]} del ${y}`;
                    break;
                case 5:
                    result = `${(d < 10 ? '0' : '') + d}/${(m < 10 ? '0' : '') + m}/${y} ${(h2 < 10 ? '0' : '') + h2}:${(mi < 10 ? '0' : '') + mi} ${am ? 'AM' : 'PM'}`;
                    break;
                case 6:
                    result = `${y}-${(m < 10 ? '0' : '') + m}-${(d < 10 ? '0' : '') + d}`;
                    break;
                case 7:
                    result = `${(d < 10 ? '0' : '') + d} de ${meses[m - 1]}`;
                    break;
                default:
                    throw new Error('Datos no correctos');
            }
            return result;
        } catch (error) {
            throw error;
        }
    }

    public static convertFormat(value: string, format: string) {
        try {
            if (typeof format !== 'string') { throw new Error('formato debe estar en string.'); }
            value = typeof value !== 'string' ? `${value}` : value;
            return value.length > format.length ? value : format.substring(0, format.length - value.length) + value;
        } catch (error) {
            throw error;
        }
    }

    public static decodeTokenJWT(Token: string) {
        try {
            let data = Token.split('.')[1];
            data = atob(data);
            data = AdapterGenerico.isJSON(data) ? JSON.parse(data) : data;
            return data;
        } catch (error) {
            throw error;
        }
    }

    public static getBase64ImageFromURL(url: string) {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.setAttribute("crossOrigin", "anonymous");
            img.onload = () => {
                let canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                let ctx = canvas.getContext("2d");
                if (ctx !== null) {
                    ctx.drawImage(img, 0, 0);
                    let dataURL = canvas.toDataURL("image/png");
                    resolve(dataURL);
                }
                else { reject(new Error('ctx null')) }
            };
            img.onerror = error => {
                reject(error);
            };
            img.src = url;
        });
    }

    public static summa(arrayJson: Array<any>, Clave: string, noincluir: any, incluir: any) {
        try {
            if (arrayJson.length === 0) { return 0 };
            let ClaveArrai = Clave.split('.');
            if (arrayJson.length === 0) {
                return 0;
            } else {
                switch (ClaveArrai.length) {
                    case 1:
                        return arrayJson.map((a) => {
                            if (typeof noincluir !== 'undefined') {
                                if (a[noincluir.key] === noincluir.value) {
                                    return 0
                                } else {
                                    return a[ClaveArrai[0]];
                                }
                            } else if (typeof incluir !== 'undefined') {
                                let ClaveArraiIncluir = incluir.key.split('.');
                                switch (ClaveArraiIncluir.length) {
                                    case 1:
                                        if (a[ClaveArraiIncluir[0]] === incluir.value) {
                                            return a[ClaveArrai[0]];
                                        } else {
                                            return 0
                                        }
                                    case 2:
                                        if (a[ClaveArraiIncluir[0]][ClaveArraiIncluir[1]] === incluir.value) {
                                            return a[ClaveArrai[0]];
                                        } else {
                                            return 0
                                        }
                                    default:
                                        return 0;
                                }
                            } else {
                                return a[ClaveArrai[0]];
                            }
                        }).reduce((b, c) => {
                            return b + c
                        })
                    case 2:
                        return arrayJson.map((a) => {
                            if (typeof noincluir !== 'undefined') {
                                let ClaveArraiNoIncluir = noincluir.key.split('.');
                                switch (ClaveArraiNoIncluir.length) {
                                    case 1:
                                        if (a[ClaveArraiNoIncluir[0]] === noincluir.value) {
                                            return 0
                                        } else {
                                            return a[ClaveArrai[0]][ClaveArrai[1]];
                                        }
                                    case 2:
                                        if (a[ClaveArraiNoIncluir[0]][ClaveArraiNoIncluir[1]] === noincluir.value) {
                                            return 0
                                        } else {
                                            return a[ClaveArrai[0]][ClaveArrai[1]];
                                        }
                                    default:
                                        return 0;
                                }
                            } else if (typeof incluir !== 'undefined') {
                                let ClaveArraiIncluir = incluir.key.split('.');
                                switch (ClaveArraiIncluir.length) {
                                    case 1:
                                        if (a[ClaveArraiIncluir[0]] === incluir.value) {
                                            return a[ClaveArrai[0]][ClaveArrai[1]];
                                        } else {
                                            return 0
                                        }
                                    case 2:
                                        if (a[ClaveArraiIncluir[0]][ClaveArraiIncluir[1]] === incluir.value) {
                                            return a[ClaveArrai[0]][ClaveArrai[1]];
                                        } else {
                                            return 0
                                        }
                                    default:
                                        return 0;
                                }
                            } else {
                                return a[ClaveArrai[0]][ClaveArrai[1]];
                            }
                        }).reduce((b, c) => {
                            return b + c
                        })
                    default:
                        return 0;
                }
            }
        } catch (error: any) {
            throw Error(error.message);
        }
        /* Ejemplos:
        //Sin exclusion
            SummationValuesinArrayJson(Documentos, 'DatosImporte.Monto')
        //Con Exclusion 1 nivel
            SummationValuesinArrayJson(Documentos, 'DatosImporte.Monto', {
                key: 'ide',
                value: Documento_Editar.ide
            })
        //Con Exclusion 2 niveles
        SummationValuesinArrayJson(a.Documentos, 'DatosImporte.Monto', {
            key: 'Status.IdStatus',
            value: 10
        });
        //Con Inclusión
        await SummationValuesinArrayJson(a.Documentos, 'DatosImporte.Monto', undefined, {
            key: 'Status.IdStatus',
            value: 1
        });
        */
    }

    public static convertFormatDecimal(number: number, decimal: number) {
        return new Intl.NumberFormat("en-EN", { maximumFractionDigits: decimal, minimumFractionDigits: decimal }).format(number);
    }

    public static ordenarArray(data: Array<any> = [], campo: string = '', dir: number = 1) {
        data.sort((a, b) => a[campo] < b[campo] ? (dir * -1) : a[campo] > b[campo] ? (dir * 1) : 0);
        return data;
    }

    public static patternSoloNumeros(minLength = 0, maxLength = 50) {
        return `^[0-9]{${minLength},${maxLength}}$`;
    }

    public static patternSoloLetras(minLength = 0, maxLength = 50) {
        return `^[a-zA-ZÀ-ÿ\u00f1\u00d1]{${minLength},${maxLength}}$`;
    }

    public static patternLetrasEspacio(minLength = 0, maxLength = 50) {
        return `^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]{${minLength},${maxLength}}$`;
    }

    public static patternLetrasCaracteres(minLength = 0, maxLength = 50) {
        return `^[\\D ]{${minLength},${maxLength}}$`;
    }

    public static patternLetrasNumeros(minLength = 0, maxLength = 50) {
        return `^[a-zA-ZÀ-ÿ\u00f1\u00d10-9]{${minLength},${maxLength}}$`;
    }

    public static patternLetrasNumerosEspacio(minLength = 0, maxLength = 50) {
        return `^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]{${minLength},${maxLength}}$`;
    }

    public static patternLetrasNumerosCaracteres(minLength = 0, maxLength = 50) {
        return `^[\\D0-9$]{${minLength},${maxLength}}$`;
    }

    public static calculoIVA(monto: number = 0, IVA: number = 0): number {
        let result = 0;
        if (!monto) return result;
        if (!IVA) return monto;

        result = (parseInt(`${monto}`) * (IVA / 100)) + (parseInt(`${monto}`));

        return result;
    }   

    public static calculoTiempo(date: Date, language: 'es' | 'en' | 'it' | 'fr' = 'es') {
        const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

        let interval = seconds / 31536000;

        if (interval > 1) {
            return language === 'es'
                ? `Hace ${Math.floor(interval)} año${Math.floor(interval) > 1 ? 's' : ''}`
                : language === 'en'
                    ? `${Math.floor(interval)} year ago`
                    : language === 'it'
                        ? `${Math.floor(interval)} anni fa`
                        : language === 'fr'
                            ? Math.floor(interval) > 1 ? `ça fait 1 an` : `il y a ${Math.floor(interval)} ans`
                            : '';
        }

        interval = seconds / 2592000;
        if (interval > 1) {
            return language === 'es'
                ? `Hace ${Math.floor(interval)} mes${Math.floor(interval) > 1 ? 'es' : ''}`
                : language === 'en'
                    ? `${Math.floor(interval)} month ago`
                    : language === 'it'
                        ? `${Math.floor(interval)} mes${Math.floor(interval) > 1 ? 'i' : 'e'} fa`
                        : language === 'fr'
                            ? Math.floor(interval) > 1 ? `il ya 1 mois` : `il y a ${Math.floor(interval)} mois`
                            : '';
        }

        interval = seconds / 86400;
        if (interval > 1) {
            return language === 'es'
                ? `Hace ${Math.floor(interval)} día${Math.floor(interval) > 1 ? 's' : ''}`
                : language === 'en'
                    ? `${Math.floor(interval)} day ago`
                    : language === 'it'
                        ? `${Math.floor(interval)} giorn${Math.floor(interval) > 1 ? 'i' : 'o'} fa`
                        : language === 'fr'
                            ? `il y a ${Math.floor(interval)} jour${Math.floor(interval) > 1 ? 's' : ''}`
                            : '';
        }

        interval = seconds / 3600;
        if (interval > 1) {
            return language === 'es'
                ? `Hace ${Math.floor(interval)} hora${Math.floor(interval) > 1 ? 's' : ''}`
                : language === 'en'
                    ? `${Math.floor(interval)} hour ago`
                    : language === 'it'
                        ? `${Math.floor(interval)} or${Math.floor(interval) > 1 ? 'e' : 'a'} fa`
                        : language === 'fr'
                            ? `il y a ${Math.floor(interval)} heure${Math.floor(interval) > 1 ? 's' : ''}`
                            : '';
        }

        interval = seconds / 60;
        if (interval > 1) {
            return language === 'es'
                ? `Hace ${Math.floor(interval)} minuto${Math.floor(interval) > 1 ? 's' : ''}`
                : language === 'en'
                    ? `${Math.floor(interval)} minute ago`
                    : language === 'it'
                        ? `${Math.floor(interval)} minut${Math.floor(interval) > 1 ? 'i' : 'o'} fa`
                        : language === 'fr'
                            ? `il y a ${Math.floor(interval)} minute${Math.floor(interval) > 1 ? 's' : ''}`
                            : '';
        }

        return language === 'es'
            ? `Hace ${Math.floor(seconds)} segundo${Math.floor(seconds) > 1 ? 's' : ''}`
            : language === 'en'
                ? `${Math.floor(seconds)} second ago`
                : language === 'it'
                    ? `${Math.floor(interval)} second${Math.floor(interval) > 1 ? 'i' : 'o'} fa`
                    : language === 'fr'
                        ? `il y a ${Math.floor(interval)} seconde${Math.floor(interval) > 1 ? 's' : ''}`
                        : '';
    }

    public static calculoDias(date: Date) {
        const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

        let interval = seconds / 31536000;

        if (interval > 1) return 365 * interval;

        interval = seconds / 2592000;
        if (interval > 1) return 30 * interval;

        interval = seconds / 86400;
        if (interval > 1) return interval;
    }

    public static formatoDinero(amount: number) {
        let amountSplit = `${amount}`.split('.');
        return amountSplit.length > 1 ?
            amountSplit[1].length === 1 ? `${amount.toLocaleString()}0` : 
                amountSplit[1].length === 2 ? amount.toLocaleString() : `${amountSplit[0]}.${amountSplit[1].slice(0, 2)}`
            :
            `${amount.toLocaleString()}.00`
    }

    public static scrollTopByClassName(className: string = "") {
        document.getElementsByClassName(className)[0]?.scrollIntoView();
    }

    public static getFechaLocal() {
        let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        let localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
        return localISOTime;
    };

    public static getIdFechaLocal(Fecha: string) {
        let Anio = parseInt(Fecha.toString().substring(0, 4)) * 10000;
        let Mes = parseInt(Fecha.toString().substring(5, 7)) * 100;
        let Dia = parseInt(Fecha.toString().substring(8, 10));
        return (Anio + Mes + Dia);
    }

    public static getIdFechaGlobal = (Fecha = new Date()) => {
        let y = Fecha.getFullYear(), m = Fecha.getMonth() + 1, d = Fecha.getDate();
        return parseInt('' + y + (m < 10 ? '0' : '') + m + (d < 10 ? '0' : '') + d);
    };

    public static chunkedArray<T>(data: Array<T>, cantidad: number): Array<Array<T>> {
        let newData: Array<Array<T>> = [];
        for (let row of data) {
            if (newData.length === 0) {
                newData.push([row]);
                continue;
            }

            if (newData[newData.length - 1].length < cantidad) {
                newData[newData.length - 1].push(row);
                continue;
            }

            newData.push([row]);
        }
        
        return newData;
    }

    public static padLeft(string: string, length: number, padding: string): string {
        try {
            let str = string + "";
            return (length <= str.length) ? str : AdapterGenerico.padLeft(padding + str, length, padding);
        } catch (error) {
            throw error;
        };
    };

    public static validarFecha(fecha: Date | string): boolean {
        let fechaValidar: Date = fecha instanceof Date ? fecha : new Date(fecha);
        let idFechaValidar: number = Number(`${fechaValidar.getFullYear()}${AdapterGenerico.padLeft(`${fechaValidar.getMonth() + 1}`, 2, '0')}${AdapterGenerico.padLeft(`${fechaValidar.getDate()}`, 2, '0')}`);

        let fechaActual: Date = new Date();
        let idFechaActual: number = Number(`${fechaActual.getFullYear()}${AdapterGenerico.padLeft(`${fechaActual.getMonth() + 1}`, 2, '0')}${AdapterGenerico.padLeft(`${fechaActual.getDate()}`, 2, '0')}`);

        return idFechaValidar < idFechaActual ? false : true;
    }

    public static removerCeroIzquierda(numero: string | number): string {
        if (!numero) return '';
        const valueParsedString = typeof numero === 'string' ? numero : `${numero}`;
        const newResult = valueParsedString.replace(/^(0+)/g, '');
        return newResult;
    }

    public static sumarHoras(horas: number[], separator: string = '.') {
        // Convertir horas a minutos y sumar
        let totalMinutos = horas.reduce((acumulador, hora) => {
          let [horas, minutos = 0] = `${hora}`.split(separator).map((row, index) => `${(row.length === 1 && index === 1) ? `${row}0` : row}`).map(Number);
          return acumulador + ((horas || 0) * 60) + (minutos);
        }, 0);
      
        // Calcular horas y minutos a partir del total de minutos
        let horasResultado = Math.floor(totalMinutos / 60);
        let minutosResultado = totalMinutos % 60;
      
        // Formatear el resultado
        return parseFloat(`${horasResultado}.${(minutosResultado > 9) ? minutosResultado : `0${minutosResultado}`}`);
    }

    public static sumarHoras2(horas: number[]) {
        const a = horas.reduce((acc, curr) => {
            const TotalFila = (curr || 0);
            let [accHoras, accMinutos = '0'] = String(acc).split('.');
            accMinutos = accMinutos.padEnd(2, '0');
            let [currHoras, currMinutos = '0'] = String(TotalFila).split('.');
            currMinutos = currMinutos.padEnd(2, '0');
            let totalHoras = parseInt(accHoras) + parseInt(currHoras);
            let totalMinutos = parseInt(accMinutos) + parseInt(currMinutos);
            totalHoras = totalHoras + Math.trunc(totalMinutos / 60);
            totalMinutos = totalMinutos % 60;
            const resultado = parseFloat(`${totalHoras}.${String(totalMinutos).padStart(2, '0')}`);
            return resultado;
        }, 0);

        return a;
    }

    public static formatNumberToHoras(hora?: number) {
        if (!hora) return '00:00'
        let [totalHour, totalMinutes] = `${hora}`.split('.');
        const formatHour = totalHour.length > 1 ? `${totalHour}` : `0${totalHour || 0}`;
        const formatMinutes = `${totalMinutes || '0'}`.length > 1 ? `${totalMinutes}` : `${totalMinutes || 0}0`;

        return `${formatHour}:${formatMinutes}`;
    }

    public static downloadDocument(url: string, nameFile: string) {
        const enlace = document.createElement('a');
        enlace.style.display = 'none';
        enlace.href = url;
        enlace.download = nameFile;
        document.body.appendChild(enlace);
        enlace.click();
        document.body.removeChild(enlace);
    }
}
