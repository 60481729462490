import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { toast } from 'react-toastify';
import { RootState } from "../../context/shared/Infraestructure/AdapterStore";
import { addLoading, removeLoading, changeOnline, changeCountProcess, removeLoadingMaestros, changeAvailableUpdate } from "../../context/shared/Infraestructure/SliceGenerico";
import { AdapterConfigure } from "./AdapterConfigure";
import { AdapterGenerico } from "../../context/shared/Infraestructure/AdapterGenerico";

import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import { useState } from "react";
import { EntityComparative } from "../../context/shared/Domain/EntityComparative";
import { UseCaseExecuteProcess } from "../Application/UseCaseExecuteProcess";
import { EntityFactura } from "../../context/shared/Domain/EntityFactura";
import { EntitySystemRequirement } from "../../context/shared/Domain/EntitySystemRequirement";
import { EntityPurchaseRequirement } from "../../context/shared/Domain/EntityPurchaseRequirement";
import { EntityPettyCashRequest } from "../../context/shared/Domain/EntityPettyCashRequest";
import { EntitySurrenderPettyCashExpenses } from "../../context/shared/Domain/EntitySurrenderPettyCashExpenses";
import { LanguageTranslate } from "../../context/shared/Infraestructure/LanguageTranslate";
import { AdapterLoadMaster } from "../../context/shared/Infraestructure/AdapterLoadMaster";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { EntityAusenciaAprobacion } from "../../context/shared/Domain/Tareo/EntityAusenciaAprobacion";
import { EntityTareoPersonal } from "../../context/shared/Domain/Tareo/EntityTareoPersonal";

export const Controller = () => {
    const { generico: { websocket, dbLocal, loadingMaestros, availableUpdate }, auth: { permisoVariables, user } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const repositoryLoadMaster = new AdapterLoadMaster(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const languageTranslate = LanguageTranslate();

    let [sw, setSW] = useState<ServiceWorkerRegistration | undefined>();
    const [updating, setUpdating] = useState<boolean>(false);

    let intervalVerifyRDI: NodeJS.Timer;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let intervalToken: NodeJS.Timer;

    const init = async () => {
        if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
            let service: ServiceWorkerRegistration | undefined = await serviceWorkerRegistration.register({ onUpdate(registration) { dispatch(changeAvailableUpdate(true)); }, });
            setSW(service);
            dispatch(changeAvailableUpdate(!!service?.waiting));
        }

        window.addEventListener('online', onOnline);
        await repositoryLoadMaster.initServicesCalled();
        window.addEventListener('offline', onOffline);

        let permisos = await updateUserInformation();

        await repositoryLoadMaster.initialService({ Delegacion: [], Grupo: [], OT: [], Pais: [] }, user);
        if (user.IdUser !== 0 && permisos) {
            await callToAllInitialServices(permisos);
        }
        dispatch(removeLoadingMaestros());

        intervalVerifyRDI = setInterval(verifyProcess, 60000);

        intervalToken = setInterval(() => {
            // console.log("validando token");
        }, 60000);
    };

    const callToAllInitialServices = async (permisos: { Pais: string[], Grupo: string[], Delegacion: string[], OT: string[] }) => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            await repositoryLoadMaster.initialService(permisos, user);
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    };

    const onOffline = () => {
        AdapterGenerico.createToast(languageTranslate.textoSinConexion, 'warning');
        dispatch(changeOnline(false));
    };

    const onOnline = async () => {
        websocket.init();

        await verifyProcess(true);

        dispatch(changeOnline(true));
        AdapterGenerico.createToast(languageTranslate.textoConexionEstablecida, 'success');
    };

    const verifyProcess = async (force: boolean = false) => {
        let count: number = 0;
        const [countComparative, countInvoice, countSystemRequirement, countPurchaseRequirement, countPettyCashRequest, countSurrenderPettyCashExpenses, countAusencia, countTareoPersonal, countTareoPersonalAprobacion] = await Promise.all([
            verifyProcessComparative(force),
            verifyProcessInvoice(force),
            verifyProcessSystemRequirement(force),
            verifyProcessPurchaseRequirement(force),
            verifyProcessPettyCashRequest(force),
            verifyProcessSurrenderPettyCashExpenses(force),
            verifyProcessAusencia(force),
            verifyProcessTareoPersonal(force),
            verifyProcessTareoPersonalAprobacion(force)
        ]);

        count += countComparative + countInvoice + countSystemRequirement + countPurchaseRequirement + countPettyCashRequest + countSurrenderPettyCashExpenses + countAusencia + countTareoPersonal + countTareoPersonalAprobacion;
        dispatch(changeCountProcess(count));
    }

    const verifyProcessComparative = async (force: boolean = false): Promise<number> => {
        if (!force && !navigator.onLine) { return 0; }

        let list: Array<EntityComparative> = await dbLocal.selectAllStore('Comparativo');
        list = list.filter(row => row.pendingSend);

        let countProcess: number = list.length;

        for (let row of list) {
            try {
                let url: string = '';
                if (row.dataSend?.extraConfig.typeSave === 'approve') url = `/navision/aprobarPreOrdenWorkFlow`;
                if (row.dataSend?.extraConfig.typeSave === 'return') url = `/navision/retornarPreOrdenWorkFlow`;

                let result: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: JSON.stringify(row.dataSend?.paramsSend), method: 'POST', typeAuth: 'bearer', typeRequest: 'json', typeResponse: 'json', url });
                if (!!result) await dbLocal.deleteByIndexStore({ nameStore: 'Comparativo', value: row._id });

                countProcess -= 1;
            } catch (error) {
                if ((error as Error).message === 'error-date-update') countProcess -= 1;
                console.error(error);
            }
        }

        return countProcess;
    };

    const verifyProcessInvoice = async (force: boolean = false): Promise<number> => {
        if (!force && !navigator.onLine) { return 0; }

        let list: Array<EntityFactura> = await dbLocal.selectAllStore('Factura');
        list = list.filter(row => row.pendingSend);

        let countProcess: number = list.length;

        for (let row of list) {
            try {
                let url: string = '';
                if (row.dataSend?.extraConfig.typeSave === 'approve') url = `/navision/aprobarPreRegistroWorkFlow`;
                if (row.dataSend?.extraConfig.typeSave === 'return') url = `/navision/retornarPreRegistroWorkFlow`;

                let result: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: JSON.stringify(row.dataSend?.paramsSend), method: 'POST', typeAuth: 'bearer', typeRequest: 'json', typeResponse: 'json', url });
                if (!!result) await dbLocal.deleteByIndexStore({ nameStore: 'Factura', value: row._id });

                countProcess -= 1;
            } catch (error) {
                if ((error as Error).message === 'error-date-update') countProcess -= 1;
                console.error(error);
            }
        }

        return countProcess;
    };

    const verifyProcessSystemRequirement = async (force: boolean = false): Promise<number> => {
        if (!force && !navigator.onLine) { return 0; }

        let list: Array<EntitySystemRequirement> = await dbLocal.selectAllStore('RequerimientoSistema');
        list = list.filter(row => row.pendingSend);

        let countProcess: number = list.length;

        for (let row of list) {
            try {
                let url: string = '';
                if (row.dataSend?.extraConfig.typeSave === 'approve') url = `/navision/aprobarRqSistemaWorkFlow`;
                if (row.dataSend?.extraConfig.typeSave === 'return') url = `/navision/retornarRqSistemaWorkFlow`;

                let result: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: JSON.stringify(row.dataSend?.paramsSend), method: 'POST', typeAuth: 'bearer', typeRequest: 'json', typeResponse: 'json', url });
                if (!!result) await dbLocal.deleteByIndexStore({ nameStore: 'RequerimientoSistema', value: row._id });

                countProcess -= 1;
            } catch (error) {
                if ((error as Error).message === 'error-date-update') countProcess -= 1;
                console.error(error);
            }
        }

        return countProcess;
    };

    const verifyProcessPurchaseRequirement = async (force: boolean = false): Promise<number> => {
        if (!force && !navigator.onLine) { return 0; }

        let list: Array<EntityPurchaseRequirement> = await dbLocal.selectAllStore('RequerimientoCompra');
        list = list.filter(row => row.pendingSend);

        let countProcess: number = list.length;

        for (let row of list) {
            try {
                let url: string = '';
                if (row.dataSend?.extraConfig.typeSave === 'approve') url = `/navision/aprobarRqCompraWorkFlow`;
                if (row.dataSend?.extraConfig.typeSave === 'return') url = `/navision/retornarRqCompraWorkFlow`;

                let result: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: JSON.stringify(row.dataSend?.paramsSend), method: 'POST', typeAuth: 'bearer', typeRequest: 'json', typeResponse: 'json', url });
                if (!!result) await dbLocal.deleteByIndexStore({ nameStore: 'RequerimientoCompra', value: row._id });

                countProcess -= 1;
            } catch (error) {
                if ((error as Error).message === 'error-date-update') countProcess -= 1;
                console.error(error);
            }
        }

        return countProcess;
    };

    const verifyProcessPettyCashRequest = async (force: boolean = false): Promise<number> => {
        if (!force && !navigator.onLine) { return 0; }

        let list: Array<EntityPettyCashRequest> = await dbLocal.selectAllStore('SolicitudCajaChica');
        list = list.filter(row => row.pendingSend);

        let countProcess: number = list.length;

        for (let row of list) {
            try {
                let url: string = '';
                if (row.dataSend?.extraConfig.typeSave === 'approve') url = `/navision/aprobarSolicitudCajaChicaWorkFlow`;
                if (row.dataSend?.extraConfig.typeSave === 'return') url = `/navision/retornarSolicitudCajaChicaWorkFlow`;

                let result: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: JSON.stringify(row.dataSend?.paramsSend), method: 'POST', typeAuth: 'bearer', typeRequest: 'json', typeResponse: 'json', url });
                if (!!result) await dbLocal.deleteByIndexStore({ nameStore: 'SolicitudCajaChica', value: row._id });

                countProcess -= 1;
            } catch (error) {
                if ((error as Error).message === 'error-date-update') countProcess -= 1;
                console.error(error);
            }
        }

        return countProcess;
    };

    const verifyProcessSurrenderPettyCashExpenses = async (force: boolean = false): Promise<number> => {
        if (!force && !navigator.onLine) { return 0; }

        let list: Array<EntitySurrenderPettyCashExpenses> = await dbLocal.selectAllStore('RendicionGastosCajaChica');
        list = list.filter(row => row.pendingSend);

        let countProcess: number = list.length;

        for (let row of list) {
            try {
                let url: string = '';
                if (row.dataSend?.extraConfig.typeSave === 'approve') url = `/navision/aprobarRendicionCajaChicaReembolsoWorkFlow`;
                if (row.dataSend?.extraConfig.typeSave === 'return') url = `/navision/retornarRendicionCajaChicaReembolsoWorkFlow`;

                let result: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: JSON.stringify(row.dataSend?.paramsSend), method: 'POST', typeAuth: 'bearer', typeRequest: 'json', typeResponse: 'json', url });
                if (!!result) await dbLocal.deleteByIndexStore({ nameStore: 'RendicionGastosCajaChica', value: row._id });

                countProcess -= 1;
            } catch (error) {
                if ((error as Error).message === 'error-date-update') countProcess -= 1;
                console.error(error);
            }
        }

        return countProcess;
    };

    const verifyProcessTareoPersonalAprobacion = async (force: boolean = false): Promise<number> => {
        if (!force && !navigator.onLine) { return 0; }

        let list: Array<EntitySurrenderPettyCashExpenses> = await dbLocal.selectAllStore('TareoAprobacion');
        list = list.filter(row => row.pendingSend);

        let countProcess: number = list.length;

        for (let row of list) {
            try {
                let url: string = '';
                if (row.dataSend?.extraConfig.typeSave === 'approve') url = `/navision/workflow/aprobar-tareo`;
                if (row.dataSend?.extraConfig.typeSave === 'return') url = `/navision/workflow/retornar-tareo`;

                let result: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: JSON.stringify(row.dataSend?.paramsSend), method: 'POST', typeAuth: 'bearer', typeRequest: 'json', typeResponse: 'json', url });
                if (!!result) await dbLocal.deleteByIndexStore({ nameStore: 'TareoAprobacion', value: row._id });

                countProcess -= 1;
            } catch (error) {
                if ((error as Error).message === 'error-date-update') countProcess -= 1;
            }
        }

        return countProcess;
    };

    const verifyProcessAusencia = async (force: boolean = false): Promise<number> => {
        if (!force && !navigator.onLine) { return 0; }

        let list: Array<EntityAusenciaAprobacion> = await dbLocal.selectAllStore('AusenciaAprobacion');
        list = list.filter(row => row.pendingSend);

        let countProcess: number = list.length;

        for (let row of list) {
            try {
                let url: string = '';
                if (row.dataSend?.extraConfig.typeSave === 'approve') url = `/navision/workflow/ausencia/aprobar`;
                if (row.dataSend?.extraConfig.typeSave === 'return') url = `/navision/workflow/ausencia/retornar`;

                let result: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: JSON.stringify(row.dataSend?.paramsSend), method: 'POST', typeAuth: 'bearer', typeRequest: 'json', typeResponse: 'json', url });
                if (!!result) await dbLocal.deleteByIndexStore({ nameStore: 'AusenciaAprobacion', value: row._id });

                countProcess -= 1;
            } catch (error) {
                if ((error as Error).message === 'error-date-update') countProcess -= 1;
            }
        }

        return countProcess;
    };

    const verifyProcessTareoPersonal = async (force: boolean = false): Promise<number> => {
        if (!force && !navigator.onLine) { return 0; }

        let list: Array<EntityTareoPersonal> = await dbLocal.selectAllStore('TareoPersonal');
        list = list.filter(row => row.isPendingSend);

        let countProcess: number = list.length;

        for (let row of list) {
            try {
                let url: string = '/Tareo/Informe/ingresarTareas';

                let result: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api-v2', body: JSON.stringify(row.dataSend), method: 'POST', typeAuth: 'bearer', typeRequest: 'json', typeResponse: 'json', url });
                if (!!result) {
                    const item: EntityTareoPersonal = Array.isArray(result) ? result[0] : result; 
                    await dbLocal.deleteByIndexStore({ nameStore: 'TareoPersonal', value: item._id });
                    await dbLocal.insertDataStore({ nameStore: 'TareoPersonal', data: item });
                }

                countProcess -= 1;
            } catch (error) {
                if ((error as Error).message === 'error-date-update') countProcess -= 1;
            }
        }

        return countProcess;
    };


    const end = async () => {
        clearInterval(intervalVerifyRDI);
        window.removeEventListener('online', onOnline);
        window.removeEventListener('offline', onOffline);
    };

    const updateApp = async () => {
        if (!sw || !sw.waiting || updating) {
            return;
        }

        try {
            setUpdating(true);
            sw.waiting.postMessage({ type: "SKIP_WAITING" });

            // Muestra el toast de inicio de descarga
            await toast.promise(
                new Promise((resolve) => {
                    sw?.update().then(() => resolve(true));
                }),
                {
                    pending: 'Descargando...',
                    success: 'Descarga exitosa',
                    error: 'Ocurrió un error'
                },
                {
                    style: {
                        fontSize: '0.8rem',
                        height: 30
                    },
                    position: "top-center"
                }
            );

            // Recarga la página después de la actualización
            window.location.reload();
        } catch (error) {
            setUpdating(false);
        }
    };

    const updateUserInformation = async () => {
        const defaultValue = { Pais: permisoVariables.arrIdPaises, Grupo: permisoVariables.arrIdGrupos, Delegacion: permisoVariables.arrIdDelegaciones, OT: permisoVariables.arrIdOT };
        if (!navigator.onLine) return defaultValue;
        if (!user.IdUser) return defaultValue;
        try {
            // Consume mucho tiempo al volver a consultar los permisos
            // let response = await repositoryLoadMaster.UpdateUserInformation(user.IdUser);
            // const { access: { Delegacion, Grupo, OT, Pais }, menu, exitoso } = response;
            // dispatch(signIn({ menu: menu }));

            // if (!exitoso) return null;

            // dispatch(changePermisoVariable({ arrIdPaises: Pais, arrIdGrupos: Grupo, arrIdDelegaciones: Delegacion, arrIdOT: OT }));
            return ({
                Pais: permisoVariables.arrIdPaises,
                Grupo: permisoVariables.arrIdGrupos,
                Delegacion: permisoVariables.arrIdDelegaciones,
                OT: permisoVariables.arrIdOT
            });
        } catch (error) {
            return defaultValue;
        }
    }

    return {
        init,
        end,

        availableUpdate,
        loadingMaestros,

        updateApp,
        callToAllInitialServices,
        updating
    };
}