// Domain
import { EntityTareoAprobacion } from "../../../../shared/Domain/Tareo/EntityTareoAprobacion";
import { AdapterGenerico } from "../../../../shared/Infraestructure/AdapterGenerico";
import { RepositoryImplGenerico } from "../../../../shared/Infraestructure/RepositoryImplGenerico";
import { addCountProcess } from "../../../../shared/Infraestructure/SliceGenerico";
import { EntityRequestAddNoteModal, EntityRequestDetail } from "../Domain/EntityRequestDetail";
import { RepositoryMain } from "../Domain/RepositoryMain";


export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async save(params: EntityRequestDetail): Promise<boolean | null> {
        try {
            let response = await this._save(params);
            let rowLocal = await this._saveLocal(params);

            if (response) await this.dbLocal.deleteByIndexStore({ nameStore: 'TareoAprobacion', value: params.paramsSend.codigo });

            return !!response ? response : rowLocal;
        } catch (error) {
            throw error;
        }
    };

    private async _save(payload: EntityRequestDetail): Promise<boolean | null> {
        if (!navigator.onLine) { this.dispatch(addCountProcess()); return null; }

        if (payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/workflow/aprobar-tareo`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/workflow/retornar-tareo`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        return false;
    }

    private async _saveLocal(payload: EntityRequestDetail): Promise<any> {
        let response = { ...payload.extraConfig.dataComplete, pendingSend: true, dataSend: payload };
        await this.dbLocal.updateByIndexStore({ nameStore: 'TareoAprobacion', value: response });
        return response;
    }

    public async findForm(code: string): Promise<EntityTareoAprobacion | null> {   
        const response: EntityTareoAprobacion | null = await this.dbLocal.selectByIndexStore({ nameStore: 'TareoAprobacion', value: code });
        if (!response) return null;

        response.Notas = (response?.Notas || []).map(row => ({
            observacion: row.Description || row.Descripcion || '',
            fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date?.Date || row.Dates?.Fecha), 3),
            usuario: `${row.User?.Name || row.Users?.Name || ''} ${row.User?.LastName || `${row.Users?.LastFather} ${row.Users?.LastMother}`}`,
            action: row.Action
        })).reverse();

        response.Bitacora = (response?.Bitacora || []).map(row => ({
            ...row,
            accion: row.Action,
            fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
            descripcion: row.Description,
            usuario: `${row.User.Name} ${row.User.LastName}`,
        })).reverse();

        return response;
    }

    public async addNote(payload: EntityRequestAddNoteModal): Promise<any[] | null> {
        let url: string = `${this.urlBaseGastos}/Tareo/Informe/addNoteReport`;
        const result: EntityTareoAprobacion[] | null = await this.service.call<any>("POST", url, JSON.stringify(payload), 'bearer', "json", 'json', { "request-decrypt-response": true }, 0);
        if (!result || result.length === 0) return null;

        return (result[0].Notas || []).map(row => ({
            observacion: row.Description || row?.Descripcion || '',
            fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date?.Date || row.Dates?.Fecha), 3),
            usuario: `${row.User?.Name || row.Users?.Name || ''} ${row.User?.LastName || `${row.Users?.LastFather} ${row.Users?.LastMother}`}`,
            action: row.Action
        })).reverse()
    }
}