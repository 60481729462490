import { EntityListContent } from "../Domain/EntityListContent";
import { NameStore } from "../Domain/EntityRequestDetail";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseGetDetailItem {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(_id: string, nameStore: NameStore): Promise<{ dataFormatted: EntityListContent<any> | null; data: any; }> {
        return await this._exec(_id, nameStore);
    }

    private async _exec(_id: string, nameStore: NameStore): Promise<{ dataFormatted: EntityListContent<any> | null; data: any; }> {
        return await this.repository.getDetailItem(_id, nameStore);
    }
}