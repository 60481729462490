/* NOTE: Modify only the value, not the key */
export const idioma_en = {
    code: 'en',
    nombreProyecto: 'Integral Administration Manager',
    textoLogin: 'Login',
    textoConfiguracion: 'Setting',
    textoCerrarSesion: 'Logout',
    textoModoCrear: 'CREATE',
    textoModoEditar: 'EDIT',
    textoModoVisualizar: 'PREVIEW',
    textoNavbarInicio: 'Home',
    textoConfigurandoAreaTrabajo: 'Setting up your work environment...',
    textoNuevaVersion: 'New version, click here to update',
    textoGuardando: 'Saving...',
    textoCopiar: 'Copied to clipboard',
    textoCargando: 'Loading...',
    textoEnviando: 'Sending...',
    textoDescargando: 'Downloading...',
    textoDescargar: 'Download',
    textoPagina: 'Page',
    textoPaginaDe: 'of',
    textoAlerta: 'Alert',
    textoIncompleto: 'Incomplete',
    textoSinConexion: 'Offline',
    textoConexionEstablecida: 'Connection established',
    textoDescripcionSinConexion: 'You do not have an internet connection',
    textSinResultados: 'No Results',
    textoExitoso: 'Successful',
    textoExitosoCambioPassword: 'Password change succeeded',
    textoExitosoRecuperacionPassword: 'Password recovery was successful, you will receive a message to the registered email',
    textoEliminar: 'Are you sure about deleting the following items?',
    btnCancelar: 'Cancel',
    btnGuardar: 'Save',
    btnSiguiente: 'Next',
    btnVerImagen: 'View images',
    btnRegresar: 'Back',
    btnSeleccionar: 'Select',
    btnDescartar: 'Discard',
    btnEliminar: 'Delete',
    btnAgregar: 'Add',
    btnRechazar: 'Reject',
    btnAprobar: 'Approve',
    btnSubirArchivo: 'Upload File',
    btnTodo: 'All',
    btnDownloadTemplate: 'Download Template',
    btnEnviar: 'Send',
    btnCancelarFlujo: 'Cancel Flow',
    btnEnviarAprobacion: 'Send Approval',
    btnAutocompletar: 'Autocomplete',
    btnLimpiar: 'Clean',
    moduloConfiguracion: {
        textoIdioma: 'Language',
        textoCambioPassword: 'Change Password',
        textoNuevaPassword: 'New Password',
        textoRepetirNuevaPassword: 'Repeat New Password',
        textoBotonSaveCambioPassword: 'Save',
        nombre: 'English',
    },
    moduloLogin: {
        textoTitulo: 'Login',
        txtUsuario: 'User',
        txtPassword: 'Password',
        btnLogin: 'LOGIN',
        chkRecordarUsuario: 'Remenber me',
        textoOlvidoContrasenia: 'Forgot your password?',
    },
    moduloRecuperarPassword: {
        textoTitulo: 'Recover Password',
        textoUsuario: 'User',
        textoIdentificacion: 'Identification number',
        textoCorreoElectronico: 'Email',
        textoAccion: 'Recover',
        textoRegresarLogin: 'Return Login'
    },
    modulo404: {
        textoDescripcion: 'The requested resource is not available',
        textoHome: 'Go Home'
    },
    moduloNotAvailable: {
        textoCabecera: 'Resource Not Available',
        textoDescripcion: 'The requested resource is not available for your device',
    },
    moduloMain: {
        textoTitulo: 'Operational Safety Management System',
        textoBienvenida: 'Welcome!',
        textoInicio: 'Home',
        textoAyuda: 'Help',
        textoTituloPreguntaAyuda: 'Redirect',
        textoDescripcionPreguntaAyuda: 'You will be redirected to the system help documentation, Are you sure?',
        textoReporte: 'Report',
        textoRefrescarDatos: 'Refresh data',
        textoTituloPreguntaReporte: 'Redirect',
        textoDescripcionPreguntaReporte: 'You will be redirected to the Service Management System, Are you sure?',
    },
    moduloCatalogo: {
        textoTitulo: 'REFRESH DATA',
        textoRefrescarTodo: 'Refresh Everything',
        textoRefrescar: 'Freshen',
        textoSincronizado: 'Synchronized',
        textoNoSincronizado: 'Out of sync',
    },
    moduloApprovals: {
        navBar: [{ text: 'APPROVALS', navigate: false, path: '' }],
        question: {
            textoDesea: 'Do you want to',
            textoAprobar: 'approve',
            textoRechazar: 'return',
            textoComparativo: 'the following comparison?',
            textoFactura: 'the following invoice?',
            textoRequerimientoSistema: 'the following system requirement?',
            textoRequerimientoCompra: 'the following purchase request?',
            textoSolicitudCajaChica: 'the following petty cash application?',
            textoRendicionGastoCajaChica: 'the following petty cash jacks?',
            textoHojaPeticion: 'the following request form?',
            textoCondicionLaboral: 'the following employment condition?'
        }
    },
    moduloGenericDetail: {
        navBarComparativo: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'COMPARATIVE', navigate: true, path: '/main/approvals/comparative' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarFactura: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'INVOICE', navigate: true, path: '/main/approvals/invoice' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarRequerimientoSistemas: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'SYSTEM REQUIREMENT', navigate: true, path: '/main/approvals/system-requirement' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarRequerimientoCompra: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'PURCHASE REQUIREMENT', navigate: true, path: '/main/approvals/purchase-requirement' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarSolicitudCajaChica: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'PETTY CASH REQUEST', navigate: true, path: '/main/approvals/petty-cash-request' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarRendicionGastosCajaChica: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'SURRENDER EXPENSES', navigate: true, path: '/main/approvals/surrender-petty-cash-expenses' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarHojaPeticion: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'REQUEST FORM', navigate: true, path: '/main/approvals/request-form' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarWorkingConditions: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'WORKING CONDITIONS', navigate: true, path: '/main/approvals/working-conditions' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarAusencia: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'ABSENCE', navigate: true, path: '/main/approvals/ausencia' }, { text: 'DETAIL', navigate: false, path: '' }],
        btnTextDevolver: 'Return',
        btnTextAprobar: 'Approve',
        itemDetail: {
            textCodigo: 'Code',
            textTitle: 'DETAIL',
            textPais: 'Country',
            textGrupo: 'Group',
            textEmpresa: 'Enterprise',
            textProveedor: 'Supplier',
            textOT: 'OT',
            textFechaRecepcion: 'Date of Receipt',
            textFormaPago: 'Payment Method',
            textTipoPago: 'Type of Payment',
            textTerminoPago: 'Payment Terms',
            textMoneda: 'Coin',
            textImporte: 'Amount',
            textComentario: 'Comment',
            textPedidoCompra: 'Order Purchase',
            textImporteFacturaAsociado: 'Associated invoice amount',
            textSolicitante: 'Applicant',
            textMultiProveedor: 'Multiple Provider',
            textContratoMarco: 'Contract Frame',
            textFechaRegistro: 'Registration date',
            textTrabajador: 'Worker',
            textAreaSolicitante: 'Area',
            textPuestoSolicitante: 'Position',
            textCargoSolicitante: 'Position',
            textObservaciones: 'observations',
        },
        itemPersonal: {
            textTitle: 'PERSONNEL',
            textModalidadTrabajo: 'Modality Work',
            textMotivoSolicitud: 'Reason for Request',
            textTipoContrato: 'Type of Contract',
            textPuesto: 'Position',
            textTipoDocumento: 'Document Type',
            textIdentificacion: 'Identification',
            textNombreCompleto: 'Full name',
            textFechaAlta: 'Date High',
            textFechaBaja: 'Cancellation Date',
            textVehiculoPropio: 'Own Vehicle',
            textVehiculoEmpresa: 'Company Vehicle',
            textHoraExtra: 'Extra Hour',
            textPrimaProduccion: 'Production Premium',
            textBonoTurnoNoche: 'Night Shift Bonus',
            textBonoTurnos: 'Turn-based Bonus',
            textRetribucionMensual: 'Monthly Remuneration',
            textRetribucionAnual: 'Annual Remuneration',
            textFunciones: 'Functions'
        },
        itemEmpleado: {
            textTitle: 'EMPLOYEE',
            textTitleActuales: 'Current Data',
            textTitlePropuestas: 'Proposed Data',
            textEmpleado: 'Employee',
            textDelegacion: 'Delegation',
            textOT: 'OT',
            textPuesto: 'Position',
            textRemuneracionMensual: 'Monthly Remuneration',
            textRemuneracionAnual: 'Annual Remuneration',
            textRemuneracionAnualEuros: 'Annual Remuneration Euros',
            textTipoCoteo: 'Costing Type',
            textFechaEfectiva: 'Effective Date',
            textVarianza: 'Variance',
            textMotivoAumentoSalarial: 'Reason for salary increase',
            textJustificacionSolicitud: 'Justification of the request'
        },
        itemDistribuccion: {
            textTitle: 'DISTRIBUTION',
            textProveedor: 'Supplier',
            textMoneda: 'Coin',
            headerTable: [
                { label: 'Account', key: 'cuenta' },
                { label: 'OT', key: 'ot' },
                { label: 'Amount', key: 'importe' },
            ],
            textImporteTotal: 'Total Amount',
            textImporteDistribuido: 'Amount Distributed',
            textImportePendiente: 'Outstanding Amount',
        },
        itemProducto: {
            textTitle: 'PRODUCT',
            headerTable: [
                { label: 'Description', key: 'descripcion' },
                { label: 'Quantity', key: 'cantidad' },
                { label: 'Unit', key: 'unidad' },
                { label: 'Unit Price', key: 'precioUnitario' },
                { label: 'Sub Total', key: 'subTotal' },
            ],
            headerTableWithoutPrice: [
                { label: 'Description', key: 'descripcion' },
                { label: 'Quantity', key: 'cantidad' },
                { label: 'Unit', key: 'unidad' },
            ],
            headerTableRQSistema: [
                { label: 'Personnel', key: 'personal' },
                { label: 'Description', key: 'descripcion' },
                { label: 'Quantity', key: 'cantidad' },
                { label: 'Unit', key: 'unidad' },
            ],
            textMontoTotal: 'Total Amount',
        },
        itemRendicion: {
            textTitle: 'EXPENSE',
            headerTable: [
                { label: 'Service name', key: 'nombreServicio' },
                { label: 'Amount', key: 'importe' },
            ],
            headerTableNuevo: [
                { label: '', key: 'checkbox' },
                { label: '', key: 'preview' },
                { label: 'Service name', key: 'DatosServicio.Servicio' },
                { label: 'Amount', key: 'DatosImporte.Importe', special: 'amountFormat' },
            ],
            textImporteTotal: 'Total Amount',
        },
        itemValorizaciones: {
            textTitle: 'VALUATIONS',
            headerTablePedidoCompra: [
                { label: 'Serial', key: 'serie' },
                { label: 'Document number', key: 'numeroDocumento' },
                { label: 'Description', key: 'descripcion' },
                { label: 'Quantity', key: 'cantidad' },
                { label: 'Unit Price', key: 'precioUnitario' },
                { label: 'Sub Total', key: 'subTotal' },
            ],
            headerTableContrato: [
                { label: 'Valorization Number', key: 'numeroValorizacion' },
                { label: 'Contract', key: 'contrato' },
                { label: 'Service', key: 'servicio' },
                { label: 'Amount', key: 'monto' },
            ],
            textMontoTotal: 'Total Amount',
        },
        itemNotas: {
            textTitle: 'NOTES',
            headerTable: [
                { label: 'Observation', key: 'observacion' },
                { label: 'Date', key: 'fecha' },
                { label: 'User', key: 'usuario' }
            ],
        },
        itemBitacora: {
            textTitle: 'BITACORA',
            headerTable: [
                { label: 'Action', key: 'accion' },
                { label: 'Date', key: 'fecha' },
                { label: 'Description', key: 'descripcion' },
                { label: 'User', key: 'usuario' },
            ],
        },
        itemDocumentos: {
            textTitle: 'DOCUMENTS',
        },
        errors: {
            comparativoErrorDetalle: 'Detail of the comparison not found',
            facturaErrorDetalle: 'Invoice detail not found',
            requerimientoSistemaErrorDetalle: 'Detail of the Requirement System Not Found',
            requerimientoCompraErrorDetalle: 'Detail of the Purchase Requirement not found',
            solicitudCajaChicaErrorDetalle: 'Detail of the petty cash request has not been found',
            rendicionGastoCajaChicaErrorDetalle: 'Detail of the surrender petty cash expenses has not been found',
            hojaPeticionErrorDetalle: 'Detail of Request Form not found',
            condicionLaboralErrorDetalle: 'Detail of the labor condition has not been found',
            facturaNoAsociada: "Can't approve invoice without an association",
            aproveBatch: 'An error occurred while approving records, please try again',
            returnBatch: 'An error occurred while returning logs, please try again'
        },
        successBatch: 'Successful registrations',
    },
    moduloGenericList: {
        navBarComparativo: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'COMPARATIVE', navigate: false, path: '' }],
        navBarFactura: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'INVOICE', navigate: false, path: '' }],
        navBarRequerimientoCompra: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'PURCHASE REQUIREMENT', navigate: false, path: '' }],
        navBarRequerimientoSistema: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'SYSTEM REQUIREMENT', navigate: false, path: '' }],
        navBarSolicitudCajaChica: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'PETTY CASH APPLICATION', navigate: false, path: '' }],
        navBarRendicionGastosCajaChica: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'SURRENDER EXPENSES', navigate: false, path: '' }],
        navBarHojaPeticion: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'REQUEST FORM', navigate: false, path: '' }],
        navBarWorkingConditions: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'WORKING CONDITIONS', navigate: false, path: '' }],
        navBarGastoAprobacion: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'SURRENDER EXPENSES', navigate: false, path: '' }],
        navBarTareo: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'TAREO', navigate: false, path: '' }],
        navBarAusencia: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'ABSENCE', navigate: false, path: '' }],

        navBarHistoricoGastos: [{ text: 'RENDITIONS', navigate: true, path: '/main/caja-chica#aprobador' }, { text: 'HISTORICAL EXPENSES', navigate: false, path: '' }],
        navBarRendicionGastos: [{ text: 'RENDITIONS', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'EXPENDITURE SURRENDER', navigate: false, path: '' }],
        navBarIngresoGastos: [{ text: 'RENDITIONS', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'INCOME EXPENSES', navigate: false, path: '' }],
        navBarIngresoGastosAsociados: [{ text: 'RENDITIONS', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'INCOME ASSOCIATED EXPENSES', navigate: false, path: '' }],
        navBarSolicitudAnticipo: [{ text: 'RENDITIONS', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'PETTY CASH APPLICATION', navigate: false, path: '' }],

        navBarTareoPersonal: [{ text: 'TAREO', navigate: true, path: '/main/tareo' }, { text: 'PERSONNEL', navigate: false, path: '' }],
        navBarTareoGrupal: [{ text: 'TAREO', navigate: true, path: '/main/tareo' }, { text: 'GROUP', navigate: false, path: '' }],

        item: {
            textoListaVacia: 'No logs',
            itemTextProveedor: 'Supplier',
            itemTextOT: 'OT',
            itemTextMoneda: 'Coin',
            itemTextImporte: 'Amount',
            itemTextFechaRegistro: 'Registration date',
            itemTextFaltaAsociacion: 'Lack of association',
            itemTextSociedad: 'Society',
            itemTextTrabajador: 'Worker',
            itemTextSolicitante: 'Applicant',
            itemTextEmpresa: 'Enterprise',
            itemTextPersonal: 'Personnel',
            itemTextImporteAprobado: 'Approved Amount',
            itemTextRendidor: 'Yielder',
            itemTextProceso: 'In process',
            itemTextPeriodo: 'Period',
            itemTextPeriodoInicial: 'Initial Period',
            itemTextPeriodoFinal: 'Final Period',
            itemTextHoras: 'Hours',
        }
    },
    moduloCajaChica: {
        navBar: [{ text: 'RENDITIONS', navigate: false, path: '' }],
    },
    moduloGasto: {
        navBarGastosPendientes: [{ text: 'RENDITIONS', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'INCOME EXPENSES', navigate: true, path: '/main/caja-chica/ingreso-gastos' }, { text: '', navigate: false, path: '' }],
        navBarGastosAsociados: [{ text: 'RENDITIONS', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'INCOME ASSOCIATED EXPENSES', navigate: true, path: '/main/caja-chica/ingreso-gastos-asociados' }, { text: '', navigate: false, path: '' }],

        detail: {
            textTitle: 'Overview',
            textOT: 'OT',
            textProveedor: 'Supplier',
            textOtroProveedor: 'Other Supplier',
            textDesactivarOtroProveedor: 'Deactivate Another Supplier',
            textTipoDocumentoProveedor: 'Supplier Document Type',
            textNumeroDocumentoProveedor: 'Supplier Document Number',
            textRazonSocialProveedor: 'Company Name Supplier',
            textFechaEmision: 'Issue Date',
            textTipoDocumentoTributario: 'Type of Tax Document',
            textNumeroDocumento: 'Document Number',
            textServicio: 'Service',
            textDivisa: 'Currency',
            textImporte: 'Amount',
            textTipoImpuesto: 'Tax Type',
            textoOtroImpuesto: 'Other Tax',
            textDesactivarOtroImpuesto: 'Turn off another tax',
            textImporteImpuesto: 'Tax Amount',
            textImporteTotal: 'Total Amount',
            textObservaciones: 'Remarks',
            textDigitalizacionFactura: 'Invoice Digitization',
        },
        validate: {
            errorOT: 'Select an OT',
            errorProveedor: 'Select a Supplier',
            errorTipoDocumentoProveedor: 'Enter a Supplier Document Type',
            errorNumeroDocumentoProveedor: 'Enter a Number Supplier Document',
            errorRazonSocialProveedor: 'Enter Business Name Provider',
            errorFechaEmision: 'Enter an Issue Date',
            errorTipoDocumento: 'Select a Document Type',
            errorNumeroDocumento: 'Enter Document Number',
            errorServicio: 'Select a Service',
            errorDivisa: 'Select a Currency',
            errorImporte: 'Enter the Amount',
            errorTipoImpuesto: 'Select a Tax Type',
            errorObservacion: 'Enter a Remark',
            errorFiles: 'Enter Digitization Invoice',
            errorNumeroDocumentoDuplicado: 'There is already an expense with the same Document Number',
        }
    },
    moduloAnticipo: {
        navBar: [{ text: 'RENDITIONS', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'PETTY CASH APPLICATION', navigate: true, path: '/main/caja-chica/solicitud-anticipo' }, { text: '', navigate: false, path: '' }],
        detail: {
            textTitle: 'Overview',
            textOT: "OT",
            textProyectos: "Restrictions",
            textTarea: "Task",
            textPersonal: "Personnel",
            textDivisa: "Currency",
            textImporte: "Amount",
            textBanco: "Bank",
            textCuentaBancaria: "Bank Account",
            textCuentaInterbancaria: "Interbank Account",
            textNota: "Description",
            textoImporteMaximo: 'Maximum Amount',
            textHelp: 'For your petty cash application to be approved, it is essential to attach the duly signed document. You can find the template at the bottom.',
        },
        validate: {
            errorOT: "Select an OT",
            errorProyectos: "Select a Project",
            errorTarea: "Select a Task",
            errorPersonal: "Select Personnel",
            errorDivisa: "Select a Currency",
            errorImporte: "Enter the Amount",
            errorImporteValido: 'Enter a valid Amount',
            errorBanco: "Enter a Bank",
            errorCuentaBancaria: "Enter Bank Account",
            errorCuentaInterbancaria: "Enter Interbank Account",
            errorNota: "Enter Description"
        }
    },
    moduloRendicion: {
        navBar: [{ text: 'RENDITIONS', navigate: true, path: '/main/caja-chica' }, { text: 'SURRENDER OF EXPENSES', navigate: true, path: '/main/caja-chica/rendicion-gastos' }, { text: '', navigate: false, path: '' }],
        detail: {
            textTituloGeneral: 'General Information',
            textOT: 'Work Order',
            textPersonal: 'Personnel',
            textAnticipo: 'Petty cash application',
            textBanco: 'Bank',
            textCuentaBancaria: 'Bank Account',
            textCuentaInterbancaria: 'Interbank Account',
            textFecha: 'Date',
            textDescripcion: 'Description',

            textTituloGastos: 'Associate Expenses',
            textProveedor: 'Supplier',
            textImporte: 'Amount',
            btnAprobacion: 'Send for Approval',

            textTituloNuevoGasto: 'New Spending',
            textTituloFlujo: 'Flow',
            textTituloAsociar: 'Associate Expense',
            textGastoEncontrado: 'Expenses found',
        },
        validate: {
            errorDetail: 'Detail for this record was not found',
            errorOT: 'Select a Work Order',
            errorPersonal: 'Select a Personnel',
            errorAnticipo: 'Select a Petty Cash Application',
            errorBanco: 'Enter a Bank',
            errorCuentaBancaria: 'Enter a Bank Account',
            errorFecha: 'Enter a Date',
            errorDescripcion: 'Enter a description',
            errorGasto: 'Select at least one expense',
            errorFlujo: 'Select a Flow'
        }
    },
    moduloGastoAprobacion: {
        navBar: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'SURRENDER EXPENSES', navigate: true, path: '/main/approvals/gasto-aprobacion' }, { text: '', navigate: false, path: '' }],
        navBarHistorico: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'HISTORICAL EXPENSES', navigate: true, path: '/main/approvals/historico-gastos' }, { text: '', navigate: false, path: '' }],
        validate: {
            errorDetail: "The record detail was not found",
            errorComentario: "Enter a comment",
            errorTitulo: "Enter a title",
            errorDescripcion: "Enter a description"
        },
        question: {
            questApprove: `You are about to approve these expenses. Please confirm this action and add a comment explaining why you are approving this expense.`,
            questReject: `You are about to reject these expenses. Please confirm this action and add a comment explaining why you are rejecting this expense.`
        },
        generic: {
            textStatusRevision: "Under Review",
            textStatusAprobado: "Approved",
            textStatusRechazado: "Rejected",
            textProveedor: "Supplier",
            textOT: "Work Order",
            textImporte: "Amount",
            textImpTotal: "Total Amount",
            textComentario: 'Comment',
            textConfirmacion: 'Confirmation',
        },
        viewReport: {
            title: "Expense Report",
            textTitleDetail: 'Detail',
            textRendidor: "Personnel",
            textFechaEnvio: "Submission Date",
            textImporteAsociado: "Associated Amount",
            textCantidadLineas: "Number of Lines",
            textGastos: "Expenses",
            textoRevisados: "reviewed",
            textFechaEmision: "Issuance Date",
            textServicio: "Service",
            textoBuscar: "Search"
        },
        viewDetail: {
            title: "Expense",
            textTabDetail: "Detail",
            textTabArchivos: "Files",
            textTitleGeneral: "General",
            textTitleDetalleGasto: "Expense Details",
            textDelegacion: "Location",
            textFecha: "Date",
            textImporte: "Amount",
            textImpuesto: "Tax",
            textTipoDoc: "Document Type",
            textServicio: "Service",
            textObservaciones: "Observations",
            textEstado: 'Status',
        },
        viewHistory: {
            title: "Notes",
            textTitleComentario: 'Note',
            textTitulo: "Title",
            textTitleNote: 'Notes',
            textDescripcion: "Description",
            btnAgregarComentario: "Add Note"
        }
    },
    moduloDocucobra: {
        navBar: [{ text: 'DOCUCOBRA', navigate: true, path: '/main/docucobra/list' }],
        textoVersion: 'Version',
        textoResumen: 'Summary',
        textoAbrirResumen: 'Open Summary',
        textoAbrirDocumento: 'Open Document',
        textoVerRelacionados: 'See Related',
        textoTituloFiltros: 'Search filters',
        textoFiltroPais: 'Country',
        textoBuscar: 'Search',

        textoTituloArchivo: 'Document',
        textoTituloArchivosRelacionados: 'Related Documents',

        textoInputBuscar: 'Search...',
    },
    moduloTareo: {
        navBar: [{ text: 'TAREO', navigate: false, path: '/main/tareo' }],
        navBarPersonal: [{ text: 'TAREO', navigate: true, path: '/main/tareo' }, { text: 'MY TAREO', navigate: true, path: '/main/tareo/personal' }, { text: '', navigate: false, path: '' }],
        navBarAprobacion: [{ text: 'APPROVALS', navigate: true, path: '/main/approvals' }, { text: 'TAREO', navigate: true, path: '/main/approvals/tareo' }, { text: 'Detail', navigate: false, path: '' }],
        detail: {
            textPeriodo: 'Period',
            textPeriodoInicial: 'Initial Period',
            textPeriodoFinal: 'Final Period',
            textEstado: 'State',
            textTotalHoras: 'Total Hours',
            textHoras: 'Hours',
            textTitle: 'DETAIL TAREO',
            textTitleGeneral: 'GENERAL DETAIL',
            textTitleModalAutocompletar: 'Autocomplete Period',
            textTituloDetalle: 'Detail',
            textOT: 'OT',
            textDisciplina: 'Discipline',
            textFechaInicio: 'Start Date',
            textFechaFinal: 'End date',
            textExcluyeSabado: 'Excludes Saturday',
            textExcluyeDomingo: 'Excludes Sunday',
            textExcluyeFeriado: 'Excludes holidays',
            table: [
                { key: 'DiaMes', label: 'Date' },
                { key: 'Nombre', label: 'Day' },
                { key: 'HorasTrabajador', label: 'Total Hours' },
                { key: 'Preview', label: '' }
            ],
            tableAusencia: [
                // { key: 'Delete', label: '' },
                { key: 'Preview', label: '' },
                { key: 'Estado', label: 'Status' },
                { key: 'FechaInicio', label: 'Start Date' },
                { key: 'FechaFinal', label: 'Final Date' },
            ]
        },
        validate: {
            errorPeriodo: 'You do not have the Period configured',
            errorDetail: 'Detail not found for',
            errorOTBase: 'You do not have the base OT configured',
            errorOT: 'Your base OT not found',
            errorOTRequired: 'Select an OT',
            errorDisciplinaRequired: 'Select a discipline',
            errorHoraRequired: 'Enter a Time',
            errorDetalleCantidad: 'You must add at least one item',
            errorHoraMaximaDia: 'Total hours per day must not exceed',
            errorDuplicadoItem: 'This OT and Discipline already exists',
        },
        question: {
            questLimpiarPeriodo: 'Do you want to clean the hours of the entire period?',
            questAutocomplete: 'It will replace all current hours and autocomplete with the current one, do you want to continue?',
        },
        days: {
            LU: "Monday",
            MA: "Tuesday",
            MI: "Wednesday",
            JU: "Thursday",
            VI: "Friday",
            SA: "Saturday",
            DO: "Sunday",
        }
    },
    menu: {
        '010': { titulo: 'APPROVALS' },
        '011': { titulo: 'System Requirement' },
        '012': { titulo: 'Purchase requirement' },
        '013': { titulo: 'Comparative' },
        '014': { titulo: 'Invoice' },
        '015': { titulo: 'Petty cash application' },
        '016': { titulo: 'Surrender of expenses' },
        '017': { titulo: 'Request Form' },
        '018': { titulo: 'Working conditions' },
        '019': { titulo: 'Surrender expenses' },

        '020': { titulo: 'DOCUCOBRA' },
        '030': { titulo: 'RENDITIONS' },
        '031': { titulo: 'Approval expenses' },
        '032': { titulo: 'Historical expenses' },
        '033': { titulo: 'Surrender' },
        '036': { titulo: 'Petty Cash Application' },

        '040': { titulo: 'TAREO' },
        '041': { titulo: 'My Tareo' },
        '042': { titulo: 'Group Tareo' },
        '043': { titulo: 'Tareo' },
        '044': { titulo: 'Absence' },
    },
    languages: [
        { code: 'es', name: 'Spanish' },
        { code: 'en', name: 'English' },
    ]
}
