import { EntityTareoPersonal } from "../../../../shared/Domain/Tareo/EntityTareoPersonal";
import { EntityRequestGenericForm } from "../Domain/EntityRequest";
import { EntityResponseInitialData } from "../Domain/EntityResponse";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseFind {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(code: string, options: EntityResponseInitialData): Promise<{ data: EntityRequestGenericForm, dataComplete: EntityTareoPersonal }  | null> {
        return await this._exec(code, options);
    }

    private async _exec(code: string, options: EntityResponseInitialData): Promise<{ data: EntityRequestGenericForm, dataComplete: EntityTareoPersonal }  | null> {
        return await this.repository.findForm(code, options);
    }
}