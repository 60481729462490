// Library
import React from 'react';

// Components
import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { ElementEmptyList } from '../../../shared/Components/ElementEmptyList';
import { ItemTareoPersonal } from './module/ItemTareoPersonal';
import { ItemTareoGrupal } from './module/ItemTareoGrupal';

// Infraestructure
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';

// Domain
import { EntityMain } from '../Domain/EntityMain';
import { EntityTypeModule } from '../Domain/EntityTypeModule';

// Styles
import './Style.scss';

interface PropsViewMain {
    data: EntityMain<any>[];
    onRedirectForm: (_id: string) => void;
    reloadData: Function;
    modeTypeModule: EntityTypeModule;
    copyCodeItem: (code: string) => Promise<void>;
    openModalFiltro: () => void;
};

export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();
    const genericProps= (module: string) => ({
        data: props.data,
        onRedirectForm: props.onRedirectForm,
        copyCodeItem: props.copyCodeItem,
        show: props.modeTypeModule.key === module,
    })

    return (
        <div className='ViewGenericListPettyCash'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={props.modeTypeModule.navBar} />

                {/* <div className='d-flex group-btn-option'>
                    <button className='btn btn-primary btn-seleccionar' onClick={() => props.onRedirectForm('form')} > <i style={{ fontSize: 14, marginRight: 10 }} className='fa-solid fa-plus'/>{languageTranslate.btnAgregar}</button>
                </div> */}

                <ItemTareoPersonal {...genericProps('TareoPersonal')} />
                <ItemTareoGrupal {...genericProps('TareoGrupal')} />

                {
                    props.data.length === 0 && ( 
                        <ElementEmptyList
                            className="data-empty"
                            text={languageTranslate.moduloGenericList.item.textoListaVacia}
                        />
                    )
                }

                <div className='position-fixed reload'>
                    <div className='item-reload bg-primary' onClick={() => props.openModalFiltro()}>
                        <i className="fa-solid fa-filter" style={{ fontSize: 20 }} />
                    </div>
                    <div className='item-reload bg-primary' onClick={() => props.reloadData()}>
                        <i className="fa-sharp fa-solid fa-arrow-rotate-right" style={{ fontSize: 20 }} />
                    </div>
                </div>
            </div>

        </div >
    )
};