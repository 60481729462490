import { LanguageTranslate } from "../Infraestructure/LanguageTranslate";
import './ElementStepperContent.scss';

interface IElementStepperContent {
    title: string;
    children: any;
    step: number;
    currentStep: number;
    onSubmit: (step: number) => void;
    removeButtonSubmit?: boolean;
    activateBackButton?: boolean;
    isLastStep?: boolean;
    buttonRightCustom?: () => JSX.Element;
}

const ElementStepperContent = (props: IElementStepperContent) => {
    if ( props.step !== props.currentStep) return <div></div>;
    const languageTranslate = LanguageTranslate();

    return (
        <div className="ElementStepperContent mb-4 shadow rounded">
            <div className="titleTab d-flex align-items-center justify-content-between rounded-top">
                <label className="d-flex h-auto text-muted">{props.title}</label>
                { props.buttonRightCustom && props.buttonRightCustom() }
            </div>
            <div className="contentTab rounded-bottom">
                { props.children }
                { props.activateBackButton && <br /> }
                {
                    !props.removeButtonSubmit && (
                        <button onClick={() => props.onSubmit(props.step + 1)} className='btn btn-primary w-100'>
                            { props.isLastStep ? languageTranslate.btnGuardar : languageTranslate.btnSiguiente }
                        </button>
                    )
                }
                {
                    props.activateBackButton && (
                        <button onClick={() => props.onSubmit(props.step - 1)} className='btn btn-dark w-100 my-3'>
                            { languageTranslate.btnRegresar }
                        </button>
                    )
                }
            </div>
        </div>
    )
}

export default ElementStepperContent;