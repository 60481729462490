import { EntityRendicionGastoAprobacion } from "../../../../../shared/Domain/EntityRendicionGastoAprobacion";
import { AdapterGenerico } from "../../../../../shared/Infraestructure/AdapterGenerico";
import { RepositoryImplGenerico } from "../../../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityRequestApproval, EntityRequestNote } from "../Domain/EntityRequest";
import { keyModule } from "../Domain/EntityUtils";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async addNote(params: EntityRequestNote): Promise<EntityRendicionGastoAprobacion | null> {
        let url = `${this.urlBaseGastos}/Rendicion/Informe/addNoteReport`;
        const response: EntityRendicionGastoAprobacion | null = (await this.service.call<any>("POST", url, JSON.stringify(params), "bearer", "json", "json", { "request-decrypt-response": true }, 0))[0];
        if (!response) return null;

        const result: EntityRendicionGastoAprobacion = await this.dbLocal.selectByIndexStore({ nameStore: 'GastoAprobacion', value: params.codigo });
        result.Notas = response.Notas;
        await this.dbLocal.updateByIndexStore({ nameStore: 'GastoAprobacion', value: result });

        if (result) {
            result.Bitacora = (result.Bitacora || []).map(row => ({
                accion: row.Action,
                descripcion: row.Description ?? '',
                fecha: row.Date.Date ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3) : '',
                usuario: `${row.User?.Name} ${row.User?.LastName}`,
            })).reverse() as any

            result.Notas = (result.Notas || []).map(row => ({
                observacion: row.Descripcion,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Dates.Fecha), 3),
                usuario: `${row.Users.Name} ${row.Users.LastName}`,
                action: row.Titulo
            })).reverse() as any

            result.FlujoFormatted = this.formatStepperUser(result.Aprobacion.Nivel, result.Flujo.Aprobacion);
        }
        return result;
    }

    public async approval(params: EntityRequestApproval): Promise<EntityRendicionGastoAprobacion | null> {
        const { type, keyModule, ..._params } = params;
        let url = this.urlBaseGastos;
        let obj;
        let result = null;
        switch(type) {
            case 'approve': 
                url += '/Rendicion/Informe/approveGasto';
                obj = _params;
                const responseApprove: EntityRendicionGastoAprobacion | null = await this.service.call<any>("POST", url, JSON.stringify(obj), "bearer", "json", 'json', { "request-decrypt-response": true }, 0);
                if (!responseApprove) return null;
                result = responseApprove;
                result.FlujoFormatted = this.formatStepperUser(result.Aprobacion.Nivel, result.Flujo.Aprobacion);
            break;
            case 'reject':
                url += '/Rendicion/Informe/rejectGasto';
                obj = _params;
                const responseReject: { Informe: EntityRendicionGastoAprobacion; InformeNuevo: EntityRendicionGastoAprobacion } | null = await this.service.call<any>("POST", url, JSON.stringify(obj), "bearer", "json", 'json', { "request-decrypt-response": true }, 0);
                if (!responseReject?.Informe) return null;
                result = responseReject.Informe;
                result.FlujoFormatted = this.formatStepperUser(result.Aprobacion.Nivel, result.Flujo.Aprobacion);
            break;
        }

        await this.dbLocal.deleteByIndexStore({ nameStore: keyModule, value: _params.codigoInforme });
        return result;
    }

    public async findForm(code: string, keyModule: keyModule): Promise<EntityRendicionGastoAprobacion | null> {
        const response: EntityRendicionGastoAprobacion | null = await this.dbLocal.selectByIndexStore({ nameStore: keyModule, value: code });
        if (response) {
            response.Bitacora = (response.Bitacora || []).map(row => ({
                accion: row.Action,
                descripcion: row.Description ?? '',
                fecha: row.Date.Date ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3) : '',
                usuario: `${row.User?.Name} ${row.User?.LastName}`,
            })).reverse() as any

            response.Notas = (response.Notas || []).map(row => ({
                observacion: row.Descripcion,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Dates.Fecha), 3),
                usuario: `${row.Users.Name} ${row.Users.LastName}`,
                action: row.Titulo
            })).reverse() as any

            response.FlujoFormatted = this.formatStepperUser(response.Aprobacion.Nivel, response.Flujo.Aprobacion);
        }
        return response
    }

    private formatStepperUser(currentNivel: number, data?: any[]): { currentNivel: number, data: any[], stepNumberStart: number } {
        if (!data) return { currentNivel: 0, data: [], stepNumberStart: 0 };
        let newCurrentNivel = (currentNivel + 1);
        let stepperUser: any[] = [];
        let positionStepper = 0;
        let maximoStepperMostrar = 2;

        if (newCurrentNivel === data.length && stepperUser.length === 0) {
            // si el nivel esta al final
            data.reverse().forEach((row, index) => {
                if (index <= maximoStepperMostrar)
                    stepperUser.push({
                        label: row.Perfil.Perfil,
                        description: `${row.Aprobador.Name} ${row.Aprobador.LastNameFather}`
                    });
            });
            stepperUser = stepperUser.reverse();
            positionStepper = stepperUser.length;
        }

        if (newCurrentNivel === 1 && stepperUser.length === 0) {
            // si el nivel esta al principio
            data.forEach((row, index) => {
                if (index <= maximoStepperMostrar)
                    stepperUser.push({
                        label: row.Perfil.Perfil,
                        description: `${row.Aprobador.Name} ${row.Aprobador.LastNameFather}`
                    });
            });
            positionStepper = 1;
        }

        if (newCurrentNivel > 1 && newCurrentNivel < data.length && stepperUser.length === 0) {
            // si el nivel esta en medio
            data.forEach((row, index) => {
                if ([(currentNivel + 1), (currentNivel - 1), currentNivel].some(nivel => nivel === index))
                    stepperUser.push({
                        label: row.Perfil.Perfil,
                        description: `${row.Aprobador.Name} ${row.Aprobador.LastNameFather}`
                    });
            });
            positionStepper = 2;
        }

        if (stepperUser.length === 0) {
            // si el nivel sobrepasa el arreglo
            return ({
                currentNivel: 1,
                data: data.filter((row, index) => index <= 2).map(row => ({ 
                    label: row.Perfil.Perfil,
                    description: `${row.Aprobador.Name} ${row.Aprobador.LastNameFather}`
                })),
                stepNumberStart: 0,
            })
        }

        return ({
            currentNivel: positionStepper,
            data: stepperUser,
            stepNumberStart: newCurrentNivel - positionStepper,
        });
    }
}