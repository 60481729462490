import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ContentForm, ViewMain } from "./Components/View";

const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        // eslint-disable-next-line
    }, []);

    return (<ViewMain
        configForm={controller.configForm}
        languageTranslate={controller.languageTranslate}
        formikForm={controller.formikForm}
        onChange={controller.onChange}
        onSubmit={controller.onSubmit}
        onCancelForm={controller.onCancelForm}
    />);
};

export const ViewGastoCustomManager = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        // eslint-disable-next-line
    }, []);

    return ({
        controller: controller,
        component: <ContentForm
            configForm={controller.configForm}
            languageTranslate={controller.languageTranslate}
            formikForm={controller.formikForm}
            onChange={controller.onChange}
            onSubmit={controller.onSubmit}
            onCancelForm={controller.onCancelForm}
            disabledOT
        />
    });
}

export default ManagerEntity;