import { InputTextArea } from "../../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../../shared/Components/ElementModalCostume"
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { EntityConfigModal } from "../../Domain/EntityUtils";

interface ContainerProps {
    closeModal(): void;
    onSubmit(evt: Event): void;
    onChange(name: string, value: string): void; 
    configModal: EntityConfigModal;
}

const languageTranslate = LanguageTranslate();

export const ViewModal = (props: ContainerProps) => {
    return (
        <ElementModalCostume
            closeModal={props.closeModal}
            show={props.configModal.show}
            title={languageTranslate.moduloGenericDetail.btnTextDevolver}
            disabledButtonClose
            height={'380px'}
        >
            <div>
                <p className="text-danger">{props.configModal.description}</p>
                <InputTextArea
                    label="Comentario"
                    name="comment"
                    onChange={props.onChange}
                    values={props.configModal}
                    isRequired
                />
            </div>
            <div className="w-100 d-flex" style={{ gap: 10 }}>
                <button className="btn btn-primary" style={{ width: '50%' }} onClick={props.closeModal}>{languageTranslate.btnCancelar}</button>
                <button className={`btn btn-danger`} style={{ width: '50%' }} onClick={(evt: any) => props.onSubmit(evt)}>{languageTranslate.moduloGenericDetail.btnTextDevolver}</button>
            </div>
        </ElementModalCostume>
    )
}