import { EntityAnticipo } from "../../../shared/Domain/EntityAnticipo";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntityGasto } from "../../../shared/Domain/EntityGasto";
import { EntityRendicionGasto } from "../../../shared/Domain/EntityRendicionGasto";
import { ErrorCostume } from "../../../shared/Domain/ErrorCostume";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { EntityRequestList } from "../Domain/EntityRequest";
import { KeyBDModule } from "../Domain/EntityTypeModule";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async deleteItems(codes: string[], keyModule: KeyBDModule, user: EntityDataUsuario): Promise<boolean> {
        let response = false;
        let url: string;
        
        try {
            switch(keyModule) {
                case 'Gastos':
                    url = `${this.urlBaseGastos}/Rendicion/Gasto/deleteMany?code[]=${codes.join("&code[]=")}`;
                    response = await this.service.bgCall<any>("DELETE", url, "{}", "bearer", "json", 'json', { "request-decrypt-response": true }, 0);
                    this.dbLocal.deleteByIndexStore(codes.map(row => ({ nameStore: keyModule, value: row})));
                    break;
                case 'RendicionGasto':
                    url = `${this.urlBaseGastos}/Rendicion/Informe/reportToDelete`;
                    let params = {
                        codigo: codes,
                        user: {
                            user: user.User,
                            lastname: `${user.LastNameFather} ${user.LastNameMother}`,
                            name: user.Name
                        }
                    }
                    response = await this.service.bgCall<any>("PATCH", url, JSON.stringify(params), "bearer", "json", 'json', { "request-decrypt-response": true }, 0);
                    this.dbLocal.deleteByIndexStore(codes.map(row => ({ nameStore: keyModule, value: row})));
                    break;
                case 'SolicitudAnticipo':
                    url = `${this.urlBase}/navision/deleteAnticipoCajaChica`;

                    for (let row of codes) {
                        const payload = {
                            filter: {
                                CodigoAnticipo: row
                            },
                            user: {
                                IdUser: user.IdUser,
                                User: user.User,
                                LastName: `${user.LastNameFather} ${user.LastNameMother}`,
                                Name: user.Name,
                                Perfil: user.Profile.find(row => row.Principal === 'SI')?.Perfil || ''
                            }
                        }

                        const result = await this.service.bgCall<any>("POST", url, JSON.stringify(payload), "bearer", "json", "json", {}, 0);
                        if (!result.status) throw Error(result.message);
                        await this.dbLocal.deleteByIndexStore({ nameStore: keyModule, value: row });
                    };

                    response = true;
                    break;
                default: url = "";
            }
        } catch(err) {
            let error: ErrorCostume = new ErrorCostume((err as Error).message);
            AdapterGenerico.createMessage(LanguageTranslate().textoAlerta, error.message, 'warning', false);
        }
        return response;
    }

    public formatToGenericList<T>(payload: any[], module: KeyBDModule): EntityMain<T>[] {

        switch(module) {
            case 'RendicionGasto':
                return payload.map((row: EntityRendicionGasto) => ({
                    Codigo: row.Codigo,
                    FechaRegistro: row.Dates?.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date(),
                    isPendingSend: row.isPendingSend,
                    dataComplete: row as T
                })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
            case 'HistoricoGasto':
            case 'GastoAprobacion':
                return payload.map((row: EntityRendicionGasto) => ({
                    Codigo: row.Codigo,
                    FechaRegistro: row.Bitacora.filter(row => row.Action === 'RENDICION EN REVISION').pop()?.Date.Date ?
                        AdapterGenerico.convertStringToDate(row.Bitacora.filter(row => row.Action === 'RENDICION EN REVISION').pop()?.Date.Date)
                        :
                        (row.Dates?.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date()),
                    isPendingSend: row.isPendingSend,
                    dataComplete: row as T
                })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
            case 'SolicitudAnticipo':
                return payload.map((row: EntityAnticipo) => ({
                    Codigo: row.Codigo,
                    FechaRegistro:  row.Dates.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date(),
                    isPendingSend: row.isPendingSend,
                    dataComplete: row as T
                })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
            default:
                return [];
        }
    }

    public async getList(user: EntityDataUsuario, keyModule: KeyBDModule, filters: any = null): Promise<{ dataFormatted: EntityMain<any>[], data: any[] } | null> {
        return this._getList(user, keyModule, filters);
    }

    private async _getList(user: EntityDataUsuario, keyModule: KeyBDModule, filters: any = null): Promise<{ dataFormatted: EntityMain<any>[], data: any[] } | null> {
        let url: string;
        let params: EntityRequestList = { fields: {}, filter: {} };
        let result: EntityMain<any>[];

        switch(keyModule) {
            case 'RendicionGasto':
                url = `${this.urlBaseGastos}/Rendicion/Informe/find`;
                if (filters) {
                    params.filter = { "Users.Registry.User": user.User, "Status.Rendicion.IdStatus": { $in: filters.value === 'pending' ? [0, 10, 5] : [1] } };
                } else {
                    params.filter = { "Users.Registry.User": user.User, "Status.Rendicion.IdStatus": { $in: [0, 10, 5] } };
                }
                await this.getDependentList(user);
                break;
            case 'SolicitudAnticipo':
                url = `${this.urlBaseGastos}/Navision/Solicitudes/findAnticipo`;
                params.filter = {
                    "Users.Registry.User": user.User,
                    "DatosPersonal.Identificacion": user.Identificacion,
                    "Status.Solicitud.IdStatus": { "$in": [0, 2, 3] },
                    "Modulo": "RENDICIONES"
                }
                if (filters) {
                    params.filter = {
                        ...params.filter,
                        "Status.Solicitud.IdStatus": {  $in: filters.value === 'pending' ? [0, 2, 3] : [1] }
                    }
                }
                break;
            case 'GastoAprobacion':
                url = `${this.urlBaseGastos}/Rendicion/Informe/findApproval`;
                params.filter = {
                    "Flujo.Aprobacion": { "$elemMatch": { "Aprobador.Identificacion": user.Identificacion } }, // 72720804
                    "$expr": {
                        "$eq": [{ "$sum": ["$Aprobacion.Nivel", 1]}, { "$arrayElemAt": ["$Flujo.Aprobacion.Nivel", { "$indexOfArray": ["$Flujo.Aprobacion.Aprobador.Identificacion", user.Identificacion ] }] }]
                    }
                };
                break;
            case 'HistoricoGasto':
                url = `${this.urlBaseGastos}/Rendicion/Informe/findHistorico`;
                params.filter = {
                    "Flujo.Aprobacion": { "$elemMatch": { "Aprobador.Identificacion": user.Identificacion } },
                    "$expr": {
                        "$gt": ["$Aprobacion.Nivel", { "$arrayElemAt": ["$Flujo.Aprobacion.Nivel", { "$indexOfArray": ["$Flujo.Aprobacion.Aprobador.Identificacion", user.Identificacion] }] }]
                    }
                };
                break;
            default: url = "";
        }

        let response = await this.service.bgCall<any>("POST", url, JSON.stringify(params), "bearer", "json", 'json', { "request-decrypt-response": true }, 0);

        switch(keyModule) {
            case 'SolicitudAnticipo':
                response = Array.isArray(response) ? response : [];
                result = this.formatToGenericList<EntityAnticipo>(response, keyModule);
                break;
            case 'RendicionGasto':
            case 'GastoAprobacion':
            case 'HistoricoGasto':
                result = this.formatToGenericList<EntityRendicionGasto>(response, keyModule);
                break;
            default: result = [];
        }

        return { data: response, dataFormatted: result };
    }

    private async getDependentList(user: EntityDataUsuario): Promise<void>{
        const urlGastos = `${this.urlBaseGastos}/Rendicion/Gasto/find`;
        let paramsGastos: EntityRequestList = { fields: {}, filter: {} };
        paramsGastos.filter = { "Status.IdStatus": { $in: [0, 1, 3, 4, 5] }, "Users.Registry.User": user.User };
        
        const urlAnticipoPendientes = `${this.urlBaseGastos}/Navision/Solicitudes/findAnticipoPendientes`;
        let paramsAnticipoPendientes = {
            Identificacion: user.Identificacion
        };

        const [responseGastos, responseAnticipoPendientes] = await Promise.all([
            await this.service.bgCall<any>("POST", urlGastos, JSON.stringify(paramsGastos), "bearer", "json", 'json', { "request-decrypt-response": true }, 0),
            await this.service.bgCall<any>("POST", urlAnticipoPendientes, JSON.stringify(paramsAnticipoPendientes), "bearer", "json", 'json', { "request-decrypt-response": true }, 0),
        ])
        
        if (responseGastos) await this.dbLocal.insertDataStore([{ nameStore: 'Gastos', data: responseGastos }]); 
        if (Array.isArray(responseAnticipoPendientes)) {
            await this.dbLocal.clearStore('CTAnticipo');
            await this.dbLocal.insertDataStore([{ nameStore: 'CTAnticipo', data: responseAnticipoPendientes || [] }]);
        }
    }
}