// Library
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';

// Infraestructure
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { addLoading, changeSaludo, removeLoading } from "../../../../shared/Infraestructure/SliceGenerico";
import { RootState } from "../../../../shared/Infraestructure/AdapterStore";
import { RepositoryImplMain } from "./RepositoryImplMain";

// Domain
import { EntityConfigForm, EntityConfigModalAusencia, EntityConfigModalAutocomplete, EntityConfigModalDetalle, EntityConfigModalItem, EntityFormModalAusencia, EntityFormModalDetalle, EntityFormModalNotes, EntityNavBar, EntityRequestFormServiceSendApproval, ModeForm, initEntityConfigForm, initEntityConfigModalAusencia, initEntityConfigModalAutocomplete, initEntityConfigModalDetalle, initEntityConfigModalItem, initEntityFormModalAusencia, initEntityFormModalAutocomplete, initEntityFormModalItem, initEntityFormModalNotes, nameModule } from "../Domain/EntityUtilts";
import { useNavigate, useParams } from "react-router-dom";
import { AdapterGenerico } from "../../../../shared/Infraestructure/AdapterGenerico";

// Adapters
import { AdapterConfigure } from "./AdapterConfigure";

// Use Case
import { UseCaseLoadInitialData } from "../Application/UseCaseLoadInitialData";
import { EntityDias, EntityRequestAddAusencia, EntityRequestAddNote, EntityRequestCancelReport, EntityRequestTareas, EntityRequestUpdateAusencia, initEntityRequestGenericForm } from "../Domain/EntityRequest";
import { AdapterValidator } from "../../../../shared/Infraestructure/AdapterValidator";
import { EntityTareoPersonal } from "../../../../shared/Domain/Tareo/EntityTareoPersonal";
import { UseCaseFind } from "../Application/UseCaseFind";
import { EntityAusencia } from "../../../../shared/Domain/Tareo/EntityAusencia";

const languageTranslate = LanguageTranslate();
let modeForm: ModeForm = '';
let dataComplete: EntityTareoPersonal;

export const Controller = () => {
    const [configForm, setConfigForm] = useState<EntityConfigForm>(initEntityConfigForm)
    const [configModalAutocompletar, setConfigModalAutocompletar] = useState<EntityConfigModalAutocomplete>(initEntityConfigModalAutocomplete);
    const [configModalDetalle, setConfigModalDetalle] = useState<EntityConfigModalDetalle>(initEntityConfigModalDetalle);
    const [configModalItem, setConfigModalItem] = useState<EntityConfigModalItem>(initEntityConfigModalItem);
    const [configModalNote, setConfigModalNote] = useState<EntityFormModalNotes>(initEntityFormModalNotes);
    const [configModalAusencia, setConfigModalAusencia] = useState<EntityConfigModalAusencia>(initEntityConfigModalAusencia);
    const { generico: { websocket, dbLocal }, auth: { user } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    // ------------------- Form Base --------------------
    const formikForm = useFormik({
        initialValues: initEntityRequestGenericForm,
        onSubmit: () => {},
        validationSchema: Yup.object({
            OTBase: Yup.object().required(languageTranslate.moduloAnticipo.validate.errorOT).nullable(),
            Periodo: Yup.object().required(languageTranslate.moduloTareo.validate.errorPeriodo),
            Observacion: Yup.string().nullable(),
            Tareas: Yup.array(),
        })
    });

    const init = () => {
        dispatch(changeSaludo(false));
        configModeType();
        AdapterGenerico.scrollTopByClassName('ViewMain');
    }

    const configModeType = async () => {
        // Carga la información de los select
        const dataOptions = await new UseCaseLoadInitialData(repository).exec(user);

        // Variables
        let navBar: EntityNavBar[] = languageTranslate.moduloTareo.navBarPersonal;

        if (params.id) {
            // Modo editar
            modeForm = 'edit';
            const findForm = await new UseCaseFind(repository).exec(`${params.id}`, dataOptions);
            if (!findForm) {
                AdapterGenerico.createMessage('Error', `${languageTranslate.moduloTareo.validate.errorDetail}: ${params.id}`, 'error').then(res => navigate(AdapterConfigure.LIST_TAREO_PERSONAL)); 
                return;
            }

            if (![0, 2].includes(findForm.data.Estado.IdStatus)) modeForm = 'preview';
            dataComplete = findForm.dataComplete;
            formikForm.setValues({
                ...findForm.data,
                User: {
                    IdUser: user.IdUser,
                    LastFather: user.LastNameFather,
                    LastMother: user.LastNameMother,
                    Name: user.Name,
                    Perfil: user.Profile.find(row => row.Principal === 'SI')?.Perfil || '',
                    User: user.User
                }
            });
        }

        // Modifica el navbar para el lenguaje
        if (navBar.length >= 3) navBar[2].text = nameModule[modeForm];

        // Guarda la información general y la del usuario
        setConfigForm(() => ({
            ...configForm,
            loadingForm: false,
            dataOptions: dataOptions,
            navBar: navBar,
            mode: modeForm,
        }));
    }

    const onClearTareo = async () => {
        const responseMessage = await AdapterGenerico.createMessage('', languageTranslate.moduloTareo.question.questLimpiarPeriodo, 'question', true);
        if (!responseMessage) return null;
        formikForm.setFieldValue('Tareas', []);
        formikForm.setFieldValue('Dias', formikForm.values.Dias.map(row => ({...row, HorasTrabajador: 0 })));
    }

    const onSubmit = async (evt: Event) => {
        try {
            evt.preventDefault();
            evt.stopPropagation();

            try { await formikForm.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formikForm.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            const dataFormated = await repository.formatDataToSave(formikForm.values);
            await repository.save(dataFormated, modeForm);

            navigate(AdapterConfigure.LIST_TAREO_PERSONAL, { replace: true });
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    const onSubmitApproval = async (evt: Event) => {
        try {
            evt.preventDefault();
            evt.stopPropagation();

            try { await formikForm.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formikForm.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            const dataFormated = await repository.formatDataToSave(formikForm.values);
            const responseSave = await repository.save(dataFormated, modeForm);
            if (!responseSave) return;
            
            // Enviar a aprobación
            const dataApproval: EntityRequestFormServiceSendApproval = {
                paramsSend: {
                    codigo: responseSave.Codigo,
                    identificacion: user.Identificacion,
                    listActividad: [],
                    listaOT: responseSave.Tareas.map(item => item.Proyecto.OT.Code),
                    listDisciplina: responseSave.Tareas.map(item => item.Disciplina.Codigo),
                },
                extraData: {
                    Codigo: responseSave.Codigo
                }
            }
            await repository.sendApproval(dataApproval);

            navigate(AdapterConfigure.LIST_TAREO_PERSONAL, { replace: true });
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    const cancelWorkFlow = async (evt: Event) => {
        try {
            evt.preventDefault();
            evt.stopPropagation();

            const responseMessage = await AdapterGenerico.createMessage('', '¿Desea anular el envío del informe?', 'warning', true)
            if (!responseMessage) return null;
            
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            // Formato de request
            const payload: EntityRequestCancelReport = {
                codigo: [formikForm.values.Codigo],
                user: {
                    IdUser: user.IdUser,
                    Name: user.Name,
                    LastFather: user.LastNameFather,
                    LastMother: user.LastNameMother,
                    User: user.User,
                    Perfil: user.Profile.find(row => row.Principal === 'SI')?.Perfil || '',
                }
            }
            await repository.cancelWorkFlow(payload);

            navigate(AdapterConfigure.LIST_TAREO_PERSONAL, { replace: true });
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    // ------------------- Modal Autocompletar --------------------
    const formModalAutocompletar = useFormik({
        initialValues: initEntityFormModalAutocomplete,
        onSubmit: () => {},
        validationSchema: Yup.object({
            OT: Yup.object().required(languageTranslate.moduloTareo.validate.errorOTRequired).nullable(),
            Disciplina: Yup.object().required(languageTranslate.moduloTareo.validate.errorDisciplinaRequired).nullable(),
            Horas: Yup.string().required(languageTranslate.moduloTareo.validate.errorHoraRequired),
            FechaInicio: Yup.string().required().nullable(),
            FechaFinal: Yup.string().required().nullable(),
            ExcluyeSabado: Yup.boolean().nullable(),
            ExcluyeDomingo: Yup.boolean().nullable(),
            ExcluyeFeriado: Yup.boolean().nullable()
        })
    })

    const openModalAutocompletar = () => {
        const OTBase = configForm.dataOptions.ot.find(row => row.dataComplete.Codigo === user.DatosTrabajo.OT);
        const minDate = AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(formikForm.values.Periodo?.FechaInicio), 6);
        const maxDate = AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(formikForm.values.Periodo?.FechaFinal), 6);
        formModalAutocompletar.setFieldValue('FechaInicio', minDate);
        formModalAutocompletar.setFieldValue('FechaFinal', maxDate);
        formModalAutocompletar.setFieldValue('OT', OTBase);
        if (OTBase?.dataComplete.Code && !AdapterConfigure.LIST_OT_BISA.includes(OTBase.dataComplete.Code)) {
            const disciplinaFinded = configForm.dataOptions.disciplina.find(row => row.dataComplete.Key === AdapterConfigure.KEY_DISCIPLINA_GENERAL);
            formModalAutocompletar.setFieldValue('Disciplina', disciplinaFinded || null)
        }
        setConfigModalAutocompletar(() => ({...initEntityConfigModalAutocomplete, show: true}));
    }
    
    const closeModalAutocompletar = () => {
        setConfigModalAutocompletar(() => ({...initEntityConfigModalAutocomplete, show: false}));
        formModalAutocompletar.setValues({...initEntityFormModalAutocomplete})
    }

    const onChangeMA = (name: string, value: any) => {
        if (name === 'Horas' && value.includes('.')) return;
        formModalAutocompletar.setFieldValue(name, value);
    }

    const onSubmitMA = async (evt: Event) => {   
        try {
            evt.preventDefault();
            evt.stopPropagation();

            try { await formModalAutocompletar.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formModalAutocompletar.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }
            
            // const responseMessage = await AdapterGenerico.createMessage('', languageTranslate.moduloTareo.question.questAutocomplete, 'warning', true)
            // if (!responseMessage) return null;

            // Verificar si ya existe la misma combinación de OT y Disciplina
            const filterItemDuplicate = formikForm.values.Tareas.find(row => 
                row.Proyecto.OT.Codigo === formModalAutocompletar.values.OT?.dataComplete.Codigo &&
                row.Disciplina.Codigo === formModalAutocompletar.values.Disciplina?.dataComplete.Code
            );

            if (filterItemDuplicate) {
                AdapterGenerico.createMessage('', 'Ya existe un ítem con la misma OT y Disciplina', 'warning');
                return;
            };

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            
            const dataFormated: EntityRequestTareas = {
                Proyecto: {
                    Delegacion: {
                        Code: formModalAutocompletar.values.OT?.dataComplete.DatosTrabajo.Delegacion.Code || '',
                        Codigo: formModalAutocompletar.values.OT?.dataComplete.DatosTrabajo.Delegacion.Codigo || '',
                        Delegacion: formModalAutocompletar.values.OT?.dataComplete.DatosTrabajo.Delegacion.Name || '',
                    },
                    OT: {
                        Code: formModalAutocompletar.values.OT?.dataComplete.Code || '',
                        Codigo: formModalAutocompletar.values.OT?.dataComplete.Codigo || '',
                        OT: formModalAutocompletar.values.OT?.dataComplete.Name || ''
                    },
                    DatosEmpresa: {
                        CDEmpresa: formModalAutocompletar.values.OT?.dataComplete.DatosEmpresa.CDEmpresa || '',
                        Empresa: formModalAutocompletar.values.OT?.dataComplete.DatosEmpresa.Empresa || ''
                    }
                },
                Disciplina: {
                    Codigo: formModalAutocompletar.values.Disciplina?.dataComplete.Code || '',
                    Name: formModalAutocompletar.values.Disciplina?.dataComplete.Name || ''
                },
                Observacion: '',
                Horas: formikForm.values.Dias.map((row,index) => {
                    let [horas, minutos] = formModalAutocompletar.values.Horas.split(':');
                    let horaBase = parseFloat(`${horas}.${minutos}`);

                    if (row.Nombre === 'SA' && formModalAutocompletar.values.ExcluyeSabado) horaBase = 0
                    else if (row.Ausencia) horaBase = 0;
                    else if (row.Bloqueado) horaBase = 0;
                    else if (row.Nombre === 'DO' && formModalAutocompletar.values.ExcluyeDomingo) horaBase = 0;
                    else if (row.Tipo === 2 && formModalAutocompletar.values.ExcluyeFeriado) horaBase = 0;
                    else if (row.DiaMes < AdapterGenerico.convertStringToDate(formModalAutocompletar.values.FechaInicio).getDate() && row.Mes === AdapterGenerico.convertStringToDate(formModalAutocompletar.values.FechaInicio).getMonth() + 1 ) horaBase = 0;
                    else if (row.DiaMes > AdapterGenerico.convertStringToDate(formModalAutocompletar.values.FechaFinal).getDate() &&
                        (AdapterGenerico.convertStringToDate(formModalAutocompletar.values.FechaFinal).getMonth() + 1 === row.Mes || row.DiaMes < formikForm.values.Dias[0].DiaMes)
                    )
                        horaBase = 0;
                        
                    return ({ ...row, HorasTrabajador: horaBase });
                })
            }

            const resultTareas = [...formikForm.values.Tareas, dataFormated];

            // Actualizar Dia y verificar si paso la hora máxima configurada
            const responseUpdated = updateDiasHoras(resultTareas);
            if (responseUpdated) {
                AdapterGenerico.createMessage('', responseUpdated, 'warning');
                return;
            }

            formikForm.setFieldValue('Tareas', resultTareas);
            closeModalAutocompletar();
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    const updateDiasHoras = (payload: EntityRequestTareas[]): string => {
        const arrDias = [...formikForm.values.Dias].map(row => ({ ...row, HorasTrabajador: 0 }));
        const resultDia = [];
        let isHourLimitExceeded = { status: false, message: '' };
        
        // Actualizar horas del día
        for (const row of arrDias) {
            const arrHoras = payload.reduce((prev, current) => {
                const itemFinded = current.Horas.find(_row => _row.Id === row.Id);
                if (itemFinded) prev.push(itemFinded.HorasTrabajador);
                return prev;
            }, [] as number[]);
            
            const totalHorasDia = AdapterGenerico.sumarHoras(arrHoras);

            if (totalHorasDia > AdapterConfigure.HORA_MAXIMA_DIA)
                isHourLimitExceeded = {
                    message: `El día ${languageTranslate.moduloTareo.days[row.Nombre]} - ${row.DiaMes} supera las ${AdapterConfigure.HORA_MAXIMA_DIA} horas`,
                    status: true
                }

            row.HorasTrabajador = totalHorasDia;

            resultDia.push(row);
        }

        if (isHourLimitExceeded.status) return isHourLimitExceeded.message;
        
        // Actualizar Dias
        formikForm.setFieldValue('Dias', resultDia);

        return '';
    }

    // ------------------- Modal Detalle --------------------
    const formModalDetalle = useFormik({
        initialValues: [] as EntityFormModalDetalle[],
        onSubmit: () => {},
        validationSchema: Yup.array().required(languageTranslate.moduloTareo.validate.errorDetalleCantidad)
    });

    const openModalDetalle = (payload: EntityDias) => {
        const ListDetail: EntityFormModalDetalle[] = formikForm.values.Tareas.map((row, index) => {
            return ({
                PositionTarea: index,
                OT: row.Proyecto.OT.OT,
                Disciplina: row.Disciplina.Name,
                IdDia: payload.IdDia,
                Id: payload.Id,
                Horas: AdapterGenerico.formatNumberToHoras(row.Horas.find(_row => _row.Id === payload.Id)?.HorasTrabajador),
            });
        });

        formModalDetalle.setValues(ListDetail);

        setConfigModalDetalle((prev) => ({
            title: `${languageTranslate.moduloTareo.detail.textTituloDetalle} - ${payload.DiaMes} / ${languageTranslate.moduloTareo.days[payload.Nombre]}`,
            show: true,
            IdDia: payload.IdDia,
            Id: payload.Id,
            isDiaFestivo: payload.Tipo === AdapterConfigure.IDENTIFIER_TIPO_FESTIVO
        }))
    }

    const closeModalDetalle = () => {
        setConfigModalDetalle((prev) => ({
            show: false,
            title: '',
            IdDia: 0,
            Id: 0,
            isDiaFestivo: false
        }))

        formModalDetalle.setValues([]);
    }

    const onChangeMD = (value: any, index: number) => {
        // Valida si hay un punto
        /*if (value.includes('.')) return;

        // Valida si el valor supera la hora máxima
        const _valurParsed = parseInt(`${value || 0}`);
        if (_valurParsed > AdapterConfigure.HORA_MAXIMA_DIA) return;

        // formatea el valor
        value = _valurParsed > 0 ? value : 0;
        value = AdapterGenerico.removerCeroIzquierda(value);

        // Almacena el valor
        const _value: any = AdapterGenerico.removerCeroIzquierda(value);*/
        const arrDetalle = formModalDetalle.values;
        arrDetalle[index].Horas = value;
        formModalDetalle.setValues(arrDetalle);
    }

    const onSubmitMD = async (evt: Event) => {
        try {
            evt.preventDefault();
            evt.stopPropagation();

            try { await formModalDetalle.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formModalDetalle.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            // Validación por Hora máxima por día
            const arrHoras = formModalDetalle.values.reduce((prev, current) => {
                let [horas, minutos] = current.Horas.split(':');
                let horaFormatedd = parseFloat(`${horas}.${minutos}`);
                prev.push(horaFormatedd);
                return prev;
            }, [] as number[])

            const sumaHoras = AdapterGenerico.sumarHoras(arrHoras);

            if (sumaHoras > AdapterConfigure.HORA_MAXIMA_DIA) {
                AdapterGenerico.createMessage(languageTranslate.textoIncompleto, `${languageTranslate.moduloTareo.validate.errorHoraMaximaDia} ${AdapterConfigure.HORA_MAXIMA_DIA}`, 'warning', false);
                return null;
            }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            // Limpiar las horas de las tareas
            const _tareas = formikForm.values.Tareas.map(row => {
                const indexFinded = row.Horas.findIndex(row => row.Id === configModalDetalle.Id);
                row.Horas[indexFinded].HorasTrabajador = 0;
                return row;
            });
            const ListTareas: EntityRequestTareas[] = []

            // Limpiar las horas del día
            const Dias: EntityDias[] = formikForm.values.Dias.map(row => {
                if (row.Id === configModalDetalle.Id) row.HorasTrabajador = 0;
                return row;
            });

            for (const row of formModalDetalle.values) {
                let [horas, minutos] = (row.Horas || '00:00').split(':');
                let HorasTrabajador = parseFloat(`${horas}.${minutos}`);

                if (row.newItem) {
                    // Agregar nuevo ítem
                    const diaFinded = row.newItem.Horas.findIndex(_row => _row.Id === row.Id);
                    if (diaFinded !== -1) {
                        const _itemDia = JSON.parse(JSON.stringify(Dias[diaFinded]));
                        _itemDia.HorasTrabajador = AdapterGenerico.sumarHoras([_itemDia.HorasTrabajador, HorasTrabajador]);
                        Dias[diaFinded] = _itemDia;

                        row.newItem.Horas[diaFinded].HorasTrabajador = HorasTrabajador;
                    }

                    // Limpiar y agregar el nuevo item al ListTareas
                    const cleanReference = JSON.parse(JSON.stringify(row.newItem))
                    ListTareas.push(cleanReference);
                } else {
                    // Editar ítem
                    const diaFinded = _tareas[row.PositionTarea].Horas.findIndex(_row => _row.Id === row.Id)
                    if (diaFinded !== -1) {
                        // Actualizar las horas totales en el listado
                        const _itemDia = JSON.parse(JSON.stringify(Dias[diaFinded]));
                        _itemDia.HorasTrabajador = AdapterGenerico.sumarHoras([_itemDia.HorasTrabajador, HorasTrabajador]);
                        Dias[diaFinded] = _itemDia;

                        // Limpiar y agregar el nuevo item al ListTareas
                        const newItem: EntityRequestTareas = JSON.parse(JSON.stringify(_tareas[row.PositionTarea]));
                        newItem.Horas[diaFinded].HorasTrabajador = HorasTrabajador;
                        ListTareas.push(newItem);
                    }
                }
            }

            // Actualizar campos
            formikForm.setFieldValue('Tareas', ListTareas);
            formikForm.setFieldValue('Dias', Dias);

            closeModalDetalle();
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    // ------------------- Modal Agregar Item --------------------
    const formModalItem = useFormik({
        initialValues: initEntityFormModalItem,
        onSubmit: () => {},
        validationSchema: Yup.object({
            OT: Yup.object().required(languageTranslate.moduloTareo.validate.errorOTRequired).nullable(),
            Disciplina: Yup.object().required(languageTranslate.moduloTareo.validate.errorDisciplinaRequired).nullable(),
            Horas: Yup.string().required(languageTranslate.moduloTareo.validate.errorHoraRequired).nullable(),
        })
    })

    const openModalItem = () => {
        const OTBase = configForm.dataOptions.ot.find(row => row.dataComplete.Codigo === user.DatosTrabajo.OT);
        formModalItem.setFieldValue('OT', OTBase);

        if (OTBase?.dataComplete.Code && !AdapterConfigure.LIST_OT_BISA.includes(OTBase.dataComplete.Code)) {
            const disciplinaFinded = configForm.dataOptions.disciplina.find(row => row.dataComplete.Key === AdapterConfigure.KEY_DISCIPLINA_GENERAL);
            formModalItem.setFieldValue('Disciplina', disciplinaFinded || null)
        }

        setConfigModalItem(() => ({ show: true, title: '' }));
    }
    
    const closeModalItem = () => {
        setConfigModalItem(() => ({ show: false, title: '' }));
        formModalItem.setValues({ ...initEntityFormModalItem });
    }

    const onChangeMI = (name: string, value: any) => {
        /*if (name === 'Horas') {
            // Valida si hay un punto
            if (value.includes('.')) return;

            // Valida si el valor supera la hora máxima
            const _value = parseInt(`${value || 0}`);
            if (_value > AdapterConfigure.HORA_MAXIMA_DIA) return;

            // formatea el valor
            value = _value > 0 ? value : 0;
            value = AdapterGenerico.removerCeroIzquierda(value);
        }*/
        formModalItem.setFieldValue(name, value);
    }

    const onSubmitMI = async (evt: Event) => {
        
        try {
            evt.preventDefault();
            evt.stopPropagation();
            
            try { await formModalItem.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formModalItem.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            // Verifica si ya existe un item con la misma combinación
            const isExistTareaInDetail = formikForm.values.Tareas.filter((row => (
                row.Disciplina.Codigo === formModalItem.values?.Disciplina?.dataComplete.Code &&
                row.Proyecto.OT.Code === formModalItem.values?.OT?.dataComplete.Code)
            ));

            const isExistTareaAgreeItem = formModalDetalle.values.filter((row => (
                row.PositionTarea !== 0 &&
                (
                    row?.newItem?.Disciplina.Codigo === formModalItem.values?.Disciplina?.dataComplete.Code &&
                    row?.newItem?.Proyecto.OT.Code === formModalItem.values?.OT?.dataComplete.Code
                )
            )));
            
            if(isExistTareaInDetail.length!==0 || isExistTareaAgreeItem.length !== 0) {
                AdapterGenerico.createMessage(languageTranslate.textoIncompleto, languageTranslate.moduloTareo.validate.errorDuplicadoItem, 'warning', false);
                return null;
            }

            // Validar la suma total no deba ser mayor que HORA_MAXIMA_DIA
            const sumaHorasDia = formModalDetalle.values.reduce((prev, current) => {
                prev += AdapterGenerico.sumarHoras([prev, parseInt(`${current.Horas || 0}`)]);
                return prev;
            }, 0)

            // Parsear valores del input
            let [horas, minutos] = formModalItem.values.Horas.split(':');
            let horaSelected = parseFloat(`${horas}.${minutos}`);

            if ((sumaHorasDia + horaSelected) > AdapterConfigure.HORA_MAXIMA_DIA) {
                AdapterGenerico.createMessage(languageTranslate.textoIncompleto, `${languageTranslate.moduloTareo.validate.errorHoraMaximaDia} ${AdapterConfigure.HORA_MAXIMA_DIA}`, 'warning', false);
                return null;
            };

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            
            // Formatear el la tarea en general
            const _payload: EntityRequestTareas = {
                Disciplina: {
                    Codigo: formModalItem.values.Disciplina?.dataComplete.Code || '',
                    Name: formModalItem.values.Disciplina?.dataComplete.Name || '',
                },
                Horas: JSON.parse(JSON.stringify(formikForm.values.Dias)).map((row: EntityDias) => {
                    row.HorasTrabajador = 0;
                    return row;
                }),
                Observacion: '',
                Proyecto: {
                    OT: {
                        Code: formModalItem.values.OT?.dataComplete.Code || '',
                        Codigo: formModalItem.values.OT?.dataComplete.Codigo || '',
                        OT: formModalItem.values.OT?.dataComplete.Name || '',
                    },
                    Delegacion: {
                        Code: formModalItem.values.OT?.dataComplete.DatosTrabajo.Delegacion.Code || '',
                        Codigo: formModalItem.values.OT?.dataComplete.DatosTrabajo.Delegacion.Codigo || '',
                        Delegacion: formModalItem.values.OT?.dataComplete.DatosTrabajo.Delegacion.Name || '',
                    },
                    DatosEmpresa: {
                        CDEmpresa: formModalItem.values.OT?.dataComplete.DatosEmpresa.CDEmpresa || '',
                        Empresa: formModalItem.values.OT?.dataComplete.DatosEmpresa.Empresa || ''
                    }
                }
            }
            
            // Formatear para el detalle y agregar el _payload con la data oficial
            const newItem: EntityFormModalDetalle = {
                Disciplina: _payload.Disciplina.Name,
                Horas: formModalItem.values.Horas,
                IdDia: configModalDetalle.IdDia,
                OT: _payload.Proyecto.OT.OT,
                PositionTarea: -1,
                newItem: _payload,
                Id: configModalDetalle.Id
            }
            
            // Actualizar la información
            const ListDetail: EntityFormModalDetalle[] = [...formModalDetalle.values, newItem];
            formModalDetalle.setValues(ListDetail);

            closeModalItem()
        } catch(error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    // ------------------- Modal Agregar Nota --------------------
    const openModalNote = () => {
        setConfigModalNote(() => ({
            note: '',
            show: true
        }))
    }

    const closeModalNote = () => {
        setConfigModalNote(() => ({
            note: '',
            show: false
        }))
    }

    const onChangeMN = (name: string, value: any) => {
        setConfigModalNote((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const onSubmitMN = async (evt: Event) => {
        try {
            evt.preventDefault();
            evt.stopPropagation();
            
            if (configModalNote.note === '') {
                AdapterGenerico.createMessage(languageTranslate.textoIncompleto, 'Ingrese un comentario', 'warning', false);
                return null;
            }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            const payload: EntityRequestAddNote = {
                codigo: formikForm.values.Codigo,
                nota: {
                    descripcion: configModalNote.note
                },
                user: {
                    IdUser: user.IdUser,
                    LastFather: user.LastNameFather,
                    LastMother: user.LastNameMother,
                    Name: user.Name,
                    Perfil: user.Profile.find(row => row.Principal === 'SI')?.Perfil || '',
                    User: user.User
                }
            }

            const response = await repository.addNote(payload);
            if (!response || response.length === 0) return; 
            formikForm.setFieldValue('Notas', response);

            closeModalNote();
        } catch(error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    // ------------------ Modal Agregar Ausencia -----------------
    const formModalAusencia = useFormik({
        initialValues: initEntityFormModalAusencia,
        onSubmit: () => {},
        validationSchema: Yup.object({
            TipoAusencia: Yup.object().required().nullable(),
            FechaInicio: Yup.string().required().nullable(),
            FechaFinal: Yup.string().required().nullable(),
            Observacion: Yup.string().nullable(),
        })
    })

    const openModalAusencia = async (mode: 'create' | 'edit' | "preview", data?: EntityAusencia) => {
        setConfigModalAusencia(() => ({
            show: true,
            mode
        }));

        if (data) {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }))
            let payload: EntityFormModalAusencia = {
                Codigo: data.Codigo,
                Documento: [],
                FechaFinal: data.FechaFin.DateString,
                FechaInicio: data.FechaInicio.DateString,
                Observacion: data.Observacion,
                TipoAusencia: {
                    label: data.TipoAusencia.TipoAusencia,
                    value: data.TipoAusencia.IdTipoAusencia,
                    dataComplete: {
                        IdTipoAusencia: data.TipoAusencia.IdTipoAusencia,
                        Codigo: data.TipoAusencia.Codigo,
                        TipoAusencia: data.TipoAusencia.TipoAusencia
                    } as any
                }
            };

            if (data.Files.length > 0) {
                const responseFile = await repository.downloadFile(data.Files[0]);
                if (responseFile)
                    payload.Documento = [{
                        type: 'server',
                        value: data.Files[0],
                        valueToShow: (window.URL ? URL : webkitURL).createObjectURL(responseFile)
                    }]
                else
                    payload.Documento = [{
                        type: 'server',
                        value: data.Files[0],
                        valueToShow: ''
                    }]
            }

            formModalAusencia.setValues((prev) => ({ ...payload }))

            dispatch(removeLoading());
        } else {
            formModalAusencia.setValues((prev) => ({ ...initEntityFormModalAusencia }));
        }

    }

    const closeModalAusencia = () => {
        setConfigModalAusencia(() => ({
            show: false,
            mode: ''
        }))
        formModalAusencia.setValues((prev) => ({ ...initEntityFormModalAusencia }));
    }

    const onChangeModAUS = (name: string, value: any) => {
        formModalAusencia.setFieldValue(name, value);
    }

    const onSubmitModAUS = async (evt: Event) => {
        try {
            evt.preventDefault();
            evt.stopPropagation();

            try { await formModalAusencia.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formModalAusencia.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            let result: EntityAusencia | null = null;
            if (configModalAusencia.mode === 'create') {
                const OTBaseUser = configForm.dataOptions.ot.find(row => row.dataComplete.Codigo === user.DatosTrabajo.OT);
                const payload: EntityRequestAddAusencia = {
                    Documento: formModalAusencia.values.Documento,
                    FechaFinal: formModalAusencia.values.FechaFinal,
                    FechaInicio: formModalAusencia.values.FechaInicio,
                    Observacion: formModalAusencia.values.Observacion,
                    DatosTrabajo: {
                        Delegacion: {
                            Code: OTBaseUser?.dataComplete.DatosTrabajo.Delegacion.Code || '',
                            Codigo: OTBaseUser?.dataComplete.DatosTrabajo.Delegacion.Codigo || '',
                            Delegacion: OTBaseUser?.dataComplete.DatosTrabajo.Delegacion.Name || ''
                        },
                        OT: {
                            Code: OTBaseUser?.dataComplete.Code || '',
                            Codigo: OTBaseUser?.dataComplete.Codigo || '',
                            OT: OTBaseUser?.dataComplete.Name || ''
                        }
                    },
                    TipoAusencia: {
                        Codigo: formModalAusencia.values.TipoAusencia.dataComplete.Code,
                        IdTipoAusencia: formModalAusencia.values.TipoAusencia.dataComplete.IdRegister,
                        TipoAusencia: formModalAusencia.values.TipoAusencia.dataComplete.Name
                    },
                    User: {
                        Identificacion: user.Identificacion,
                        IdUser: user.IdUser,
                        LastFather: user.LastNameFather,
                        LastMother: user.LastNameMother,
                        Name: user.Name,
                        Perfil: user.Profile.find(row => row.Principal === 'SI')?.Perfil || '',
                        User: user.User
                    }
                }
                
                result = await repository.addAusencia(payload);

            } else {
                const payload: EntityRequestUpdateAusencia = {
                    filter: {
                        Codigo: formModalAusencia.values.Codigo,
                    },
                    update: {
                        Documento: formModalAusencia.values.Documento,
                        FechaFinal: formModalAusencia.values.FechaFinal,
                        FechaInicio: formModalAusencia.values.FechaInicio,
                        Observacion: formModalAusencia.values.Observacion,
                        TipoAusencia: {
                            Codigo: formModalAusencia.values.TipoAusencia.dataComplete.Code,
                            IdTipoAusencia: formModalAusencia.values.TipoAusencia.dataComplete.IdRegister,
                            TipoAusencia: formModalAusencia.values.TipoAusencia.dataComplete.Name
                        }
                    },
                    user: {
                        Identificacion: user.Identificacion,
                        IdUser: user.IdUser,
                        LastFather: user.LastNameFather,
                        LastMother: user.LastNameMother,
                        Name: user.Name,
                        Perfil: user.Profile.find(row => row.Principal === 'SI')?.Perfil || '',
                        User: user.User
                    }
                }
                
                result = await repository.updateAusencia(payload);
            }

            if (!result) return null;

            const data = configForm.dataOptions.ausencia;
            if (configModalAusencia.mode === 'create') {
                data.push(result);
            } else {
                let positionFinded = data.findIndex(row => row.Codigo === result?.Codigo);
                if (positionFinded !== -1) {
                    data[positionFinded] = result;
                }
            }

            const responseTareo = await repository.updateDetailTareo(formikForm.values.Codigo);
            if (responseTareo) {
                formikForm.setFieldValue('Tareas', responseTareo.Tareas)
                formikForm.setFieldValue('Dias', responseTareo.Dias)
            }

            setConfigForm(prev => ({
                ...prev,
                dataOptions: {
                    ...prev.dataOptions,
                    ausencia: data
                }
            }));

            closeModalAusencia();
        } catch(error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    return ({
        init,

        // Formulario Principal
        configForm,
        formikForm,
        onClearTareo,
        onSubmit,
        onSubmitApproval,
        cancelWorkFlow,

        // Functions Modal Autocompletar
        formModalAutocompletar,
        openModalAutocompletar,
        closeModalAutocompletar,
        configModalAutocompletar,
        onChangeMA,
        onSubmitMA,

        // Functions Modal Detalle
        formModalDetalle,
        openModalDetalle,
        closeModalDetalle,
        configModalDetalle,
        onChangeMD,
        onSubmitMD,

        // Functions Modal Agregar Item
        formModalItem,
        openModalItem,
        closeModalItem,
        configModalItem,
        onChangeMI,
        onSubmitMI,

        // Functions Modal Notes
        configModalNote,
        openModalNote,
        closeModalNote,
        onChangeMN,
        onSubmitMN,

        // Functions Modal Ausencia
        formModalAusencia,
        configModalAusencia,
        openModalAusencia,
        closeModalAusencia,
        onChangeModAUS,
        onSubmitModAUS,

    })
}