import { InputTextIcon } from "../../../../../../shared/Components/ElementInputsCostume"
import ElementModalCostume from "../../../../../../shared/Components/ElementModalCostume"
import { EntityGasto } from "../../../../../../shared/Domain/EntityGasto";
import { EntityGenericDetailForm } from "../../../../../../shared/Domain/EntityGenericDetailForm";
import { EntityLanguage } from "../../../../../../shared/Domain/EntityLanguage";
import { AdapterGenerico } from "../../../../../../shared/Infraestructure/AdapterGenerico";
import { EntityRequestGenericForm } from "../../Domain/EntityRequest";
import { EntityConfigModalAsociar } from "../../Domain/EntityUtils"

interface ContainerProps {
    closeModalAsociar(): void;
    onChangeSearchMA(name: string, value: string): void;
    onChangeItemMA(gasto: EntityGasto[]): void ;
    onSubmitSearchMA(): void;
    onSubmitMA(): void;
    configModalAsociar: EntityConfigModalAsociar;
    languageTranslate: EntityLanguage;
    formikForm: EntityGenericDetailForm<EntityRequestGenericForm>;
}

export const ViewAsociarGasto = (props: ContainerProps) => {
    if (!props.configModalAsociar.show) return null;

    const prevChangeItem = (data: EntityGasto) => {
        const newList: EntityGasto[] = props.configModalAsociar.selectGasto;
        const indexFinded = newList.findIndex(row => row.Codigo === data.Codigo);
        if (indexFinded === -1) newList.push(data);
        else newList.splice(indexFinded, 1);

        props.onChangeItemMA(newList);
    }
    const pendingGasto = props.configModalAsociar.showListGasto.filter(row => !props.formikForm.values.GastoAsociado.find(_row => _row.Codigo === row.Codigo));

    return (
        <>
            <ElementModalCostume
                title={props.languageTranslate.moduloRendicion.detail.textTituloAsociar}
                closeModal={props.closeModalAsociar}
                show={props.configModalAsociar.show}
                height={"500px"}
            >
                <div>
                    <div>
                        <form onSubmit={(evt) => { evt.preventDefault(); props.onSubmitSearchMA()}} >
                            <InputTextIcon
                                label={''}
                                placeholder={'Buscar'}
                                values={{ search: props.configModalAsociar.textSearch }}
                                onChange={props.onChangeSearchMA}
                                className='form-group w-100'
                                name='search'
                                icon="fa-solid fa-magnifying-glass"
                                onSubmit={props.onSubmitSearchMA}
                            />
                        </form>
                    </div>
                    <hr />
                    <div className="GroupContentBodyMA">
                        <p>{props.languageTranslate.moduloRendicion.detail.textGastoEncontrado} ({pendingGasto.length})</p>

                        <div className="GroupItems">
                            {
                                pendingGasto.map(row => {

                                    return (
                                        <div className={`mb-3 ItemGastoSelect ${props.configModalAsociar.selectGasto.some(gst => gst.Codigo === row.Codigo) ? 'ItemGastoSelected' : ''}`} onClick={() => prevChangeItem(row)}>
                                            <p>{props.languageTranslate.moduloRendicion.detail.textProveedor}: <span className="text-muted">{row.DatosEmpresa.RazonSocial}</span></p>
                                            <p className="text-muted">{AdapterGenerico.formatoDinero(row.DatosImporte.Total)} {row.DatosImporte.Moneda.Nombre} - {AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.FechaEmision.Fecha), 3)}</p>
                                            <p className="text-danger CalculoTiempo">{AdapterGenerico.calculoTiempo(AdapterGenerico.convertStringToDate(row.FechaEmision.Fecha))}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
                <div className="d-flex ButtonFixedBottom">
                    <button
                        className="btn btn-primary w-100 mb-1"
                        onClick={props.onSubmitMA}
                    >
                        {props.languageTranslate.btnGuardar}
                    </button>
                </div>
            </ElementModalCostume>
        </>
    )
}