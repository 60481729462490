import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../context/shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { EntityProcessOffline } from "../../context/shared/Domain/EntityProcessOffline";

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async executeProcess<T>(params: EntityProcessOffline): Promise<T> {
        let response: T | null = null;
        switch (params.type) {
            case 'api-v2':
                response = await this.service.call<any>(params.method, this.urlBaseGastos + params.url, params.body, params.typeAuth, params.typeRequest, params.typeResponse, { "Request-Decrypt-Response": true }, 3);
                break;
            case 'api':
                response = await this.service.call<any>(params.method, this.urlBase + params.url, params.body, params.typeAuth, params.typeRequest, params.typeResponse, {}, 3);
                break;
            case 'ws':
                response = await this.websocket.emit(params.url, params.body);
                break;
            default:
                break;
        }
        return response as T;
    }
}