import { EntityAnticipo } from "../../../../../shared/Domain/EntityAnticipo";
import { EntityRequestGenericForm } from "../Domain/EntityRequest";
import { EntityResponseInitialData } from "../Domain/EntityResponse";
import { keyModule } from "../Domain/EntityUtils";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseFind {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(code: string, keyModule: keyModule, options: EntityResponseInitialData): Promise<{ data: EntityRequestGenericForm, dataComplete: EntityAnticipo }  | null> {
        return await this._exec(code, keyModule, options);
    }

    private async _exec(code: string, keyModule: keyModule, options: EntityResponseInitialData): Promise<{ data: EntityRequestGenericForm, dataComplete: EntityAnticipo }  | null> {
        return await this.repository.findForm(code, keyModule, options);
    }
}