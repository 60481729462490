export const ErrorCode = [
    {
        match: [
            { isExact: false, value: 'cannot get' },
            { isExact: false, value: 'cannot post' },
            { isExact: false, value: 'cannot put' },
            { isExact: false, value: 'cannot patch' },
            { isExact: false, value: 'cannot delete' },
        ],
        code: '020001',
        message: {
            es: 'El servicio que intenta consumir no se encuentra disponible o no existe',
            en: 'Service you are trying to consume is not available or does not exist'
        },
    },
    {
        match: [
            { isExact: true, value: 'cannot get' }
        ],
        code: '020002',
        message: {
            es: 'El servicio que intenta consumir solicita que se remitan parámetros',
            en: 'Service you are trying to consume requests parameters to be sent'
        },
    }
]