const SCHEMA: string = process.env.REACT_APP_MASTER_MAIN_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MASTER_MAIN_ENTITY || '';
const LIST_TAREO_APROBACION: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_TAREO}`;
const IDENTIFIER_TIPO_FESTIVO = 2;

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    LIST_TAREO_APROBACION,
    IDENTIFIER_TIPO_FESTIVO,
};