import './Style.scss';

import logo from '../../../../assets/img/IconosAppGIAMovil/logo.svg';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import esFlag from '../../../../assets/img/es.png';
import usFlag from '../../../../assets/img/us.png';
import { useState } from 'react';
import ElementModalCostume from '../../../shared/Components/ElementModalCostume';

//Imagenes

interface PropsViewMain {
    formLogin: { values: any; touched: any; errors: any; handleBlur: Function; };
    onChange: Function;
    onSubmit: Function;
    showPassword: boolean;
    setShowPassword: Function;
    onClickRecuperarPassword: Function;
    onClickRegisterRDI: Function;
    language?: string;
    onChangeLanguage?: Function;
};

export const ViewMain = (props: PropsViewMain) => {
    const flags = {
        es: esFlag,
        en: usFlag,
    };  
    const languageTranslate = LanguageTranslate();
     const [showModal, setModal] = useState(false);
    return (
        <div className="ViewMasterLogin">
            <div className="fondo"></div>
            <div className="login-block">
                {/* <div className="container"> */}
                <div className="row text-center">
                    <div className="col-12 d-flex flex-column align-items-center">
                        <img src={logo} alt="logo.png" width={80} />
                        <span className='fw-bold mt-2' style={{ fontSize: 16 }}>Gestor Integral de Administración</span>
                    </div>
                </div>
                <div className='card p-3 shadow-sm mt-5 rounded-5'>
                    <form noValidate onSubmit={(evt) => { props.onSubmit(evt); }}>
                        
                        <div className="row pt-2">
                            <label className="fw-semibold">{languageTranslate.moduloLogin.txtUsuario}</label>
                            <div className="col-12">
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text" >
                                        <i className="fa-solid fa-user" />
                                    </span>
                                    <input
                                        type="text"
                                        name="user"
                                        className="form-control"
                                        required
                                        placeholder={languageTranslate.moduloLogin.txtUsuario}
                                        value={props.formLogin.values['username']}
                                        onChange={(evt) => { props.onChange('username', evt.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row pt-3">
                            <label className='fw-semibold' >{languageTranslate.moduloLogin.txtPassword}</label>
                            <div className="col-12">
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="fa-solid fa-lock" />
                                    </span>
                                    <input
                                        type={props.showPassword ? 'text' : 'password'}
                                        name="password"
                                        className="form-control"
                                        required
                                        placeholder={languageTranslate.moduloLogin.txtPassword}
                                        value={props.formLogin.values['password']}
                                        onChange={(evt) => { props.onChange('password', evt.target.value) }}
                                    />
                                    <button className="btn btn-primary addon-button-primary" type="button" onClick={() => props.setShowPassword(!props.showPassword)}><i className={`fa-solid fa-eye${props.showPassword ? '-slash' : ''}`} /></button>
                                </div>
                            </div>
                        </div>

                        <div className="row pt-3 ms-1">
                            <div className="col-12">
                                <div className="form-check m-0">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        
                                        checked={props.formLogin.values['recordar']}
                                        onChange={(evt) => { props.onChange('recordar', evt.target.checked) }}
                                        id="flexCheckDefault"
                                    />
                                    <label className="form-check-label text-primary ms-0" htmlFor="flexCheckDefault">{languageTranslate.moduloLogin.chkRecordarUsuario}</label>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row pt-2">
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary" style={{ width: '100%', backgroundColor: '#01397D' }}>
                                    {languageTranslate.moduloLogin.btnLogin}
                                </button>
                            </div>
                        </div>
                    </form>

                    <div className="row pt-3">
                        <div className="col-12 text-center">
                            <div className="forgot-phone">
                                {
                                    // eslint-disable-next-line
                                    <a href="#" className="text-primary" onClick={() => props.onClickRecuperarPassword()} style={{ fontWeight: 'bold' }}>{languageTranslate.moduloLogin.textoOlvidoContrasenia}</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                
            
            </div>
            <div className='login-block fw-semibold d-flex justify-content-end align-items-end' style={{top:'95%'}}>
                <div onClick={()=>{
                    setModal(true);
                    
                }}>
                    <span className="text-primary">{languageTranslate.moduloConfiguracion.textoIdioma}:</span>
                    <span>{languageTranslate.moduloConfiguracion.nombre}</span>
                    {/*<i className="fa fa-globe px-1" aria-hidden="true"></i>*/}
                    {props.language===null?<img src={flags["es" as keyof typeof flags]} alt="flag" className='px-1'/>:<img src={flags[props.language as keyof typeof flags]} alt="flag" className='px-1'/>}
                    
                </div>
                
            </div> 
            <ElementModalCostume
                show={showModal}
                closeModal={() => setModal(!showModal)}
                title= {languageTranslate.moduloConfiguracion.textoIdioma}
                height='150px'
            >
                <div className="card-body">
                    <div className="row pt-2">
                        {
                            languageTranslate.languages.map(row => (
                            <div className="col-4" key={row.code}>
                                <input
                                    type="radio"
                                    className="btn-check"
                                    name="options-outlined"
                                    id={`inputIdioma${row.code}`}
                                    value={row.code}
                                    onChange={(evt) => evt.target.checked ? !!props.onChangeLanguage ? props.onChangeLanguage(row.code) : undefined : undefined}
                                    checked={props.language === row.code ? true : false}
                                />
                                <label className="btn btn-sm btn-outline-primary" htmlFor={`inputIdioma${row.code}`}>
                                    <img src={flags[row.code as keyof typeof flags]} alt="flag" /> {row.name}
                                </label>
                            </div>
                            ))
                        }
                    </div>
                </div>
            </ElementModalCostume>
        </div >
        
    )
   
    
};