import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";
import { EntityResponseInitialData, initEntityResponseInitialData } from "./EntityResponse";

export interface EntityConfigForm {
    key: keyModule;
    mode: ModeForm;
    navBar: EntityNavBar[];
    loadingForm: boolean;
    dataOptions: EntityResponseInitialData;
}

export interface EntityNavBar {
    text: string;
    navigate: boolean;
    path: string;
}

export const initEntityConfigForm: EntityConfigForm = {
    key: "",
    navBar: [],
    mode: '',
    loadingForm: true,
    dataOptions: initEntityResponseInitialData
}

const languageTranslate = LanguageTranslate();
export const nameModule = {
    'preview': languageTranslate.textoModoVisualizar,
    'create': languageTranslate.textoModoCrear,
    'edit': languageTranslate.textoModoEditar,
    '': 'No Found'
}

export interface EntityServicioCustom {
    Code: string;
    Codigo: string;
    Servicio: string;
    Country: {
        Code: string;
    }
}

export type keyModule = 'Gastos' | '';
export type ModeForm = 'preview' | 'create' | 'edit' | '';