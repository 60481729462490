import { EntityResponseInitialData } from "../Domain/EntityResponse";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseLoadInitialData {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(): Promise<EntityResponseInitialData> {
        return await this._exec();
    }

    private async _exec(): Promise<EntityResponseInitialData> {
        return await this.repository.loadInitialData();
    }
}