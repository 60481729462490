import { LanguageTranslate } from "../Infraestructure/LanguageTranslate";
import { ErrorCode } from "./EnumError";

export class ErrorCostume extends Error {
    public numCode: string = '';

    constructor(msg: string) {
        super(msg);

        let language = LanguageTranslate();

        for (let row of ErrorCode) {
            for (let subrow of row.match) {
                if (subrow.isExact) {
                    if (this.message === subrow.value) {
                        this.numCode = row.code;
                        this.message = row.message[language.code as keyof typeof row.message];
                        break;
                    }
                }
                else {
                    if (this.message.includes(subrow.value)) {
                        this.numCode = row.code;
                        this.message = row.message[language.code as keyof typeof row.message];
                        break;
                    }
                }
            }
            if (!!this.numCode) { break; }
        }

        if (!this.numCode) {
            this.numCode = '000000';
        }

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ErrorCostume.prototype);
    }
}