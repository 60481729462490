import { EntityOT } from "../Domain/Catalogos/EntityOT";
import { EntityProveedor } from "../Domain/Catalogos/EntityProveedor";

interface props {
    ot: Array<EntityOT>;
    proveedor: Array<EntityProveedor>;
    personal: Array<any>;
};

export const AdapterData: props = {
    ot: [],
    proveedor: [],
    personal: []
};