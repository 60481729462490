import { EntityFlujoOT } from "../../../../../shared/Domain/Catalogos/EntityFlujoOT";
import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";
import { EntityResponseInitialData, initEntityResponseInitialData } from "./EntityResponse";

export interface EntityConfigForm {
    key: keyModule;
    mode: ModeForm;
    navBar: EntityNavBar[];
    loadingForm: boolean;
    dataOptions: EntityResponseInitialData;
}

export interface EntityNavBar {
    text: string;
    navigate: boolean;
    path: string;
}

export const initEntityConfigForm: EntityConfigForm = {
    key: "",
    navBar: [],
    mode: '',
    loadingForm: true,
    dataOptions: initEntityResponseInitialData
}

const languageTranslate = LanguageTranslate();
export const nameModule = {
    'preview': languageTranslate.textoModoVisualizar,
    'create': languageTranslate.textoModoCrear,
    'edit': languageTranslate.textoModoEditar,
    '': 'No Found'
}

export interface EntityConfigModalFlujo {
    show: boolean;
    flujo: EntityFlujoOT[];
    flujoSelected?: EntityFlujoOT;
}

export const initEntityConfigModalFlujo: EntityConfigModalFlujo = {
    show: false,
    flujo: []
}

export interface EntityConfigModalNotas {
    show: boolean;
    title: string;
    nota: string;
}

export const initEntityConfigModalNotas: EntityConfigModalNotas = {
    show: false,
    title: '',
    nota: ''
}

export type keyModule = 'SolicitudAnticipo' | '';
export type ModeForm = 'preview' | 'create' | 'edit' | '';