const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';

const PAIS: string = '/search/Pais';
const PROYECTO: string = '/search/Proyectos';
const EMPRESA: string = '/search/Empresas';
const DELEGACION: string = '/search/Delegaciones';
const PERSONAL: string = '/search/PersonalPWA';
const TIPOINCIDENTE: string = '/TipoIncidente/1';
const FACTORRIESGO: string = '/FactorRiesgo';
const OT: string = '/search/OTs';
const TIPOLOGIA: string = '/search/Tipologias';
const TITULOS: string = '/search/Titulos';
const ITEMS: string = '/search/Items';
const NOTIFICACIONES_TERMINOS = '/search/Notificaciones';//: string = 'SelectNotificaciones';
const ACTIVIDAD = '/search/Actividad';
const AREA = '/search/Area';
const FORMATOINSPECCIONES = '/search/FormatoInspeccion';
const TIPOINSPECCION = '/search/TipoInspeccion';
const ANOMALIAINSPECIONES = '/search/TipoAnomalia';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,

    PAIS,
    PROYECTO,
    EMPRESA,
    DELEGACION,
    PERSONAL,
    TIPOINCIDENTE,
    FACTORRIESGO,
    OT,
    TIPOLOGIA,
    TITULOS,
    ITEMS,
    NOTIFICACIONES_TERMINOS,
    ACTIVIDAD,
    AREA,
    FORMATOINSPECCIONES,
    TIPOINSPECCION,
    ANOMALIAINSPECIONES
};