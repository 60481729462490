import { EntityRequestAddNote } from "../Domain/EntityRequestDetail";
import { EntityResponseAddNote } from "../Domain/EntityResponse";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseAddNote {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(data: EntityRequestAddNote): Promise<EntityResponseAddNote | null> {
        return await this._exec(data);
    }

    private async _exec(data: EntityRequestAddNote): Promise<EntityResponseAddNote | null> {
        return await this.repository.addNote(data);
    }
}