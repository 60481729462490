const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const ROUTE_PREVIEW_SYSTEM_REQUIREMENT: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_SISTEMA}`;
const ROUTE_PREVIEW_PURCHARSE_REQUIREMENT: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_COMPRA}`;
const ROUTE_PREVIEW_COMPARATIVE: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_COMPARATIVO}`;
const ROUTE_PREVIEW_INVOICE: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_FACTURA}`;
const ROUTE_PREVIEW_PETTY_CASH_REQUEST: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_SOLICITUD_CAJA_CHICA}`;
const ROUTE_PREVIEW_SURRENDER_PETTY_CASH_EXPENSES: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_RENDICION_GASTOS_CAJA_CHICA}`;

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    ROUTE_PREVIEW_SYSTEM_REQUIREMENT,
    ROUTE_PREVIEW_PURCHARSE_REQUIREMENT,
    ROUTE_PREVIEW_COMPARATIVE,
    ROUTE_PREVIEW_INVOICE,
    ROUTE_PREVIEW_PETTY_CASH_REQUEST,
    ROUTE_PREVIEW_SURRENDER_PETTY_CASH_EXPENSES
};