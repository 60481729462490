import { ElementCollapseCostume } from "../../../../../../shared/Components/ElementCollapseCostume"
import { InputCheckNormal } from "../../../../../../shared/Components/ElementInputsCostume";
import ElementStepperHeader from "../../../../../../shared/Components/ElementStepperHeader";
import { ElementTableCostume } from "../../../../../../shared/Components/ElementTableCostume";
import { EntityGasto } from "../../../../../../shared/Domain/EntityGasto";
import { EntityLanguage } from "../../../../../../shared/Domain/EntityLanguage"
import { AdapterGenerico } from "../../../../../../shared/Infraestructure/AdapterGenerico";
import { EntityConfigApproval, EntityConfigForm, EntityConfigItems } from "../../Domain/EntityUtils";

interface ContainerProps {
    openModalDetail: (row: EntityGasto, position: number) => void
    onChangeSearch: (textSearch: string) => void;
    onSubmitSearch: () => void;
    languageTranslate: EntityLanguage;
    configForm: EntityConfigForm;
    configItems: EntityConfigItems;
    activeModeSelectItem: () => void;
    disabledModeSelectItem: () => void;
    onSelectAllItem: () => void;
    onSelectItem: (gasto: EntityGasto) => void;
    onSubmitApproval: (props: EntityConfigApproval) => Promise<void>;
    openModalComment(): void;
}

export const ViewReport = (props: ContainerProps) => {
    // Valores globales
    const bitacoraEnvio: any[] = props.configForm.data.Bitacora.filter((row: any) => row.accion === 'RENDICION EN REVISION');
    /* let gastoRevisado = 0;
    const importeAprobado = props.configForm.data.Gastos.reduce((prev, current) => {
        if ([1, 4].includes(current.Status.IdStatus)) {
            // gastoRevisado += 1;
            prev += current.DatosImporte.Importe;
        }
        return prev;
    }, 0)*/

    return (
        <div>
            {/*                             stepper                             */}
            <ElementStepperHeader
                data={props.configForm.data.FlujoFormatted.data}
                onChange={() => {}}
                stepPosition={props.configForm.data.FlujoFormatted.currentNivel}
                stepNumberStart={props.configForm.data.FlujoFormatted.stepNumberStart}
            />


            { /*                            general                           */ }
            <ElementCollapseCostume
                textTitle={props.languageTranslate.moduloGastoAprobacion.viewReport.textTitleDetail}
                className='my-3'
                persistsOpen
                isOpenDefault
            >
                <div className='ContentItemCollapse'>
                    <div className='d-flex'>
                        <span>{props.languageTranslate.moduloGenericDetail.itemDetail.textPais}:</span>
                        <span className='text-muted'> {props.configForm.data.DatosPais.Nombre} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{props.languageTranslate.moduloGastoAprobacion.viewReport.textRendidor}:</span>
                        <span className='text-muted'> {props.configForm.data.DatosPersonal.Nombres} {props.configForm.data.DatosPersonal.ApellidoPaterno} {props.configForm.data.DatosPersonal.ApellidoMaterno}</span>
                    </div>
                    <div className='d-flex'>
                        <span>{props.languageTranslate.moduloGastoAprobacion.generic.textOT}:</span>
                        <span className='text-muted'> {props.configForm.data.DatosTrabajo.OT.Codigo} - {props.configForm.data.DatosTrabajo.OT.OT} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{props.languageTranslate.moduloGastoAprobacion.viewReport.textImporteAsociado}:</span>
                        <span className='text-success'> {AdapterGenerico.formatoDinero(props.configForm.data.DatosImporte.Importe)} ({props.configForm.data.DatosImporte.Moneda.Nombre}) </span>
                    </div>
                    <div className='d-flex'>
                        <span>{props.languageTranslate.moduloGastoAprobacion.viewReport.textFechaEnvio}:</span>
                        <span className='text-muted'> {AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(bitacoraEnvio[bitacoraEnvio.length - 1]?.fecha), 3)} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{props.languageTranslate.moduloGastoAprobacion.viewReport.textCantidadLineas}:</span>
                        <span className='text-muted'> {props.configForm.data.Gastos.length} </span>
                    </div>
                    {
                        props.configForm.data.Anticipo?.Codigo ?  
                            <>
                                <div className='d-flex'>
                                    <span>Solicitud Caja Chica:</span>
                                    <span className='text-muted'> {props.configForm.data.Anticipo.Codigo} </span>
                                </div>
                                <div className='d-flex'>
                                    <span>Importe Solicitud Caja Chica:</span>
                                    <span className='text-muted'> {AdapterGenerico.formatoDinero(props.configForm.data.Anticipo.DatosImporte?.Solicitud.ImporteTotal || 0)} </span>
                                </div>
                            </>
                        : null
                    }
                </div>
            </ElementCollapseCostume>

            { /*                            gastos                            */ }
            <ElementCollapseCostume
                textTitle={props.languageTranslate.moduloGenericDetail.itemRendicion.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <ElementTableCostume
                        className="TableNoWrap"
                        listHeader={props.languageTranslate.moduloGenericDetail.itemRendicion.headerTableNuevo.map(row => {
                            if (row.key === 'preview') row.render = (text: string, data: any, position: number) => <i onClick={() => props.openModalDetail(data, position)} className="fa-solid fa-eye" style={{ fontSize: '12px' }} />
                            if (row.key === 'checkbox') row.render = (text: string, data: EntityGasto, position: number) => (
                                <InputCheckNormal
                                    label=""
                                    name="item"
                                    onChange={() => props.onSelectItem(data)}
                                    values={props.configItems.selectItems.find(row => row.Codigo === data.Codigo)}
                                    disabled={data.Status.IdStatus !== 5}
                                />
                            )
                            return row;
                        })}
                        listData={props.configItems.items}
                    />

                    <div className='d-flex w-100' style={{ borderTop: '2px solid #dee2e6', paddingTop: 10 }}>
                        <span className='ms-auto'>{props.languageTranslate.moduloGenericDetail.itemRendicion.textImporteTotal}:</span>
                        <span className='text-muted'> {AdapterGenerico.formatoDinero(props.configItems.filterItems.reduce((prev, current) => prev + current.DatosImporte.Importe, 0))} </span>
                    </div>
                </div>
            </ElementCollapseCostume>

            {/*                             notas                             */ }
            <ElementCollapseCostume
                textTitle={props.languageTranslate.moduloGenericDetail.itemNotas.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <div className="w-100 mb-2" style={{ textAlign: 'end' }}>
                        <button className="btn btn-primary" onClick={props.openModalComment}>Agregar</button>
                    </div>
                    <ElementTableCostume
                        listHeader={props.languageTranslate.moduloGenericDetail.itemNotas.headerTable}
                        listData={props.configForm.data.Notas}
                        className="CustomTableNota"
                    />
                </div>
            </ElementCollapseCostume>

            {/*                              bitacora                         */ }
            <ElementCollapseCostume
                textTitle={props.languageTranslate.moduloGenericDetail.itemBitacora.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <ElementTableCostume
                        listHeader={props.languageTranslate.moduloGenericDetail.itemBitacora.headerTable}
                        listData={props.configForm.data.Bitacora}
                    />
                </div>
            </ElementCollapseCostume>


            {/*
            <div className="GroupListGastos">
                <div className={`HeaderItems ${props.configItems.active ? 'w-100' : ''}`}>
                    {
                        props.configItems.active ?
                            null
                        :
                        <p className="text-muted my-0">
                            <span className="text-dark">{props.languageTranslate.moduloGastoAprobacion.viewReport.textGastos}</span>
                        </p>
                    }
                    <div className={`d-flex group-btn-option ${props.configItems.active ? 'w-100' : ''}`}>
                        {
                            props.configItems.active ?
                            <div className="d-flex w-100" style={{ justifyContent: 'end', gap: 10 }}>
                                <button onClick={props.onSelectAllItem} className='btn btn-outline-primary btn-seleccionar'>{ props.configItems.selectItems.length === props.configItems.items.length ? props.languageTranslate.btnDescartar : props.languageTranslate.btnSeleccionar } {props.languageTranslate.btnTodo}</button>
                                <button onClick={props.disabledModeSelectItem} className='btn btn-danger btn-seleccionar'> <i className='fa-solid fa-xmark' style={{ fontSize: 14 }}/></button>
                            </div>
                            :
                            <>
                                {
                                    props.configForm.mode !== 'preview' ?
                                        <button className='btn btn-primary btn-seleccionar' onClick={props.activeModeSelectItem}>
                                            {props.languageTranslate.btnSeleccionar}
                                        </button>
                                    : null
                                }
                            </>
                        }
                    </div>
                </div>
                <div className="GroupSearch">
                    <form onSubmit={(evt) => { evt.preventDefault(); props.onSubmitSearch() }} >
                        <InputTextIcon
                            label={''}
                            placeholder={props.languageTranslate.moduloGastoAprobacion.viewReport.textoBuscar}
                            values={{ search: props.configItems.textSearch }}
                            onChange={(_, value) => props.onChangeSearch(value)}
                            className='form-group w-100'
                            name='search'
                            icon="fa-solid fa-magnifying-glass"
                            onSubmit={props.onSubmitSearch}
                        />
                    </form>
                </div>
                <hr />
                <div className="GroupItems">
                    {
                        props.configItems.filterItems.length === 0 ? 
                            <span>{props.languageTranslate.textSinResultados}</span>
                        :
                            props.configItems.filterItems.map((row, index) =>
                                <div className="row ItemGasto" key={row.Codigo}>
                                    <div className="col-12">
                                        <div className={`${props.configItems.active ? 'ModeSelectItem' : ''} ${props.configItems.selectItems.some((_row) => _row.Codigo === row.Codigo) ? 'ModeSelectItem-select' : ''}`} onClick={() => props.onSelectItem(row)} />
                                        <div className="row" style={{ margin: '1px' }} onClick={() => props.openModalDetail(row, index)}>
                                            <label className="TextNumber text-danger">#{ index + 1 }</label>
                                            <div className="col-10" style={{ fontSize: 12 }}>
                                                <div className='GroupText d-flex'>
                                                    <span>{props.languageTranslate.moduloGastoAprobacion.generic.textProveedor}:</span>
                                                    <span className='text-muted'>&nbsp;{row.DatosEmpresa.RazonSocial} </span>
                                                </div>
                                                <div className='GroupText d-flex'>
                                                    <span>{props.languageTranslate.moduloGastoAprobacion.viewReport.textFechaEmision}:</span>
                                                    <span className='text-muted'>&nbsp;{AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.FechaEmision.Fecha), 3)} </span>
                                                </div>
                                                <div className='GroupText d-flex'>
                                                    <span>{props.languageTranslate.moduloGastoAprobacion.generic.textImporte}:</span>
                                                    <span className='text-muted'>&nbsp;{AdapterGenerico.formatoDinero(row.DatosImporte.Importe)} </span>
                                                </div>
                                                <div className='GroupText d-flex'>
                                                    <span>{props.languageTranslate.moduloGastoAprobacion.viewReport.textServicio}:</span>
                                                    <span className='text-muted'>&nbsp;{row.DatosServicio.Servicio} </span>
                                                </div>
                                            </div>
                                            <div className={`BadgetStatus ${
                                                    row.Status.IdStatus === 1 ? 'BadgetAprobado' :
                                                    (row.Status.IdStatus === 4 ? 'BadgetRechazado' : 'BadgetRevision')
                                                }
                                            `}>
                                                <p>
                                                    {
                                                        row.Status.IdStatus === 1 ? props.languageTranslate.moduloGastoAprobacion.generic.textStatusAprobado :
                                                            (row.Status.IdStatus === 4 ? props.languageTranslate.moduloGastoAprobacion.generic.textStatusRechazado : props.languageTranslate.moduloGastoAprobacion.generic.textStatusRevision)
                                                    }
                                                </p>
                                            </div>
                                            <div className="icon-eye-item">
                                                { props.configItems.active ? null : <i className="fa-solid fa-eye" style={{ fontSize: '20px' }} /> }
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            )
                    }
                </div>
            </div>
            */ }

            {
                // Botones de aprobaciones Grupal
                <div className='container_group_button shadow-lg'>
                    <div className='group_button'>
                        <button
                            className='btn btn-danger'
                            onClick={() => props.onSubmitApproval({ mode: 'group', type: 'reject' })}
                            // disabled={props.configItems.selectItems.length === 0}
                        >
                            <i className="fa-solid fa-arrow-rotate-left" style={{ fontSize: 14, marginRight: 12 }} />
                            {props.languageTranslate.moduloGenericDetail.btnTextDevolver}
                        </button>
                        <button
                            className='btn btn-success'
                            onClick={() => props.onSubmitApproval({ mode: 'group', type: 'approve' })}
                            // disabled={props.configItems.selectItems.length === 0}
                        >
                            <i className="fa-solid fa-check" style={{ fontSize: 14, marginRight: 12 }} />
                            {props.languageTranslate.moduloGenericDetail.btnTextAprobar}
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}