//Dependency External
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';

//Adapters
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { addLoading, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterConfigure } from './AdapterConfigure';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';

//Repository
import { RepositoryImplMain } from './RepositoryImplMain';
import { UseCaseRecuperarPassword } from '../Application/UseCaseRecuperarPassword';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';

//UseCase

//Entities


export const Controller = () => {
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const languageTranslate = LanguageTranslate();

    const form = useFormik({
        initialValues: { identificacion: ''},
        validationSchema: Yup.object({
            identificacion: Yup.string().required('Ingresar Número Doc. Identidad').matches(/^[a-zA-Z0-9]{0,12}$/i, 'El Número Doc. Identidad es incorrecto'),
        }),

        onSubmit: (values, formikHelpers) => { },
    });

    const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        form.setFieldValue(name, value);
    };

    const onSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await form.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await form.validateForm()); } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            await (new UseCaseRecuperarPassword(repository)).exec({ identificacion: form.values.identificacion });

            dispatch(removeLoading());
            form.resetForm();
            AdapterGenerico.createMessage(languageTranslate.textoExitoso, languageTranslate.textoExitosoRecuperacionPassword, 'success');
            onClickLogin();
            
        } catch (error) {
            console.error(error);
            
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        };
    };

    const onClickLogin = () => {
        navigate(`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`, { replace: true });
    };


    return {
        form,
        onChange,
        onSubmit,
        onClickLogin,
    };
}