import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { signIn } from "../../../shared/Infraestructure/SliceAuthentication";
import { EntityMain } from "../Domain/EntityMain";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class RepositoryImplMain
  extends RepositoryImplGenerico<any>
  implements RepositoryMain {
  public async consultarFilesGIA(filtros: any): Promise<EntityMain[]> {
    try {
      let data: EntityMain[] = (await this.service.call<EntityMain[]>(
        "POST",
        `${this.urlBase}/docuCobra/select`,
        JSON.stringify(filtros),
        "bearer",
        "json",
        "json",
        {},
        0
      )) as EntityMain[];
      data = data.filter((row) =>
        row.Type === "FOLDER" ? (row.Publicado === 1 ? true : false) : true
      );
      return data;
    } catch (error) {
      return [];
    }
  }

  public async obtenerResumen(filtros: any): Promise<string> {
    try {
      let data: { uriBase64: string } = (await this.service.call<{ uriBase64: string; }>("POST", `${this.urlBase}/file/donwloadWaterMarkUriBase64`, JSON.stringify(filtros), "bearer", "json", "json", {}, 0)) as { uriBase64: string };
      return data?.uriBase64 || "";
    } catch (error) {
      throw error;
    }
  }

  public async obtenerDocumento(filtros: any): Promise<string> {
    try {
      let data: { uriBase64: string } = (await this.service.call<{
        uriBase64: string;
      }>(
        "POST",
        `${this.urlBase}/docuCobra/downloadDocumentoVersionActiva`,
        JSON.stringify(filtros),
        "bearer",
        "json",
        "json",
        {},
        0
      )) as { uriBase64: string };
      return data?.uriBase64 || "";
    } catch (error) {
      throw error;
    }
  }

  public async obtenerRelacionados(filtros: any): Promise<EntityMain[]> {
    let data: EntityMain[] = (await this.service.call<EntityMain[]>(
      "POST",
      `${this.urlBase}/docuCobra/selectArchivosRelacionados`,
      JSON.stringify(filtros),
      "bearer",
      "json",
      "json",
      {},
      0
    )) as EntityMain[];
    return data;
  }

  public async obtenerPermisos(): Promise<EntityMain[]> {
    let responseSisacce: any = await this.service.call<any>(
      "POST",
      `${process.env?.REACT_APP_URL_MASTER || ""
      }/Sisacce/Acceso/Permiso/obtenerPermisos`,
      JSON.stringify({ sistema: "DOCUCOBRA", ambiente: "dev" }),
      "bearer",
      "json",
      "json",
      { "request-decrypt-response": "yes" },
      0
    );

    const rolesSisacce = this.generarRoles(
      parseInt(responseSisacce.rol.codigo)
    );
    const paisesSisacce = await this.getPaisesPermisos(responseSisacce.grupo);
    const filialesSisacce = await this.getFilialPermisos(responseSisacce.grupo);
    const opcionesSisacce = await this.getOpcionesFolder(
      responseSisacce.opciones,
      "",
      true,
      0
    );

    this.dispatch(
      signIn({ rolesSisacce, paisesSisacce, filialesSisacce, opcionesSisacce })
    );

    return opcionesSisacce;
  }

  private generarRoles(codigoRol: number): Array<string> {
    let list: string[] = [];

    for (let i = 0; i < codigoRol; i++) {
      if (i !== 0) list.push(i.toString());
    }
    list.push(codigoRol.toString());
    return list;
  }

  private async getPaisesPermisos(dataGrupos: any[]) {
    const paises: Array<{ _id: string; codigo: string; nombre: string }> = [];

    for (let g of dataGrupos || []) {
      for (let p of g?.pais || []) {
        if (p) {
          const i = paises.map((row) => row.codigo).indexOf(p.codigo);
          if (i === -1)
            paises.push({ _id: p._id, codigo: p.codigo, nombre: p.nombre });
        }
      }
    }

    return paises;
  }

  private async getFilialPermisos(dataGrupos: any[]) {
    const filiales: Array<{ _id: string; codigo: string; nombre: string }> = [];

    for (let g of dataGrupos || []) {
      for (let p of g?.pais || []) {
        for (let d of p?.delegacion || []) {
          if (d.filial !== null) {
            const p = filiales
              .map((row) => row.codigo)
              .indexOf(d.filial.codigo);
            if (p === -1)
              filiales.push({ _id: d._id, codigo: d.codigo, nombre: d.nombre });
          }
        }
      }
    }

    return filiales;
  }

  private async getOpcionesFolder(
    data: any[],
    idPadre: any = null,
    option: boolean,
    nivel: number
  ) {
    const listOpciones: EntityMain[] = [];
    try {
      for (const o of data) {
        if (option) {
          const items = this.structureData(o, option, nivel);
          listOpciones.push(items);
        } else {
          if (o.Padre === idPadre) {
            const items = this.structureData(o, option, nivel);
            listOpciones.push(items);
          }
        }
      }
      return listOpciones;
    } catch (error) {
      return listOpciones;
    }
  }

  private structureData = (
    row: any,
    option: boolean,
    nivel: number
  ): EntityMain => {
    let validate = "Name",
      Permiso = { Code: "", _id: "", Nombre: "" };
    if (option) {
      validate = "descripcion";
      Permiso = row.padre
        ? { Code: row.padre.codigo, _id: row._id, Nombre: row.padre.nombre }
        : { ...Permiso };
    } else {
      Permiso = row.Permiso ?? { ...Permiso };
    }
    return {
      _id: row._id,
      Code: "",
      Padre: row.padre ? row.padre._id : "",
      Name: row[`${validate}`],
      Extension: "FOLDER",
      Nivel: nivel,
      Parents: [],
      Permiso: Permiso,
      Description: "",
      DateRegistry: "null",
      Resumen: "",
      Status: 1,
      StatusWorkflow: { IdStatus: 1, Status: "Aprobado" },
      Type: "FOLDER",
      VersionFileName: "",
      ArchivosRelacionados: [],
      Publicado: 1,
    };
  };
}
