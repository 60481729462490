const SCHEMA: string = process.env.REACT_APP_MASTER_MAIN_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MASTER_MAIN_ENTITY || '';
const LIST_INGRESO_GASTO: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_CAJACHICA}/${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_INGRESO_GASTOS}`;
const LIST_INGRESO_GASTO_ASOCIADO: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_CAJACHICA}/${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_INGRESO_GASTOS_ASOCIADOS}`;

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    LIST_INGRESO_GASTO,
    LIST_INGRESO_GASTO_ASOCIADO
};