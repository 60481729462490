export interface EntityMenu {
    id: number;
    codigo: string;
    sigla: string;
    orden: number;
    ruta: string;
    icono: string;
    padre: number | null;
    esOpcion: boolean;
    permitirSubOpcion: boolean;
    estado: boolean;
}

export const initEntityMenu: Array<EntityMenu> = [
    {
        id: 1,
        codigo: "001",
        icono: "fa-solid fa-person-falling-burst",
        orden: 1,
        padre: null,
        ruta: "main/home",
        sigla: "GIAAPP_HOME",
        esOpcion: false,
        permitirSubOpcion: false,
        estado: true,
    },
    {
        id: 2,
        codigo: "002",
        icono: "fa-solid fa-person-falling-burst",
        orden: 2,
        padre: null,
        ruta: "main/profile",
        sigla: "GIAAPP_PROFILE",
        esOpcion: false,
        permitirSubOpcion: false,
        estado: true,
    },

    // módulo aprobaciones
    {
        id: 10,
        codigo: "010",
        icono: "fa-solid fa-tv",
        orden: 3,
        padre: null,
        ruta: "main/approvals",
        sigla: "GIAAPP_APPROVALS",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    // sub-módulo de aprobaciones
    {
        id: 11,
        codigo: "011",
        icono: "fa-solid fa-tv",
        orden: 4,
        padre: 10,
        ruta: "main/approvals/system-requirement",
        sigla: "GIAAPP_APPROVALS_SYSTEM_REQUIREMENT",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 12,
        codigo: "012",
        icono: "fa-solid fa-tv",
        orden: 5,
        padre: 10,
        ruta: "main/approvals/purchase-requirement",
        sigla: "GIAAPP_APPROVALS_PURCHA_SEREQUIREMENT",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 13,
        codigo: "013",
        icono: "fa-solid fa-tv",
        orden: 6,
        padre: 10,
        ruta: "main/approvals/comparative",
        sigla: "GIAAPP_APPROVALS_COMPARATIVE",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 14,
        codigo: "014",
        icono: "fa-solid fa-tv",
        orden: 7,
        padre: 10,
        ruta: "main/approvals/invoice",
        sigla: "GIAAPP_APPROVALS_INVOICE",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 15,
        codigo: "015",
        icono: "fa-solid fa-tv",
        orden: 8,
        padre: 10,
        ruta: "main/approvals/petty-cash-request",
        sigla: "GIAAPP_APPROVALS_PETTY_CASH_REQUEST",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    /*{
        id: 16,
        codigo: "016",
        icono: "fa-solid fa-tv",
        orden: 9,
        padre: 10,
        ruta: "main/approvals/surrender-petty-cash-expenses",
        sigla: "GIAAPP_APPROVALS_SURRENDER_PETTY_CASH_EXPENSES",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },*/
    {
        id: 16,
        codigo: "019",
        icono: "fa-solid fa-tv",
        orden: 9,
        padre: 10,
        ruta: "main/approvals/gasto-aprobacion",
        sigla: "GIAAPP_APPROVALS_GASTOAPROBACCION",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 17,
        codigo: "017",
        icono: "fa-solid fa-tv",
        orden: 10,
        padre: 10,
        ruta: "main/approvals/request-form",
        sigla: "GIAAPP_APPROVALS_REQUEST_FORM",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 18,
        codigo: "018",
        icono: "fa-solid fa-tv",
        orden: 11,
        padre: 10,
        ruta: "main/approvals/working-conditions",
        sigla: "GIAAPP_APPROVALS_WORKING_CONDITIONS",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 43,
        codigo: "043",
        icono: "fa-solid fa-tv",
        orden: 12,
        padre: 10,
        ruta: "main/approvals/tareo",
        sigla: "GIAAPP_APPROVALS_TAREO",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 44,
        codigo: "044",
        icono: "fa-solid fa-tv",
        orden: 13,
        padre: 10,
        ruta: "main/approvals/ausencia",
        sigla: "GIAAPP_APPROVALS_AUSENCIA",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },

    /*{
        id: 20,
        codigo: "020",
        icono: "fa-solid fa-tv",
        orden: 4,
        padre: null,
        ruta: "main/docucobra",
        sigla: "GIAAPP_DOCUCOBRA",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },*/

    {
        id: 30,
        codigo: "030",
        icono: "fa-solid fa-tv",
        orden: 5,
        padre: null,
        ruta: "main/caja-chica",
        sigla: "GIAAPP_CAJACHICA",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 32,
        codigo: "032",
        icono: "fa-solid fa-tv",
        orden: 2,
        padre: 30,
        ruta: "main/caja-chica/historico-gastos",
        sigla: "GIAAPP_HISTORICOGASTOS",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 33,
        codigo: "033",
        icono: "fa-solid fa-tv",
        orden: 3,
        padre: 30,
        ruta: "main/caja-chica/rendicion-gastos",
        sigla: "GIAAPP_RENDICIONGASTO",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 36,
        codigo: "036",
        icono: "fa-solid fa-tv",
        orden: 1,
        padre: 30,
        ruta: "main/caja-chica/solicitud-anticipo",
        sigla: "GIAAPP_ANTICIPO",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },

    // módulo tareo
    {
        id: 40,
        codigo: "040",
        icono: "fa-solid fa-tv",
        orden: 4,
        padre: null,
        ruta: "main/tareo",
        sigla: "GIAAPP_TAREO",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    // sub-módulo tareo
    {
        id: 41,
        codigo: "041",
        icono: "fa-solid fa-tv",
        orden: 1,
        padre: 40,
        ruta: "main/tareo/personal",
        sigla: "GIAAPP_TAREO_PERSONAL",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    /*{
        id: 42,
        codigo: "042",
        icono: "fa-solid fa-tv",
        orden: 2,
        padre: 40,
        ruta: "main/tareo/grupal",
        sigla: "GIAAPP_TAREO_GRUPAL",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    }*/
];

export const initEntityMenuDocucobra: Array<EntityMenu> = [
    {
        id: 1,
        codigo: "001",
        icono: "fa-solid fa-person-falling-burst",
        orden: 1,
        padre: null,
        ruta: "main/home",
        sigla: "GIAAPP_HOME",
        esOpcion: false,
        permitirSubOpcion: false,
        estado: true,
    },
    {
        id: 2,
        codigo: "002",
        icono: "fa-solid fa-person-falling-burst",
        orden: 2,
        padre: null,
        ruta: "main/profile",
        sigla: "GIAAPP_PROFILE",
        esOpcion: false,
        permitirSubOpcion: false,
        estado: true,
    },

    // módulo aprobaciones
    {
        id: 10,
        codigo: "010",
        icono: "fa-solid fa-tv",
        orden: 3,
        padre: null,
        ruta: "main/approvals",
        sigla: "GIAAPP_APPROVALS",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    // sub-módulo de aprobaciones
    {
        id: 11,
        codigo: "011",
        icono: "fa-solid fa-tv",
        orden: 4,
        padre: 10,
        ruta: "main/approvals/system-requirement",
        sigla: "GIAAPP_APPROVALS_SYSTEM_REQUIREMENT",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 12,
        codigo: "012",
        icono: "fa-solid fa-tv",
        orden: 5,
        padre: 10,
        ruta: "main/approvals/purchase-requirement",
        sigla: "GIAAPP_APPROVALS_PURCHA_SEREQUIREMENT",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 13,
        codigo: "013",
        icono: "fa-solid fa-tv",
        orden: 6,
        padre: 10,
        ruta: "main/approvals/comparative",
        sigla: "GIAAPP_APPROVALS_COMPARATIVE",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 14,
        codigo: "014",
        icono: "fa-solid fa-tv",
        orden: 7,
        padre: 10,
        ruta: "main/approvals/invoice",
        sigla: "GIAAPP_APPROVALS_INVOICE",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 15,
        codigo: "015",
        icono: "fa-solid fa-tv",
        orden: 8,
        padre: 10,
        ruta: "main/approvals/petty-cash-request",
        sigla: "GIAAPP_APPROVALS_PETTY_CASH_REQUEST",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    /*{
        id: 16,
        codigo: "016",
        icono: "fa-solid fa-tv",
        orden: 9,
        padre: 10,
        ruta: "main/approvals/surrender-petty-cash-expenses",
        sigla: "GIAAPP_APPROVALS_SURRENDER_PETTY_CASH_EXPENSES",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },*/
    {
        id: 16,
        codigo: "019",
        icono: "fa-solid fa-tv",
        orden: 9,
        padre: 10,
        ruta: "main/approvals/gasto-aprobacion",
        sigla: "GIAAPP_APPROVALS_GASTOAPROBACCION",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 17,
        codigo: "017",
        icono: "fa-solid fa-tv",
        orden: 10,
        padre: 10,
        ruta: "main/approvals/request-form",
        sigla: "GIAAPP_APPROVALS_REQUEST_FORM",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 18,
        codigo: "018",
        icono: "fa-solid fa-tv",
        orden: 11,
        padre: 10,
        ruta: "main/approvals/working-conditions",
        sigla: "GIAAPP_APPROVALS_WORKING_CONDITIONS",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 43,
        codigo: "043",
        icono: "fa-solid fa-tv",
        orden: 12,
        padre: 10,
        ruta: "main/approvals/tareo",
        sigla: "GIAAPP_APPROVALS_TAREO",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 44,
        codigo: "044",
        icono: "fa-solid fa-tv",
        orden: 13,
        padre: 10,
        ruta: "main/approvals/ausencia",
        sigla: "GIAAPP_APPROVALS_AUSENCIA",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },

    // módulo docucobra
    {
        id: 20,
        codigo: "020",
        icono: "fa-solid fa-tv",
        orden: 4,
        padre: null,
        ruta: "main/docucobra",
        sigla: "GIAAPP_DOCUCOBRA",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },

    // módulo rendiciones
    {
        id: 30,
        codigo: "030",
        icono: "fa-solid fa-tv",
        orden: 5,
        padre: null,
        ruta: "main/caja-chica",
        sigla: "GIAAPP_CAJACHICA",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 32,
        codigo: "032",
        icono: "fa-solid fa-tv",
        orden: 2,
        padre: 30,
        ruta: "main/caja-chica/historico-gastos",
        sigla: "GIAAPP_HISTORICOGASTOS",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 33,
        codigo: "033",
        icono: "fa-solid fa-tv",
        orden: 3,
        padre: 30,
        ruta: "main/caja-chica/rendicion-gastos",
        sigla: "GIAAPP_RENDICIONGASTO",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    {
        id: 36,
        codigo: "036",
        icono: "fa-solid fa-tv",
        orden: 1,
        padre: 30,
        ruta: "main/caja-chica/solicitud-anticipo",
        sigla: "GIAAPP_ANTICIPO",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },

    // módulo tareo
    {
        id: 40,
        codigo: "040",
        icono: "fa-solid fa-tv",
        orden: 4,
        padre: null,
        ruta: "main/tareo",
        sigla: "GIAAPP_TAREO",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    // sub-módulo tareo
    {
        id: 41,
        codigo: "041",
        icono: "fa-solid fa-tv",
        orden: 1,
        padre: 40,
        ruta: "main/tareo/personal",
        sigla: "GIAAPP_TAREO_PERSONAL",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    },
    /*{
        id: 42,
        codigo: "042",
        icono: "fa-solid fa-tv",
        orden: 2,
        padre: 40,
        ruta: "main/tareo/grupal",
        sigla: "GIAAPP_TAREO_GRUPAL",
        esOpcion: true,
        permitirSubOpcion: true,
        estado: true,
    }*/
];