import { EntityAnticipoAprobado } from "../../../../../shared/Domain/Catalogos/EntityAnticipoAprobado";
import { EntityFlujoOT } from "../../../../../shared/Domain/Catalogos/EntityFlujoOT";
import { EntityFlujosDelegacion } from "../../../../../shared/Domain/Catalogos/EntityFlujosDelegacion";
import { EntityOT } from "../../../../../shared/Domain/Catalogos/EntityOT";
import { EntitySelectBase } from "../../../../../shared/Domain/Catalogos/EntitySelectBase";
import { EntityGasto } from "../../../../../shared/Domain/EntityGasto";

export interface EntityResponseInitialData {
    ot: EntitySelectBase<EntityOT>[];
    anticipo: EntitySelectBase<EntityAnticipoAprobado>[];
    gasto: EntityGasto[];
    gastoAsociado: EntityGasto[];
    flujo: EntityFlujoOT[];
    flujoDelegacion: EntityFlujosDelegacion[];
}

export const initEntityResponseInitialData: EntityResponseInitialData  = {
    ot: [],
    anticipo: [],
    gasto: [],
    gastoAsociado: [],
    flujo: [],
    flujoDelegacion: []
}