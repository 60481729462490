import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { useFormik } from "formik";
import * as Yup from 'yup'

// Infraestructure
import { addLoading, changeSaludo, removeLoading } from "../../../../../shared/Infraestructure/SliceGenerico";
import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";
import { RootState } from "../../../../../shared/Infraestructure/AdapterStore";
import { RepositoryImplMain } from "./RepositoryImplMain";

// Adapters
import { AdapterValidator } from "../../../../../shared/Infraestructure/AdapterValidator";
import { AdapterGenerico } from "../../../../../shared/Infraestructure/AdapterGenerico";
import { AdapterConfigure } from "./AdapterConfigure";

// Domain
import { EntityConfigForm, EntityNavBar, ModeForm, initEntityConfigForm, keyModule, nameModule } from "../Domain/EntityUtils";
import { initEntityRequestGenericForm } from "../Domain/EntityRequest";

// Use Case
import { UseCaseLoadInitialData } from "../Application/UseCaseLoadInitialData";
import { UseCaseFind } from "../Application/UseCaseFind";
import { EntityGasto } from "../../../../../shared/Domain/EntityGasto";

const languageTranslate = LanguageTranslate();
let nameStore: keyModule = '';
let modeForm: ModeForm = '';

export const Controller = () => {
    const [configForm, setConfigForm] = useState<EntityConfigForm>(initEntityConfigForm)
    const { generico: { websocket, dbLocal }, auth: { user } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const formikForm = useFormik({
        initialValues: initEntityRequestGenericForm,
        onSubmit: () => {},
        validationSchema: Yup.object({
            OT: Yup.object().required(languageTranslate.moduloGasto.validate.errorOT).nullable(),
            Proveedor: Yup.object().when(['OtroProveedor'], {
                is: (OtroProveedor: boolean) => (!OtroProveedor),
                then: Yup.object().required(languageTranslate.moduloGasto.validate.errorProveedor).nullable()
            }).nullable(),
            TipoDocumentoProveedor: Yup.object().when(['OtroProveedor'], {
                is: (OtroProveedor: boolean) => (OtroProveedor),
                then: Yup.object().required(languageTranslate.moduloGasto.validate.errorTipoDocumentoProveedor).nullable()
            }).nullable(),
            NumeroDocumentoProveedor: Yup.number().when(['OtroProveedor'], {
                is: (OtroProveedor: boolean) => (OtroProveedor),
                then: Yup.number().required(languageTranslate.moduloGasto.validate.errorNumeroDocumentoProveedor).nullable()
            }).nullable(),
            RazonSocialProveedor: Yup.string().when(['OtroProveedor'], {
                is: (OtroProveedor: boolean) => (OtroProveedor),
                then: Yup.string().required(languageTranslate.moduloGasto.validate.errorRazonSocialProveedor).nullable()
            }).nullable(),
            FechaEmision: Yup.string().required(languageTranslate.moduloGasto.validate.errorFechaEmision).nullable(),
            TipoDocumento: Yup.object().required(languageTranslate.moduloGasto.validate.errorTipoDocumento).nullable(),
            NumeroDocumento: Yup.string().required(languageTranslate.moduloGasto.validate.errorNumeroDocumento).max(20, 'Número Documento no debe superar los 20 caracteres.').nullable(),
            Servicio: Yup.object().required(languageTranslate.moduloGasto.validate.errorServicio).nullable(),
            // Divisa: Yup.object().required(languageTranslate.moduloGasto.validate.errorDivisa).nullable(),
            Importe: Yup.number().min(1, languageTranslate.moduloGasto.validate.errorImporte).required(languageTranslate.moduloGasto.validate.errorImporte).nullable(),
            /*TipoImpuesto: Yup.object().when(['OtroImpuesto'], {
                is: (OtroImpuesto: boolean) => (!OtroImpuesto),
                then: Yup.object().required(languageTranslate.moduloGasto.validate.errorTipoImpuesto).nullable()
            }).nullable(),*/
            Observacion: Yup.string().nullable(), //required(languageTranslate.moduloGasto.validate.errorObservacion).nullable(),
            Files: Yup.array().min(1, languageTranslate.moduloGasto.validate.errorFiles).nullable(),
        })
    });

    const init = async () => {
        dispatch(changeSaludo(false));
        configModeType();
    };

    const configModeType = async () => {
        // Carga la información de los select
        const dataOptions = await new UseCaseLoadInitialData(repository).exec();

        // Variables
        let navBar: EntityNavBar[] = [];
        const arrPathname = window.location.pathname.split('/');
        const validationPathName = (module: string) => arrPathname.some(item => item === module);
        nameStore = 'Gastos';

        // Valida la ruta para activar el formulario
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_RENDICION_GASTOS}`)) {
            navBar = languageTranslate.moduloGasto.navBarGastosPendientes;
            modeForm = 'create';
        }

        // Incia solo para crear un Nuevo Gasto
        if (modeForm === 'create') {

            formikForm.setFieldValue('Codigo', `${Date.now()}`)
            formikForm.setFieldValue('FechaEmision', AdapterGenerico.convertDateToString(new Date(), 6));

            if (user.DatosTrabajo.OT) {

                // Verificar que la OT exista
                const resultOT = dataOptions.ot.find(row => row.dataComplete.Code === user.DatosTrabajo.OT)
                if (!resultOT) {
                    AdapterGenerico.createMessage('Error', `OT '${user.DatosTrabajo.OT}' no encontrada`, 'error').then(res => navigate(AdapterConfigure.LIST_INGRESO_GASTO)); 
                    return;
                }
                
                // Autcompletar valores de la OT
                const CodigoPais = resultOT.dataComplete.DatosPais.Code;
                formikForm.setFieldValue('Pais', { CDPais: resultOT.dataComplete.DatosPais.CDPais, Codigo: CodigoPais, Nombre: resultOT.dataComplete.DatosPais.Name });
                formikForm.setFieldValue('Delegacion', { Codigo: resultOT.dataComplete.DatosTrabajo.Delegacion.Codigo, Code: resultOT.dataComplete.DatosTrabajo.Delegacion.Code, Delegacion: resultOT.dataComplete.DatosTrabajo.Delegacion.Name });
                formikForm.setFieldValue('OT', resultOT);
                
                // Mostar los servicios por la OT/País
                if (CodigoPais === 'PE') {
                    dataOptions.servicio = dataOptions.catServicio;
                } else if (['CL', 'EC'].includes(CodigoPais)) {
                    dataOptions.servicio = configForm.dataOptions.catProducto;
                } else if (CodigoPais === 'CO') {
                    dataOptions.servicio = await repository.loadServiceColombia(resultOT.dataComplete.DatosEmpresa.Empresa);
                } else {}
            }
        }

        // Modifica el navbar para el lenguaje
        if (navBar.length >= 3) navBar[2].text = nameModule[modeForm]; 

        // Guarda la información general y la del usuario
        setConfigForm(() => ({
            ...configForm,
            loadingForm: false,
            dataOptions: dataOptions,
            key: nameStore,
            navBar: navBar,
            mode: modeForm,
        }));
        formikForm.setFieldValue("User", { LastName: `${user.LastNameFather} ${user.LastNameMother}`, Name: user.Name, User: user.User })
    };

    const onChange = (name: string, value: any) => {
        if (!value) return formikForm.setFieldValue(name, null);

        switch (name) {
            case 'OtroProveedor':
                formikForm.setFieldValue('Proveedor', null)
                break;
            case 'OtroImpuesto':
                formikForm.setFieldValue('TipoImpuesto', null)
                break;
            case 'Importe':
                formikForm.setFieldValue('Importe', AdapterGenerico.removerCeroIzquierda(value))
                return;
        }
        formikForm.setFieldValue(name, value);
    };

    const onSubmit = async (e: Event, otherModule: boolean = false, gastos: EntityGasto[] = []) => {
        let result: EntityGasto | null = null;
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formikForm.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formikForm.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }
            if (gastos.find(row => row.DatosDocumento.NroDocumento === formikForm.values.NumeroDocumento && row.Codigo !== formikForm.values.Codigo)) {
                AdapterGenerico.createMessage(languageTranslate.textoIncompleto, languageTranslate.moduloGasto.validate.errorNumeroDocumentoDuplicado, 'warning', false);
                return null;
            }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const dataFormatted = await repository.formatDataToSave(formikForm.values);
            result = await repository.save(dataFormatted, configForm.mode)
            dispatch(removeLoading());

            if (otherModule) return result;
            navigate(AdapterConfigure.LIST_INGRESO_GASTO, { replace: true });
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        }

        return result;
    };

    const onCancelForm = () => {
        navigate(configForm.key === 'Gastos' ? AdapterConfigure.LIST_INGRESO_GASTO : AdapterConfigure.LIST_INGRESO_GASTO_ASOCIADO);
    };

    const resetForm = () => {
        const prevValues = {
            Pais: formikForm.values.Pais,
            User: formikForm.values.User,
            OT: formikForm.values.OT
        }
        formikForm.resetForm();
        formikForm.setFieldValue('Pais', prevValues.Pais);
        formikForm.setFieldValue('User', prevValues.User);
        formikForm.setFieldValue('OT', prevValues.OT);
        formikForm.setFieldValue('Codigo', `${Date.now()}`)
        formikForm.setFieldValue('FechaEmision', AdapterGenerico.convertDateToString(new Date(), 6));
    }

    const findFormConfig = async (codigo: string, mode: 'preview' | 'edit' | 'create' | ''): Promise<Boolean> => {
        modeForm = ['create', 'edit'].includes(mode) ? 'edit' : 'preview';
        nameStore = 'Gastos';
        const findForm = await new UseCaseFind(repository).exec(codigo, nameStore, configForm.dataOptions);
        if (!findForm) {
            AdapterGenerico.createMessage('Error', `No se encontró el detalle para: ${params.id}`, 'error'); 
            return false;
        }
        formikForm.setValues(findForm) 

        // Guarda la información general y la del usuario
        setConfigForm(() => ({
            ...configForm,
            loadingForm: false,
            nameStore: nameStore,
            mode: modeForm,
        }));
        formikForm.setFieldValue("User", { LastName: `${user.LastNameFather} ${user.LastNameMother}`, Name: user.Name, User: user.User })
        
        return true;
    }

    const deleteFormConfig = async (codigos: string[]) => repository.deleteGasto(codigos)

    const createFormConfig = async () => {
        modeForm = 'create';
        nameStore = 'Gastos';

        // Guarda la información general y la del usuario
        setConfigForm(() => ({
            ...configForm,
            nameStore: nameStore,
            mode: modeForm,
        }));
    }

    return ({
        init,
        onChange,
        onSubmit,
        onCancelForm,
        resetForm,

        formikForm,
        configForm,
        languageTranslate,
        findFormConfig,
        deleteFormConfig,
        createFormConfig
    });
}