import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dispatch } from "redux";

import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import {
  addLoading,
  changeSaludo,
  removeLoading,
} from "../../../shared/Infraestructure/SliceGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";

import { Template, Viewer } from "@pdfme/ui";

import { useFormik } from "formik";
import * as Yup from "yup";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";

export const Controller = () => {
  const {
    generico: { websocket, dbLocal, countProcess },
    auth: {
      user,
      opcionesSisacce,
      filialesSisacce,
      rolesSisacce,
      paisesSisacce: dataPais,
    },
  } = useSelector((state: RootState) => state);
  const [data, setData] = useState<Array<EntityMain>>([]);
  const [navBar, setNavBar] = useState<
    Array<{ text: string; navigate: boolean; path: string }>
  >([]);

  const [modalItem, setModalItem] = useState<{
    item: EntityMain | null;
    showModal: boolean;
  }>({ item: null, showModal: false });
  const [modalRelacionados, setModalRelacionados] = useState<{
    data: EntityMain[];
    indexCarousel: number;
    showModal: boolean;
  }>({ data: [], indexCarousel: 0, showModal: false });
  const [modalPDF, setModalPDF] = useState<{
    type: string | null;
    item: EntityMain | null;
    src: string | null;
    showModal: boolean;
  }>({ type: null, item: null, src: null, showModal: false });

  const [modalFiltros, setModalFiltros] = useState<{ showModal: boolean }>({
    showModal: false,
  });

  const formFiltros = useFormik({
    initialValues: {
      pais: [],
    },
    onSubmit: () => { },
    validationSchema: Yup.object({
      pais: Yup.array().nullable(),
    }),
  });

  const [viewer, setViewer] = useState<Viewer | null>(null);
  const [buscarText, setBuscarText] = useState<string>("");

  const languageTranslate = LanguageTranslate();
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const repository: RepositoryImplMain = new RepositoryImplMain(
    websocket,
    dbLocal,
    dispatch,
    AdapterConfigure.SCHEMA,
    AdapterConfigure.ENTITY
  );

  useEffect(() => {
    setBuscarText("");
    init();
    // eslint-disable-next-line
  }, [params.id]);

  const init = async () => {
    try {
      let pais: string[] =
        formFiltros.values.pais.length === dataPais.length
          ? []
          : formFiltros.values.pais.map(
            (row: {
              label: string;
              value: { _id: string; codigo: string; nombre: string };
            }) => row.value.codigo
          );
      dispatch(changeSaludo(false));
      dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
      let opciones: EntityMain[] = [];
      if (!params.id) {
        opciones = getOpcionesFolder(opcionesSisacce, "", false, 0);
        setNavBar([]);
        setData(opciones);
      } else {
        let exists = opcionesSisacce.find((row) => row._id === params.id);

        if (!!exists) {
          if (exists?.Padre === "") {
            opciones = getOpcionesFolder(opcionesSisacce, exists._id, false, 1);

            generateNavBar({
              text: exists.Name,
              navigate: true,
              path: `/main/docucobra/list/${exists._id}`,
            });
            setData(opciones);
          } else {
            const Query = {
              _idPermiso: exists.Permiso._id,
              nivel: 1,
              codeFolder: exists.Code,
              pais,
            };
            let nuevaEstructura = await repository.consultarFilesGIA(
              queryGetFiles(Query)
            );
            nuevaEstructura = nuevaEstructura.filter((row) =>
              row.Type === "FOLDER"
                ? row.Publicado === 1
                  ? true
                  : false
                : true
            );
            generateNavBar({
              text: exists.Name,
              navigate: true,
              path: `/main/docucobra/list/${exists._id}`,
            });
            setData(nuevaEstructura);
          }
        } else {
          exists = data.find((row) => row._id === params.id);
          if (!exists) {
            opciones = getOpcionesFolder(opcionesSisacce, "", false, 0);
            setNavBar([]);
            setData(opciones);
          } else {
            const Query = {
              _idPermiso: exists.Permiso._id,
              nivel: exists.Nivel + 1,
              codeFolder: exists.Code,
              pais,
            };
            let nuevaEstructura = await repository.consultarFilesGIA(
              queryGetFiles(Query)
            );
            nuevaEstructura = nuevaEstructura.filter((row) =>
              row.Type === "FOLDER"
                ? row.Publicado === 1
                  ? true
                  : false
                : true
            );
            generateNavBar({
              text: exists.Name,
              navigate: true,
              path: `/main/docucobra/list/${exists._id}`,
            });
            setData(nuevaEstructura);
          }
        }
      }
      dispatch(removeLoading());
    } catch (error) {
      console.error(error);
      AdapterGenerico.createMessage(
        languageTranslate.textoAlerta,
        (error as Error).message || "",
        "warning"
      );
    }
  };

  const generateNavBar = (item: {
    text: string;
    navigate: boolean;
    path: string;
  }) => {
    let newNavbar: typeof navBar = [];
    let exists: boolean = false;
    for (let row of navBar) {
      if (row.path === item.path) {
        newNavbar.push(row);
        exists = true;
        break;
      }
      newNavbar.push(row);
    }

    if (!exists) {
      newNavbar.push(item);
    }

    setNavBar(newNavbar);
  };

  const getOpcionesFolder = (
    data: any[],
    idPadre: any = null,
    option: boolean,
    nivel: number
  ) => {
    const listOpciones: EntityMain[] = [];
    try {
      for (const o of data) {
        if (option) {
          const items = structureData(o, option, nivel);
          listOpciones.push(items);
        } else {
          if (o.Padre === idPadre) {
            const items = structureData(o, option, nivel);
            listOpciones.push(items);
          }
        }
      }
      return listOpciones;
    } catch (error) {
      return listOpciones;
    }
  };

  const structureData = (
    row: any,
    option: boolean,
    nivel: number
  ): EntityMain => {
    let validate = "Name",
      Permiso = { Code: "", _id: "", Nombre: "" };
    if (option) {
      validate = "descripcion";
      Permiso = row.padre
        ? { Code: row.padre.codigo, _id: row._id, Nombre: row.padre.nombre }
        : { ...Permiso };
    } else {
      Permiso = row.Permiso ?? { ...Permiso };
    }

    return {
      _id: row._id,
      Code: "",
      Padre: row.padre ? row.padre._id : "",
      Name: row[`${validate}`],
      Extension: "FOLDER",
      Nivel: nivel,
      Parents: [],
      Permiso: Permiso,
      Description: "",
      DateRegistry: "null",
      Resumen: "",
      Status: 1,
      StatusWorkflow: { IdStatus: 1, Status: "Aprobado" },
      Type: "FOLDER",
      VersionFileName: "",
      ArchivosRelacionados: [],
      Publicado: 1,
    };
  };

  const onClickItem = async (item: EntityMain) => {
    if (item.Type === "FOLDER") {
      let exists2 = data.find((row) => row._id === item._id);
      navigate(`/main/docucobra/list/${exists2?._id || ""}`, { replace: true });
    } else {
      setModalItem({ item, showModal: true });
    }
  };

  const onCloseModalItem = () => {
    setModalItem({ item: null, showModal: false });
  };

  const queryGetFiles = (query: any): any => {
    const security = rolesSisacce || ["1"];
    const filial = filialesSisacce.map((row) => row.codigo) || [];

    return {
      idStatusWorkFlow: 1, // MOVIL SOLO MUESTRA EL ESTADO 1 "APROBADOS"
      security: [...security],
      filial: ["", ...filial],
      ...query,
    };
  };

  const onClickAbrirResumen = async (item: EntityMain | null) => {
    try {
      if (!item) {
        return;
      }
      dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

      let filtros = {
        directory: ["NAVISI", "OPERACIONES", "M01", item.Code, "Resumen"],
        fileName: item.Resumen,
        user: `${user.FullName}`,
        security: item.Security?.nombre || "",
      };

      let base64: string = await repository.obtenerResumen(filtros);
      const blob = base64toBlob(base64);


      // let responseBlob = await fetch(base64);
      // let blob = await responseBlob.blob();
      let urlBlob = URL.createObjectURL(blob);

      // const link = document.createElement("a");
      // link.href = urlBlob;
      // link.download = `${item.Name}.pdf`;
      // document.body.appendChild(link);
      // link.dispatchEvent(
      //   new MouseEvent("click", {
      //     bubbles: true,
      //     cancelable: true,
      //     view: window,
      //   })
      // );

      // document.body.removeChild(link);

      // window.open(`${urlBlob}#toolbar=0&navpanes=0`, "_blank");
      dispatch(removeLoading());

      setModalPDF({
        type: languageTranslate.moduloDocucobra.textoResumen,
        item,
        src: urlBlob,
        showModal: true,
      });

      // setTimeout(() => {
      // const iframe = document.getElementById("iframePDF");
      // if (!iframe) return;
      //   const template: Template = {
      //     basePdf: base64,
      //     schemas: [],
      //   };
      //   const inputs = [{}];

      //   const view = new Viewer({ domContainer, template, inputs });
      //   setViewer(view);
      //   dispatch(removeLoading());
      // }, 1000);
    } catch (error) {
      dispatch(removeLoading());
      console.error(error);
    }
  };

  const onClickAbrirDocumento = async (item: EntityMain | null) => {
    try {
      if (!item) {
        return;
      }
      dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

      let filtros = {
        directory: ["NAVISI", "OPERACIONES", "M01", item.Code, "Version"],
        code: item.Code,
        user: `${user.FullName}`,
        security: item.Security?.nombre || "",
      };

      let base64 = await repository.obtenerDocumento(filtros);
      const blob = base64toBlob(base64);

      // let responseBlob = await fetch(base64);
      // let blob = await responseBlob.blob();
      let urlBlob = URL.createObjectURL(blob);

      // const link = document.createElement("a");
      // link.href = urlBlob;
      // link.download = `${item.Name}.pdf`;
      // document.body.appendChild(link);
      // link.dispatchEvent(
      //   new MouseEvent("click", {
      //     bubbles: true,
      //     cancelable: true,
      //     view: window,
      //   })
      // );

      // document.body.removeChild(link);

      // window.open(`${urlBlob}#toolbar=0&navpanes=0`, "_blank");
      dispatch(removeLoading());
      setModalPDF({
        type: languageTranslate.moduloDocucobra.textoVersion,
        item: item,
        src: urlBlob,
        showModal: true,
      });

      // setTimeout(() => {
      //   const domContainer = document.getElementById("divDisplayPDF");
      //   if (!domContainer) return;
      //   const template: Template = {
      //     basePdf: base64,
      //     schemas: [{}],
      //     columns: [],
      //     sampledata: [{}],
      //   };
      //   const inputs = [{}];

      //   const view = new Viewer({ domContainer, template, inputs });
      //   setViewer(view);
      //   dispatch(removeLoading());
      // }, 1000);
    } catch (error) {
      dispatch(removeLoading());
      console.error(error);
    }
  };

  const onClickVerRelacionados = async () => {
    try {
      if (!modalItem.item) {
        return;
      }
      dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

      let filtros = {
        Code: modalItem.item.Code,
      };

      let data = await repository.obtenerRelacionados(filtros);
      setModalRelacionados({ data: data, indexCarousel: 0, showModal: true });
      dispatch(removeLoading());
    } catch (error) {
      console.error(error);
    }
  };

  const onCloseModalPDF = () => {
    if (viewer) viewer.destroy();
    setModalPDF({ type: null, item: null, src: null, showModal: false });
  };

  const onChangeIndexCarouselModalRelacionados = (indexCarousel: number) => {
    setModalRelacionados({ ...modalRelacionados, indexCarousel });
  };

  const onCloseModalRelacionados = () => {
    setModalRelacionados({ data: [], indexCarousel: 0, showModal: false });
  };

  const onShowModalFiltros = (showModal: boolean) => {
    setModalFiltros({ showModal });
  };

  const onChangeValueFormFiltros = (name: string, value: any) => {
    formFiltros.setFieldValue(name, value);
  };

  const onClickBuscarFiltros = async () => {
    dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
    await consultarOpciones();
    setModalFiltros({ showModal: false });
    dispatch(removeLoading());
  };

  const consultarOpciones = async () => {
    await repository.obtenerPermisos();
    navigate(`/main/docucobra/list`, { replace: true });
  };

  const base64toBlob = (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  return {
    data,
    dataPais,
    countProcess,

    buscarText,
    setBuscarText,

    init,
    navBar,
    onClickItem,

    modalItem,
    onCloseModalItem,
    onClickAbrirResumen,
    onClickAbrirDocumento,
    onClickVerRelacionados,

    modalPDF,
    onCloseModalPDF,

    modalRelacionados,
    onCloseModalRelacionados,

    modalFiltros,
    formFiltros,
    onShowModalFiltros,
    onChangeValueFormFiltros,
    onClickBuscarFiltros,
    onChangeIndexCarouselModalRelacionados,
  };
};
