import { EntityComparative } from "../../../shared/Domain/EntityComparative";
import { EntityFactura } from "../../../shared/Domain/EntityFactura";
import { EntityPettyCashRequest } from "../../../shared/Domain/EntityPettyCashRequest";
import { EntityPurchaseRequirement } from "../../../shared/Domain/EntityPurchaseRequirement";
import { EntityRequestForm } from "../../../shared/Domain/EntityRequestForm";
import { EntitySurrenderPettyCashExpenses } from "../../../shared/Domain/EntitySurrenderPettyCashExpenses";
import { EntitySystemRequirement } from "../../../shared/Domain/EntitySystemRequirement";
import { EntityWorkingCondition } from "../../../shared/Domain/EntityWorkingCondition";
import { EntityAusenciaAprobacion } from "../../../shared/Domain/Tareo/EntityAusenciaAprobacion";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { addCountProcess } from "../../../shared/Infraestructure/SliceGenerico";
import { EntityListContent, EntityProductoGenericDetail, EntityValorizacionGenericDetail } from "../Domain/EntityListContent";
import { EntityRequestAddNote, EntityRequestDetail, NameStore } from "../Domain/EntityRequestDetail";
import { EntityRequestDownloadFile } from "../Domain/EntityRequestDownloadFile";
import { EntityResponseAddNote } from "../Domain/EntityResponse";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async save(params: EntityRequestDetail): Promise<boolean | null> {
        try {
            let response = await this._save(params);
            let rowLocal = await this._saveLocal(params);

            if (response) await this.dbLocal.deleteByIndexStore({ nameStore: params.extraConfig.nameStore, value: rowLocal._id });

            return !!response ? response : rowLocal;
        } catch (error) {
            throw error;
        }
    };

    private async _save(payload: EntityRequestDetail): Promise<boolean | null> {
        if (!navigator.onLine) { this.dispatch(addCountProcess()); return null; }

        if (payload.extraConfig.moduleKey === 'comparative' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarPreOrdenWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'comparative' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarPreOrdenWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'invoice' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarPreRegistroWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'invoice' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarPreRegistroWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }
        
        if (payload.extraConfig.moduleKey === 'system-requirement' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarRqSistemaWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'system-requirement' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarRqSistemaWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'purchase-requirement' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarRqCompraWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'purchase-requirement' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarRqCompraWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'petty-cash-request' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarSolicitudCajaChicaWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'petty-cash-request' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarSolicitudCajaChicaWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'surrender-petty-cash-expenses' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarRendicionCajaChicaReembolsoWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'surrender-petty-cash-expenses' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarRendicionCajaChicaReembolsoWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'request-form' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarHojaPeticionWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'request-form' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarHojaPeticionWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'working-conditions' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarCondicionLaboralWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'working-conditions' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarCondicionLaboralWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'ausencia' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/workflow/ausencia/aprobar`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'ausencia' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/workflow/ausencia/retornar`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        return false;
    }

    private async _saveLocal(payload: EntityRequestDetail): Promise<any> {
        let response = { ...payload.extraConfig.dataComplete, pendingSend: true, dataSend: payload };
        await this.dbLocal.updateByIndexStore({ nameStore: payload.extraConfig.nameStore, value: response });
        return response;
    }

    public async downloadFile(params: EntityRequestDownloadFile): Promise<File | null> {
        let url: string = `${this.urlBaseFile}/Master/File/download`;
        return await this.service.call<File>("POST", url, JSON.stringify(params), "bearer", "json", 'blob', { 'request-decrypt-response': 'yes' }, 0) as File;
    }

    public async getDetailItem(_id: string, nameStore: NameStore): Promise<{ dataFormatted: EntityListContent<any> | null; data: any; }> {
        let response = await this.dbLocal.selectByIndexStore({ nameStore, value: _id });
        let dataFormatted: EntityListContent<any> | null = null;
        if (!response) return { dataFormatted, data: response };

        switch(nameStore) {
            case 'Comparativo': dataFormatted = this.formatComparativeToGenericDetail(response); break;
            case 'Factura': dataFormatted = this.formatInvoiceToGenericDetail(response); break;
            case 'RequerimientoSistema': dataFormatted = this.formatSystemRequirementToGenericDetail(response); break;
            case 'RequerimientoCompra': dataFormatted = this.formatPurcharseRequirementToGenericDetail(response); break;
            case 'SolicitudCajaChica': dataFormatted = this.formatPettyCashRequestToGenericDetail(response); break;
            case 'RendicionGastosCajaChica': dataFormatted = this.formatSurrenderPettyCashExpensesToGenericDetail(response); break;
            case 'HojaPeticion': dataFormatted = this.formatRequestFormToGenericDetail(response); break;
            case 'CondicionLaboral': dataFormatted = this.formatWorkingConditionsToGenericDetail(response); break;
            case 'AusenciaAprobacion': dataFormatted = await this.formatAusenciaToGenericDetail(response); break;
        }
        return ({ dataFormatted, data: response });
    }

    private async formatAusenciaToGenericDetail(payload: EntityAusenciaAprobacion): Promise<EntityListContent<EntityAusenciaAprobacion>> {
        let stepperUser = this.formatStepperUser(payload.Nivel, payload.Niveles);
        let documento: any[] = [];
        let producto: EntityProductoGenericDetail[] = [];
        let hasFilePrincipal = false;

        if (payload.Files.length > 0) {
            documento.push({
                name: 'Doc. Ausencia',
                path: payload.Files[0],
                code: payload.Files[0].fileName,
                principal: true
            })
        }

        return ({
            detail: {
                codigo: payload.Codigo,
                pais: '',
                grupo: '',
                empresa: '',
                ot: `${payload.DatosTrabajo.OT.Codigo} - ${payload.DatosTrabajo.OT.OT}`,
                solicitante: '',
                cargoSolicitante: '',
                proveedor: '',
                tipoPago: '',
                terminoPago: '',
                moneda: '',
                importe: 0,
                comentario: '',
                fechaRecepcion: '',
                formaPago: '',
                pedidoCompra: [],
                isFacturaAsociada: false,
                importeFacturaAsociada: 0,
                tipoFacturaAsociada: '',
                sumaImporteDetalle:0,
                fechaRegistro: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(payload.Dates.Registry.Date ? payload.Dates.Registry.Date : AdapterGenerico.convertDateToString(new Date())), 3)
            },
            producto: producto,
            valorizacion: [],
            rendicion: [],
            notas: [],
            bitacora: [],
            documento: documento,
            distribuccion: [],
            currentStepUser: stepperUser.currentNivel,
            stepperUser: stepperUser.data,
            stepNumberStartUser: stepperUser.stepNumberStart,
            hasFilePrincipal,
            listFileDownloaded: (payload.listFileDownloaded || []),
            alreadyAcceptItem: payload.pendingSend,
            dataComplete: payload
        })
    };

    private formatWorkingConditionsToGenericDetail(payload: EntityWorkingCondition): EntityListContent<EntityWorkingCondition> {
        let stepperUser = this.formatStepperUser(payload.Nivel, payload.Niveles);
        let documento: any[] = [];
        let producto: EntityProductoGenericDetail[] = [];
        let hasFilePrincipal = false;

        if (Array.isArray(payload.Files))
            payload.Files.forEach(row =>
                row.files.forEach((file, index: number) => {
                    if (row.principal && index === 0) hasFilePrincipal = true;
                    documento.push({ autoDownload: !!(row.principal && index === 0), name: row.code === 'Anexo' ? file.text : `${row.text} ${index + 1}`, path: file.path, code: file.code, keyGroup: row.code, textGroup: row.text })
                })
            );

        return ({
            detail: {
                codigo: payload.Codigo,
                pais: payload.DatosPais.Name,
                grupo: payload.DatosEmpresa.Grupo,
                empresa: payload.DatosEmpresa.Empresa,
                ot: `${payload.DatosTrabajo.OT.Codigo} - ${payload.DatosTrabajo.OT.OT}`,
                solicitante: `${payload.DatosSolicitante.Nombres} ${payload.DatosSolicitante.ApellidoPaterno} ${payload.DatosSolicitante.ApellidoMaterno}`,
                cargoSolicitante: payload.DatosSolicitante.Puesto.Name,
                proveedor: '',
                tipoPago: '',
                terminoPago: '',
                moneda: '',
                importe: 0,
                comentario: '',
                fechaRecepcion: '',
                formaPago: '',
                pedidoCompra: [],
                isFacturaAsociada: false,
                importeFacturaAsociada: 0,
                tipoFacturaAsociada: '',
                sumaImporteDetalle:0,
                fechaRegistro: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(payload.Dates.Registry.Date ? payload.Dates.Registry.Date : AdapterGenerico.convertDateToString(new Date())), 3)
            },
            empleado: {
                actual: {
                    delegacion: payload.DatosPersonal.Delegacion.Name,
                    empleado: `${payload.DatosPersonal.Nombres}`,
                    OT: payload.DatosPersonal.OT.Name,
                    puesto: payload.DatosPersonal.Puesto.Name,
                    remuneracionAnual: AdapterGenerico.formatoDinero(payload.DatosPersonal.Remuneracion * 12),
                    remuneracionAnualEuros: AdapterGenerico.formatoDinero((payload.DatosPersonal.Remuneracion * 12) / 4),
                    remuneracionMensual: AdapterGenerico.formatoDinero(payload.DatosPersonal.Remuneracion)
                },
                propuesto: {
                    fechaEfectiva: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(payload.CondicionLaboralSolicitada.FechaEfectiva), 3),
                    justificacionSolicitud: payload.CondicionLaboralSolicitada.Justificacion,
                    motivoAumentoSalarial: payload.CondicionLaboralSolicitada.MotivoAumentoSalarial.Name,
                    OT: payload.CondicionLaboralSolicitada.OT.OT,
                    puesto: payload.CondicionLaboralSolicitada.Puesto.Name,
                    remuneracionAnual: AdapterGenerico.formatoDinero(payload.CondicionLaboralSolicitada.Remuneracion * 12),
                    remuneracionAnualEuros: AdapterGenerico.formatoDinero((payload.CondicionLaboralSolicitada.Remuneracion * 12) / 4),
                    remuneracionMensual: AdapterGenerico.formatoDinero(payload.CondicionLaboralSolicitada.Remuneracion),
                    tipoCoteo: payload.CondicionLaboralSolicitada.TipoCosteo.Name,
                    varianza: AdapterGenerico.formatoDinero(((payload.CondicionLaboralSolicitada.Remuneracion - payload.DatosPersonal.Remuneracion) / payload.DatosPersonal.Remuneracion) * 100)
                }
            },
            producto: producto,
            valorizacion: [],
            rendicion: [],
            notas: (payload.Notas || []).map(row => ({
                observacion: row.Description,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
                usuario: `${row.User.Name} ${row.User.LastName}`,
                action: row.Action
            })).reverse(),
            bitacora: (payload.Bitacora || []).map(row => ({
                accion: row.Action,
                descripcion: row.Description ?? '',
                fecha: row.Date.Date ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3) : '',
                usuario: `${row.User.Name} ${row.User.LastName}`,
            })).reverse(),
            documento: documento,
            distribuccion: [],
            currentStepUser: stepperUser.currentNivel,
            stepperUser: stepperUser.data,
            stepNumberStartUser: stepperUser.stepNumberStart,
            hasFilePrincipal,
            listFileDownloaded: (payload.listFileDownloaded || []),
            alreadyAcceptItem: payload.pendingSend,
            dataComplete: payload
        })
    };

    private formatRequestFormToGenericDetail(payload: EntityRequestForm): EntityListContent<EntityRequestForm> {
        let stepperUser = this.formatStepperUser(payload.Nivel, payload.Niveles);
        let documento: any[] = [];
        let producto: EntityProductoGenericDetail[] = [];
        let hasFilePrincipal = false;

        if (Array.isArray(payload.Files))
            payload.Files.forEach(row =>
                row.files.forEach((file: any, index: number) => {
                    if (row.principal && index === 0) hasFilePrincipal = true;
                    documento.push({ autoDownload: !!(row.principal && index === 0), name: row.code === 'Anexo' ? file.text : `${row.text} ${index + 1}`, path: file.path, code: file.code, keyGroup: row.code, textGroup: row.text })
                })
            );

        return ({
            detail: {
                codigo: payload.Codigo,
                pais: payload.DatosPais.Name,
                grupo: payload.DatosEmpresa.Grupo,
                empresa: payload.DatosEmpresa.Empresa,
                ot: `${payload.DatosTrabajo.OT.Codigo} - ${payload.DatosTrabajo.OT.OT}`,
                solicitante: `${payload.DatosSolicitante.Nombres} ${payload.DatosSolicitante.ApellidoPaterno} ${payload.DatosSolicitante.ApellidoMaterno}`,
                cargoSolicitante: payload.DatosSolicitante.Puesto.Name,
                observaciones: payload.DatosGenerales.Observacion,
                proveedor: '',
                tipoPago: '',
                terminoPago: '',
                moneda: '',
                importe: 0,
                comentario: '',
                fechaRecepcion: '',
                formaPago: '',
                pedidoCompra: [],
                isFacturaAsociada: false,
                importeFacturaAsociada: 0,
                tipoFacturaAsociada: '',
                sumaImporteDetalle:0,
                fechaRegistro: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(payload.Dates.Registry.Date ? payload.Dates.Registry.Date : AdapterGenerico.convertDateToString(new Date())), 3)
            },
            personal: {
                bonoTurnoNoche: payload.DatosDirecto.BonoTurnoNoche ? 'SI' : 'NO',
                bonoTurnos: payload.DatosDirecto.BonoTurnos ? 'SI' : 'NO',
                fechaAlta: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(payload.Dates.FechaAlta.Date), 3),
                fechaBaja: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(payload.Dates.FechaBaja.Date), 3),
                funciones: payload.DatosGenerales.Funciones,
                horaExtra: payload.DatosDirecto.HorasExtras ? 'SI' : 'NO',
                identificacion: payload.DatosDirecto.Identificacion,
                modalidadTrabajo: payload.DatosGenerales.ModalidadTrabajo.Name,
                motivoSolicitud: payload.DatosGenerales.MotivoSolicitud.Name,
                nombreCompleto: `${payload.DatosDirecto.Nombres} ${payload.DatosDirecto.ApellidoPaterno} ${payload.DatosDirecto.ApellidoMaterno}`,
                primaProduccion: payload.DatosDirecto.PrimasProduccion ? 'SI' : 'NO',
                puesto: payload.DatosDirecto.Puesto.Name,
                retribucionAnual: AdapterGenerico.formatoDinero(parseInt(payload.DatosIndirecto.Retribucion) * 12),
                retribucionMensual: AdapterGenerico.formatoDinero(parseInt(payload.DatosIndirecto.Retribucion)),
                tipoContrato: payload.DatosGenerales.TipoContrato.Name,
                tipoDocumento: payload.DatosDirecto.TipoDocumento.Name,
                vehiculoEmpresa: payload.DatosDirecto.VehiculoEmpresa ? 'SI' : 'NO',
                vehiculoPropio: payload.DatosDirecto.VehiculoPropio ? 'SI' : 'NO',
                nuevoIngreso: !payload.DatosIndirecto.CandidatoExiste
            },
            producto: producto,
            valorizacion: [],
            rendicion: [],
            notas: (payload.Notas || []).map(row => ({
                observacion: row.Description,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
                usuario: `${row.User.Name} ${row.User.LastName}`,
                action: row.Action
            })).reverse(),
            bitacora: (payload.Bitacora || []).map(row => ({
                accion: row.Action,
                descripcion: row.Description ?? '',
                fecha: row.Date.Date ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3) : '',
                usuario: `${row.User.Name} ${row.User.LastName}`,
            })).reverse(),
            documento: documento,
            distribuccion: [],
            currentStepUser: stepperUser.currentNivel,
            stepperUser: stepperUser.data,
            stepNumberStartUser: stepperUser.stepNumberStart,
            hasFilePrincipal,
            listFileDownloaded: (payload.listFileDownloaded || []),
            alreadyAcceptItem: payload.pendingSend,
            dataComplete: payload
        })
    };

    private formatSurrenderPettyCashExpensesToGenericDetail(payload: EntitySurrenderPettyCashExpenses): EntityListContent<EntitySurrenderPettyCashExpenses> {
        let stepperUser = this.formatStepperUser(payload.Nivel, payload.Niveles);
        let documento: any[] = [];
        let producto: EntityProductoGenericDetail[] = [];
        let hasFilePrincipal = false;

        if (Array.isArray(payload.Files))
            payload.Files.forEach(row =>
                row.files.forEach((file: any, index: number) => {
                    if (row.principal && index === 0) hasFilePrincipal = true;
                    documento.push({ autoDownload: !!(row.principal && index === 0), name: row.code === 'Anexo' ? file.text : `${row.text} ${index + 1}`, path: file.path, code: file.code, keyGroup: row.code, textGroup: row.text })
                })
            );

        return ({
            detail: {
                codigo: payload.Codigo,
                pais: payload.DatosPais.Name,
                grupo: payload.DatosEmpresa.Grupo,
                empresa: payload.DatosEmpresa.Empresa,
                proveedor: payload.DatosPersonal.FullName,
                ot: `${payload.DatosTrabajo.OT.Codigo} - ${payload.DatosTrabajo.OT.OT}`,
                tipoPago: '',
                terminoPago: '',
                moneda: payload.DatosImporte.Moneda.Moneda,
                importe: payload.DatosImporte.Solicitud.ImporteTotal,
                comentario: '',
                fechaRecepcion: '',
                formaPago: '',
                pedidoCompra: [],
                isFacturaAsociada: false,
                importeFacturaAsociada: 0,
                tipoFacturaAsociada: '',
                sumaImporteDetalle:0,
                fechaRegistro: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(payload.Dates.Registry.Date ? payload.Dates.Registry.Date : AdapterGenerico.convertDateToString(new Date())), 3)
            },
            producto: producto,
            valorizacion: [],
            rendicion: (payload.Documentos || []).map((row, index) => ({
                id: index,
                importe: AdapterGenerico.formatoDinero(row.DatosImporte.MontoTotal),
                importeTypeNumber: row.DatosImporte.MontoTotal,
                nombreServicio: row.DatosServicio.Servicio,
                razonSocial: row.DatosEmpresa.RazonSocial,
            })),
            notas: (payload.Notas || []).map(row => ({
                observacion: row.Description,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
                usuario: `${row.User.Name} ${row.User.LastName}`,
                action: row.Action
            })).reverse(),
            bitacora: (payload.Bitacora || []).map(row => ({
                accion: row.Action,
                descripcion: row.Description ?? '',
                fecha: row.Date.Date ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3) : '',
                usuario: `${row.User?.Name} ${row.User?.LastName}`,
            })).reverse(),
            documento: documento,
            distribuccion: [],
            currentStepUser: stepperUser.currentNivel,
            stepperUser: stepperUser.data,
            stepNumberStartUser: stepperUser.stepNumberStart,
            hasFilePrincipal,
            listFileDownloaded: (payload.listFileDownloaded || []),
            alreadyAcceptItem: payload.pendingSend,
            dataComplete: payload
        })
    };

    private formatPettyCashRequestToGenericDetail(payload: EntityPettyCashRequest): EntityListContent<EntityPettyCashRequest> {
        let stepperUser = this.formatStepperUser(payload.Nivel, payload.Niveles);
        let documento: any[] = [];
        let producto: EntityProductoGenericDetail[] = [];
        let hasFilePrincipal = false;

        if (Array.isArray(payload.Files))
            payload.Files.forEach(row =>
                row.files.forEach((file: any, index: number) => {
                    if (row.principal && index === 0) hasFilePrincipal = true;
                    documento.push({ autoDownload: !!(row.principal && index === 0), name: row.code === 'Anexo' ? file.text : `${row.text} ${index + 1}`, path: file.path, code: file.code, keyGroup: row.code, textGroup: row.text })
                })
            );

        return ({
            detail: {
                codigo: payload.Codigo,
                pais: payload.DatosPais.Name,
                grupo: payload.DatosEmpresa.Grupo,
                empresa: payload.DatosEmpresa.Empresa,
                proveedor: payload.DatosPersonal.FullName,
                ot: `${payload.DatosTrabajo.OT.Codigo} - ${payload.DatosTrabajo.OT.OT}`,
                tipoPago: '',
                terminoPago: '',
                moneda: payload.DatosImporte.Moneda.Moneda,
                importe: payload.DatosImporte.Solicitud.Importe,
                comentario: '',
                fechaRecepcion: '',
                formaPago: '',
                pedidoCompra: [],
                isFacturaAsociada: false,
                importeFacturaAsociada: 0,
                tipoFacturaAsociada: '',
                sumaImporteDetalle:0,
                fechaRegistro: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(payload.Dates.Registry.Date ? payload.Dates.Registry.Date : AdapterGenerico.convertDateToString(new Date())), 3)
            },
            producto: producto,
            valorizacion: [],
            rendicion: [],
            notas: (payload.Notas || []).map(row => ({
                observacion: row.Description,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
                usuario: `${row.User.Name} ${row.User.LastName}`,
                action: row.Action
            })).reverse(),
            bitacora: (payload.Bitacora || []).map(row => ({
                accion: row.Action,
                descripcion: row.Description ?? '',
                fecha: row.Date.Date ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3) : '',
                usuario: `${row.User.Name} ${row.User.LastName}`,
            })).reverse(),
            documento: documento,
            distribuccion: [],
            currentStepUser: stepperUser.currentNivel,
            stepperUser: stepperUser.data,
            stepNumberStartUser: stepperUser.stepNumberStart,
            hasFilePrincipal,
            listFileDownloaded: (payload.listFileDownloaded || []),
            alreadyAcceptItem: payload.pendingSend,
            dataComplete: payload
        })
    };

    private formatPurcharseRequirementToGenericDetail(payload: EntityPurchaseRequirement): EntityListContent<EntityPurchaseRequirement> {
        let stepperUser = this.formatStepperUser(payload.Nivel, payload.Niveles);
        let documento: any[] = [];
        let hasFilePrincipal = false;

        if (Array.isArray(payload.Files))
            payload.Files.forEach(row =>
                row.files.forEach((file: any, index: number) => {
                    if (row.principal && index === 0) hasFilePrincipal = true;
                    documento.push({ autoDownload: !!(row.principal && index === 0), name: row.code === 'Anexo' ? file.text : `${row.text} ${index + 1}`, path: file.path, code: file.code, keyGroup: row.code, textGroup: row.text })
                })
            );

        return ({
            detail: {
                codigo: payload.Codigo,
                pais: payload.DatosPais.Name,
                grupo: payload.DatosEmpresa.Grupo,
                empresa: payload.DatosEmpresa.Empresa,
                proveedor: `${payload.Users.Registry.Name} ${payload.Users.Registry.LastName}`,
                ot: `${payload.DatosTrabajo.OT.Codigo} - ${payload.DatosTrabajo.OT.OT}`,
                tipoPago: '',
                terminoPago: '',
                moneda: '',
                importe: 0,
                comentario: '',
                fechaRecepcion: '',
                formaPago: '',
                pedidoCompra: [],
                isFacturaAsociada: false,
                importeFacturaAsociada: 0,
                tipoFacturaAsociada: '',
                sumaImporteDetalle:0,
            },
            producto: (payload.DetalleProductos || []).map(row => ({
                id: `${row.Id}`,
                descripcion: row.DescripcionADD,
                cantidad: row.Cantidad,
                unidad: row.Unidad,
                precioUnitario: '',
                subTotal: '',
                subTotalTypeNumber: 0
            })),
            valorizacion: [],
            rendicion: [],
            notas: (payload.Notas || []).map(row => ({
                observacion: row.Description,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
                usuario: `${row.User.Name} ${row.User.LastName}`,
                action: row.Action
            })).reverse(),
            bitacora: (payload.Bitacora || []).map(row => ({
                accion: row.Action,
                descripcion: row.Description ?? '',
                fecha: row.Date.Date ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3) : '',
                usuario: `${row.User.Name} ${row.User.LastName}`,
            })).reverse(),
            documento: documento,
            distribuccion: [],
            currentStepUser: stepperUser.currentNivel,
            stepperUser: stepperUser.data,
            stepNumberStartUser: stepperUser.stepNumberStart,
            hasFilePrincipal,
            listFileDownloaded: (payload.listFileDownloaded || []),
            alreadyAcceptItem: payload.pendingSend,
            dataComplete: payload
        })
    };

    private formatSystemRequirementToGenericDetail(payload: EntitySystemRequirement): EntityListContent<EntitySystemRequirement> {
        let stepperUser = this.formatStepperUser(payload.Nivel, payload.Niveles);
        let documento: any[] = [];
        let producto: EntityProductoGenericDetail[] = [];
        let hasFilePrincipal = false;

        if (Array.isArray(payload.Files))
            payload.Files.forEach(row =>
                row.files.forEach((file: any, index: number) => {
                    if (row.principal && index === 0) hasFilePrincipal = true;
                    documento.push({ autoDownload: !!(row.principal && index === 0), name: row.code === 'Anexo' ? file.text : `${row.text} ${index + 1}`, path: file.path, code: file.code, keyGroup: row.code, textGroup: row.text })
                })
            );
        
        if (Array.isArray(payload.DatosPersonal))
            payload.DatosPersonal.forEach(row => 
                row.DatosProductos.forEach(rowProducto => {
                    producto.push({
                        id: rowProducto.Id,
                        descripcion: rowProducto.Producto,
                        cantidad: rowProducto.Cantidad,
                        unidad: rowProducto.Unidad,
                        precioUnitario: '0',
                        subTotal: '0',
                        subTotalTypeNumber: 0,
                        personal: row.FullName
                    })
                })    
            )

        return ({
            detail: {
                codigo: payload.Codigo,
                pais: payload.DatosPais.Name,
                grupo: payload.DatosEmpresa.Grupo,
                empresa: payload.DatosEmpresa.Empresa,
                proveedor: `${payload.Users.Registry.Name} ${payload.Users.Registry.LastName}`,
                ot: `${payload.DatosTrabajo.OT.Codigo} - ${payload.DatosTrabajo.OT.OT}`,
                tipoPago: '',
                terminoPago: '',
                moneda: '',
                importe: 0,
                comentario: '',
                fechaRecepcion: '',
                formaPago: '',
                pedidoCompra: [],
                isFacturaAsociada: false,
                importeFacturaAsociada: 0,
                tipoFacturaAsociada: '',
                sumaImporteDetalle:0,
            },
            producto: producto,
            valorizacion: [],
            rendicion: [],
            notas: (payload.Notas || []).map(row => ({
                observacion: row.Description,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
                usuario: `${row.User.Name} ${row.User.LastName}`,
                action: row.Action
            })).reverse(),
            bitacora: (payload.Bitacora || []).map(row => ({
                accion: row.Action,
                descripcion: row.Description ?? '',
                fecha: row.Date.Date ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3) : '',
                usuario: `${row.User.Name} ${row.User.LastName}`,
            })).reverse(),
            documento: documento,
            distribuccion: [],
            currentStepUser: stepperUser.currentNivel,
            stepperUser: stepperUser.data,
            stepNumberStartUser: stepperUser.stepNumberStart,
            hasFilePrincipal,
            listFileDownloaded: (payload.listFileDownloaded || []),
            alreadyAcceptItem: payload.pendingSend,
            dataComplete: payload
        })
    };

    private formatComparativeToGenericDetail(payload: EntityComparative): EntityListContent<EntityComparative> {
        let stepperUser = this.formatStepperUser(payload.Nivel, payload.Niveles);
        let documento: any[] = [];
        let hasFilePrincipal = false;

        if (Array.isArray(payload.Files))
            payload.Files.forEach(row =>
                row.files.forEach((file, index) => {
                    if (row.principal && index === 0) hasFilePrincipal = true;
                    documento.push({ autoDownload: !!(row.principal && index === 0), name: row.code === 'Anexo' ? file.text : `${row.text} ${index + 1}`, path: file.path, code: file.code, keyGroup: row.code, textGroup: row.text })
                })
            );

        return ({
            detail: {
                codigo: payload.Codigo,
                pais: payload.DatosPais.Name,
                grupo: payload.DatosEmpresa.Grupo,
                empresa: payload.DatosEmpresa.Empresa,
                proveedor: payload.EmpresaGanadora.Empresa,
                ot: `${payload.DatosTrabajo.OT.Codigo} - ${payload.DatosTrabajo.OT.OT}`,
                tipoPago: payload.DatosPago.FormaPago.Codigo,
                terminoPago: payload.DatosPago.TerminoPago.Codigo,
                moneda: payload.DatosPago.Moneda.Descripcion,
                importe: payload.DatosPago.Importe.Monto,
                comentario: payload.DatosPago.Comentarios,
                fechaRecepcion: '',
                formaPago: '',
                pedidoCompra: [],
                isFacturaAsociada: false,
                importeFacturaAsociada: 0,
                tipoFacturaAsociada: '',
                sumaImporteDetalle: 0,
                global: payload.Global,
                multiProveedor: payload.Multiproveedor,
            },
            producto: (payload.DetalleProductos || []).map(row => ({
                id: row.Id,
                descripcion: row.Producto.DescripcionADD || row.Producto.Producto,
                cantidad: row.Producto.Cantidad,
                unidad: row.Producto.Unidad,
                precioUnitario: AdapterGenerico.formatoDinero(row.Producto.Precio),
                subTotal: AdapterGenerico.formatoDinero(row.Producto.Cantidad * row.Producto.Precio),
                subTotalTypeNumber: row.Producto.Cantidad * row.Producto.Precio,
                dataComplete: row
            })),
            valorizacion: [],
            rendicion: [],
            notas: (payload.Notas || []).map(row => ({
                observacion: row.Description,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
                usuario: `${row.User.Name} ${row.User.LastName}`,
                action: row.Action
            })).reverse(),
            bitacora: (payload.Bitacora || []).map(row => ({
                accion: row.Action,
                descripcion: row.Description ?? '',
                fecha: row.Date.Date ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3) : '',
                usuario: `${row.User.Name} ${row.User.LastName}`,
            })).reverse(),
            documento: documento,
            distribuccion: [],
            currentStepUser: stepperUser.currentNivel,
            stepperUser: stepperUser.data,
            stepNumberStartUser: stepperUser.stepNumberStart,
            hasFilePrincipal,
            listFileDownloaded: (payload.listFileDownloaded || []),
            alreadyAcceptItem: payload.pendingSend,
            dataComplete: payload
        })
    };

    private formatInvoiceToGenericDetail(payload: EntityFactura): EntityListContent<EntityFactura> {
        let stepperUser = this.formatStepperUser(payload.Nivel, payload.Niveles);
        let documento: any[] = [];
        let hasFilePrincipal = false;
        let importeAsociado: number = 0;

        let currentFile = ((payload.Contrato || []).flatMap(row => (row.Valorizaciones || []).flatMap(_row => _row.FacturasAsociados))).find(row => row.Factura === payload.Codigo);

        if (Array.isArray(payload.Files))
            payload.Files.forEach(row =>
                row.files.forEach((file, index) => {
                    if (row.code === "Valorizacion" && file.text !== currentFile?.NroDocumento) return; 
                    if (row.principal && index === 0) hasFilePrincipal = true;
                    documento.push({ autoDownload: (row.principal && index === 0), name: row.code === 'Anexo' ? file.text : `${row.text} ${index + 1}`, path: file.path, code: file.code, keyGroup: row.code, textGroup: row.text })
                })
            );

        let valorizacion: EntityValorizacionGenericDetail[] = [];

        if (payload.TipoEmision === '(Recepciones)')
            payload.Emisiones?.forEach(row => {
                row.FacturasAsociados?.forEach((itemFactura, index) => {
                    if (itemFactura.Factura !== payload.Codigo) return;
                    if (payload.DatosPais.Code === 'PE') importeAsociado += itemFactura.importeAsociado;
                    itemFactura.Asociacion?.forEach(itemValorizacion => {
                        let priceWithDiscount = (itemValorizacion.PrecioFinal - (itemValorizacion.PrecioFinal * itemValorizacion.discountLine));
                        valorizacion.push({
                            id: itemValorizacion.Movimiento.Id,
                            serie: itemFactura.Serie,
                            numeroDocumento: itemFactura.NroDocumento,
                            descripcion: itemValorizacion.Movimiento.DescripcionADD,
                            cantidad: itemValorizacion.CantidadAsociada,
                            precioUnitario: AdapterGenerico.formatoDinero(priceWithDiscount),
                            subTotal: AdapterGenerico.formatoDinero(itemValorizacion.CantidadAsociada * priceWithDiscount),
                            subTotalTypeNumber: itemValorizacion.CantidadAsociada * priceWithDiscount,
                        })
                    })
                });
            });

        if (payload.TipoEmision === '(Valorizaciones)') {
            payload.Contrato?.forEach(row => {
                row.Valorizaciones?.forEach(itemValorizacion => {
                    itemValorizacion.FacturasAsociados?.forEach((itemFacturaAsociado, index) => {
                        if (itemFacturaAsociado.Factura !== payload.Codigo) return;
                        valorizacion.push({
                            id: index,
                            numeroValorizacion: itemValorizacion.CodeValorizacion.slice(itemValorizacion.CodeValorizacion.length - 4),
                            contrato: itemValorizacion.CodeValorizacion.slice(0, itemValorizacion.CodeValorizacion.length - 5),
                            servicio: row.DatosContrato.Service.Descripcion,
                            monto: AdapterGenerico.formatoDinero(itemFacturaAsociado.importeAsociado),
                            subTotalTypeNumber: itemFacturaAsociado.importeAsociado
                        })
                    })
                })
            })
        }

        return ({
            detail: {
                codigo: payload.Codigo,
                pais: payload.DatosPais.Name,
                grupo: payload.DatosEmpresa.Grupo,
                empresa: payload.DatosEmpresa.Empresa,
                proveedor: payload.DatosPago.Empresa.Name,
                ot: `${payload.DatosTrabajo.OT.Codigo} - ${payload.DatosTrabajo.OT.OT}`,
                tipoPago: payload.DatosPago.FormaPago.Codigo,
                terminoPago: payload.DatosPago.TerminoPago.Codigo,
                moneda: payload.Moneda,
                importe: payload.DatosPago.Importe.Monto,
                comentario: '',
                fechaRecepcion: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(payload.Dates.Recepcion.Date), 3),
                formaPago: payload.DatosPago.FormaPago.Codigo,
                pedidoCompra: Array.isArray(payload.DatosDocumento.TipoCompra.NroDocumento) ? payload.DatosDocumento.TipoCompra.NroDocumento : [payload.DatosDocumento.TipoCompra.NroDocumento],
                isFacturaAsociada: payload.FacturaAsociada,
                importeFacturaAsociada: payload.SumaEmitido,
                tipoFacturaAsociada: payload.TipoEmision,
                sumaImporteDetalle: (payload.DistribucionOT || []).reduce((prev,currentValue)=> prev+currentValue.Importe,0),
                importeAsociado
            },
            producto: [],
            valorizacion,
            rendicion: [],
            distribuccion: (payload.DistribucionOT || []).map(row=>({
                cuenta:"",
                ot: row.OT?.Codigo || (typeof row.OT === 'string' ? row.OT : ''),
                importe: AdapterGenerico.formatoDinero(row.Importe) as any
            })),
            notas: (payload.Notas || []).map(row => ({
                observacion: row.Description,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
                usuario: `${row.User.Name} ${row.User.LastName}`,
                action: row.Action
            })).reverse(),
            bitacora: (payload.Bitacora || []).map(row => ({
                accion: row.Action,
                descripcion: row.Description ?? '',
                fecha: row.Date.Date ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3) : '',
                usuario: `${row.User.Name} ${row.User.LastName}`,
            })).reverse(),
            documento: documento,
            currentStepUser: stepperUser.currentNivel,
            stepperUser: stepperUser.data,
            stepNumberStartUser: stepperUser.stepNumberStart,
            hasFilePrincipal,
            listFileDownloaded: (payload.listFileDownloaded || []),
            alreadyAcceptItem: payload.pendingSend,
            dataComplete: payload
        })
    }

    private formatStepperUser(currentNivel: number, data?: any[]): { currentNivel: number, data: any[], stepNumberStart: number } {
        if (!data) return { currentNivel: 0, data: [], stepNumberStart: 0 };
        let newCurrentNivel = (currentNivel + 1);
        let stepperUser: any[] = [];
        let positionStepper = 0;
        let maximoStepperMostrar = 2;

        if (newCurrentNivel === data.length && stepperUser.length === 0) {
            // si el nivel esta al final
            data.reverse().forEach((row, index) => {
                if (index <= maximoStepperMostrar)
                    stepperUser.push({
                        label: row.Perfil.Perfil,
                        description: `${row.Aprobador.Name} ${row.Aprobador.LastNameFather}`
                    });
            });
            stepperUser = stepperUser.reverse();
            positionStepper = stepperUser.length;
        }

        if (newCurrentNivel === 1 && stepperUser.length === 0) {
            // si el nivel esta al principio
            data.forEach((row, index) => {
                if (index <= maximoStepperMostrar)
                    stepperUser.push({
                        label: row.Perfil.Perfil,
                        description: `${row.Aprobador.Name} ${row.Aprobador.LastNameFather}`
                    });
            });
            positionStepper = 1;
        }

        if (newCurrentNivel > 1 && newCurrentNivel < data.length && stepperUser.length === 0) {
            // si el nivel esta en medio
            data.forEach((row, index) => {
                if ([(currentNivel + 1), (currentNivel - 1), currentNivel].some(nivel => nivel === index))
                    stepperUser.push({
                        label: row.Perfil.Perfil,
                        description: `${row.Aprobador.Name} ${row.Aprobador.LastNameFather}`
                    });
            });
            positionStepper = 2;
        }

        if (stepperUser.length === 0) {
            // si el nivel sobrepasa el arreglo
            return ({
                currentNivel: 1,
                data: data.filter((row, index) => index <= 2).map(row => ({ 
                    label: row.Perfil.Perfil,
                    description: `${row.Aprobador.Name} ${row.Aprobador.LastNameFather}`
                })),
                stepNumberStart: 0,
            })
        }

        return ({
            currentNivel: positionStepper,
            data: stepperUser,
            stepNumberStart: newCurrentNivel - positionStepper,
        });
    }

    public async addNote(payload: EntityRequestAddNote): Promise<EntityResponseAddNote | null> {
        if (payload.extraConfig.moduleKey === 'comparative') {
            let url: string = `${this.urlBase}/navision/agregarNotaPreOrdenWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'invoice') {
            let url: string = `${this.urlBase}/navision/agregarNotaPreRegistroWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }
        
        if (payload.extraConfig.moduleKey === 'system-requirement') {
            let url: string = `${this.urlBase}/navision/agregarNotaRqSistemaWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'purchase-requirement') {
            let url: string = `${this.urlBase}/navision/agregarNotaRqCompraWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'petty-cash-request') {
            let url: string = `${this.urlBase}/navision/agregarNotaSolicitudCajaChicaWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'surrender-petty-cash-expenses') {
            let url: string = `${this.urlBase}/navision/agregarNotaRendicionCajaChicaReembolsoWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        return null;
    }
}