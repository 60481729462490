import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import { ViewAsociarGasto } from './Components/Modal/ViewAsociarGasto';
import { ViewFormGasto } from './Components/Modal/ViewFormGasto';
import { ViewFlujoOT } from './Components/Modal/ViewFlujoOT';
import { ViewNote } from './Components/Modal/ViewNote';


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ViewMain
                configForm={controller.configForm}
                formikForm={controller.formikForm}
                languageTranslate={controller.languageTranslate}
                onCancelForm={controller.onCancelForm}
                onChange={controller.onChange}
                onSubmit={controller.onSubmit}
                onChangeStep={controller.onChangeStep}
                openModalAsociar={controller.openModalAsociar}
                openModalForm={controller.openModalForm}
                openModalFlujo={controller.openModalFlujo}
                onDeleteMF={controller.onDeleteMF}
                openModalNotes={controller.openModalNotes}
            />
            <ViewAsociarGasto
                closeModalAsociar={controller.closeModalAsociar}
                formikForm={controller.formikForm}
                configModalAsociar={controller.configModalAsociar}
                onChangeItemMA={controller.onChangeItemMA}
                onChangeSearchMA={controller.onChangeSearchMA}
                onSubmitMA={controller.onSubmitMA}
                onSubmitSearchMA={controller.onSubmitSearchMA}
                languageTranslate={controller.languageTranslate}
            />
            <ViewFormGasto
                languageTranslate={controller.languageTranslate}
                configModalForm={controller.configModalForm}
                component={controller.componentFormGasto}
                closeModalForm={controller.closeModalForm}
                onSubmitMF={controller.onSubmitMF}
            />
            <ViewFlujoOT
                closeModalFlujo={controller.closeModalFlujo}
                configModalFlujo={controller.configModalFlujo}
                formikForm={controller.formikForm}
                onSubmitMFL={controller.onSubmitMFL}
                onChangeMFL={controller.onChangeMFL}
                languageTranslate={controller.languageTranslate}
            />
            <ViewNote
                closeModalNotes={controller.closeModalNotes}
                configModalNote={controller.configModalNotes}
                languageTranslate={controller.languageTranslate}
                onChangeMN={controller.onChangeMN}
                onSubmitMN={controller.onSubmitMN}
            />
        </>
    );
};

export default ManagerEntity;