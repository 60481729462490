import React from "react";
import './ElementChartCostume.scss';

interface ContainerProps {
  data: {
    percentage: number;
    label: string;
    color: string;
  }[];
  total: number;
  children: any;
}

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
};

// Configurar tamaño del chart
const heightChart = 160;
const centerHeightChart = heightChart / 2;

export const ElementChartCostume = (props: ContainerProps) => {

    const renderSlices = () => {
        let currentAngle = 0;
    
        return props.data.map((item, index) => {
            const slicePercentage = (item.percentage / props.total) * 360;
            const largeArcFlag = slicePercentage >= 180 ? 1 : 0;
            const sweepFlag = 1;
        
            const start = polarToCartesian(centerHeightChart, centerHeightChart, centerHeightChart, currentAngle);
            const end = polarToCartesian(centerHeightChart, centerHeightChart, centerHeightChart, currentAngle + slicePercentage);
      
            let pathData = 
            item.percentage === props.total ?
                [
                    'M', heightChart, centerHeightChart,
                    'A', centerHeightChart, centerHeightChart, 0, 1, 1, 0, centerHeightChart,
                    'A', centerHeightChart, centerHeightChart, 0, 1, 1, heightChart, centerHeightChart,
                    'Z'
                ].join(' ')
                :
                [
                    'M', start.x, start.y,
                    'A', centerHeightChart, centerHeightChart, 0, largeArcFlag, sweepFlag, end.x, end.y,
                    'L', centerHeightChart, centerHeightChart,
                    'Z'
                ].join(' ')
      
            currentAngle += slicePercentage;
      
            return (
                <path
                key={index}
                d={pathData}
                fill={item.color}
                />
            );
        });
    };

    return (
        <div className="ElementChartCostume">
            <svg width={heightChart} height={heightChart}>
                <g> { renderSlices() } </g>
            </svg>
            { props.children }
        </div>
    );
};
