import * as React from "react";
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";

const ManagerEntity = () => {
  const controller = Controller();

  React.useEffect(() => {
    controller.init();
    // eslint-disable-next-line
  }, [controller.countProcess]);

  return (
    <ViewMain
      data={controller.data}
      dataPais={controller.dataPais}
      navBar={controller.navBar}
      buscarText={controller.buscarText}
      setBuscarText={controller.setBuscarText}
      onClickItem={controller.onClickItem}
      modalItem={controller.modalItem}
      onCloseModalItem={controller.onCloseModalItem}
      onClickAbrirResumen={controller.onClickAbrirResumen}
      onClickAbrirDocumento={controller.onClickAbrirDocumento}
      onClickVerRelacionados={controller.onClickVerRelacionados}
      modalPDF={controller.modalPDF}
      onCloseModalPDF={controller.onCloseModalPDF}
      modalRelacionados={controller.modalRelacionados}
      onCloseModalRelacionados={controller.onCloseModalRelacionados}
      onChangeIndexCarouselModalRelacionados={
        controller.onChangeIndexCarouselModalRelacionados
      }
      modalFiltros={controller.modalFiltros}
      formFiltros={controller.formFiltros}
      onShowModalFiltros={controller.onShowModalFiltros}
      onChangeValueFormFiltros={controller.onChangeValueFormFiltros}
      onClickBuscarFiltros={controller.onClickBuscarFiltros}
    />
  );
};

export default ManagerEntity;
