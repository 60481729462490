import { EntityTareoAprobacion } from "../../../../shared/Domain/Tareo/EntityTareoAprobacion";

export interface EntityNavBar {
    text: string;
    navigate: boolean;
    path: string;
}

export interface EntityConfigForm {
    navBar: EntityNavBar[];
    modeForm: 'preview';
    data: EntityTareoAprobacion;
}

export interface EntityFormModalDetalle {
    PositionTarea: number;
    OT: string;
    Disciplina: string;
    IdDia: number;
    Id: number;
    Horas: number;
}

export const initEntityConfigForm: EntityConfigForm = {
    navBar: [],
    modeForm: "preview",
    data: {
        _id: "",
        Codigo: "",
        IdTareo: 0,
        pendingSend: false,
        Periodo: {
            Codigo: "",
            FechaInicio: "",
            FechaFinal: "",
            Criterio: ""
        },
        TipoOperacion: {
            IdTipoOperacion: 0,
            Codigo: "",
            Code: "",
            TipoOperacion: ""
        },
        DatosTrabajo: {
            Delegacion: {
                Code: "",
                Codigo: "",
                Delegacion: ""
            },
            OT: {
                Code: "",
                Codigo: "",
                OT: ""
            }
        },
        DatosPais: {
            CDPais: "",
            Code: "",
            Name: ""
        },
        Grupo: {
            Codigo: ""
        },
        DatosEmpresa: {
            CodEmpresa: "",
            CDEmpresa: "",
            Empresa: ""
        },
        Semanas: 0,
        CantidadHoras: 0,
        Observacion: "",
        Tareas: [],
        Bitacora: [],
        Dates: {
            Registry: {
                IdDate: 0,
                Date: ""
            },
            Update: {
                Year: 0,
                IdDate: 0,
                Fecha: ""
            },
            PreAccepted: {
                IdDate: 0,
                Date: ""
            },
            Canceled: {
                IdDate: 0,
                Date: ""
            }
        },
        Status: {
            IdStatus: 0,
            Status: ""
        },
        Notas: [],
        Firma: [],
        Personal: {
            IdUser: 0,
            LastFather: "",
            LastMother: "",
            Name: "",
            User: ""
        },
        Users: {
            Registry: {
                IdUser: 0,
                User: "",
                Perfil: "",
                LastFather: "",
                LastMother: "",
                Name: ""
            },
            Update: {
                IdUser: 0,
                User: "",
                LastName: "",
                Name: "",
                Perfil: ""
            },
            PreAccepted: {
                IdUser: 0,
                User: "",
                LastName: "",
                Name: "",
                Perfil: ""
            },
            Canceled: {
                IdUser: 0,
                User: "",
                LastName: "",
                Name: "",
                Perfil: ""
            }
        },
        Aprobacion: {
            IdNivel: 0,
            Nivel: 0,
            Monto: 0,
            Status: {
                IdStatus: 0,
                Status: ""
            },
            Perfil: {
                IdPerfil: 0,
                Perfil: "",
                Initial: ""
            },
            Aprobador: {
                LastNameFather: "",
                LastNameMother: "",
                Name: "",
                Identificacion: "",
                Status: {
                    IdStatus: 0,
                    Status: ""
                },
                mail: "",
                name: "",
                FullName: "",
                statusMail: 0
            },
            StatusSendMail: false
        },
        Nivel: 0,
        Niveles: [],
        Files: [],
        Dias: []
    }
}

export interface EntityConfigModalDetalle {
    show: boolean;
    title: string;
    IdDia: number;
    ListDetail: {
        OT: string;
        Disciplina: string;
        IdDia: number;
        Horas: number;
    }[];
    message: string;
}

export const initEntityConfigModalDetalle: EntityConfigModalDetalle = {
    show: false,
    title: '',
    IdDia: 0,
    ListDetail: [],
    message: ''
}

export interface EntityConfigModalMessageReject {
    title: string;
    comment: string;
    description: string;
    show: boolean;
}

export const initEntityConfigModalMessageReject: EntityConfigModalMessageReject = {
    title: "",
    comment: "",
    description: "",
    show: false
}

export interface EntityFormModalNotes {
    show: boolean;
    note: string;
}

export const initEntityFormModalNotes: EntityFormModalNotes = {
    show: false,
    note: ''
}
