// Adapters
import { RepositoryImplGenerico } from "../../../../../shared/Infraestructure/RepositoryImplGenerico";
import { AdapterData } from "../../../../../shared/Infraestructure/AdapterData";

// Domain
import { EntityServicio } from "../../../../../shared/Domain/Catalogos/EntityServicio";
import { EntityTipoDocumentoTributario } from "../../../../../shared/Domain/Catalogos/EntityTipoDocumentoTributario";
import { EntityRequestGenericForm, EntityRequestFormService, DocumentFile } from "../Domain/EntityRequest";
import { EntityTipoDocumento } from "../../../../../shared/Domain/Catalogos/EntityTipoDocumento";
import { EntityVAT } from "../../../../../shared/Domain/Catalogos/EntityVAT";
import { EntityGasto } from "../../../../../shared/Domain/EntityGasto";
import { EntityResponseInitialData, EntityResponseServiceColombia } from "../Domain/EntityResponse";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityServicioCustom, keyModule } from "../Domain/EntityUtils";

// Infraestructure
import { addCountProcess } from "../../../../../shared/Infraestructure/SliceGenerico";
import { AdapterGenerico } from "../../../../../shared/Infraestructure/AdapterGenerico";
import { EntitySelectBase } from "../../../../../shared/Domain/Catalogos/EntitySelectBase";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async save(params: EntityRequestFormService, type: 'create' | 'edit' | 'preview'  | ''): Promise<EntityGasto | null> {
        try {
            let response = await this._save(params, type);
            let rowLocal = await this._saveLocal(params, type);

            await this.dbLocal.deleteByIndexStore({ nameStore: 'Gastos', value: rowLocal.Codigo });
            await this.dbLocal.insertDataStore({ nameStore: 'Gastos', data: response ? response : rowLocal });

            return !!response ? response : rowLocal;
        } catch (error) {
            throw error;
        }
    }

    private async _saveLocal(params: EntityRequestFormService, type: 'create' | 'edit' | 'preview'  | ''): Promise<any> {
        const StatusOffline = { Estado: "No Enviado", IdEstado: -1 }
        const { Codigo, Users, ...rest } = params;

        let dataSend = type === 'create' ? [params] : {
            filter: {
                Codigo: params.Codigo
            },
            update: rest,
            user: {
                user: params.Users.Registry.User,
                lastname: params.Users.Registry.LastName,
                name: params.Users.Registry.Name
            }
        }
        let response = { ...params, modeSave: type, Estado: StatusOffline, dataSend };
        if (!!params.Codigo) return response;

        await this.dbLocal.insertDataStore({ nameStore: 'Gastos', data: response });
        return response;
    }

    private async _save(params: EntityRequestFormService, modeSave: 'create' | 'edit' | 'preview'  | ''): Promise<EntityGasto | null> {
        const url = `${this.urlBaseGastos}${modeSave === 'create' ? '/Rendicion/Gasto/saveUpload' : '/Rendicion/Gasto/updateUpload'}`
        const formData = new FormData();
        if (!navigator.onLine) { this.dispatch(addCountProcess()); return null; }
        
        if (modeSave === 'create') {
            const { Codigo, Files, ...rest } = params;
            formData.append('data', JSON.stringify([{...rest, Files: Files.map(row => row.value.name)}]))
            Files.forEach((row: DocumentFile) => {
                formData.append('File', row.value, row.value.name);
            })
        } else {
            const { Codigo, Users, Files, ...rest } = params;

            let _params = {
                filter: {
                    Codigo: params.Codigo
                },
                update: {
                    ...rest,
                    Files: Files.map(row => row.type === 'local' ? row.value.name : row.value)
                },
                user: {
                    user: params.Users.Registry.User,
                    lastname: params.Users.Registry.LastName,
                    name: params.Users.Registry.Name
                }
            };

            formData.append('data', JSON.stringify(_params));
            Files.forEach(row => {
                if (row.type === 'local') formData.append('File', row.value, row.value.name);
            })
        }
        
        const response = await this.service.call<any>(modeSave === 'create' ? "POST" : "PATCH", url, formData, "bearer", "form", 'json', { "request-decrypt-response": true }, 0);
        return response[0];
    }

    public async findForm(code: string, keyModule: keyModule, options: EntityResponseInitialData): Promise<EntityRequestGenericForm | null> {
        let response: EntityGasto | null = await this.dbLocal.selectByIndexStore({ nameStore: keyModule, value: code });
        if (!response) return null;

        const resultOT = options.ot.find(row => row.dataComplete.Code === response?.DatosTrabajo.OT.Code) || { label: response.DatosTrabajo.OT.OT, value: response.DatosTrabajo.OT.Codigo, dataComplete: response.DatosTrabajo.OT as any };
        const resultProveedor = options.proveedor.find(row => row.dataComplete.Identificacion === response?.DatosEmpresa.NroDocumento) || null;
        // const resultDivisa = options.moneda.find(row => row.dataComplete.Key === response.DatosImporte.Moneda.Codigo);
        const resultTipoDocumentoProveedor = options.tipoDocumento.find(row => row.dataComplete.Code === response?.DatosEmpresa.TipoDocumento.Codigo);
        const resultTipoDocumentoTributario = options.tipoDocumentoTributario.find(row => row.dataComplete.Codigo === response?.DatosDocumento.TipoDocumento.Codigo);
        const resultTipoImpuesto = options.VAT.find(row => row.dataComplete.Code === response?.DatosImporte.CodigoIVA);
        let resultFile: any[] = [];
        if (response.Files[0]) {
            let responseFile = await this.downloadFile(response.Files[0])
            if (responseFile)
                resultFile = [{
                    type: 'server',
                    value: response.Files[0],
                    valueToShow: (window.URL ? URL : webkitURL).createObjectURL(responseFile)
                }]
                else
                resultFile = [{
                    type: 'server',
                    value: response.Files[0],
                    valueToShow: ''
                }]
        }

        // Busca el servicio dependiendo el país
        if (response.DatosPais.Codigo === 'PE') {
            options.servicio = options.catServicio;
        } else if (['CL', 'EC'].includes(response.DatosPais.Codigo)) {
            options.servicio = options.catProducto;
        } else if (response.DatosPais.Codigo === 'CO') {
            options.servicio = await this.loadServiceColombia(resultOT.dataComplete.DatosEmpresa.Empresa);
        } else {}

        const resultServicio = options.servicio.find(row => row.dataComplete.Code === response?.DatosServicio.Code);

        return ({
            Codigo: response.Codigo,
            OT: resultOT,
            Proveedor: resultProveedor || null,
            OtroProveedor: !resultProveedor,
            TipoDocumentoProveedor: !!resultProveedor ? undefined : resultTipoDocumentoProveedor,
            NumeroDocumentoProveedor: !!resultProveedor ? undefined : response.DatosEmpresa.NroDocumento,
            RazonSocialProveedor: !!resultProveedor ? undefined : response.DatosEmpresa.RazonSocial,
            FechaEmision: response.FechaEmision.Fecha,
            TipoDocumento: resultTipoDocumentoTributario,
            NumeroDocumento: response.DatosDocumento.NroDocumento,
            Servicio: resultServicio,
            // Divisa: resultDivisa,
            Importe: response.DatosImporte.Importe,
            TipoImpuesto: resultTipoImpuesto,
            OtroImpuesto: !resultTipoImpuesto,
            ImporteImpuesto: response.DatosImporte.MontoIVA,
            ImporteTotal: response.DatosImporte.Total,
            Pais: {
                CDPais: resultOT.dataComplete.DatosPais.CDPais,
                Codigo: resultOT.dataComplete.DatosPais.Code,
                Nombre: resultOT.dataComplete.DatosPais.Name
            },
            User: response.Users.Registro,
            Delegacion: response.DatosTrabajo.Delegacion,
            Files: resultFile,
            Observacion: response.Observacion
        }) as any;
    }

    public async loadInitialData(): Promise<EntityResponseInitialData> {
        const { ot, proveedor } = AdapterData;
        const [tipoDocumento, servicio, tipoDocumentoTributario, vat, producto] = await this.dbLocal.selectAllStore(['CTTipoDocumento', 'CTServicio', 'CTTipoDocumentoTributario', 'CTVAT', 'CTProducto']);

        return ({
            ot: ot.map(row => ({ label: `${row.Codigo} - ${row.Name.trim()}`, value: row.Id, dataComplete: row })),
            proveedor: proveedor.map(row => ({ label: `${row.Identificacion} - ${row.Name.trim()}`, value: row.IdRegister, dataComplete: row })),
            // moneda: moneda.map((row: EntityMoneda) => ({ label: row.Name.trim(), value: row.IdRegister, dataComplete: row })),
            servicio: [],
            catServicio: servicio.map((row: EntityServicio) => ({ label: row.Name.trim(), value: row.IdRegister, dataComplete: {
                Code: row.Code,
                Codigo: row.Key,
                Servicio: row.Name,
                Country: { Code: row.Country.Code }
            }})),
            catProducto: producto.map((row: EntityServicio) => ({ label: row.Name.trim(), value: row.IdRegister, dataComplete: {
                Code: row.Code,
                Codigo: row.Key,
                Servicio: row.Name,
                Country: { Code: row.Country.Code }
            }})),
            tipoDocumento: tipoDocumento.map((row: EntityTipoDocumento) => ({ label: row.Name.trim(), value: row.IdRegister, dataComplete: row })),
            tipoDocumentoTributario: tipoDocumentoTributario.map((row: EntityTipoDocumentoTributario) => ({ label: row.Descripcion, value: row.Codigo, dataComplete: row })),
            VAT: vat.map((row: EntityVAT) => ({ label: row.Name, value: row.Code, dataComplete: row }))
        })
    }

    public async formatDataToSave(form: EntityRequestGenericForm): Promise<EntityRequestFormService> {
        return ({
            Codigo: form.Codigo,
            Observacion: form.Observacion,
            DatosDocumento: {
                NroDocumento: form.NumeroDocumento,
                TipoDocumento: {
                    Codigo: form.TipoDocumento.dataComplete.Codigo,
                    Descripcion: form.TipoDocumento.dataComplete.Descripcion
                }
            },
            DatosEmpresa: form.OtroProveedor ?
                {
                    NroDocumento: form.NumeroDocumentoProveedor || '',
                    RazonSocial: form.RazonSocialProveedor || '',
                    TipoDocumento: {
                        Codigo: form.TipoDocumentoProveedor?.dataComplete.Code || '',
                        Descripcion: form.TipoDocumentoProveedor?.dataComplete.Name || ''
                    }
                }
                :
                {
                    NroDocumento: form.Proveedor.dataComplete.Identificacion,
                    RazonSocial: form.Proveedor.dataComplete.Name || form.Proveedor.dataComplete.Code,
                    TipoDocumento: {
                        Codigo: form.Proveedor.dataComplete.TipoDocumento.Key,
                        Descripcion: form.Proveedor.dataComplete.TipoDocumento.Name
                    }
                },
            DatosImporte: {
                Importe: parseFloat(parseFloat(`${form.Importe}`).toFixed(2)),
                Moneda: {
                    Codigo: "", //form.Divisa?.dataComplete.Key || '',
                    Descripcion: "Moneda Local", //form.Divisa?.dataComplete.Name || '',
                    Moneda: "Moneda Local", // form.Divisa?.dataComplete.Name || '',
                    Nombre: "Moneda Local" // form.Divisa?.dataComplete.Name || ''
                },
                CodigoIVA: '', //form.OtroImpuesto ? '' : form.TipoImpuesto.dataComplete.Code,
                MontoIVA: 0, //(form.OtroImpuesto && form.ImporteImpuesto) ? parseInt(`${form.ImporteImpuesto}`) : form.TipoImpuesto.dataComplete.Value,
                Total: parseFloat(parseFloat(`${form.Importe}`).toFixed(2)), //(parseInt(`${form.Importe}`) * (((form.OtroImpuesto && form.ImporteImpuesto) ? parseInt(`${form.ImporteImpuesto}`) : form.TipoImpuesto.dataComplete.Value) / 100)) + parseInt(`${form.Importe}`) 
            },
            DatosPais: {
                CDPais: form.Pais.CDPais,
                Codigo: form.Pais.Codigo,
                Nombre: form.Pais.Nombre
            },
            DatosTrabajo: {
                Delegacion: {
                    Code: form.Delegacion.Code,
                    Codigo: form.Delegacion.Codigo,
                    Delegacion: form.Delegacion.Delegacion
                },
                OT: {
                    Code: form.OT.dataComplete.Code,
                    Codigo: form.OT.dataComplete.Codigo,
                    OT: form.OT.dataComplete.Name
                }
            },
            DatosServicio: {
                Code: form.Servicio.dataComplete.Code,
                Codigo: form.Servicio.dataComplete.Codigo,
                Servicio: form.Servicio.dataComplete.Servicio,
            },
            FechaEmision: {
                Fecha: form.FechaEmision,
                IdFecha: AdapterGenerico.getIdFechaLocal(form.FechaEmision)
            },
            Files: form.Files,
            Users: {
                Registry: form.User
            }
        })
    }

    public async loadServiceColombia(NameEmpresa: string): Promise<EntitySelectBase<EntityServicioCustom>[]> {
        let result: EntitySelectBase<EntityServicioCustom>[] = [];
        try {
            const payload = {
                data: {
                    Type: "Caja Chica",
                    Empresa: NameEmpresa
                }
            }
            let response: EntityResponseServiceColombia = await this.service.call<any>("POST", `${this.urlBase}/navision/serviciosProductosColombia`, JSON.stringify(payload), "bearer", "json", 'json', { "request-decrypt-response": true }, 0);
            if (!response.error) {
                result = response.result.map(row => ({
                    label: row.Description._text,
                    value: row.No._text,
                    dataComplete: {
                        Code: row.No._text,
                        Codigo: row.No._text,
                        Servicio: row.Description._text,
                        Country: {
                            Code: 'CO'
                        }
                    }
                }))
            }
        } catch(errror) {}
        return result;
    }

    public async deleteGasto(Codigos: string[]) {
        const payload = { Codigos };
        let response: string = await this.service.call<any>("POST", `${this.urlBaseGastos}/Rendicion/Gasto/deleteMany`, JSON.stringify(payload), "bearer", "json", 'json', { "request-decrypt-response": true }, 0);
        return response;
    }
}