import ElementModalCostume from "../../../../../../shared/Components/ElementModalCostume";
import { EntityLanguage } from "../../../../../../shared/Domain/EntityLanguage";
import { EntityConfigModalForm } from "../../Domain/EntityUtils";

interface ContainerProps {
    languageTranslate: EntityLanguage;
    component: JSX.Element;
    configModalForm: EntityConfigModalForm;
    closeModalForm(): void;
    onSubmitMF(evt: Event): Promise<void>;
}

export const ViewFormGasto = (props: ContainerProps) => {
    if (!props.configModalForm.show) return null;

    return (
        <>
            <ElementModalCostume
                title={props.languageTranslate.moduloRendicion.detail.textTituloNuevoGasto}
                closeModal={props.closeModalForm}
                show={props.configModalForm.show}
                height={"500px"}
            >
                {props.component}
                <div className='group-button d-flex'>
                    <button className='btn btn-dark' onClick={props.closeModalForm}> {props.languageTranslate.btnCancelar} </button>
                    <button className='btn btn-primary' onClick={(evt: any) => props.onSubmitMF(evt)}> {props.languageTranslate.btnGuardar} </button>
                </div>
            </ElementModalCostume>
        </>
    )
}