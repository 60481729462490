import { EntityAnticipoAprobado } from "../../../../../shared/Domain/Catalogos/EntityAnticipoAprobado";
import { EntityPersonal } from "../../../../../shared/Domain/Catalogos/EntityPersonal";
import { EntityRendicionGasto } from "../../../../../shared/Domain/EntityRendicionGasto";
import { EntityRendicionGastoAprobacion } from "../../../../../shared/Domain/EntityRendicionGastoAprobacion";
import { AdapterData } from "../../../../../shared/Infraestructure/AdapterData";
import { AdapterGenerico } from "../../../../../shared/Infraestructure/AdapterGenerico";
import { RepositoryImplGenerico } from "../../../../../shared/Infraestructure/RepositoryImplGenerico";
import { addCountProcess } from "../../../../../shared/Infraestructure/SliceGenerico";
import { EntityRequestGenericForm, EntityRequestFormService, EntityRequestUpdateGasto, EntityRequestFormServiceSendApproval, EntityRequestNote } from "../Domain/EntityRequest";
import { EntityResponseInitialData } from "../Domain/EntityResponse";
import { keyModule } from "../Domain/EntityUtils";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async addNote(params: EntityRequestNote): Promise<{ Notas: any[], Bitacora: any[] }> {
        let url = `${this.urlBaseGastos}/Rendicion/Informe/addNoteReport`;
        const response: EntityRendicionGastoAprobacion | null = (await this.service.call<any>("POST", url, JSON.stringify(params), "bearer", "json", "json", { "request-decrypt-response": true }, 0))[0];
        if (!response) return { Notas: [], Bitacora: [] };

        // Actualizar
        const result: EntityRendicionGasto = await this.dbLocal.selectByIndexStore({ nameStore: 'GastoAprobacion', value: params.codigo });
        result.Notas = response.Notas;
        await this.dbLocal.updateByIndexStore({ nameStore: 'GastoAprobacion', value: result });

        if (result) {
            result.Bitacora = (result.Bitacora || []).map(row => ({
                accion: row.Action,
                descripcion: row.Description ?? '',
                fecha: row.Date.Date ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3) : '',
                usuario: `${row.User?.Name} ${row.User?.LastName}`,
            })).reverse() as any

            result.Notas = (result.Notas || []).map(row => ({
                observacion: row.Descripcion,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Dates.Fecha), 3),
                usuario: `${row.Users.Name} ${row.Users.LastName}`,
                action: row.Titulo
            })).reverse() as any
        }

        return { Notas: result.Notas, Bitacora: result.Bitacora };
    }

    public async save(params: EntityRequestFormService, type: 'create' | 'edit' | 'preview'  | '', Gasto: EntityRequestUpdateGasto): Promise<EntityRendicionGasto | null> {
        try {
            let response = await this._save(params, type);
            let rowLocal = await this._saveLocal(params, type);
            
            await this._updateCollectionGasto(Gasto);
            await this.dbLocal.deleteByIndexStore({ nameStore: 'RendicionGasto', value: rowLocal.Codigo });
            await this.dbLocal.insertDataStore({ nameStore: 'RendicionGasto', data: response ? response : rowLocal });

            return !!response ? response : rowLocal;
        } catch (error) {
            throw error;
        }
    }

    private async _saveLocal(params: EntityRequestFormService, type: 'create' | 'edit' | 'preview'  | ''): Promise<any> {
        const StatusOffline = { Estado: "No Enviado", IdEstado: -1 }
        const { Codigo, User, ...rest } = params;

        let dataSend = type === 'create' ? {...rest, User} : {
            filter: {
                Codigo: params.Codigo
            },
            update: rest,
            user: {
                user: params.User.User,
                lastname: params.User.LastName,
                name: params.User.Name
            }
        }
        let response = { ...params, modeSave: type, Estado: StatusOffline, dataSend };
        if (!!params.Codigo) return response;

        await this.dbLocal.insertDataStore({ nameStore: 'RendicionGasto', data: response });
        return response;
    }

    private async _save(params: EntityRequestFormService, modeSave: 'create' | 'edit' | 'preview'  | ''): Promise<EntityRendicionGasto | null> {
        const url = `${this.urlBaseGastos}${modeSave === 'create' ? '/Rendicion/informe/saveOne' : '/Rendicion/informe/updateOne'}`
        let _params = {}
        if (!navigator.onLine) { this.dispatch(addCountProcess()); return null; }
        
        if (modeSave === 'create') {
            const { Codigo, ...rest } = params;
            _params = rest;
        } else {
            const { Codigo, User, ...rest } = params;
            _params = {
                filter: {
                    Codigo: params.Codigo
                },
                update: {
                    ...rest
                },
                user: {
                    user: params.User.User,
                    lastname: params.User.LastName,
                    name: params.User.Name
                }
            };
        }
        
        const response = await this.service.call<any>(modeSave === 'create' ? "POST" : "PATCH", url, JSON.stringify(_params), "bearer", "json", 'json', { "request-decrypt-response": true }, 0);
        return response;
    }

    public async sendApproval(params: EntityRequestFormServiceSendApproval): Promise<EntityRendicionGasto | null> {
        const url = `${this.urlBaseGastos}/Rendicion/Informe/reportToReview`;
        const response: EntityRendicionGasto | null = (await this.service.call<any>("PATCH", url, JSON.stringify(params), "bearer", "json", 'json', { "request-decrypt-response": true }, 0))[0];
        if (!response) return null;
        
        // Mover registro de la colección
        await this.dbLocal.deleteByIndexStore({ nameStore: 'RendicionGasto', value: response.Codigo });
        await this.dbLocal.insertDataStore({ nameStore: 'GastoAprobacion', data: response });

        // Eliminar Gastos
        await this.dbLocal.deleteByIndexStore(response.GastoAsociado.map(row => ({ nameStore: 'Gastos', value: row })));

        return response;
    }

    private async _updateCollectionGasto({ GastoEnd, GastoInit }: EntityRequestUpdateGasto) {
        /*const asociados = GastoEnd.filter(row => !GastoInit.find(_row => _row.Codigo === row.Codigo));
        const desasociados = GastoInit.filter(row => !GastoEnd.find(_row => _row.Codigo === row.Codigo));

        // asociados
        if (asociados.length > 0) {
            await this.dbLocal.deleteByIndexStore(asociados.map(row => ({ nameStore: 'IngresoGasto', value: row.Codigo})));
            await this.dbLocal.insertDataStore(({ nameStore: 'IngresoGastoAsociado', data: asociados}));
        }
        
        // desasociados
        if (desasociados.length > 0) {
            await this.dbLocal.deleteByIndexStore(desasociados.map(row => ({ nameStore: 'IngresoGastoAsociado', value: row.Codigo})));
            await this.dbLocal.insertDataStore(({ nameStore: 'IngresoGasto', data: desasociados}));
        }*/
    }

    public async loadInitialData(): Promise<EntityResponseInitialData> {
        const { ot } = AdapterData;
        const [gastos, flujo, anticipo, delegacion] = await this.dbLocal.selectAllStore(['Gastos', 'CTFlujosOT', 'CTAnticipo', 'CTFlujosDelegacion']);

        return ({
            ot: ot.map(row => ({ label: `${row.Codigo} - ${row.Name.trim()}`, value: row.Id, dataComplete: row })),
            anticipo: anticipo.map((row: EntityAnticipoAprobado) => ({ label: `${row.DatosImporte.Solicitud.ImporteTotal} - ${row.DatosEmpresa.Empresa}`, value: row.Codigo, dataComplete: row })),
            gasto: gastos,
            gastoAsociado: gastos,
            flujo,
            flujoDelegacion: delegacion
        })
    }

    public async findPersonal(identifier: string): Promise<EntityPersonal | null> {
        const result = await this.dbLocal.selectByIndexStore({ nameStore: 'CTPersonal', value: identifier })
        return result;
    }

    public async findForm(code: string, keyModule: keyModule, options: EntityResponseInitialData): Promise<EntityRequestGenericForm | null> {
        const response: EntityRendicionGasto | null = await this.dbLocal.selectByIndexStore({ nameStore: keyModule, value: code });
        if (!response) return null;

        const resultOT = options.ot.find(row => row.dataComplete.Code === response.DatosTrabajo.OT.Code);
        const resultAnticipo = options.anticipo.find(row => row.dataComplete.Codigo === response.Anticipo?.Codigo);
        const resultGastoAsociado = options.gastoAsociado.filter(row => response.GastoAsociado.includes(row.Codigo));

        return ({
            Codigo: response.Codigo,
            OT: resultOT as any,
            Empresa: {
                CDEmpresa: response.DatosEmpresa.Codigo,
                Empresa: response.DatosEmpresa.Empresa,
                Grupo: response.DatosEmpresa.Grupo
            },
            Delegacion: {
                Code: response.DatosTrabajo.Delegacion.Codigo,
                Codigo: response.DatosTrabajo.Delegacion.Codigo,
                Name: response.DatosTrabajo.Delegacion.Delegacion
            },
            Pais: {
                CDPais: response.DatosPais.CDPais,
                Code: response.DatosPais.Codigo,
                Name: response.DatosPais.Nombre
            },
            Personal: response.DatosPersonal,
            ActivarAnticipo: !!resultAnticipo,
            Anticipo: resultAnticipo as any,
            Banco: response.DatosBanco.Banco,
            CuentaBancaria: response.DatosBanco.CuentaBancaria,
            CuentaInterbancaria: response.DatosBanco.CuentaInterbancaria,
            Fecha: response.FechaEmision.Date,
            Descripcion: response.Descripcion,
            GastoAsociado: resultGastoAsociado,
            GastoAsociadoInit: resultGastoAsociado,
            User: {
                IdUser: response.Users.Registry.IdUser,
                LastName: response.Users.Registry.LastName,
                Name: response.Users.Registry.Name,
                Perfil: response.Users.Registry.Perfil,
                User: response.Users.Registry.User
            },
            Status: {
                IdStatus: response.Status.Rendicion.IdStatus,
                Status: response.Status.Rendicion.Status
            },
            Aprobacion: {
                Nivel: response.Aprobacion?.Nivel || 0
            },
            Flujo: {
                Aprobacion: response.Flujo?.Aprobacion || [],
                Code: response.Flujo?.Code || "",
                Codigo: response.Flujo?.Codigo || "",
                Descripcion: response.Flujo?.Descripcion || "",
                Id: response.Flujo?.Id || 0,
                Name: response.Flujo?.Name || "",
            },
            Notas: (response?.Notas || []).map(row => ({
                observacion: row.Descripcion,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Dates?.Fecha || new Date()), 3),
                usuario: `${row.Users.Name} ${row.Users.LastName}`,
                action: row?.Titulo
            })).reverse(),
            Bitacora: (response?.Bitacora || []).map(row => ({
                accion: row.Action,
                fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
                descripcion: row.Description,
                usuario: `${row.User.Name} ${row.User.LastName}`
            })).reverse()
        })
    }

    public async formatDataToSave(form: EntityRequestGenericForm): Promise<EntityRequestFormService> {
        const Moneda = form.GastoAsociado[form.GastoAsociado.length - 1].DatosImporte.Moneda;
        let extraData = {
            Anticipo: {
                Codigo: `${form.Anticipo?.dataComplete.Codigo}`,
                Nav: `${form.Anticipo?.dataComplete?.DatosDocumento?.Contabilizado.NroDocumento}`
            }
        }

        return ({
            ...(form.ActivarAnticipo ? extraData : {}),
            Codigo: form.Codigo,
            DatosPersonal: {
                ApellidoMaterno: form.Personal.ApellidoMaterno,
                ApellidoPaterno: form.Personal.ApellidoPaterno,
                Identificacion: form.Personal.Identificacion,
                Nombres: form.Personal.Nombres,
                TipoDocumento: form.Personal.TipoDocumento,
            },
            DatosBanco: {
                Banco: (form.ActivarAnticipo && form.Anticipo) ? form.Anticipo.dataComplete.DatosBanco.Banco : form.Banco,
                CuentaBancaria: (form.ActivarAnticipo && form.Anticipo) ? form.Anticipo.dataComplete.DatosBanco.NroCuenta : form.CuentaBancaria,
                CuentaInterbancaria: (form.ActivarAnticipo && form.Anticipo) ? form.Anticipo.dataComplete.DatosBanco.CuentaInterbancaria : form.CuentaInterbancaria,
                TipoCuenta: ''
            },
            DatosEmpresa: {
                Codigo: form.Empresa.CDEmpresa,
                Empresa: form.Empresa.Empresa,
                Grupo: form.Empresa.Grupo
            },
            DatosImporte: {
                Moneda,
                Importe: form.GastoAsociado.reduce((prev, current) => { prev += current.DatosImporte.Importe; return prev; }, 0),
                MontoIVA: form.GastoAsociado.reduce((prev, current) => { prev += current.DatosImporte.MontoIVA; return prev; }, 0),
                Total: form.GastoAsociado.reduce((prev, current) => { prev += current.DatosImporte.Total; return prev; }, 0),
            },
            DatosPais: {
                CDPais: form.Pais.CDPais,
                Codigo: form.Pais.Code,
                Nombre: form.Pais.Name
            },
            DatosTrabajo: {
                Delegacion: {
                    Code: form.Delegacion.Code,
                    Codigo: form.Delegacion.Codigo,
                    Delegacion: form.Delegacion.Name
                },
                OT: {
                    Code: form.OT.dataComplete.Code,
                    Codigo: form.OT.dataComplete.Codigo,
                    OT: form.OT.dataComplete.Name
                }
            },
            FechaEmision: {
                Date: form.Fecha,
                IdDate: AdapterGenerico.getIdFechaLocal(form.Fecha)
            },
            GastoAsociado: form.GastoAsociado.map(row => row.Codigo),
            User: {
                LastName: form.User.LastName,
                Name: form.User.Name,
                User: form.User.User,
                IdUser: form.User.IdUser,
                Perfil: form.User.Perfil
            },
            Descripcion: form.Descripcion
        })
    }
}