import AsyncSelect from 'react-select/async';
import Select, { GroupBase, MenuListProps } from "react-select";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import makeAnimated from "react-select/animated";
import { AdapterGeolocation } from "../Infraestructure/AdapterGeolocation";
import { AdapterGenerico } from "../Infraestructure/AdapterGenerico";
import { useState } from "react";
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

interface InputBase {
  label: string;
  name: string;
  onChange: (name: string, value: any) => void;
  values: any;
  isRequired?: boolean;
  disabled?: boolean;
  className?: string;
  isHorizontal?: boolean;
  children?: any;
}

interface IInputSelect extends InputBase {
  options?: any[];
  loading?: boolean;
  isMulti?: boolean;
  disabledVirtualized?: boolean;
  isClearable?: boolean;
  arrFilterOption?: string[];
  isAsync?: boolean;
  delaySearch?: number;
  closeMenuOnSelect?: boolean;
  blurInputOnSelect?: boolean;
  controlShouldRenderValue?: boolean;
}

interface IInput extends InputBase {
  type?: React.HTMLInputTypeAttribute;
  uppercase?: boolean;
  max?: number;
}

interface IInputIcon extends IInput {
  icon?: string;
  text?: string;
  max?: number;
  placeholder?: string;
  onSubmit?(): void;
}

export const InputSelect = (props: IInputSelect) => {
  const {
    label,
    name,
    onChange,
    options,
    values,
    disabled,
    isRequired,
    loading,
    className,
    disabledVirtualized,
    isHorizontal,
    children,
    arrFilterOption
  } = props;

  const extraProps = {
    filterOption: (option: FilterOptionOption<any>, inputValue: string) => {
      if (!inputValue) return true;
      if (arrFilterOption) {
        let _arrFilterOption = arrFilterOption.some(row => (row.split(".").reduce((prev, current) => {
          prev = prev[current];
          return prev;
        }, option.data.dataComplete)).toLocaleLowerCase().search(inputValue.toLocaleLowerCase()) >= 0);

        return _arrFilterOption
      }
      return (
        (`${option.label}`).toLocaleLowerCase().search(inputValue.toLocaleLowerCase()) >= 0 ||
        (`${option.value}`).toLocaleLowerCase().search(inputValue.toLocaleLowerCase()) >= 0
      )
    }
  };

  return (
    <div className={`${className || ""} form-row d-flex mb-3`}>
      <div
        className={`${
          isHorizontal ? "d-flex justify-content-between" : "form-group"
        } w-100`}
      >
        {
          label ?
            <label
              className="mb-1"
              style={props.isHorizontal ? { width: "49%" } : {}}
            >
              {" "}
              {label} {""} {isRequired && <span className="text-danger">*</span>}
            </label>
          : null
        }
        <Select
          {...extraProps}
          className={`custom-select ${isHorizontal ? "w-50" : ""}`}
          components={
            disabledVirtualized
              ? animatedComponents
              : { MenuList: MenuListVirtualized }
          }
          onChange={(value) => onChange(name, value)}
          value={values[name]}
          isDisabled={disabled}
          isMulti={props.isMulti}
          options={options || []}
          isLoading={loading}
          isClearable={props.isClearable}
        />
        { children }
      </div>
    </div>
  );
};

const animatedComponents = makeAnimated();
const MenuListVirtualized = (props: any) => {
  const rows = Array.isArray(props.children) ? props.children : [];
  const allOptions = props.options;
  const RowRenderer = ({ index, style }: any) => (
    <div
      className="d-inline-block text-truncate option-virtualized-custom"
      style={{
        ...style,
        minHeight: "max-content",
        minWidth: "max-content",
        overFlowY: "scroll",
      }}
      key={allOptions[index].value}
    >
      {" "}
      {rows[index]}{" "}
    </div>
  );

  const RowRendererEmpty = ({ index, style }: any) => (
    <div style={style} key={-1}>
      {" "}
      No Options{" "}
    </div>
  );
  return (
    <div
      style={{ height: (rows.length || 0) * 40, maxHeight: 300, minHeight: 40 }}
    >
      <AutoSizer>
        {({ height, width }: any) => (
          <List
            className="List"
            height={height as number}
            itemCount={rows.length}
            itemSize={35}
            width={width as number}
          >
            {rows.length === 0 ? RowRendererEmpty : RowRenderer}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};

let delayTimerAsynSelect: NodeJS.Timer;
const loadOptionsAsyncSelect = (textSearch: string, inputValue: string, filteredOptions: any[], setFilteredOptions: React.Dispatch<React.SetStateAction<any[]>>, delaySearch: number = 1000, options: any[] = [], arrFilterOption: any[] = [] ) => {
  return new Promise<any>((resolve) => {
    if (delayTimerAsynSelect) clearTimeout(delayTimerAsynSelect)
    delayTimerAsynSelect = setTimeout(() => {
      if (textSearch === '') return resolve([]);
      else if (textSearch === inputValue && filteredOptions.length > 0) return resolve(filteredOptions);
      else {
        const result = options?.filter(option => arrFilterOption?.some(row => (option.dataComplete[row] as string || '').toLocaleLowerCase().search(textSearch.toLocaleLowerCase()) >= 0) as any) || [];
        resolve(result.slice(0, 50))
        setFilteredOptions(result.slice(0, 50));
      }
    }, delaySearch);
  })
}

const OptionAsyncSelect = ({ innerProps, label, isSelected, ...rest }: any) => {
  let isTouchScrolling = false;
  const customOnClick = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (isTouchScrolling) {
      return;
    }
    rest.selectOption(rest.data);
  }
  const handleTouchMove = () => {
    // Establecer el estado para indicar que se está realizando un desplazamiento táctil
    isTouchScrolling = true;
  };

  const onTouchCancel = () => {
    // Establecer el estado en false después de que finalice el desplazamiento táctil
    isTouchScrolling = false;
  };
  return (
    <div
      style={{
        backgroundColor: isSelected ? '#01397d54' : 'white',
        padding: '8px',
      }}
      onClick={customOnClick}
      onTouchEnd={customOnClick}
      onTouchMove={handleTouchMove}
      onTouchCancel={onTouchCancel}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <span>{label}</span>
        <span className='text-muted' style={{ fontSize: 12 }} >{rest.data.dataComplete?.CodigoDelegacion}-{rest.data.dataComplete?.Delegacion}</span>
        <span className='text-muted' style={{ fontSize: 12 }} >{rest.data.dataComplete?.RazonSocial}</span>
      </div>
    </div>
  );
}

export const InputSelectAsyncCustom = (props: IInputSelect) => {
  const { label, name, onChange, options, values, disabled, isRequired, loading, className, isHorizontal, arrFilterOption } = props;
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  let ComponentsVirtualized: any = {
    MenuList: (propsMenuList: MenuListProps<any, boolean, GroupBase <unknown>>) => MenuListVirtualized({ ...propsMenuList, selectOptionCustomPersonnel: true }),
    Option: OptionAsyncSelect
  }

  return (
    <div className={`${className || ''} form-row d-flex mb-3`}>
      <div className={`${isHorizontal ? 'd-flex justify-content-between' : 'form-group'} w-100`}>
        { label ? <label className="mb-1" style={props.isHorizontal ? { width: '49%' } : {}}> {label} {""} {isRequired && <span className="text-danger">*</span>}</label> : null }
        <AsyncSelect
          className={`custom-select custom-select-async ${isHorizontal ? 'w-50' : ''}`}
          placeholder='Buscar'
          components={ComponentsVirtualized}
          onChange={(value) => onChange(name, value)}
          value={values[name]}
          isDisabled={disabled}
          isMulti={props.isMulti}
          options={options || []}
          defaultOptions={filteredOptions}
          isLoading={loading}
          closeMenuOnSelect={!props.closeMenuOnSelect}
          onInputChange={(newValue, action: any) => { if (!["input-blur", "set-value", "menu-close"].includes(action.action)) { setInputValue(newValue); } return newValue; }}
          // inputValue={inputValue}
          loadOptions={(textSearch) => loadOptionsAsyncSelect(textSearch, inputValue, filteredOptions, setFilteredOptions, props.delaySearch, props.options, arrFilterOption)}
        />
        { props.children }
      </div>
    </div>
  )
}

export const InputText = (props: IInput) => {
  const {
    label,
    name,
    onChange,
    values,
    disabled,
    isRequired,
    type,
    uppercase,
    className,
    isHorizontal,
    max
  } = props;
  return (
    <div className={`${className || ""} form-row d-flex mb-3`}>
      <div
        className={`${
          isHorizontal ? "d-flex justify-content-between" : "form-group"
        } w-100`}
      >
        <label
          className="mb-1"
          style={props.isHorizontal ? { width: "49%" } : {}}
        >
          {" "}
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>
        <input
          {
            ... type === 'number' ? 
              {
                min: 0,
                max: max || Infinity 
              }
            : {}
          }
          type={type ? type : "text"}
          className={`form-control ${isHorizontal ? "w-50" : ""}`}
          placeholder={label}
          onChange={(evt) =>
            onChange(
              name,
              uppercase
                ? evt.target.value.toLocaleUpperCase()
                : evt.target.value
            )
          }
          value={values[name]}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export const InputTextIcon = (props: IInputIcon) => {
  const {
    label,
    name,
    onChange,
    values,
    disabled,
    isRequired,
    type,
    uppercase,
    className,
    isHorizontal,
    icon,
    text,
    max,
    placeholder
  } = props;

  return (
    <div className={`${className || ""} form-row d-flex mb-3`}>
      <div
        className={`${
          isHorizontal ? "d-flex justify-content-between" : "form-group"
        } w-100`}
      >
        <label
          className="mb-1"
          style={props.isHorizontal ? { width: "49%" } : {}}
        >
          {" "}
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>
        <div className="input-group input-group-l">
          <input
            {
              ... type === 'number' ? 
                {
                  min: 0,
                  max: max || Infinity 
                }
              : {}
            }
            type={type ? type : "text"}
            className={`form-control ${isHorizontal ? "w-50" : ""}`}
            placeholder={placeholder || label}
            onChange={(evt) =>
              onChange(
                name,
                uppercase
                  ? evt.target.value.toLocaleUpperCase()
                  : evt.target.value
              )
            }
            value={values[name]}
            disabled={disabled}
          />
          <span className="input-group-text" style={{ fontSize: 14, color: "#1b458f" }} onClick={() => props?.onSubmit ? props.onSubmit() : null}>
            { icon ? <i className={icon} /> : null }
            { text }
          </span>
        </div>
        { props.children }
      </div>
    </div>
  );
}

export const InputTextArea = (props: IInput) => {
  const {
    label,
    name,
    onChange,
    values,
    disabled,
    isRequired,
    uppercase,
    className,
    isHorizontal,
  } = props;
  return (
    <div className={`${className || ""} form-row d-flex mb-3`}>
      <div
        className={`${
          isHorizontal ? "d-flex justify-content-between" : "form-group"
        } w-100`}
      >
        <label
          className="mb-1"
          style={props.isHorizontal ? { width: "49%" } : {}}
        >
          {" "}
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>
        <textarea
          className={`form-control ${isHorizontal ? "w-50" : ""}`}
          placeholder={label}
          onChange={(evt) =>
            onChange(
              name,
              uppercase
                ? evt.target.value.toLocaleUpperCase()
                : evt.target.value
            )
          }
          value={values[name]}
          disabled={disabled}
          rows={5}
        />
      </div>
    </div>
  );
};

export const InputCheck = (props: IInput) => {
  const { label, name, onChange, values, disabled, isRequired, className } =
    props;
  return (
    <div className={`${className || ""} form-row d-flex mb-3`}>
      <div className="form-group w-100 d-flex">
        <label className="mb-1" style={{ marginRight: "auto" }}>
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>
        <div className="form-check form-switch">
          <input
            checked={values[name]}
            disabled={disabled}
            onChange={(evt) => onChange(name, evt.target.checked)}
            className="form-check-input"
            type="checkbox"
          />
        </div>
      </div>
    </div>
  );
};

export const InputCheckNormal = (props: IInput) => {
  const { label, name, onChange, values, disabled, isRequired, className } =
    props;
  return (
    <div style={{ maxHeight: 20, marginTop: -15 }}>
        <input
          checked={values} // item / boolean
          disabled={disabled}
          onChange={(evt) => onChange(name, evt.target.checked)}
          className="form-check-input"
          type="checkbox"
        />
    </div>
  );
};

export const InputAutoCompleteLocation = (props: IInput) => {
  const [loading, setLoading] = useState(false);
  const { label, name, onChange, values, disabled, isRequired, className } =
    props;

  const autoCompleteLocation = async () => {
    if (!navigator.onLine) {
      AdapterGenerico.createMessage(
        "Alerta",
        "Debe contar con internet para usar el autocompletado de la ubicación actual",
        "warning"
      );
      return;
    }

    setLoading(true);
    onChange(name, (await AdapterGeolocation.getAllGeolocation()).address);
    setLoading(false);
  };

  return (
    <div className={`${className || ""} form-row d-flex mb-3`}>
      <div className="form-group w-100">
        <label className="mb-1">
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>
        <div className="input-group">
          <input
            type={"text"}
            className="form-control"
            placeholder={label}
            onChange={(evt) => onChange(name, evt.target.value)}
            value={values[name]}
            disabled={disabled || loading}
          />
          <button
            className="btn btn-primary addon-button-primary"
            disabled={props.disabled || loading}
            type="button"
            onClick={autoCompleteLocation}
          >
            <i className="fa-solid fa-location-dot" />
          </button>
        </div>
      </div>
    </div>
  );
};

interface IInputDate extends IInput {
  minDate?: string;
  maxDate?: string;
  removeMaxDate?: boolean;
  removeMinDate?: boolean;
}

export const InputDate = (props: IInputDate) => {
  const {
    label,
    name,
    onChange,
    values,
    disabled,
    isRequired,
    className,
    isHorizontal,
  } = props;

  const fechaActual = new Date();
  const inicioAnioPasado = new Date(fechaActual.getFullYear() - 1, 0, 1);
  const maxDate = props.maxDate || `${fechaActual.getFullYear()}-${(fechaActual.getMonth() + 1).toString().padStart(2, '0')}-${fechaActual.getDate().toString().padStart(2, '0')}`;
  const minDate = props.minDate || `${inicioAnioPasado.getFullYear()}-${(inicioAnioPasado.getMonth() + 1).toString().padStart(2, '0')}-${inicioAnioPasado.getDate().toString().padStart(2, '0')}`;

  const prevOnChange = (value: string) => {
    let newValue;
    // Convertir las fechas a objetos Date
    const selectedDateObject = new Date(value);
    const maxDateObject = new Date(maxDate);
    const minDateObject = new Date(minDate);

    if (selectedDateObject > maxDateObject && !props.removeMaxDate) {
      newValue = maxDate;
    } else if (selectedDateObject < minDateObject && !props.removeMinDate) {
      newValue = minDate;
    } else {
      newValue = value;
    }

    onChange(
      name,
      newValue
    )
  }

  return (
    <div className={`${className || ""} form-row d-flex mb-3`}>
      <div
        className={`${
          isHorizontal ? "d-flex justify-content-between" : "form-group"
        } w-100`}
      >
        <label
          className="mb-1"
          style={props.isHorizontal ? { width: "49%" } : {}}
        >
          {" "}
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>
        <input
          { ...(props.removeMaxDate ? {} : { max: maxDate }) }
          { ...(props.removeMinDate ? {} : { min: minDate }) }
          type={"date"}
          className={`form-control ${isHorizontal ? "w-50" : ""}`}
          placeholder={label}
          onChange={(evt) =>
            prevOnChange(evt.target.value)
          }
          value={values[name]}
          disabled={disabled}
        />
      </div>
    </div>
  );
};