import React from 'react';
import './Style.scss';
import { ElementBreadCrumb } from '../../../../../shared/Components/ElementBreadCrumb';
import { EntityConfigForm } from '../Domain/EntityUtils';
import ElementStepperContent from '../../../../../shared/Components/ElementStepperContent';
import { EntityLanguage } from '../../../../../shared/Domain/EntityLanguage';
import { InputDate, InputSelect, InputSelectAsyncCustom, InputText, InputTextArea, InputTextIcon } from '../../../../../shared/Components/ElementInputsCostume';
import { EntityGenericDetailForm } from '../../../../../shared/Domain/EntityGenericDetailForm';
import { EntityRequestGenericForm } from '../Domain/EntityRequest';
import ElementViewDocumentsCostume from '../../../../../shared/Components/ElementViewDocumentsCostume';
import { AdapterFilterOption } from '../../../../../shared/Infraestructure/AdapterFilterOptions';

interface ContainerProps {
    configForm: EntityConfigForm;
    languageTranslate: EntityLanguage;
    formikForm: EntityGenericDetailForm<EntityRequestGenericForm>;
    disabledOT?: boolean;
    onChange: (name: string, value: any) => void;
    onSubmit: (e: any) => Promise<any>;
    onCancelForm: () => void;
}

export const ViewMain = (props: ContainerProps) => {
    return (
        <div className='ViewGastoForm'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={props.configForm.navBar} />
            </div>
            <div className="container-form">
                <ElementStepperContent
                    step={1}
                    currentStep={1}
                    title={props.languageTranslate.moduloGasto.detail.textTitle}
                    isLastStep
                    removeButtonSubmit
                    onSubmit={() => {}}
                >

                    <ContentForm {...props} />

                    <div className='group-button d-flex'>
                        <button className='btn btn-dark' onClick={props.onCancelForm}> {props.languageTranslate.btnCancelar} </button>
                        <button className='btn btn-primary' disabled={props.configForm.mode === 'preview'} onClick={props.onSubmit}> {props.languageTranslate.btnGuardar} </button>
                    </div>

                </ElementStepperContent>
            </div>
        </div>
    )
}

export const ContentForm = (props: ContainerProps) => {

    return (
        <div className="form-row mb-0">
            <InputSelect
                label={props.languageTranslate.moduloGasto.detail.textOT}
                options={props.configForm.dataOptions.ot}
                values={props.formikForm.values}
                onChange={props.onChange}
                loading={props.configForm.loadingForm}
                disabled={props.configForm.mode === 'preview'}
                className='form-group w-100'
                arrFilterOption={AdapterFilterOption.OT}
                name='OT'
                isRequired
                isClearable
            />

            <InputSelectAsyncCustom
                className='mt-3 mb-0'
                label={props.languageTranslate.moduloGasto.detail.textProveedor}
                arrFilterOption={AdapterFilterOption.Proveedor}
                name='Proveedor'
                disabled={props.configForm.mode === 'preview' || props.formikForm.values.OtroProveedor}
                isRequired
                onChange={props.onChange}
                values={props.formikForm.values}
                options={props.configForm.dataOptions.proveedor.filter(
                    (row) =>
                        row.dataComplete.Country.Code ===
                        props.formikForm.values.Pais.Codigo
                )}
                isAsync={true}
                delaySearch={1000}
                isClearable
            >
                <div className='mt-1'>
                    {/*
                        props.configForm.mode !== 'preview' ? (
                            props.formikForm.values.OtroProveedor ? 
                            <span className='text-secondary' style={{ fontSize: 12 }} onClick={() => props.onChange('OtroProveedor', false)}>{props.languageTranslate.moduloGasto.detail.textDesactivarOtroProveedor}</span> :
                            <span className='text-secondary' style={{ fontSize: 12 }} onClick={() => props.onChange('OtroProveedor', true)}>{props.languageTranslate.moduloGasto.detail.textOtroProveedor}</span>
                        ) : null
                    */}
                </div>
            </InputSelectAsyncCustom>

            {
                props.formikForm.values.OtroProveedor ? 
                <>
                    <InputSelect
                        label={props.languageTranslate.moduloGasto.detail.textTipoDocumentoProveedor}
                        options={props.configForm.dataOptions.tipoDocumento.filter(
                            (row) => 
                                row.dataComplete.Country.some(row => row.Code === props.formikForm.values.Pais.Codigo)
                        )}
                        values={props.formikForm.values}
                        onChange={props.onChange}
                        loading={props.configForm.loadingForm}
                        disabled={props.configForm.mode === 'preview'}
                        disabledVirtualized
                        className='form-group w-100'
                        name='TipoDocumentoProveedor'
                        isRequired
                        isClearable
                    />
                    <InputText
                        label={props.languageTranslate.moduloGasto.detail.textNumeroDocumentoProveedor}
                        values={props.formikForm.values}
                        onChange={props.onChange}
                        disabled={props.configForm.mode === 'preview'}
                        className='form-group w-100'
                        name='NumeroDocumentoProveedor'
                        isRequired
                    />
                    <InputText
                        label={props.languageTranslate.moduloGasto.detail.textRazonSocialProveedor}
                        values={props.formikForm.values}
                        onChange={props.onChange}
                        disabled={props.configForm.mode === 'preview'}
                        className='form-group w-100'
                        name='RazonSocialProveedor'
                        isRequired
                    />
                </>
                : null
            }
            <InputDate
                label={props.languageTranslate.moduloGasto.detail.textFechaEmision}
                values={props.formikForm.values}
                onChange={props.onChange}
                disabled={props.configForm.mode === 'preview'}
                className='form-group w-100'
                name='FechaEmision'
                isRequired
            />

            <InputSelect
                label={props.languageTranslate.moduloGasto.detail.textTipoDocumentoTributario}
                options={props.configForm.dataOptions.tipoDocumentoTributario.filter(
                    (row) => 
                        row.dataComplete.CDPais === props.formikForm.values.Pais.CDPais
                )}
                values={props.formikForm.values}
                onChange={props.onChange}
                loading={props.configForm.loadingForm}
                disabled={props.configForm.mode === 'preview'}
                disabledVirtualized
                className='form-group w-100'
                name='TipoDocumento'
                isRequired
                isClearable
            />

            <InputText
                label={props.languageTranslate.moduloGasto.detail.textNumeroDocumento}
                values={props.formikForm.values}
                onChange={props.onChange}
                disabled={props.configForm.mode === 'preview'}
                className='form-group w-100'
                name='NumeroDocumento'
                isRequired
            />

            <InputSelect
                label={props.languageTranslate.moduloGasto.detail.textServicio}
                options={props.configForm.dataOptions.servicio.filter(
                    (row) => 
                        row.dataComplete.Country.Code ===
                        props.formikForm.values.Pais.Codigo
                )}
                values={props.formikForm.values}
                onChange={props.onChange}
                loading={props.configForm.loadingForm}
                disabled={props.configForm.mode === 'preview'}
                disabledVirtualized
                className='form-group w-100'
                name='Servicio'
                isRequired
                isClearable
            />

            {/* <InputSelect
                label={props.languageTranslate.moduloGasto.detail.textDivisa}
                options={props.configForm.dataOptions.moneda.filter(
                    (row) => 
                        row.dataComplete.Country.Code ===
                        props.formikForm.values.Pais.Codigo
                )}
                values={props.formikForm.values}
                onChange={props.onChange}
                loading={props.configForm.loadingForm}
                disabled={props.configForm.mode === 'preview'}
                disabledVirtualized
                className='form-group w-100'
                name='Divisa'
                isRequired
            /> */ }

            <InputText
                label={props.languageTranslate.moduloGasto.detail.textImporte}
                values={props.formikForm.values}
                onChange={props.onChange}
                disabled={props.configForm.mode === 'preview'}
                className='form-group w-100'
                name='Importe'
                isRequired
                type='number'
                // text={props.formikForm.values.Divisa?.dataComplete.Symbol || '-'}
            />

            {/*

            <InputSelect
                label={props.languageTranslate.moduloGasto.detail.textTipoImpuesto}
                options={
                    props.configForm.dataOptions.VAT.filter(
                        (row) =>
                            row.dataComplete.Country.Code === props.formikForm.values.Pais.Codigo &&
                            row.dataComplete.TipoVAT.Key === (props.formikForm.values.Proveedor?.dataComplete?.PostingGroup?.VATBus?.Key || 'NACIONAL')
                    )
                }
                values={props.formikForm.values}
                onChange={props.onChange}
                loading={props.configForm.loadingForm}
                disabled={props.configForm.mode === 'preview' || props.formikForm.values.OtroImpuesto}
                disabledVirtualized
                className='form-group w-100'
                name='TipoImpuesto'
                isRequired
                isClearable
            >
                <div className='mt-1'>
                    {
                        props.configForm.mode !== 'preview' ? (
                            props.formikForm.values.OtroImpuesto ? 
                            <span className='text-secondary' style={{ fontSize: 12 }} onClick={() => props.onChange('OtroImpuesto', false)}>{props.languageTranslate.moduloGasto.detail.textDesactivarOtroImpuesto}</span> :
                            <span className='text-secondary' style={{ fontSize: 12 }} onClick={() => props.onChange('OtroImpuesto', true)}>{props.languageTranslate.moduloGasto.detail.textoOtroImpuesto}</span>
                        ) : null
                    }
                </div>
            </InputSelect>

            {
                props.formikForm.values.OtroImpuesto ? 
                <InputTextIcon
                    label={props.languageTranslate.moduloGasto.detail.textImporteImpuesto}
                    values={props.formikForm.values}
                    onChange={props.onChange}
                    disabled={props.configForm.mode === 'preview'}
                    className='form-group w-100'
                    name='ImporteImpuesto'
                    icon='fa-solid fa-percent'
                    type='number'
                    isRequired
                />
                : null
            }

            <InputTextIcon
                label={props.languageTranslate.moduloGasto.detail.textImporteTotal}
                values={{ ImporteTotal: AdapterGenerico.calculoIVA(
                    props.formikForm.values.Importe,
                    props.formikForm.values.OtroImpuesto ?
                        props.formikForm.values.ImporteImpuesto
                        :
                        props.formikForm.values.TipoImpuesto?.dataComplete.Value || 0
                )}}
                onChange={props.onChange}
                disabled
                className='form-group w-100'
                name='ImporteTotal'
                isRequired
            />
            */}

            <InputTextArea
                label={props.languageTranslate.moduloGasto.detail.textObservaciones}
                type=''
                values={props.formikForm.values}
                onChange={props.onChange}
                disabled={props.configForm.mode === 'preview'}
                className='form-group w-100'
                name='Observacion'
            />

            <label className='mb-2'>{props.languageTranslate.moduloGasto.detail.textDigitalizacionFactura} <span className='text-danger'>*</span></label>
            <ElementViewDocumentsCostume
                name='Files'
                title={props.languageTranslate.moduloGasto.detail.textDigitalizacionFactura}
                documents={props.formikForm.values['Files']}
                onChange={props.onChange}
                disabled={props.configForm.mode === 'preview'}
                maxFile={1}
            />
        </div>
    )
}