import { InputDate, InputSelect, InputTextArea } from "../../../../../shared/Components/ElementInputsCostume"
import ElementModalCostume from "../../../../../shared/Components/ElementModalCostume"
import ElementViewDocumentsCostume from "../../../../../shared/Components/ElementViewDocumentsCostume";
import { EntityGenericDetailForm } from "../../../../../shared/Domain/EntityGenericDetailForm";
import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";
import { EntityRequestGenericForm } from "../../Domain/EntityRequest";
import { EntityConfigForm, EntityConfigModalAusencia, EntityFormModalAusencia } from "../../Domain/EntityUtilts";

interface ContainerProps {
    closeModalAusencia: () => void;
    configModalAusencia: EntityConfigModalAusencia;
    formModalAusencia: EntityGenericDetailForm<EntityFormModalAusencia>;
    onChangeModAUS: (name: string, value: any) => void;
    onSubmitModAUS: (evt: Event) => void;
    configForm: EntityConfigForm;
    formikForm: EntityGenericDetailForm<EntityRequestGenericForm>;
}

const languageTranslate = LanguageTranslate();

export const ModalFormAusencia = (props: ContainerProps) => {

    return (
        <ElementModalCostume
            closeModal={props.closeModalAusencia}
            show={props.configModalAusencia.show}
            title={props.configModalAusencia.mode === 'create' ? 'Agregar Ausencia' : (props.configModalAusencia.mode === 'preview' ? 'Visualizar Ausencia' : 'Editar Ausencia') }
        >
            <div className="ModalFormAusencia">

                <InputSelect
                    label={'Tipo Ausencia'}
                    name="TipoAusencia"
                    onChange={props.onChangeModAUS}
                    values={props.formModalAusencia.values}
                    options={props.configForm.dataOptions.tipoAusencia}
                    isRequired
                    disabled={props.configModalAusencia.mode === 'preview'}
                />

                <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <InputDate
                        label={languageTranslate.moduloTareo.detail.textFechaInicio}
                        name='FechaInicio'
                        onChange={props.onChangeModAUS}
                        values={props.formModalAusencia.values}
                        isRequired
                        removeMaxDate
                        removeMinDate
                        className="input-date-custom"
                        disabled={props.configModalAusencia.mode === 'preview'}
                    />

                    <InputDate
                        label={languageTranslate.moduloTareo.detail.textFechaFinal}
                        name='FechaFinal'
                        onChange={props.onChangeModAUS}
                        values={props.formModalAusencia.values}
                        isRequired
                        removeMaxDate
                        removeMinDate
                        className="input-date-custom"
                        disabled={props.configModalAusencia.mode === 'preview'}
                    />

                </div>

                <InputTextArea
                    label={'Observación'}
                    name='Observacion'
                    onChange={props.onChangeModAUS}
                    values={props.formModalAusencia.values}
                    disabled={props.configModalAusencia.mode === 'preview'}
                />

                <label className='mb-2'>Adjuntar archivo <span className='text-danger'>*</span></label>
                <ElementViewDocumentsCostume
                    name='Documento'
                    title={'Visualizar documento'}
                    documents={props.formModalAusencia.values['Documento']}
                    onChange={props.onChangeModAUS}
                    disabled={props.configModalAusencia.mode === 'preview'}
                    maxFile={1}
                />

                <div className="w-100 d-flex my-3 mt-4" style={{ gap: 10 }}>
                    {/* <button disabled={props.configModalAusencia.mode === 'preview'} className='btn btn-dark w-50' onClick={props.closeModalAusencia}>{languageTranslate.btnCancelar}</button> */}
                    <button disabled={props.configModalAusencia.mode === 'preview'} className='btn btn-primary w-100' onClick={(evt: any) => props.onSubmitModAUS(evt)}>Enviar a aprobación</button>
                </div>
            </div>
        </ElementModalCostume>
    )
}