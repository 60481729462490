import { ElementCollapseCostume } from "../../../../shared/Components/ElementCollapseCostume";
import { EntityAusenciaAprobacion } from "../../../../shared/Domain/Tareo/EntityAusenciaAprobacion";
import { AdapterGenerico } from "../../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { EntityListContent } from "../../Domain/EntityListContent";
import { EntityRequestDownloadFile } from "../../Domain/EntityRequestDownloadFile";
import { TableGenericDoc } from "../generic/TableGenericDoc";

interface ContainerProps {
    listContent: EntityListContent<EntityAusenciaAprobacion>;
    viewDocument: (payload: EntityRequestDownloadFile, code: string) => Promise<any>;
    show: boolean;
}

export const ViewAusencia = (props: ContainerProps) => {
    if (!props.show) return null;
    const languageTranslate = LanguageTranslate();

    return (
        <>
            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemDetail.textTitle}
                isOpenDefault
                persistsOpen
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textOT}:</span>
                        <span className='text-muted'> {props.listContent.dataComplete?.DatosTrabajo.OT.Code} {props.listContent.dataComplete?.DatosTrabajo.OT.OT} </span>
                    </div>
                    <div className='d-flex'>
                        <span>Fecha:</span>
                        <span className='text-muted'> &nbsp;
                            <span className='text-danger'>{props.listContent.dataComplete ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(props.listContent.dataComplete?.FechaInicio.DateString || ''), 3) : ''}</span>
                            &nbsp;hasta&nbsp;
                            <span className='text-danger'>{props.listContent.dataComplete ? AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(props.listContent.dataComplete?.FechaFin.DateString || ''), 3) : ''}</span>
                        </span>
                    </div>
                    <div className='d-flex'>
                        <span>Personal:</span>
                        <span className='text-muted'> {props.listContent.dataComplete?.Personal.Name} {props.listContent.dataComplete?.Personal.LastFather} {props.listContent.dataComplete?.Personal.LastMother} </span>
                    </div>
                    <div className='d-flex'>
                        <span>Ausencia:</span>
                        <span className='text-muted'> {props.listContent.dataComplete?.TipoAusencia.TipoAusencia} </span>
                    </div>
                    <div className='d-flex'>
                        <span>Observación:</span>
                        <span className='text-muted'> {props.listContent.dataComplete?.Observacion} </span>
                    </div>
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemDocumentos.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <TableGenericDoc
                        payload={props.listContent.documento}
                        viewDocument={props.viewDocument}
                    />
                </div>
            </ElementCollapseCostume>
        </>
    )
}