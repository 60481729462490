import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { EntityMenu } from "../../../shared/Domain/EntityMenu";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { changeSaludo } from "../../../shared/Infraestructure/SliceGenerico";

const languageTranslate = LanguageTranslate();

export const Controller = () => {
    let { auth: { menu }} = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();

    menu = menu.filter(row => row.esOpcion && row.padre === 40).sort((rowA, rowB) => rowA.orden - rowB.orden);
    const init = async () => {
        dispatch(changeSaludo(false));
    };
    
    const onClickMenu = (element: EntityMenu) => {
        navigate(`/${element.ruta}`, { replace: true });
    };

    return {
        init,
        menu,

        languageTranslate,
        onClickMenu,
    };
}