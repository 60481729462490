import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoRequestRecuperarPassword } from "../Domain/DtoRequestRecuperarPassword";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";

const languageTranslate = LanguageTranslate();
export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async recuperarPassword(params: DtoRequestRecuperarPassword): Promise<void> {
        return this._recuperarPassword(params);
    }

    private async _recuperarPassword(params: DtoRequestRecuperarPassword): Promise<void> {
        if (!navigator.onLine) {
            this.dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoSinConexion, languageTranslate.textoDescripcionSinConexion, 'warning');
            return;
        }
        return await this.service.call<any>("POST", `${this.urlBase}/usuario/resetPassword`, JSON.stringify(params), "basic", "json", "json", {}, 0);
        
        //await this.websocket.emit('ProcesoUsuario', { IdProceso: 10, DataUsuario: params });
    }
}