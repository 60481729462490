import { EntityRendicionGastoAprobacion } from "../../../../../shared/Domain/EntityRendicionGastoAprobacion";
import { keyModule } from "../Domain/EntityUtils";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseFind {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(code: string, keyModule: keyModule): Promise<EntityRendicionGastoAprobacion | null> {
        return await this._exec(code, keyModule);
    }

    private async _exec(code: string, keyModule: keyModule): Promise<EntityRendicionGastoAprobacion | null> {
        return await this.repository.findForm(code, keyModule);
    }
}