import { ElementCollapseCostume } from "../../../../shared/Components/ElementCollapseCostume"
import { ElementTableCostume } from "../../../../shared/Components/ElementTableCostume";
import { EntityWorkingCondition } from "../../../../shared/Domain/EntityWorkingCondition";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { EntityListContent } from "../../Domain/EntityListContent";
import { EntityRequestDownloadFile } from "../../Domain/EntityRequestDownloadFile";
import { TableGenericDoc } from "../generic/TableGenericDoc";

interface ContainerProps {
    listContent: EntityListContent<EntityWorkingCondition>;
    viewDocument: (payload: EntityRequestDownloadFile, code: string) => Promise<any>;
    show: boolean;
    copyCodeItem: Function;
}

export const ViewCondicionLaboral = (props: ContainerProps) => {
    if (!props.show) return null;
    const languageTranslate = LanguageTranslate();

    return (
        <>
            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemDetail.textTitle}
                isOpenDefault
                persistsOpen
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <div className='d-flex' onClick={(evt) => props.copyCodeItem(props.listContent.detail.codigo)}>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textCodigo}:</span>
                        <span className='text-muted'> {props.listContent.detail.codigo} </span>
                        <button className='icon-copy-custom'><i className="fa-solid fa-copy" /></button>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textPais}:</span>
                        <span className='text-muted'> {props.listContent.detail.pais} </span>
                        <span> {" "}|{" "} </span>
                        <span> {languageTranslate.moduloGenericDetail.itemDetail.textGrupo}: </span>
                        <span className='text-muted'> {props.listContent.detail.grupo} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textEmpresa}:</span>
                        <span className='text-muted'> {props.listContent.detail.empresa} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textOT}:</span>
                        <span className='text-muted'> {props.listContent.detail.ot.trim()} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textSolicitante}:</span>
                        <span className='text-muted'> {props.listContent.detail.solicitante} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textCargoSolicitante}:</span>
                        <span className='text-muted'> {props.listContent.detail.cargoSolicitante} </span>
                    </div>
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemEmpleado.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <label className='text-danger'>{languageTranslate.moduloGenericDetail.itemEmpleado.textTitleActuales}</label>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textEmpleado}:</span>
                        <span className='text-muted'> {props.listContent.empleado?.actual.empleado} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textDelegacion}:</span>
                        <span className='text-muted'> {props.listContent.empleado?.actual.delegacion} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textOT}:</span>
                        <span className='text-muted'> {props.listContent.empleado?.actual.OT} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textPuesto}:</span>
                        <span className='text-muted'> {props.listContent.empleado?.actual.puesto} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textRemuneracionMensual}:</span>
                        <span className='text-dark'> {props.listContent.empleado?.actual.remuneracionMensual} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textRemuneracionAnual}:</span>
                        <span className='text-dark'> {props.listContent.empleado?.actual.remuneracionAnual}</span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textRemuneracionAnualEuros}:</span>
                        <span className='text-dark'> {props.listContent.empleado?.actual.remuneracionAnualEuros} </span>
                    </div>

                    <hr />

                    <label className='text-danger'>{languageTranslate.moduloGenericDetail.itemEmpleado.textTitlePropuestas}</label>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textTipoCoteo}:</span>
                        <span className='text-muted'> {props.listContent.empleado?.propuesto.tipoCoteo} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textPuesto}:</span>
                        <span className='text-muted'> {props.listContent.empleado?.propuesto.puesto} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textFechaEfectiva}:</span>
                        <span className='text-muted'> {props.listContent.empleado?.propuesto.fechaEfectiva} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textOT}:</span>
                        <span className='text-muted'> {props.listContent.empleado?.propuesto.OT.trim()} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textRemuneracionMensual}:</span>
                        <span className='text-dark'> {props.listContent.empleado?.propuesto.remuneracionMensual} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textRemuneracionAnual}:</span>
                        <span className='text-dark'> {props.listContent.empleado?.propuesto.remuneracionAnual} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textRemuneracionAnualEuros}:</span>
                        <span className='text-dark'> {props.listContent.empleado?.propuesto.remuneracionAnualEuros} </span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textVarianza}:</span>
                        <span className='text-muted'> {props.listContent.empleado?.propuesto.varianza}%</span>
                    </div>
                    <div className='d-flex'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textMotivoAumentoSalarial}:</span>
                        <span className='text-muted'> {props.listContent.empleado?.propuesto.motivoAumentoSalarial.trim()}</span>
                    </div>
                    <div className='d-flex flex-wrap'>
                        <span>{languageTranslate.moduloGenericDetail.itemEmpleado.textJustificacionSolicitud}:</span>
                        <span className='text-muted'> {props.listContent.empleado?.propuesto.justificacionSolicitud.trim()} </span>
                    </div>
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemBitacora.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <ElementTableCostume
                        listHeader={languageTranslate.moduloGenericDetail.itemBitacora.headerTable}
                        listData={props.listContent.bitacora}
                    />
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemDocumentos.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <TableGenericDoc
                        payload={props.listContent.documento}
                        viewDocument={props.viewDocument}
                    />
                </div>
            </ElementCollapseCostume>
        </>
    )
}