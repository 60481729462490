// Component
import { ElementEmptyList } from "../../../../../../shared/Components/ElementEmptyList";
import ElementModalCostume from "../../../../../../shared/Components/ElementModalCostume"

// Domain
import { EntityFlujoOT } from "../../../../../../shared/Domain/Catalogos/EntityFlujoOT";
import { EntityGenericDetailForm } from "../../../../../../shared/Domain/EntityGenericDetailForm";
import { EntityLanguage } from "../../../../../../shared/Domain/EntityLanguage";
import { EntityRequestGenericForm } from "../../Domain/EntityRequest";
import { EntityConfigModalFlujo } from "../../Domain/EntityUtils"

interface ContainerProps {
    languageTranslate: EntityLanguage;
    formikForm: EntityGenericDetailForm<EntityRequestGenericForm>;
    configModalFlujo: EntityConfigModalFlujo;
    closeModalFlujo: () => void;
    onSubmitMFL: (evt: Event) => Promise<any>;
    onChangeMFL: (flujo: EntityFlujoOT) => void;
}

export const ViewFlujoOT = (props: ContainerProps) => {
    if (!props.configModalFlujo.show) return null;

    return (
        <>
            <ElementModalCostume
                title={props.languageTranslate.moduloRendicion.detail.textTituloFlujo}
                closeModal={props.closeModalFlujo}
                show={props.configModalFlujo.show}
                height={"500px"}
            >
                <div className="GroupContentBody">
                        {
                            props.configModalFlujo.flujo.map(row =>
                                <div className="ItemFlujo" onClick={() => props.onChangeMFL(row)}>
                                    <div className={`ItemCheck ${row.Code === props.configModalFlujo.flujoSelected?.Code ? 'ItemSelected' : ''}`}>
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                    <label>{row.Name}</label>
                                    <ul>
                                        {
                                            row.Aprobacion.map(_row =>
                                                <li className="text-muted">
                                                    {_row.Aprobador.Identificacion} - {_row.Aprobador.FullName}
                                                </li>    
                                            )
                                        }
                                    </ul>
                                </div>    
                            )
                        }
                        {
                            props.configModalFlujo.flujo.length === 0 ? <ElementEmptyList text="Esta OT/Delegación no presenta Flujos de aprobación" className="ElementEmptyListCustom"/> : null
                        }
                </div>
                {
                    props.configModalFlujo.flujo.length > 0 ? 
                    <div className="d-flex ButtonFixedBottom" style={{ gap: 10 }}>
                        <button
                            className="btn btn-dark w-100 mb-1"
                            onClick={props.closeModalFlujo}
                        >
                            {props.languageTranslate.btnCancelar}
                        </button>
                        <button
                            className="btn btn-primary w-100 mb-1"
                            onClick={(evt: any) => props.onSubmitMFL(evt)}
                        >
                            {props.languageTranslate.btnEnviar}
                        </button>
                    </div>
                    : null
                }
            </ElementModalCostume>
        </>
    )
}