import { RepositoryMain } from "../Domain/RepositoryMain";
import { DtoRequestLogin } from "../Domain/DtoRequestLogin";
import { DtoResponseLogin } from '../Domain/DtoResponseLogin';

export class UseCaseLogin {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: DtoRequestLogin): Promise<DtoResponseLogin | null> {
        return await this._exec(params);
    }

    private async _exec(params: DtoRequestLogin): Promise<DtoResponseLogin | null> {
        let result: DtoResponseLogin | null = await this.repository.login(params);
        return result;
    }
}