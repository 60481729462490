import { EntityMoneda } from "../../../../../shared/Domain/Catalogos/EntityMoneda";
import { EntityOT } from "../../../../../shared/Domain/Catalogos/EntityOT";
import { EntityProveedor } from "../../../../../shared/Domain/Catalogos/EntityProveedor";
import { EntitySelectBase } from "../../../../../shared/Domain/Catalogos/EntitySelectBase";
import { EntityTipoDocumento } from "../../../../../shared/Domain/Catalogos/EntityTipoDocumento";
import { EntityTipoDocumentoTributario } from "../../../../../shared/Domain/Catalogos/EntityTipoDocumentoTributario";
import { EntityVAT } from "../../../../../shared/Domain/Catalogos/EntityVAT";
import { EntityServicioCustom } from "./EntityUtils";

export interface EntityResponseInitialData {
    ot: EntitySelectBase<EntityOT>[];
    proveedor: EntitySelectBase<EntityProveedor>[];
    tipoDocumento: EntitySelectBase<EntityTipoDocumento>[];
    servicio: EntitySelectBase<EntityServicioCustom>[];
    catServicio: EntitySelectBase<EntityServicioCustom>[];
    catProducto: EntitySelectBase<EntityServicioCustom>[];
    // moneda: EntitySelectBase<EntityMoneda>[];
    tipoDocumentoTributario: EntitySelectBase<EntityTipoDocumentoTributario>[];
    VAT: EntitySelectBase<EntityVAT>[];
}

export const initEntityResponseInitialData: EntityResponseInitialData  = {
    ot: [],
    proveedor: [],
    tipoDocumento: [],
    servicio: [],
    catServicio: [],
    catProducto: [],
    // moneda: [],
    tipoDocumentoTributario: [],
    VAT: []
}

export interface EntityResponseServiceColombia {
    error: boolean;
    result: {
        _attributes: {
            xmlns: string;
        },
        No: {
            _text: string;
        },
        Description: {
            _text: string;
        },
        BaseUnitofMeasure: {
            _text: string;
        },
        UnitCost: {
            _text: string;
        }
    }[];
}