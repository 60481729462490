export interface EntityPendingWorkflow {
    GIAAPP_APPROVALS_SYSTEM_REQUIREMENT: number;
    GIAAPP_APPROVALS_PURCHA_SEREQUIREMENT: number;
    GIAAPP_APPROVALS_COMPARATIVE: number;
    GIAAPP_APPROVALS_INVOICE: number;
    GIAAPP_APPROVALS_PETTY_CASH_REQUEST: number;
    GIAAPP_APPROVALS_SURRENDER_PETTY_CASH_EXPENSES: number;
    GIAAPP_APPROVALS_REQUEST_FORM: number;
    GIAAPP_APPROVALS_WORKING_CONDITIONS: number;
    GIAAPP_APPROVALS_GASTOAPROBACCION: number;
    GIAAPP_APPROVALS_TAREO: number;
    GIAAPP_APPROVALS_AUSENCIA: number;
}


export const initEntityPendingWorkflow: EntityPendingWorkflow = {
    GIAAPP_APPROVALS_SYSTEM_REQUIREMENT: 0,
    GIAAPP_APPROVALS_PURCHA_SEREQUIREMENT: 0,
    GIAAPP_APPROVALS_COMPARATIVE: 0,
    GIAAPP_APPROVALS_INVOICE: 0,
    GIAAPP_APPROVALS_PETTY_CASH_REQUEST: 0,
    GIAAPP_APPROVALS_SURRENDER_PETTY_CASH_EXPENSES: 0,
    GIAAPP_APPROVALS_REQUEST_FORM: 0,
    GIAAPP_APPROVALS_WORKING_CONDITIONS: 0,
    GIAAPP_APPROVALS_GASTOAPROBACCION: 0,
    GIAAPP_APPROVALS_TAREO: 0,
    GIAAPP_APPROVALS_AUSENCIA: 0
}