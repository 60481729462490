// Library
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useState } from "react";

// Infraestructure
import { RootState } from "../../../../shared/Infraestructure/AdapterStore";
import { addLoading, changeSaludo, removeLoading } from "../../../../shared/Infraestructure/SliceGenerico";
import { AdapterGenerico } from "../../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { AdapterConfigure } from "./AdapterConfigure";

// Domain
import { EntityConfigForm, EntityConfigModalDetalle, EntityConfigModalMessageReject, EntityFormModalDetalle, EntityFormModalNotes, initEntityConfigForm, initEntityConfigModalDetalle, initEntityConfigModalMessageReject, initEntityFormModalNotes } from "../Domain/EntityUtils";

// Application
import { UseCaseFind } from "../Application/UseCaseFind";
import { EntityDias } from "../../../../Tareo/Detail/Personal/Domain/EntityRequest";
import { EntityRequestAddNoteModal, EntityRequestDetail } from "../Domain/EntityRequestDetail";
import { UseCaseSave } from "../Application/UseCaseSave";

const languageTranslate = LanguageTranslate();

export const Controller = () => {
    
    const { generico: { websocket, dbLocal }, auth: { user } } = useSelector((state: RootState) => state);
    const [configForm, setConfigForm] = useState<EntityConfigForm>(initEntityConfigForm);
    const [configModalMessageReject, setConfigModalMessageReject] = useState<EntityConfigModalMessageReject>(initEntityConfigModalMessageReject);
    const [configModalDetalle, setConfigModalDetalle] = useState<EntityConfigModalDetalle>(initEntityConfigModalDetalle);
    const [configModalNote, setConfigModalNote] = useState<EntityFormModalNotes>(initEntityFormModalNotes);
    const dispatch: Dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const init = () => {
        dispatch(changeSaludo(false));
        configModeType();
        AdapterGenerico.scrollTopByClassName('ViewTareoAprobacion');
    };

    const configModeType = async () => {
        const findForm = await new UseCaseFind(repository).exec(`${params.id}`);
        if (!findForm) {
            AdapterGenerico.createMessage('Error', `No se encontró el detalle para: ${params.id}`, 'error').then(res => navigate(AdapterConfigure.LIST_TAREO_APROBACION)); 
            return;
        };

        // Configurar Dias
        let ListDia: EntityDias[] = findForm.Dias.map(row => ({
            ...row,
            HorasTrabajador: 0
        }));

        // Acumular las Horas de las tareas
        for (const row of findForm.Tareas) {
            row.Horas.forEach((item, index) => {
                ListDia[index].HorasTrabajador += item.Horas;
            })
        }

        findForm.Dias = ListDia;

        setConfigForm((prev) => ({
            data: findForm,
            modeForm: 'preview',
            navBar: languageTranslate.moduloTareo.navBarAprobacion
        }))
    };

    // Modal Detalle
    const openModalDetalle = (payload: EntityDias) => {
        const ListDetail: EntityFormModalDetalle[] = configForm.data.Tareas.map((row, index) => {
            return ({
                PositionTarea: index,
                OT: row.Proyecto.OT.OT,
                Disciplina: row.Disciplina.Name,
                IdDia: payload.IdDia,
                Id: payload.Id,
                Horas: row.Horas.find(_row => _row.Id === payload.Id)?.Horas || 0,
            });
        });

        let message = '';

        if (payload.Ausencia) message = `Este día el personal tiene ausencia de "${payload.Ausencia.TipoAusencia}".`;
        if (payload.Bloqueado) message = `Este día el personal tiene bloqueado por "${payload.Bloqueado.Motivo}".`;
        else if (payload.Tipo === AdapterConfigure.IDENTIFIER_TIPO_FESTIVO) message = `Este día esta considerado como "Día Festivo".`;

        setConfigModalDetalle((prev) => ({
            title: `Detalle - ${payload.DiaMes} / ${languageTranslate.moduloTareo.days[payload.Nombre]}`,
            show: true,
            IdDia: payload.IdDia,
            ListDetail,
            message
        }))
    }

    const closeModalDetalle = () => {
        setConfigModalDetalle((prev) => ({
            show: false,
            title: '',
            IdDia: 0,
            ListDetail: [],
            message: ''
        }))
    }

    // ------------------- Modal Agregar Nota --------------------
    const openModalNote = () => {
        setConfigModalNote(() => ({
            note: '',
            show: true
        }))
    }

    const closeModalNote = () => {
        setConfigModalNote(() => ({
            note: '',
            show: false
        }))
    }

    const onChangeMN = (name: string, value: any) => {
        setConfigModalNote((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const onSubmitMN = async (evt: Event) => {
        try {
            evt.preventDefault();
            evt.stopPropagation();
            
            if (configModalNote.note === '') {
                AdapterGenerico.createMessage(languageTranslate.textoIncompleto, 'Ingrese un comentario', 'warning', false);
                return null;
            }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            const payload: EntityRequestAddNoteModal = {
                codigo: configForm.data.Codigo,
                nota: {
                    descripcion: configModalNote.note
                },
                user: {
                    IdUser: user.IdUser,
                    LastFather: user.LastNameFather,
                    LastMother: user.LastNameMother,
                    Name: user.Name,
                    Perfil: user.Profile.find(row => row.Principal === 'SI')?.Perfil || '',
                    User: user.User
                }
            }

            const response = await repository.addNote(payload);
            if (!response || response.length === 0) return; 
            setConfigForm((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    Notas: response
                }
            }))

            closeModalNote();
        } catch(error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    // Aprobar - Rechazar
    const confirmDetail = async () => {
        const message = `${languageTranslate.moduloApprovals.question.textoDesea} ${languageTranslate.moduloApprovals.question.textoAprobar} el siguiente registro?`;
        const isAccept = await AdapterGenerico.createMessage('', message, 'info', true);
        if (!isAccept || !configForm.data.Codigo) return;
        const updateDate = (configForm.data.Dates as any)?.Update?.Date;
        const bitacora = configForm.data.Bitacora || [];
        let paramDate = !!updateDate ? updateDate : bitacora[(bitacora.length - 1)].Date.Date;

        const payload: EntityRequestDetail = {
            paramsSend: {
                codigo: configForm.data.Codigo,
                identificacion: user.Identificacion,
                fecha: paramDate
            },
            extraConfig: {
                dataComplete: configForm.data,
                typeSave: 'approve'
            }
        }

        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoEnviando }))
            let response = await new UseCaseSave(repository).exec(payload);
            if (response) navigate(AdapterConfigure.LIST_TAREO_APROBACION);
        }
        catch (error) {
            let msg = (error as Error).message;
            if (msg === 'error-date-update') {
                msg = `No se puede ${languageTranslate.moduloApprovals.question.textoAprobar}, el registro actualmente cuenta con modificaciones`
            }
            msg = `${msg} - ${configForm.data.Codigo}`
            AdapterGenerico.createMessage('Error', msg, 'error', false);
        }
        finally {
            dispatch(removeLoading());
        }
    }

    const openModalMessageReject = () => {
        setConfigModalMessageReject(prev => ({
            ...prev,
            title: 'Devolver',
            comment: '',
            description: 'Por favor confirme esta acción y agregue un comentario explicando por qué está devolviendo este registro.',
            show: true
        }))
    }

    const closeModalMessageReject = () => {
        setConfigModalMessageReject(prev => ({
            ...prev,
            title: '',
            comment: '',
            description: '',
            show: false
        }))
    }

    const onChangeMMS = (name: string, value: any) => {
        setConfigModalMessageReject(prev => ({
            ...prev,
            comment: value,
        }))
    }

    const onSubmitMMS = async () => {
        if (!configModalMessageReject.comment) return AdapterGenerico.createMessage('Error', 'Ingrese un comentario del porque se esta rechazando', 'error', false);
        const updateDate = (configForm.data.Dates as any)?.Update?.Date;
        const bitacora = configForm.data.Bitacora || [];
        let paramDate = !!updateDate ? updateDate : bitacora[(bitacora.length - 1)].Date.Date;

        const payload: EntityRequestDetail = {
            paramsSend: {
                codigo: configForm.data.Codigo,
                identificacion: user.Identificacion,
                fecha: paramDate,
                comentario: configModalMessageReject.comment
            },
            extraConfig: {
                dataComplete: configForm.data,
                typeSave: 'return'
            }
        }

        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoEnviando }))
            let response = await new UseCaseSave(repository).exec(payload);
            if (response) navigate(AdapterConfigure.LIST_TAREO_APROBACION);
        }
        catch (error) {
            let msg = (error as Error).message;
            if (msg === 'error-date-update') {
                msg = `No se puede ${languageTranslate.moduloApprovals.question.textoAprobar}, el registro actualmente cuenta con modificaciones`
            }
            msg = `${msg} - ${configForm.data.Codigo}`
            AdapterGenerico.createMessage('Error', msg, 'error', false);
        }
        finally {
            dispatch(removeLoading());
        }
    }

    return ({
        init,
        configForm,

        // Modal Detalle
        configModalDetalle,
        closeModalDetalle,
        openModalDetalle,

        // Functions Modal Notes
        configModalNote,
        openModalNote,
        closeModalNote,
        onChangeMN,
        onSubmitMN,

        // Boton de aprobar
        confirmDetail,
        configModalMessageReject,
        openModalMessageReject,
        closeModalMessageReject,
        onChangeMMS,
        onSubmitMMS
    })
}