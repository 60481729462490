import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { EntityMenu } from "../../../shared/Domain/EntityMenu";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { changeSaludo } from "../../../shared/Infraestructure/SliceGenerico";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { AdapterConfigure } from "./AdapterConfigure";
import { UseCaseStatsMyAccount } from "../Application/UseCaseStatsMyAccount";
import { useState } from "react";
import { EntityStatsMyAccount, initEntityStatsMyAccount } from "../Domain/EntityUtils";

const languageTranslate = LanguageTranslate();

export const Controller = () => {
    let { auth: { menu, user }, generico: { websocket, dbLocal } } = useSelector((state: RootState) => state);
    const [statsAccount, SetStatsAccount] = useState<EntityStatsMyAccount>(initEntityStatsMyAccount);
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    menu = menu.filter(row => row.esOpcion && row.padre === 30).sort((rowA, rowB) => rowA.orden - rowB.orden);
    const init = async () => {
        dispatch(changeSaludo(false));
        reloadStatsAccount();
    };
    
    const onClickMenu = (element: EntityMenu) => {
        navigate(`/${element.ruta}`, { replace: true });
    };
    
    const reloadStatsAccount = async () => {
        SetStatsAccount((prev) => ({ ...prev, loading: true }))
        const result = await new UseCaseStatsMyAccount(repository).exec({ User: user.User }); // 'RALANIAS'
        if (!!result) {
            SetStatsAccount(() => ({...result, show: true, loading: false}));
        } else {
            SetStatsAccount((prev) => ({...prev, show: false, loading: false}));
        }
    }

    return {
        init,
        menu,

        languageTranslate,
        onClickMenu,
        statsAccount,
        reloadStatsAccount
    };
}