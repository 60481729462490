import { InputTextArea } from "../../../../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../../../../shared/Components/ElementModalCostume";
import { EntityLanguage } from "../../../../../../shared/Domain/EntityLanguage";
import { EntityConfigModalNotes } from "../../Domain/EntityUtils";

interface ContainerProps {
    configModalNote: EntityConfigModalNotes;
    languageTranslate: EntityLanguage;
    closeModalNotes(): void;
    onSubmitMN(evt: Event): Promise<any>;
    onChangeMN(name:string, value: string): void;
}

export const ViewNote = (props: ContainerProps) => {
    if (!props.configModalNote.show) return null;

    return (
        <>
            <ElementModalCostume
                title={props.languageTranslate.moduloGastoAprobacion.viewHistory.textTitleNote}
                closeModal={props.closeModalNotes}
                show={props.configModalNote.show}
                height={"300px"}
            >
                <div className="ViewnModalNotes">
                    {/*<InputText
                        label={props.languageTranslate.moduloGastoAprobacion.viewHistory.textTitulo}
                        name="titulo"
                        onChange={props.onChangeMN}
                        values={{ titulo: props.configModalNote.titulo }}
                        isRequired
                    />*/} 
                    <InputTextArea
                        label={props.languageTranslate.moduloGastoAprobacion.viewHistory.textTitleComentario}
                        name="comentario"
                        onChange={props.onChangeMN}
                        values={{ comentario: props.configModalNote.comentario }}
                        isRequired
                    />
                </div>
                <div className='group-button d-flex' style={{ gap: 10, marginTop: 10 }}>
                    <button className='btn btn-dark w-100' onClick={props.closeModalNotes}> {props.languageTranslate.btnCancelar} </button>
                    <button className={`btn btn-primary w-100`} onClick={(evt: any) => props.onSubmitMN(evt)}> {props.languageTranslate.btnGuardar} </button>
                </div>
            </ElementModalCostume>
        </>
    )
}