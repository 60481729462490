import { ElementCollapseCostume } from "../../../../shared/Components/ElementCollapseCostume"
import { ElementTableCostume } from "../../../../shared/Components/ElementTableCostume";
import { EntitySurrenderPettyCashExpenses } from "../../../../shared/Domain/EntitySurrenderPettyCashExpenses";
import { AdapterGenerico } from "../../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { EntityListContent } from "../../Domain/EntityListContent";
import { EntityRequestDownloadFile } from "../../Domain/EntityRequestDownloadFile";
import { keyModal } from "../../Domain/EntityUtils";
import { TableGenericDoc } from "../generic/TableGenericDoc";

interface ContainerProps {
    listContent: EntityListContent<EntitySurrenderPettyCashExpenses>;
    viewDocument: (payload: EntityRequestDownloadFile, code: string) => Promise<any>;
    openModal: (key: keyModal) => void;
    show: boolean;
    copyCodeItem: Function;
}

export const ViewRendicionGastosCajaChica = (props: ContainerProps) => {
    if (!props.show) return null;
    const languageTranslate = LanguageTranslate();

    return (
        <>
            <ElementCollapseCostume
                    textTitle={languageTranslate.moduloGenericDetail.itemDetail.textTitle}
                    isOpenDefault
                    persistsOpen
                    className='my-3'
                >
                    <div className='ContentItemCollapse'>
                        <div className='d-flex' onClick={(evt) => props.copyCodeItem(props.listContent.detail.codigo)}>
                        <span>{languageTranslate.moduloGenericDetail.itemDetail.textCodigo}:</span>
                        <span className='text-muted'> {props.listContent.detail.codigo} </span>
                        <button className='icon-copy-custom'><i className="fa-solid fa-copy" /></button>
                    </div>
                        <div className='d-flex'>
                            <span>{languageTranslate.moduloGenericDetail.itemDetail.textPais}:</span>
                            <span className='text-muted'> {props.listContent.detail.pais} </span>
                            <span> {" "}|{" "} </span>
                            <span> {languageTranslate.moduloGenericDetail.itemDetail.textGrupo}: </span>
                            <span className='text-muted'> {props.listContent.detail.grupo} </span>
                        </div>
                        <div className='d-flex'>
                            <span>{languageTranslate.moduloGenericDetail.itemDetail.textTrabajador}:</span>
                            <span className='text-muted'> {props.listContent.detail.proveedor} </span>
                        </div>
                        <div className='d-flex'>
                            <span>{languageTranslate.moduloGenericDetail.itemDetail.textOT}:</span>
                            <span className='text-muted'> {props.listContent.detail.ot} </span>
                        </div>
                        <div className='d-flex'>
                            <span>{languageTranslate.moduloGenericDetail.itemDetail.textImporte}: </span>
                            <span className='text-danger'> {AdapterGenerico.formatoDinero(props.listContent.detail.importe)} ({props.listContent.detail.moneda}) </span>
                        </div>
                        <div className="d-flex">
                            <span>{languageTranslate.moduloGenericDetail.itemDetail.textFechaRegistro}:</span>
                            <span className='text-muted'> {props.listContent.detail.fechaRegistro}</span>
                        </div>
                    </div>
                </ElementCollapseCostume>

                <ElementCollapseCostume
                    textTitle={languageTranslate.moduloGenericDetail.itemRendicion.textTitle}
                    className='my-3'
                >
                    <div className='ContentItemCollapse'>
                        <ElementTableCostume
                            className="TableNoWrap"
                            listHeader={languageTranslate.moduloGenericDetail.itemRendicion.headerTable}
                            listData={props.listContent.rendicion}
                        />

                        <div className='d-flex w-100' style={{ borderTop: '2px solid #dee2e6', paddingTop: 10 }}>
                            <span className='ms-auto'>{languageTranslate.moduloGenericDetail.itemRendicion.textImporteTotal}:</span>
                            <span className='text-muted'> {AdapterGenerico.formatoDinero(props.listContent.rendicion.reduce((prev, current) => prev + current.importeTypeNumber, 0))} </span>
                        </div>
                    </div>
                </ElementCollapseCostume>

                <ElementCollapseCostume
                    textTitle={languageTranslate.moduloGenericDetail.itemNotas.textTitle}
                    className='my-3'
                >
                    <div className='ContentItemCollapse'>
                        <div className="w-100 mb-2" style={{ textAlign: 'end' }}>
                            <button className="btn btn-primary" onClick={() => props.openModal('notes')}>Agregar</button>
                        </div>
                        <ElementTableCostume
                            listHeader={languageTranslate.moduloGenericDetail.itemNotas.headerTable}
                            listData={props.listContent.notas}
                            className="CustomTableNota"
                        />
                    </div>
                </ElementCollapseCostume>

                <ElementCollapseCostume
                    textTitle={languageTranslate.moduloGenericDetail.itemBitacora.textTitle}
                    className='my-3'
                >
                    <div className='ContentItemCollapse'>
                        <ElementTableCostume
                            listHeader={languageTranslate.moduloGenericDetail.itemBitacora.headerTable}
                            listData={props.listContent.bitacora}
                        />
                    </div>
                </ElementCollapseCostume>

                <ElementCollapseCostume
                    textTitle={languageTranslate.moduloGenericDetail.itemDocumentos.textTitle}
                    className='my-3'
                >
                    <div className='ContentItemCollapse'>
                        <TableGenericDoc
                            payload={props.listContent.documento}
                            viewDocument={props.viewDocument}
                        />
                    </div>
                </ElementCollapseCostume>
        </>
    )
}