import { InputText } from "../../../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../../../shared/Components/ElementModalCostume"
import { ElementTableCostume } from "../../../../../shared/Components/ElementTableCostume"
import { EntityGenericDetailForm } from "../../../../../shared/Domain/EntityGenericDetailForm";
import { AdapterGenerico } from "../../../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";
import { EntityRequestGenericForm } from "../../Domain/EntityRequest";
import { EntityConfigModalDetalle, EntityFormModalDetalle } from "../../Domain/EntityUtilts";
import { AdapterConfigure } from "../../Infraestructure/AdapterConfigure";

interface ContainerProps {
    closeModalDetalle: () => void;
    configModalDetalle: EntityConfigModalDetalle;
    formModalDetalle: EntityGenericDetailForm<EntityFormModalDetalle[]>;
    formikForm: EntityGenericDetailForm<EntityRequestGenericForm>;
    onChangeMD: (value: any, index: number) => void;
    onSubmitMD: (evt: Event) => void;
    openModalItem: () => void;
}

const languageTranslate = LanguageTranslate();

export const ModalDetalle = (props: ContainerProps) => {

    return (
        <ElementModalCostume
            closeModal={props.closeModalDetalle}
            show={props.configModalDetalle.show}
            title={props.configModalDetalle.title}
        >
            <div className="ModalDetalle">
                {
                    props.configModalDetalle.isDiaFestivo ?
                        <div className='alert alert-warning' style={{ textAlign: 'center' }}>
                            <span style={{ fontSize: '0.8rem' }}>
                                Este día esta considerado como "Día Festivo".
                            </span>
                        </div>
                    : null
                }
                {
                    [0, 2].includes(props.formikForm.values.Estado.IdStatus) ?
                        <div className="w-100 d-flex" style={{ justifyContent: 'right', gap: 10 }}>
                            <button className='btn btn-primary' onClick={props.openModalItem}>{languageTranslate.btnAgregar}</button>
                        </div>
                    : null
                }

                <ElementTableCostume
                    listData={props.formModalDetalle.values}
                    listHeader={[
                        { key: 'OT', label: languageTranslate.moduloTareo.detail.textOT },
                        { key: 'Disciplina', label: languageTranslate.moduloTareo.detail.textDisciplina },
                        { key: 'Horas', label: '', render: (text, data, index) => 
                            <InputText
                                label=""
                                onChange={(name, newHour) => props.onChangeMD(newHour, index)}
                                values={{ Horas: data.Horas }}
                                name="Horas"
                                type="time"
                                className="CustomInputModalDetalle"
                                max={AdapterConfigure.HORA_MAXIMA_DIA}
                                key={index}
                            />
                        }
                    ]}
                    className='my-4'
                />

                <div className="w-100 d-flex" style={{ justifyContent: 'right' }}>
                    <span><strong>{languageTranslate.moduloTareo.detail.textTotalHoras}: {
                        AdapterGenerico.sumarHoras(props.formModalDetalle.values.map(row => {
                            let [horas, minutos] = row.Horas.split(':');
                            let horaFormatedd = parseFloat(`${horas}.${minutos}`);
                            return horaFormatedd;
                        }))
                    }</strong></span>
                </div>

                {
                    [0, 2].includes(props.formikForm.values.Estado.IdStatus) ?
                        <div className="d-flex my-3" style={{ gap: 10 }}>
                            <button className='btn btn-dark w-50' onClick={props.closeModalDetalle}>{languageTranslate.btnCancelar}</button>
                            <button className='btn btn-primary w-50' onClick={(evt: any) => props.onSubmitMD(evt)}>{languageTranslate.btnGuardar}</button>
                        </div>
                    : null
                }

            </div>
        </ElementModalCostume>
    )
}