export interface EntityLoadMaster {
    CTFlujosOT: (permitido: string[]) => BaseTemplateCatalogo;
    CTFlujosDelegacion: (permitido: { pais: string[]; delegacion: string[]; }) => BaseTemplateCatalogo;
    CTMoneda: (permitido: string[]) => BaseTemplateCatalogo;
    CTOT: (permitido: string[]) => BaseTemplateCatalogo;
    CTProveedor: (permitido: string[]) => BaseTemplateCatalogo;
    CTServicio: (permitido: string[]) => BaseTemplateCatalogo;
    CTTipoCambio: (permitido: string[]) => BaseTemplateCatalogo;
    CTTipoDocumento: (permitido: string[]) => BaseTemplateCatalogo;
    CTVAT: (permitido: string[]) => BaseTemplateCatalogo;
    CTTipoDocumentoTributario: (permitido: []) => BaseTemplateCatalogo;
    CTProyectos: (permitido: []) => BaseTemplateCatalogo;
    CTPersonal: (permitido: { identificacion: string }) => BaseTemplateCatalogo;
    CTProducto: (permitido: { pais: string[] }) => BaseTemplateCatalogo;
    CTPeriodo: (permitido: { ot: string[] }) => BaseTemplateCatalogo;
    CTDisciplina: (permitido: { ot: string[] }) => BaseTemplateCatalogo;
    CTTipoAusencia: () => BaseTemplateCatalogo;
}

export type BaseTemplateCatalogo = {
    Filtros: any[];
    CollectionName: "Catalogo_FlujosOT" | "Catalogo_OT" | "Catalogo_Proveedor" | "Catalogo_Moneda" | "Catalogo_TipoCambio" | "Catalogo_TipoDocumento" | "Catalogo_Servicio" | "Catalogo_VAT" | "MasterTable_DocumentTypePayment" | "Catalogo_Proyectos" | "Catalogo_Personal" | "Catalogo_Producto" | "Catalogo_FlujosDelegacion" | "Catalogo_Disciplina" | "Catalogo_TiposAusencias" | "";
    CollectionNameLocal: "CTFlujosOT" | "CTOT" | "CTProveedor" | "CTMoneda" | "CTTipoCambio" | "CTTipoDocumento" | "CTServicio" | "CTVAT" | "CTTipoDocumentoTributario" | "CTProyectos" | "CTPersonal" | "CTProducto" | "CTFlujosDelegacion" | "CTPeriodo" | "CTDisciplina" | "CTTipoAusencia";
    DataBase: "gia" | "";
    CustomFilter?: any;
}

export const paramsCatalogos: EntityLoadMaster = {
    CTFlujosOT: (permitido: string[]) => ({
        Filtros: [ { "$match": { "Status.IdStatus": 1, "OT.Code": { "$in": permitido }, "TipoOperacion.Codigo": { "$in": ['O01', 'O02', 'O03'] } } } ],
        CollectionName: "Catalogo_FlujosOT",
        CollectionNameLocal: "CTFlujosOT",
        DataBase: "gia"
    }),
    CTFlujosDelegacion: (permitido: { pais: string[]; delegacion: string[]; }) => ({
        Filtros: [ { "$match": { "Status.IdStatus": 1, "DatosPais.CDPais": { "$in": permitido.pais }, "Delegacion.Code": { "$in": permitido.delegacion }, "TipoOperacion.Codigo": { "$in": ['O01', 'O02', 'O03'] } } } ],
        CollectionName: "Catalogo_FlujosDelegacion",
        CollectionNameLocal: "CTFlujosDelegacion",
        DataBase: "gia"
    }),
    CTMoneda: (permitido: string[]) => ({
        Filtros: [ { "$project": { "Dates": 0, "Users": 0, "Bitacora": 0  } }],
        CollectionName: "Catalogo_Moneda",
        CollectionNameLocal: "CTMoneda",
        DataBase: "gia"
    }),
    CTOT: (permitido: string[]) => ({
        Filtros: [
            { "$match": { "Status": 1, 
            // "Code": { "$in": permitido } 
        } },
            { "$project": { "Bitacora": 0, "Notas": 0, "Users": 0, "Status": 0, "Dates": 0 } }
        ],
        CollectionName: 'Catalogo_OT',
        CollectionNameLocal: 'CTOT',
        DataBase: "gia"
    }),
    CTProveedor: (permitido: string[]) => ({
        Filtros: [
            { "$match": { "Status.IdStatus": 1, "Country.Key": { "$in": permitido }, "DatosTributacion.TipoContribuyente.Key": { $nin: ["01", "02", ""] } } },
            { "$project": { "Bitacora": 0, "Users": 0, "LoginExterno": 0, "Geografy": 0, "DatosTributacion": 0, "DatosGenerales": 0, "Dates": 0, "Status": 0 } }
        ],
        CollectionName: 'Catalogo_Proveedor',
        CollectionNameLocal: 'CTProveedor',
        DataBase: 'gia'
    }),
    CTServicio: (permitido: string[]) => ({
        Filtros: [
            { "$match": { "Status.IdStatus": 1, "Country.Key": { "$in": permitido }, "Permitido.CajaChica": true } },
            { "$project": { "Bitacora": 0, "Users": 0, "Dates": 0, "Status": 0 } }
        ],
        CollectionName: 'Catalogo_Servicio',
        CollectionNameLocal: 'CTServicio',
        DataBase: 'gia'
    }),
    CTTipoCambio: (permitido: string[]) => ({
        Filtros: [
            { "$match": { "Status.IdStatus": 1 } },
            { "$project": { "Bitacora": 0, "Users": 0, "Dates": 0, "Status": 0 } },
            { "$sort": { "_id": -1 } },
            { "$limit": 10 }
        ],
        CollectionName: 'Catalogo_TipoCambio',
        CollectionNameLocal: 'CTTipoCambio',
        DataBase: 'gia'
    }),
    CTTipoDocumento: (permitido: string[]) => ({
        Filtros: [
            { "$match": { "Status.IdStatus": 1 } },
            { "$project": { "Bitacora": 0, "Users": 0, "Dates": 0, "Status": 0 } }
        ],
        CollectionName: 'Catalogo_TipoDocumento',
        CollectionNameLocal: 'CTTipoDocumento',
        DataBase: 'gia'
    }),
    CTVAT: (permitido: string[]) => ({
        Filtros: [
            { "$match": { "Status.IdStatus": 1 } },
            { "$project": { "Status": 0, "Dates": 0, "Users": 0, "Bitacora": 0 } }
        ],
        CollectionName: 'Catalogo_VAT',
        CollectionNameLocal: 'CTVAT',
        DataBase: 'gia'
    }),
    CTTipoDocumentoTributario: (permitido: string[]) => ({
        Filtros: [
            { "$match": { "No_": "10", "GIA Caja Chica": 0 } }
        ],
        CollectionName: 'MasterTable_DocumentTypePayment',
        CollectionNameLocal: 'CTTipoDocumentoTributario',
        DataBase: 'gia'
    }),
    CTProyectos: (permitido: string[]) => ({
        Filtros: [
            { "$match": { "DatosTrabajo.OT.Codigo": { "$in": permitido } } },
            { "$project": { "Status": 0, "Dates": 0, "Users": 0, "Bitacora": 0 } }
        ],
        CollectionName: 'Catalogo_Proyectos',
        CollectionNameLocal: 'CTProyectos',
        DataBase: 'gia'
    }),
    CTPersonal: (permitido: { identificacion: string }) => ({
        Filtros: [
            { "$match": { "Identificacion": permitido.identificacion } },
            { "$project": { "Status": 0, "Dates": 0, "Users": 0, "Bitacora": 0, "Notas": 0 } }
        ],
        CollectionName: 'Catalogo_Personal',
        CollectionNameLocal: 'CTPersonal',
        DataBase: 'gia'
    }),
    CTProducto: (permitido: { pais: string[] }) => ({
        Filtros: [
            { "$match": { "Detalle.TipoItem.Key": "02", "Status.IdStatus": 1, "Country.Code": { "$in": permitido.pais.filter(row => ['CL', 'EC'].includes(row))} } },
            { "$project": { "Status": 0, "Dates": 0, "Users": 0, "Bitacora": 0 } }
        ],
        CollectionName: 'Catalogo_Producto',
        CollectionNameLocal: 'CTProducto',
        DataBase: 'gia'
    }),
    CTPeriodo: (permitdo: { ot: string[] }) => ({
        Filtros: [
            {
                filter: {},
                field: {}
            }
        ],
        CollectionName: '',
        CollectionNameLocal: 'CTPeriodo',
        DataBase: ''
    }),
    CTDisciplina: (permitdo: { ot: string[] }) => ({
        Filtros: [],
        CollectionName: 'Catalogo_Disciplina',
        CollectionNameLocal: 'CTDisciplina',
        DataBase: 'gia'
    }),
    CTTipoAusencia: () => ({
        Filtros: [
            { "$match": { "Status.IdStatus": 1 } },
            { "$project": { "Dates": 0, "Status": 0, "Users": 0, "Bitacora": 0 } }
        ],
        CollectionName: 'Catalogo_TiposAusencias',
        CollectionNameLocal: 'CTTipoAusencia',
        DataBase: 'gia'
    })
};