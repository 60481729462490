import { EntityFlujoOT } from "../../../../../shared/Domain/Catalogos/EntityFlujoOT";
import { EntityFlujosDelegacion } from "../../../../../shared/Domain/Catalogos/EntityFlujosDelegacion";
import { EntityOT } from "../../../../../shared/Domain/Catalogos/EntityOT";
import { EntityProyectos } from "../../../../../shared/Domain/Catalogos/EntityProyectos";
import { EntitySelectBase } from "../../../../../shared/Domain/Catalogos/EntitySelectBase";
import { EntityTipoDocumentoTributario } from "../../../../../shared/Domain/Catalogos/EntityTipoDocumentoTributario";
import { EntityAnticipo } from "../../../../../shared/Domain/EntityAnticipo";

export interface EntityResponseInitialData {
    ot: EntitySelectBase<EntityOT>[];
    // moneda: EntitySelectBase<EntityMoneda>[];
    tipoDocumentoTributario: EntitySelectBase<EntityTipoDocumentoTributario>[];
    proyecto: EntitySelectBase<EntityProyectos>[];
    flujoOT: EntityFlujoOT[];
    flujoDelegacion: EntityFlujosDelegacion[];
}

export const initEntityResponseInitialData: EntityResponseInitialData  = {
    ot: [],
    // moneda: [],
    tipoDocumentoTributario: [],
    proyecto: [],
    flujoOT: [],
    flujoDelegacion: []
}

export interface EntityResponseServiceSave {
    status: boolean;
    message: string;
    register: EntityAnticipo[];
}

export interface EntityResponseAddNote {
    result: boolean;
    data: {
        IdRegistro: number;
        Action: string;
        Date: {
            Year: number;
            IdDate: number;
            Date: string;
        }
        User: {
            IdUser: string;
            User: string;
            LastName: string;
            Name: string;
            Perfil: string; 
        },
        Description: string;
    }
}