export interface EntityConfigModal {
    show: boolean;
    title: string;
    description: string;
    comment: string;
    key: keyModal;
    payload?: any;
}

export const initEntityConfigModal: EntityConfigModal = {
    show: false,
    title: "",
    description: "",
    comment: "",
    key: "notes"
}

export type keyModal = 'notes' | 'reject' | 'product' | 'resume-factura';