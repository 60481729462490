import { EntityFlujoOT } from "../../../../../shared/Domain/Catalogos/EntityFlujoOT";
import { EntityGasto } from "../../../../../shared/Domain/EntityGasto";
import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";
import { EntityResponseInitialData, initEntityResponseInitialData } from "./EntityResponse";

export interface EntityConfigForm {
    key: keyModule;
    mode: ModeForm;
    navBar: EntityNavBar[];
    loadingForm: boolean;
    dataOptions: EntityResponseInitialData;
    step: number;
}

export interface EntityNavBar {
    text: string;
    navigate: boolean;
    path: string;
}

export const initEntityConfigForm: EntityConfigForm = {
    key: "",
    navBar: [],
    mode: '',
    loadingForm: true,
    dataOptions: initEntityResponseInitialData,
    step: 1
}

export interface EntityConfigModalAsociar {
    show: boolean;
    loadingSearch: boolean;
    listGasto: EntityGasto[];
    showListGasto: EntityGasto[]
    textSearch: string;
    selectGasto: EntityGasto[];
}

export const initConfigEntityModalAsociar: EntityConfigModalAsociar = {
    show: false,
    loadingSearch: false,
    listGasto: [],
    showListGasto: [],
    textSearch: '',
    selectGasto: []
}

export interface EntityConfigModalForm {
    show: boolean;
    codigo: string;
}

export const initEntityConfigModalForm: EntityConfigModalForm = {
    show: false,
    codigo: ''
}

export interface EntityConfigModalFlujo {
    show: boolean;
    flujo: EntityFlujoOT[];
    flujoSelected?: EntityFlujoOT;
}

export const initEntityConfigModalFlujo: EntityConfigModalFlujo = {
    show: false,
    flujo: []
}

export interface EntityConfigModalNotas {
    show: boolean;
    title: string;
    nota: string;
}

export const initEntityConfigModalNotas: EntityConfigModalNotas = {
    show: false,
    title: '',
    nota: ''
}

export type EntityConfigModalNotes = {
    show: boolean;
    comentario: string;
    titulo: string;
}

export const initEntityConfigModalNotes: EntityConfigModalNotes = {
    show: false,
    comentario: "",
    titulo: ""
}

const languageTranslate = LanguageTranslate();
export const nameModule = {
    'preview': languageTranslate.textoModoVisualizar,
    'create': languageTranslate.textoModoCrear,
    'edit': languageTranslate.textoModoEditar,
    '': 'No Found'
}

export type keyModule = 'RendicionGasto' | '';
export type ModeForm = 'preview' | 'create' | 'edit' | '';