import React from 'react';
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { EntityMain } from '../../Domain/EntityMain';
import { EntityModeMultipleSelect } from '../../Domain/EntityModeMultipleSelect';
import { EntityRendicionGastoAprobacion } from '../../../../shared/Domain/EntityRendicionGastoAprobacion';

interface ContainerProps {
    data: EntityMain<EntityRendicionGastoAprobacion>[];
    onRedirectForm(id: string): any;
    show: boolean;
    copyCodeItem: (code: string) => Promise<void>;
    modeMultipleSelect: EntityModeMultipleSelect;
    onSelectItem: (code: string) => void;
}

export const ViewAprobacionGasto = (props: ContainerProps) => {
    if (!props.show) return null;
    const languageTranslate = LanguageTranslate();

    return (
        <div className="row pt-3 mb-5">
            <div className="col-12 mb-5">
                {
                    props.data.map(row => {
                        if (props.modeMultipleSelect.active && row.isPendingSend) return null;
                        let inProccess = false;
                        const importeAprobado = row.dataComplete.Gastos.reduce((prev, current) => {
                            if (current.Status.IdStatus === 1) {
                                inProccess = true
                                prev += current.DatosImporte.Importe;
                            }
                            return prev;
                        }, 0)

                        return (
                            <div className="row pb-3 ItemGenericList" key={row.Codigo}>
                                <div className="col-12">
                                    <div className={`shadow-lg position-relative ${row.isPendingSend ? 'pending' : ''}`} style={{ width: '100%', padding: 5, margin: 0, borderRadius: '10px', cursor: 'pointer' }}>
                                        <div className={`${props.modeMultipleSelect.active ? 'ModeSelectItem' : ''} ${props.modeMultipleSelect.listCode.includes(row.Codigo) ? 'ModeSelectItem-select' : ''}`} onClick={() => props.onSelectItem(row.Codigo)} />
                                        <div className="row pt-1" style={{ margin: '1px' }} onClick={() => props.onRedirectForm(row.Codigo)} >
                                            <div className="col-10" style={{ fontSize: 12 }}>
                                                <div className='d-flex'>
                                                    <span>{languageTranslate.moduloGenericList.item.itemTextProveedor}:</span>
                                                    <span className='text-muted'> {row.dataComplete.DatosTrabajo.OT.OT} </span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>{languageTranslate.moduloGenericList.item.itemTextImporteAprobado}:</span>
                                                    <span className='text-muted'> {importeAprobado} / {row.dataComplete.DatosImporte.Importe}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>{languageTranslate.moduloGenericList.item.itemTextRendidor}:</span>
                                                    <span className='text-muted'> {row.dataComplete.DatosPersonal.Identificacion} - {row.dataComplete.DatosPersonal.Nombres} {row.dataComplete.DatosPersonal.ApellidoPaterno}</span>
                                                </div>
                                            </div>
                                            <div className="icon-arrow-item">
                                                <i className="fa-solid fa-angle-right" style={{ fontSize: '20px' }} />
                                            </div>
                                        </div>
                                        {
                                            inProccess ? <div className='BadgetProceso'><span>{languageTranslate.moduloGenericList.item.itemTextProceso}</span></div> : null
                                        }
                                        <div className="row pt-1" style={{ margin: '1px' }} onClick={() => props.onRedirectForm(row.Codigo)} >
                                            <div className="col-6">
                                                <span className='text-danger' style={{ fontSize: '13px' }}> {AdapterGenerico.calculoTiempo(row.FechaRegistro)} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}