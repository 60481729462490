import { AdapterGenerico } from '../Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../Infraestructure/LanguageTranslate';
import './ElementInputFileImage.scss'

const languageTranslate = LanguageTranslate();
interface PropsView {
    onChange: (fileList: FileList) => any;
    icon: string;
    disabled?: boolean;
    size?: number;
    capture?: boolean;
    multiple?: boolean;
    text?: string;
    accept?: string;
};

export const ElementInputFileImage = (props: PropsView) => {
    const isImage = (fileList: FileList | null) => {
        if(!fileList) return;
        
        let error = { isError: false, fileName: '' };
        Array.from(fileList).forEach(file => {
            if (props.accept) {
                let acceptArr: string[] = props.accept.split(',').map(row => row.trim());
                let result = !acceptArr.some(row => file.type.match(row));
                if (result) error = { isError: true, fileName: file.name }
            } else {
                if(!file.type.match('image/*')) error = { isError: true, fileName: file.name }
            }
        });

        if (error.isError) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, `${error.fileName} no es un recurso válido `, 'warning', false);
            return;
        }

        props.onChange(fileList);
    }

    return (
        <label className="ElementInputFileImage">
            <input type="file" accept={ props.accept || "image/*"} multiple={props.multiple} capture={props.capture} onChange={(evt) => isImage(evt.target.files)} disabled={props.disabled} value=""/>
            <i className={props.icon} style={{ fontSize: props.size || 30 }} />
            <span>{ props.text }</span>
        </label>
    );
};
