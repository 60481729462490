import React from 'react';
import './ElementTableCostume.scss'
import { AdapterGenerico } from '../Infraestructure/AdapterGenerico';

interface ContainerProps {
    listHeader: { label: string; key: string; render?: (text: string, data: any, index: number) => any; special?: string; }[];
    listData: any[];
    className?: string;
}

export const ElementTableCostume = (props: ContainerProps) => {
    return (
        <div className='table-responsive-xl'>
            <table className={`table table-striped table-sm table-responsive-xl ElementTableCostume ${props.className || ''}`}>
                <thead>
                    <tr> { props.listHeader.map((row, position) => <th scope='col' key={`${row.key}${position}`}>{ row.label } </th>) } </tr>
                </thead>
                <tbody>
                    {
                        props.listData.map((rowData, position) => {
                            let tempClassName = '';
                            if (rowData.action === 'NUEVA NOTA DEVUELTO') tempClassName = 'CustomNotaDevuelto'
                            return (
                                <tr key={position} className={tempClassName}>
                                    {
                                        props.listHeader.map(rowHeader => {
                                            let currentValue = rowHeader.key.split('.').reduce((prev, current) => { prev = prev[current]; return prev; }, rowData);
                                            if (rowHeader.special === 'amountFormat') currentValue = AdapterGenerico.formatoDinero(currentValue); 
                                            return (
                                                rowHeader.render ? <td> { rowHeader.render(currentValue, rowData, position) }</td> :
                                                <td key={`${rowHeader.key}${rowData[rowHeader.key]}${position}`}><span> {currentValue} </span></td>)
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}