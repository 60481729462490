import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { EntityMenu } from "../../../shared/Domain/EntityMenu";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { changeSaludo } from "../../../shared/Infraestructure/SliceGenerico";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { AdapterConfigure } from "./AdapterConfigure";
import { useState } from "react";
import { EntityPendingWorkflow, initEntityPendingWorkflow } from "../Domain/EntityPendingWorkflow";

export const Controller = () => {
    const { generico: { websocket, dbLocal }, auth: { user, menu } } = useSelector((state: RootState) => state);
    const [WorkflowPending, setWorkFlowPending] = useState<EntityPendingWorkflow>(initEntityPendingWorkflow);
    const languageTranslate = LanguageTranslate();
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();
    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const init = async () => {
        dispatch(changeSaludo(false));
        loadPendingDocuments();
    };

    const onClickMenu = (element: EntityMenu) => {
        navigate(`/${element.ruta}`, { replace: true });
    };

    const loadPendingDocuments = async () => {
        try {
            if (!navigator.onLine) return;
            const result = await repository.getPendingWorkflow({ identificacion: user.Identificacion });
            setWorkFlowPending(() => ({...result}));
        } catch (error) { }
    }

    return {
        init,
        menu: menu.filter(row => row.esOpcion && row.padre === 10),
        WorkflowPending,
        languageTranslate,
        onClickMenu,
    };
}