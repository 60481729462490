import './ElementItemCard.scss'

interface PropsView {
    classItem?: string;
    textHeader?: string | JSX.Element;
    textSubHeader?: string | JSX.Element;
    textBody?: string | JSX.Element;
    elementOpts?: JSX.Element;
    ago?: string;
    state?: string;
};


export const ElementItemCard = (props: PropsView) => {
    return (
        <div className={`ElementItemCard`}>
            <div className="row pb-3">
                <div className="col-12">
                    <div className={`shadow-lg ${props.classItem}`} style={{ width: '100%', padding: 5, margin: 0, borderRadius: '10px', cursor: 'pointer', backgroundColor: 'white' }}>
                        <div className="row pb-1" style={{ margin: '1px' }}>
                            <div className="col-7">
                                <span className="text-danger" style={{ fontSize: '13px' }}>{props.textHeader}</span>
                            </div>
                            <div className="col-5">
                                <span className="text-danger" style={{ fontSize: '13px' }}>{props.textSubHeader}</span>
                            </div>

                        </div>
                        {
                            !!props.textBody
                                ? <div className="row pb-1" style={{ margin: '1px' }}>
                                    <div className="col-10">
                                        <span style={{ fontSize: '13px' }}>{props.textBody}</span>
                                    </div>
                                    <div className="col-2 text-center">
                                        {props.elementOpts}
                                    </div>
                                </div>
                                : <></>
                        }
                        <div className="row pb-1" style={{ margin: '1px' }}>
                            <div className="col-7">
                                <span className='text-muted' style={{ fontSize: '13px' }}> {props.ago}</span>
                            </div>
                            <div className="col-5">
                                <span className='text-muted' style={{ fontSize: '13px' }}>{props.state}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
