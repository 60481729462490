import { AdapterConfigure } from "./AdapterConfigure";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  addLoading,
  changeSaludo,
  hideIconMenu,
  removeLoading,
} from "../../../shared/Infraestructure/SliceGenerico";
import { EntityInformationDataInitial } from "../../../shared/Domain/EntityInformationDataInitial";
import { useState } from "react";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { AdapterLoadMaster } from "../../../shared/Infraestructure/AdapterLoadMaster";

export const Controller = () => {
  const { permisoVariables } = useSelector((state: RootState) => state.auth);
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
  const dispatch: Dispatch = useDispatch();
  const languageTranslate = LanguageTranslate();

  const repositoryLoadMaster = new AdapterLoadMaster(
    websocket,
    dbLocal,
    dispatch,
    AdapterConfigure.SCHEMA,
    AdapterConfigure.ENTITY
  );
  const dataInitial = repositoryLoadMaster.initServicesCalled();
  const [informationDataInitial, setInformationDataInitial] = useState<EntityInformationDataInitial>(dataInitial);

  const init = async () => {
    dispatch(changeSaludo(false));
    dispatch(hideIconMenu());
    
  };

  const onClickLoadCatalogo = async (name: string) => {
    try {
      dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
      let userPermisos = {
        Pais: permisoVariables.arrIdPaises,
        Grupo: permisoVariables.arrIdGrupos,
        Delegacion: permisoVariables.arrIdDelegaciones,
        OT: permisoVariables.arrIdOT
      }
      await repositoryLoadMaster.reloadItemMaster(name as any, userPermisos);
      const payloadServiceCalled = repositoryLoadMaster.initServicesCalled();
      setInformationDataInitial(() => ({...payloadServiceCalled}));
    } catch (error) {

    } finally {
      dispatch(removeLoading());
    }
  };

  const onClickLoadAll = async () => {
    try {
      dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
      let userPermisos = {
        Pais: permisoVariables.arrIdPaises,
        Grupo: permisoVariables.arrIdGrupos,
        Delegacion: permisoVariables.arrIdDelegaciones,
        OT: permisoVariables.arrIdOT
      }
      await repositoryLoadMaster.reloadAllMaster(userPermisos);
      
      dispatch(removeLoading());
    } catch (error) {
      dispatch(removeLoading());
    }
  };

  return {
    init,

    informationDataInitial,

    onClickLoadCatalogo,
    onClickLoadAll,
  };
};
