import { EntityPersonal } from "../Domain/Catalogos/EntityPersonal";
import { EntityDataUsuario } from "../Domain/EntityDataUsuario";
import { EntityInformationDataInitial } from "../Domain/EntityInformationDataInitial";
import { paramsCatalogos } from "../Domain/EntityLoadMaster";
import { EntityMenu, initEntityMenu } from "../Domain/EntityMenu";
import { EntityProcessOffline } from "../Domain/EntityProcessOffline";
import { EntityUserAccess, EntityUserAccessData, EntityUserAccessDataFormatted, GetDataForUser, deleteDuplicatePermiss } from "../Domain/EntityUserAccess";
import { AdapterData } from "./AdapterData";
import { AdapterGenerico } from "./AdapterGenerico";
import { AdapterStorage } from "./AdapterStorage";
import { RepositoryImplGenerico } from "./RepositoryImplGenerico";

const listCustomFilter: string[] = [];

export class AdapterLoadMaster extends RepositoryImplGenerico<any> {

    public initServicesCalled(){
        const SERVICESCALLED_NAME_FROM_LOCAL_STORAGE = "servicesCalleds";

        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial | null } = AdapterStorage.get(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE);
        const COUNT_COLECTIONS_INDEXEDDB = 35;

        if (servicesCalleds === null) {
            servicesCalleds = {
                CTFlujosOT: { called: false, count: 0, date: '' },
                CTFlujosDelegacion: { called: false, count: 0, date: '' },
                CTMoneda: { called: false, count: 0, date: '' },
                CTOT: { called: false, count: 0, date: '' },
                CTProveedor: { called: false, count: 0, date: '' },
                CTServicio: { called: false, count: 0, date: '' },
                CTTipoCambio: { called: false, count: 0, date: '' },
                CTTipoDocumento: { called: false, count: 0, date: '' },
                CTVAT: { called: false, count: 0, date: '' },
                CTTipoDocumentoTributario: { called: false, count: 0, date: '' },
                CTProyectos: { called: false, count: 0, date: '' },
                CTPersonal: { called: false, count: 0, date: '' },
                CTProducto: { called: false, count: 0, date: '' },
                CTPeriodo: { called: false, count: 0, date: '' },
                CTDisciplina: { called: false, count: 0, date: '' },
                CTTipoAusencia: { called: false, count: 0, date: '' },
            };
            AdapterStorage.set(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE, servicesCalleds);
        }
        // Validar si faltan colecciones pendientes - ejemp: pueden ser nuevas colecciones
        if (Object.values(servicesCalleds).length <= COUNT_COLECTIONS_INDEXEDDB) {
            if (!servicesCalleds.CTFlujosOT)  Object.assign(servicesCalleds, { CTFlujosOT: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTFlujosDelegacion) Object.assign(servicesCalleds, { CTFlujosDelegacion: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTMoneda) Object.assign(servicesCalleds, { CTMoneda: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTOT) Object.assign(servicesCalleds, { CTOT: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTProveedor) Object.assign(servicesCalleds, { CTProveedor: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTServicio) Object.assign(servicesCalleds, { CTServicio: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTTipoCambio) Object.assign(servicesCalleds, { CTTipoCambio: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTTipoDocumento) Object.assign(servicesCalleds, { CTTipoDocumento: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTVAT) Object.assign(servicesCalleds, { CTVAT: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTTipoDocumentoTributario) Object.assign(servicesCalleds, { CTTipoDocumentoTributario: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTProyectos) Object.assign(servicesCalleds, { CTProyectos: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTPersonal) Object.assign(servicesCalleds, { CTPersonal: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTProducto) Object.assign(servicesCalleds, { CTProducto: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTPeriodo) Object.assign(servicesCalleds, { CTPeriodo: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTDisciplina) Object.assign(servicesCalleds, { CTDisciplina: { called: false, count: 0, date: '' } });
            if (!servicesCalleds.CTTipoAusencia) Object.assign(servicesCalleds, { CTTipoAusencia: { called: false, count: 0, date: '' } });

            AdapterStorage.set(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE, servicesCalleds);
        }

        servicesCalleds.CTFlujosOT.date = !!servicesCalleds.CTFlujosOT.date ? new Date(`${servicesCalleds.CTFlujosOT.date}`) : new Date();
        servicesCalleds.CTFlujosDelegacion.date = !!servicesCalleds.CTFlujosDelegacion.date ? new Date(`${servicesCalleds.CTFlujosDelegacion.date}`) : new Date();
        servicesCalleds.CTMoneda.date = !!servicesCalleds.CTMoneda.date ? new Date(`${servicesCalleds.CTMoneda.date}`) : new Date();
        servicesCalleds.CTOT.date = !!servicesCalleds.CTOT.date ? new Date(`${servicesCalleds.CTOT.date}`) : new Date();
        servicesCalleds.CTProveedor.date = !!servicesCalleds.CTProveedor.date ? new Date(`${servicesCalleds.CTProveedor.date}`) : new Date();
        servicesCalleds.CTServicio.date = !!servicesCalleds.CTServicio.date ? new Date(`${servicesCalleds.CTServicio.date}`) : new Date();
        servicesCalleds.CTTipoCambio.date = !!servicesCalleds.CTTipoCambio.date ? new Date(`${servicesCalleds.CTTipoCambio.date}`) : new Date();
        servicesCalleds.CTTipoDocumento.date = !!servicesCalleds.CTTipoDocumento.date ? new Date(`${servicesCalleds.CTTipoDocumento.date}`) : new Date();
        servicesCalleds.CTVAT.date = !!servicesCalleds.CTVAT.date ? new Date(`${servicesCalleds.CTVAT.date}`) : new Date();
        servicesCalleds.CTTipoDocumentoTributario.date = !!servicesCalleds.CTTipoDocumentoTributario.date ? new Date(`${servicesCalleds.CTTipoDocumentoTributario.date}`) : new Date();
        servicesCalleds.CTProyectos.date = !!servicesCalleds.CTProyectos.date ? new Date(`${servicesCalleds.CTProyectos.date}`) : new Date();
        servicesCalleds.CTPersonal.date = !!servicesCalleds.CTPersonal.date ? new Date(`${servicesCalleds.CTPersonal.date}`) : new Date();
        servicesCalleds.CTProducto.date = !!servicesCalleds.CTProducto.date ? new Date(`${servicesCalleds.CTProducto.date}`) : new Date();
        servicesCalleds.CTPeriodo.date = !!servicesCalleds.CTPeriodo.date ? new Date(`${servicesCalleds.CTPeriodo.date}`) : new Date();
        servicesCalleds.CTDisciplina.date = !!servicesCalleds.CTDisciplina.date ? new Date(`${servicesCalleds.CTDisciplina.date}`) : new Date();
        servicesCalleds.CTTipoAusencia.date = !!servicesCalleds.CTTipoAusencia.date ? new Date(`${servicesCalleds.CTTipoAusencia.date}`) : new Date();

        return servicesCalleds;
    }

    public async UpdateUserInformation(IdUser: number): Promise<{ access: EntityUserAccessDataFormatted, menu: EntityMenu[], exitoso: boolean }> {
        let url = `${this.urlBase}/master/select`;
        let menu: EntityMenu[] = initEntityMenu;
        
        const defaultData = {
            access: {
                OT: [],
                Delegacion: [],
                Pais: [],
                Grupo: []
            },
            menu: menu.filter(row => (row.id !== 30 && row.padre !== 30) || (row.id !== 40 && row.padre !== 40)),
            exitoso: false
        };

        let payloadRequest = {
            Filtros: [{ "$match": { "IdUser": IdUser, "Padre": { "$elemMatch": { "Padre": { $in: ["Rendiciones", "Tareo"] } } } } }], // permiso para acceder al módulo rendiciones y tareo
            CollectionName: 'Master_UserPages',
            DataBase: 'gia'
        };

        let response: EntityUserAccess[] | null = (await this.service.call<any>("POST", url, JSON.stringify(payloadRequest), "basic", "json", 'json', {}, 0));

        const rendiciones = response?.filter(row => row.Description === "Rendiciones");
        const tareo = response?.filter(row => row.Description === "Tareo Personal");
        
        if (!response || response.length === 0) return defaultData

        let AccessGlobal: any[] = [];
        if (rendiciones && rendiciones?.length > 0) {
            AccessGlobal = rendiciones[0].Access.Global || rendiciones[0].Access.Globals
        } else {
            menu = menu.filter(row => row.id !== 30 && row.padre !== 30)
        }

        if (tareo && tareo?.length > 0) {
            AccessGlobal = [...AccessGlobal, ...(tareo[0].Access.Global || tareo[0].Access.Globals)]
        } else {
            menu = menu.filter(row => row.id !== 40 && row.padre !== 40)
        }

        let responseGlobal: EntityUserAccessData[] = (await this.service.call<any>("POST", `${this.urlBase}/master/selectGlobal`, JSON.stringify({ IdProceso: 2, data: AccessGlobal }), "bearer", "json", 'json', {}, 0));

        try {
            let { user }: { user: EntityPersonal } = AdapterStorage.get(['user']);
            await this.reloadItemMaster(paramsCatalogos.CTPersonal({ identificacion: user.Identificacion }).CollectionNameLocal, { Delegacion: [], Grupo: [], OT: [], Pais: [] });
        } catch (error) {}

        // performance del login
        // console.log('inicio')
        // let startTime: number = 0;
        // startTime = performance.now();
        const arrWithoutDuplicate = deleteDuplicatePermiss(responseGlobal);
        // console.log(arrWithoutDuplicate);
        const accessData = GetDataForUser(arrWithoutDuplicate)
        // const endTime = performance.now();
        // console.log(Number((endTime - startTime).toFixed(2)));
        // console.log('final')

        return ({ access: accessData, menu: menu, exitoso: true });
    }

    public async initialService(permisos: { Pais: string[]; Grupo: string[]; Delegacion: string[]; OT: string[] }, user: EntityDataUsuario): Promise<void> {
        await this._initialService(permisos, user);
    }

    private async _initialService(permisos: { Pais: string[]; Grupo: string[]; Delegacion: string[]; OT: string[] }, user_: EntityDataUsuario): Promise<void> {
        let { servicesCalleds, user }: { servicesCalleds: EntityInformationDataInitial; user: EntityPersonal } = AdapterStorage.get(['servicesCalleds', 'user']);
        if (!user?.Identificacion && !user_?.Identificacion) return;

        await Promise.all([
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTFlujosOT([]).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTFlujosDelegacion({ delegacion: [], pais: [] }).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTMoneda([]).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTOT([]).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTProveedor([]).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTServicio([]).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTTipoCambio([]).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTTipoDocumento([]).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTVAT([]).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTTipoDocumentoTributario([]).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTProyectos([]).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTPersonal({ identificacion: ''}).CollectionNameLocal, { ...permisos, Identificacion: user_?.Identificacion || user?.Identificacion } ),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTProducto({ pais: [] }).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTDisciplina({ ot: [] }).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTTipoAusencia().CollectionNameLocal, permisos),
        ]);
        AdapterStorage.set('servicesCalleds', servicesCalleds);
    }

    private async selectTemplateCatalogo(informationDataInitial: EntityInformationDataInitial, catalog: keyof EntityInformationDataInitial, permisos: { Pais: string[]; Grupo: string[]; Delegacion: string[]; OT: string[]; Identificacion?: string }): Promise<void> {
        let url: string = `${this.urlBase}/master/select`;
        let methodService: 'POST' | 'GET' = 'POST';
        let matchUpdate = (fecha: Date) => ({});

        switch (catalog) {
            default: 
                url = `${this.urlBase}/master/select`;
                methodService = "POST";
                matchUpdate = (fecha: Date) => ({ $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } });
            break;
        }

        let count: number = await this.dbLocal.countStore(catalog);
        let { user }: { user: EntityPersonal } = AdapterStorage.get(['user']);
        let validData: boolean = informationDataInitial[catalog] ? informationDataInitial[catalog].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[catalog].date as string) : true : true;
        let data: object[];
        let _params;
        let arrIdAccess: any = {
            "CTFlujosOT": permisos.OT,
            "CTOT": permisos.OT,
            "CTProveedor": permisos.Pais,
            "CTServicio": permisos.Pais,
            "CTProyectos": permisos.OT,
            "CTPersonal": {
                identificacion: user?.Identificacion || permisos?.Identificacion || ''
            },
            "CTProducto": {
                pais: permisos.Pais
            },
            "CTFlujosDelegacion": {
                pais: permisos.Pais,
                delegacion: permisos.Delegacion
            },
            "CTDisciplina": {
                ot: permisos.OT
            }
        }

        if (!informationDataInitial[catalog] || !informationDataInitial[catalog]?.called || count === 0) {
            try {
                _params = paramsCatalogos[catalog](arrIdAccess[catalog]);
                data = await this.service.bgCall<any>(methodService, url, JSON.stringify(listCustomFilter.includes(catalog) ? _params.CustomFilter : _params), "bearer", "json", 'json', { "request-decrypt-response": true }, 0);
                Object.assign(informationDataInitial, { [catalog]: { called: true, count: data.length, date: new Date() } });
            } catch (error) {
                console.error(error);
                data = [];
            }
            await this.dbLocal.insertDataStore({ nameStore: catalog, data });
        } else if (!validData) {
            let fecha: Date = new Date(informationDataInitial[catalog].date as string);
            let match: any = matchUpdate(fecha);
            try {
                _params = paramsCatalogos[catalog](arrIdAccess[catalog]);
                _params.Filtros = [match, ..._params.Filtros];
                data = await this.service.bgCall<any>(methodService, url, JSON.stringify(listCustomFilter.includes(catalog) ? _params.CustomFilter : _params), "bearer", "json", 'json', { "request-decrypt-response": true }, 0);
                Object.assign(informationDataInitial, { [catalog]: { called: true, count: (count + data.length), date: new Date() } });
            } catch (error) {
                console.error(error);
                data = [];
            }
            await this.dbLocal.insertDataStore({ nameStore: catalog, data });
        } else {}

        if ('CTOT' === catalog) AdapterData.ot = await this.dbLocal.selectAllStore(catalog);
        if ('CTProveedor' === catalog) AdapterData.proveedor = await this.dbLocal.selectAllStore(catalog);
        // if ('CTPersonal' === catalog) AdapterData.personal = await this.dbLocal.selectAllStore(catalog);
    }

    public async reloadAllMaster(permisos: { Pais: string[]; Grupo: string[]; Delegacion: string[]; OT: string[] }): Promise<void> {
        await this._reloadAllMaster(permisos);
    }

    public async reloadItemMaster(CollectionNameLocal: any, permisos: { Pais: string[]; Grupo: string[]; Delegacion: string[]; OT: string[] }): Promise<void>  {
        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial } = AdapterStorage.get('servicesCalleds');
        await Promise.all([
            this.selectReloadItemMaster(servicesCalleds, CollectionNameLocal, permisos),
        ])
        AdapterStorage.set('servicesCalleds', servicesCalleds);
    }

    private async _reloadAllMaster(permisos: { Pais: string[]; Grupo: string[]; Delegacion: string[]; OT: string[] }): Promise<void> {
        let { servicesCalleds, user }: { servicesCalleds: EntityInformationDataInitial; user: EntityPersonal } = AdapterStorage.get(['servicesCalleds', 'user']);
        await Promise.all([
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTFlujosOT([]).CollectionNameLocal, permisos),
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTFlujosDelegacion({ delegacion: [], pais: [] }).CollectionNameLocal, permisos),
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTMoneda([]).CollectionNameLocal, permisos),
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTOT([]).CollectionNameLocal, permisos),
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTProveedor([]).CollectionNameLocal, permisos),
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTServicio([]).CollectionNameLocal, permisos),
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTTipoCambio([]).CollectionNameLocal, permisos),
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTTipoDocumento([]).CollectionNameLocal, permisos),
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTVAT([]).CollectionNameLocal, permisos),
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTTipoDocumentoTributario([]).CollectionNameLocal, permisos),
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTProyectos([]).CollectionNameLocal, permisos),
            this.selectTemplateCatalogo(servicesCalleds, paramsCatalogos.CTPersonal({ identificacion: user.Identificacion }).CollectionNameLocal, permisos),
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTProducto({ pais: [] }).CollectionNameLocal, permisos),
            this.selectReloadItemMaster(servicesCalleds, paramsCatalogos.CTTipoAusencia().CollectionNameLocal, permisos),
        ])
        AdapterStorage.set('servicesCalleds', servicesCalleds);
    }

    private async selectReloadItemMaster(informationDataInitial: EntityInformationDataInitial, catalog: keyof EntityInformationDataInitial, permisos: { Pais: string[]; Grupo: string[]; Delegacion: string[]; OT: string[] }): Promise<void> {
        let url: string = `${this.urlBase}/master/select`;
        let methodService: 'POST' | 'GET' = 'POST';

        switch (catalog) {
            default:
                url = `${this.urlBase}/master/select`;
                methodService = 'POST';
            break;
        }

        let data: object[];
        let _params;
        let { user }: { user: EntityPersonal } = AdapterStorage.get(['user']);
        let arrIdAccess: any = {
            "CTFlujosOT": permisos.OT,
            "CTOT": permisos.OT,
            "CTProveedor": permisos.Pais,
            "CTServicio": permisos.Pais,
            "CTProyectos": permisos.OT,
            "CTPersonal": {
                identificacion: user.Identificacion
            },
            "CTProducto": {
                pais: permisos.Pais
            },
            "CTFlujosDelegacion": {
                pais: permisos.Pais,
                delegacion: permisos.Delegacion
            },
            "CTDisciplina": {
                ot: permisos.OT
            }
        };

        try {
            _params = paramsCatalogos[catalog](arrIdAccess[catalog]);
            data = await this.service.bgCall<any>(
                methodService,
                url,
                JSON.stringify(listCustomFilter.includes(catalog) ? _params.CustomFilter : _params),
                "bearer",
                "json",
                'json',
                { "request-decrypt-response": true },
                0
            );
            Object.assign(informationDataInitial, { [catalog]: { called: true, count: data.length, date: new Date() } });
        } catch (error) {
            console.error(error);
            data = [];
        }
        await this.dbLocal.insertDataStore({ nameStore: catalog, data });
        

        if ('CTOT' === catalog) AdapterData.ot = await this.dbLocal.selectAllStore(catalog);
        if ('CTProveedor' === catalog) AdapterData.proveedor = await this.dbLocal.selectAllStore(catalog);
    }

    public async executeProcess<T>(params: EntityProcessOffline): Promise<T> {
        let response: T | null = null;
        switch (params.type) {
            case 'api':
                response = await this.service.call<any>(params.method, this.urlBase + params.url, params.body, params.typeAuth, params.typeRequest, params.typeResponse, {}, 3);
                break;
            case 'ws':
                response = await this.websocket.emit(params.url, params.body);
                break;
            default:
                break;
        }
        return response as T;
    }
}