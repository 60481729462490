const SCHEMA: string = process.env.REACT_APP_MASTER_MAIN_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MASTER_MAIN_ENTITY || '';
const LIST_TAREO_PERSONAL: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_TAREO}/${process.env.REACT_APP_ROUTE_PATH_TAREO_PERSONAL}`;
const HORA_MAXIMA_DIA = 24;
const IDENTIFIER_TIPO_FESTIVO = 2;
const ACTION_ITEM_REJECT = ['SOLICITUD RECHAZADA', 'SOLICITUD PRE RECHAZADA'];
const LIST_OT_BISA = ['CM0227O1109', 'DI0798O1013'];
const KEY_DISCIPLINA_GENERAL = 'General';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    LIST_TAREO_PERSONAL,
    HORA_MAXIMA_DIA,
    IDENTIFIER_TIPO_FESTIVO,
    ACTION_ITEM_REJECT,
    LIST_OT_BISA,
    KEY_DISCIPLINA_GENERAL
};