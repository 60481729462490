import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { KeyBDModule } from "../Domain/EntityTypeModule";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseDeleteItem {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(codes: string[], keyModule: KeyBDModule, user: EntityDataUsuario): Promise<boolean> {
        return await this._exec(codes, keyModule, user);
    }

    private async _exec(codes: string[], keyModule: KeyBDModule, user: EntityDataUsuario): Promise<boolean> {
        return await this.repository.deleteItems(codes, keyModule, user);
    }
}