export interface EntityListContent<T> {
    detail: {
        codigo: string;
        pais: string;
        grupo: string;
        empresa: string;
        proveedor: string;
        ot: string;
        tipoPago: string;
        fechaRecepcion: string;
        formaPago: string;
        terminoPago: string;
        moneda: string;
        importe: number;
        pedidoCompra: string[];
        comentario: string;
        isFacturaAsociada: boolean;
        importeFacturaAsociada: number;
        tipoFacturaAsociada: string;
        sumaImporteDetalle: number;
        fechaRegistro?: string;
        global?: boolean;
        multiProveedor?: boolean;
        importeAsociado?: number;
        solicitante?: string;
        cargoSolicitante?: string;
        observaciones?: string;
    };
    personal?: {
        modalidadTrabajo: string;
        motivoSolicitud: string;
        tipoContrato: string;
        puesto: string;
        tipoDocumento: string;
        identificacion: string;
        nombreCompleto: string;
        fechaAlta: string;
        fechaBaja: string;
        vehiculoPropio: string;
        vehiculoEmpresa: string;
        horaExtra: string;
        primaProduccion: string;
        bonoTurnoNoche: string;
        bonoTurnos: string;
        retribucionMensual: string;
        retribucionAnual: string;
        funciones: string;
        nuevoIngreso: boolean;
    };
    empleado?: {
        actual: {
            empleado: string;
            OT: string;
            delegacion: string;
            puesto: string;
            remuneracionMensual: string;
            remuneracionAnual: string;
            remuneracionAnualEuros: string;
        };
        propuesto: {
            tipoCoteo: string;
            puesto: string;
            fechaEfectiva: string;
            OT: string;
            remuneracionMensual: string;
            remuneracionAnual: string;
            remuneracionAnualEuros: string;
            varianza: string;
            motivoAumentoSalarial: string;
            justificacionSolicitud: string;
        };
    };
    producto: EntityProductoGenericDetail[];
    rendicion: EntityRendicionGenericDetail[];
    valorizacion: EntityValorizacionGenericDetail[];
    notas: {
        observacion: string;
        fecha: string;
        usuario: string;
        action: string;
    }[];
    bitacora: {
        accion: string;
        fecha: string;
        descripcion: string;
        usuario: string;
    }[];
    distribuccion: {
        cuenta: string;
        ot: string;
        importe: number;
    }[];
    documento: EntityDocumentoContent[];
    stepperUser: { label: string; description: string }[]
    currentStepUser: number;
    stepNumberStartUser: number;
    hasFilePrincipal: boolean;
    listFileDownloaded: { code: string; file: File; }[];
    alreadyAcceptItem?: boolean;
    dataComplete: T;
}

export interface EntityDocumentoContent {
    path: {
        directory: string[];
        fileName: string;
    };
    name: string;
    autoDownload: boolean;
    code: string;
    keyGroup: string;
    textGroup: string;
}

export interface EntityRendicionGenericDetail {
    id: any;
    nombreServicio: string;
    razonSocial: string;
    importe: string;
    importeTypeNumber: number;
}

export interface EntityProductoGenericDetail {
    id: string;
    descripcion: string;
    cantidad: number | string;
    unidad: number | string;
    precioUnitario: string;
    subTotal: string;
    subTotalTypeNumber: number;
    personal?: string;
}

export interface EntityValorizacionGenericDetail {
    id: any;
    serie?: string;
    numeroDocumento?: string;
    descripcion?: string;
    cantidad?: number;
    precioUnitario?: string;
    subTotal?: string;
    subTotalTypeNumber: number;

    numeroValorizacion?: string;
    contrato?: string;
    servicio?: string;
    monto?: string;
}

export const EntityListContentInitial: EntityListContent<any> = {
    producto: [],
    valorizacion: [],
    rendicion: [],
    notas: [],
    bitacora: [],
    documento: [],
    distribuccion: [],
    detail: {
        codigo: '',
        pais: '',
        grupo: '',
        empresa: '',
        proveedor: '',
        ot: '',
        tipoPago: '',
        terminoPago: '',
        moneda: '',
        importe: 0,
        comentario: '',
        fechaRecepcion: '',
        formaPago: '',
        pedidoCompra: [],
        isFacturaAsociada: false,
        importeFacturaAsociada: 0,
        tipoFacturaAsociada: '',
        sumaImporteDetalle:0,
    },
    stepperUser: [],
    currentStepUser: 0,
    stepNumberStartUser: 0,
    hasFilePrincipal: false,
    listFileDownloaded: [],
    alreadyAcceptItem: false,
    dataComplete: null
}