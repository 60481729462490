import { InputSelect, InputText } from "../../../../../shared/Components/ElementInputsCostume"
import ElementModalCostume from "../../../../../shared/Components/ElementModalCostume"
import { EntityGenericDetailForm } from "../../../../../shared/Domain/EntityGenericDetailForm";
import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";
import { EntityConfigForm, EntityConfigModalItem, EntityFormModalItem } from "../../Domain/EntityUtilts";
import { AdapterConfigure } from "../../Infraestructure/AdapterConfigure";

interface ContainerProps {
    closeModalItem: () => void;
    configModalItem: EntityConfigModalItem;
    formModalItem: EntityGenericDetailForm<EntityFormModalItem>;
    onChangeMI: (value: any, index: number) => void;
    onSubmitMI: (evt: Event) => void;
    configForm: EntityConfigForm;
}

const languageTranslate = LanguageTranslate();

export const ModalAgregarItem = (props: ContainerProps) => {

    return (
        <ElementModalCostume
            closeModal={props.closeModalItem}
            show={props.configModalItem.show}
            title={languageTranslate.btnAgregar}
        >
            <div className="ModalAgregarItem">

                <InputSelect
                    label={languageTranslate.moduloTareo.detail.textOT}
                    name="OT"
                    onChange={props.onChangeMI}
                    values={props.formModalItem.values}
                    options={props.configForm.dataOptions.ot}
                    isRequired
                />

                <InputSelect
                    label={languageTranslate.moduloTareo.detail.textDisciplina}
                    name="Disciplina"
                    onChange={props.onChangeMI}
                    values={props.formModalItem.values}
                    options={
                        AdapterConfigure.LIST_OT_BISA.includes(props.formModalItem.values.OT?.dataComplete.Code || '') ?
                        props.configForm.dataOptions.disciplina.filter(row => AdapterConfigure.KEY_DISCIPLINA_GENERAL !== row.dataComplete.Key)
                        :
                        props.configForm.dataOptions.disciplina.filter(row => AdapterConfigure.KEY_DISCIPLINA_GENERAL === row.dataComplete.Key)
                    }
                    isRequired
                />

                <InputText
                    label={languageTranslate.moduloTareo.detail.textHoras}
                    name="Horas"
                    onChange={props.onChangeMI}
                    values={props.formModalItem.values}
                    type="time"
                    isRequired
                />

                <div className="w-100 d-flex my-3" style={{ gap: 10 }}>
                    <button className='btn btn-dark w-50' onClick={props.closeModalItem}>{languageTranslate.btnCancelar}</button>
                    <button className='btn btn-primary w-50' onClick={(evt: any) => props.onSubmitMI(evt)}>{languageTranslate.btnGuardar}</button>
                </div>
            </div>
        </ElementModalCostume>
    )
}