import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { ElementMenuTab } from "../../../../../../shared/Components/ElementMenuTab";
import { EntityGasto } from "../../../../../../shared/Domain/EntityGasto";
import { AdapterGenerico } from "../../../../../../shared/Infraestructure/AdapterGenerico";
import { EntityConfigApproval, EntityConfigForm, EntityConfigModalDetail } from "../../Domain/EntityUtils";
import { LanguageTranslate } from "../../../../../../shared/Infraestructure/LanguageTranslate";
import { EntityLanguage } from "../../../../../../shared/Domain/EntityLanguage";

interface ContainerProps {
    languageTranslate: EntityLanguage;
    configModalDetail: EntityConfigModalDetail;
    listGasto: EntityGasto[];
    configForm: EntityConfigForm;
    nextGasto: () => void;
    prevGasto: () => void;
    closeModalDetail: () => void;
    onSubmitApproval: (props: EntityConfigApproval) => Promise<void>;
}

export const ViewDetailGasto = (props: ContainerProps) => {
    if (!props.configModalDetail.show) return null;

    return (
        <div className={`ViewDetailGasto`}>
            <div className="GroupDetail">
                <div className="GroupDetailHeader">
                    <div className="Title">
                        {props.listGasto.length > 1 ? <div onClick={props.prevGasto}><i className="fa-solid fa-arrow-left" /></div> : null}
                        <p>
                            {props.languageTranslate.moduloGastoAprobacion.viewDetail.title} #{props.configModalDetail.currentPosition + 1}
                        </p>
                        {props.listGasto.length > 1 ? <div onClick={props.nextGasto}><i className="fa-solid fa-arrow-right" /></div> : null}
                    </div>
                    <div className="BtnClose">
                        <i onClick={props.closeModalDetail} className="fa-regular fa-circle-xmark"></i>
                    </div>
                </div>
                <div className="GroupDetailContent">
                    <ElementMenuTab
                        MenuList={[
                            {
                                key: 'Detalle',
                                title: 'Detalle',
                                content: <div className="ViewDetailModal">
                                    <div className="GroupTextGeneric">
                                        <label>{props.languageTranslate.moduloGastoAprobacion.viewDetail.textTitleGeneral}</label>
                                        <div className="GroupText">
                                            <div className="d-flex">
                                                <span>{props.languageTranslate.moduloGastoAprobacion.viewDetail.textEstado}:&nbsp;</span>
                                                <span className={`${
                                                        props.configModalDetail.gastoSelect.Status.IdStatus === 1 ? 'TextAprobado' :
                                                        (props.configModalDetail.gastoSelect.Status.IdStatus === 4 ? 'TextRechazado' : 'TextRevision')
                                                    }`
                                                }>
                                                {
                                                    props.configModalDetail.gastoSelect.Status.IdStatus === 1 ? props.languageTranslate.moduloGastoAprobacion.generic.textStatusAprobado :
                                                        (props.configModalDetail.gastoSelect.Status.IdStatus === 4 ? props.languageTranslate.moduloGastoAprobacion.generic.textStatusRechazado: props.languageTranslate.moduloGastoAprobacion.generic.textStatusRevision)
                                                }
                                                </span>
                                            </div>
                                            <div className="d-flex">
                                                <span>{props.languageTranslate.moduloGastoAprobacion.generic.textOT}:&nbsp;</span>
                                                <span className="text-muted">{props.configModalDetail.gastoSelect.DatosTrabajo.OT.Codigo} - {props.configModalDetail.gastoSelect.DatosTrabajo.OT.OT}</span>
                                            </div>
                                            <div className="d-flex">
                                                <span>{props.languageTranslate.moduloGastoAprobacion.viewDetail.textDelegacion}:&nbsp;</span>
                                                <span className="text-muted">{props.configModalDetail.gastoSelect.DatosTrabajo.Delegacion.Delegacion}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="GroupTextGeneric">
                                        <label>{props.languageTranslate.moduloGastoAprobacion.viewDetail.textTitleDetalleGasto}</label>
                                        <div className="GroupText">
                                            <div className="d-flex">
                                                <span>{props.languageTranslate.moduloGastoAprobacion.generic.textProveedor}:&nbsp;</span>
                                                <span className="text-muted">{props.configModalDetail.gastoSelect.DatosEmpresa.RazonSocial}</span>
                                            </div>
                                            <div className="d-flex">
                                                <span>{props.languageTranslate.moduloGastoAprobacion.viewDetail.textFecha}:&nbsp;</span>
                                                <span className="text-muted">{AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(props.configModalDetail.gastoSelect.FechaEmision.Fecha), 3)}</span>
                                            </div>
                                            <div className="d-flex">
                                                <span>{props.languageTranslate.moduloGastoAprobacion.generic.textImporte}:&nbsp;</span>
                                                <span className="text-muted">{AdapterGenerico.formatoDinero(props.configModalDetail.gastoSelect.DatosImporte.Importe)} {props.configModalDetail.gastoSelect.DatosImporte.Moneda.Codigo}</span>
                                            </div>
                                            {/*<div className="d-flex">
                                                <span>{props.languageTranslate.moduloGastoAprobacion.viewDetail.textImpuesto}:&nbsp;</span>
                                                <span className="text-secondary">{props.configModalDetail.gastoSelect.DatosImporte.MontoIVA}%</span>
                                            </div>
                                            <div className="d-flex">
                                                <span>{props.languageTranslate.moduloGastoAprobacion.generic.textImpTotal}:&nbsp;</span>
                                                <span className="text-danger">{props.configModalDetail.gastoSelect.DatosImporte.Total} {props.configModalDetail.gastoSelect.DatosImporte.Moneda.Codigo}</span>
                                            </div>*/}
                                        </div>
                                        <div className="GroupText">
                                            <div className="d-flex">
                                                <span className="w-100">{props.languageTranslate.moduloGastoAprobacion.viewDetail.textTipoDoc}:&nbsp;</span>
                                                <span className="text-muted">{props.configModalDetail.gastoSelect.DatosDocumento.TipoDocumento.Descripcion}</span>
                                            </div>
                                            <div className="d-flex">
                                                <span>{props.languageTranslate.moduloGastoAprobacion.viewDetail.textServicio}:&nbsp;</span>
                                                <span className="text-muted">{props.configModalDetail.gastoSelect.DatosServicio.Servicio}</span>
                                            </div>
                                            <div className="d-flex">
                                                <span>{props.languageTranslate.moduloGastoAprobacion.viewDetail.textObservaciones}:&nbsp;</span>
                                                <span className="text-muted">{props.configModalDetail.gastoSelect.Observacion}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            },
                            {
                                key: 'Archivos',
                                title: props.languageTranslate.moduloGastoAprobacion.viewDetail.textTabArchivos,
                                content: <div className="ViewArchivosModal">
                                    {
                                        props.configModalDetail.gastoSelect.Files.find(row => row.fileName.toLocaleLowerCase().match('pdf')) ?
                                        <ViewPDF urlFile={props.configModalDetail.gastoSelect.FileUrl}/>
                                    :
                                        <img
                                            className={`shadow`}
                                            height={330}
                                            width="100%"
                                            src={props.configModalDetail.gastoSelect.FileUrl}
                                            alt="not source"
                                        />
                                    }
                                </div>
                            } 
                        ]}
                        defaultKey={window.location.hash || ''}
                    />
                </div>
            </div>
            
            {/* Botón de Aprobación individual */}
            {
                (props.configModalDetail.gastoSelect.Status.IdStatus === 5 && props.configForm.mode !== 'preview') ?
                    <div className='container_group_button shadow-lg'>
                        <div className='group_button'>
                            <button
                                className='btn btn-danger'
                                onClick={() => props.onSubmitApproval({ mode: 'individual', type: 'reject' })}
                            >
                                <i className="fa-solid fa-arrow-rotate-left" style={{ fontSize: 14, marginRight: 12 }} />
                                {props.languageTranslate.moduloGenericDetail.btnTextDevolver}
                            </button>
                            <button
                                className='btn btn-success'
                                onClick={() => props.onSubmitApproval({ mode: 'individual', type: 'approve' })}
                            >
                                <i className="fa-solid fa-check" style={{ fontSize: 14, marginRight: 12 }} />
                                {props.languageTranslate.btnAprobar}
                            </button>
                        </div>
                    </div>
                :
                null
            }
        </div>
    )
}

const ViewPDF = (props: { urlFile?: string; }) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages }: any) => setNumPages(numPages);
    const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
    const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1,);
    const languageTranslate = LanguageTranslate();

    return (
        <div className="row">
            <div className="col-12" style={{ minHeight: 200 }}>
                {
                !!props.urlFile
                    ? <div style={{ height: '100%' }}>
                        <Document file={props.urlFile} onLoadSuccess={onDocumentLoadSuccess} >
                            <Page pageNumber={pageNumber} />
                        </Document>
                    </div>
                    : <></>
                }
            </div>
            <div className="col-12">
                <div className="divPaginado">
                    <button className="btn btn-primary" onClick={goToPrevPage}>{`<`}</button>
                    <p>
                        {languageTranslate.textoPagina} {pageNumber} {languageTranslate.textoPaginaDe} {numPages}
                    </p>
                    <button className="btn btn-primary" onClick={goToNextPage}>{`>`}</button>
                </div>
            </div>
        </div>
    )
}