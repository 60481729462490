import './Style.scss';

import logo from '../../../../assets/img/IconosAppGIAMovil/logo.svg';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';

//Imagenes

interface PropsViewMain {
    form: { values: any; touched: any; errors: any; handleBlur: Function; };
    onChange: Function;
    onSubmit: Function;
    onClickLogin: Function;
};

export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();

    return (
        <div className="ViewMasterRecuperarPassword">
            <div className="fondo"></div>
            <div className="login-block">
                {/* <div className="container"> */}
                <div className="row text-center">
                    <div className="col-12 d-flex flex-column align-items-center">
                        <img src={logo} alt="logo.png" width={80} />
                        <span className='fw-bold mt-2' style={{ fontSize: 16 }}>Gestor Integral de Administración</span>
                    </div>
                </div>
                <div className='card p-3 shadow-sm mt-5 rounded-5'>
                    <form noValidate onSubmit={(evt) => { props.onSubmit(evt); }}>
                        <div className="row pt-2">
                            
                            <div className="col-12 pt-3">
                                <label className="fw-semibold">{languageTranslate.moduloRecuperarPassword.textoIdentificacion}</label>
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text" >
                                        <i className="fa-solid fa-id-card" />
                                    </span>
                                    <input
                                        type="text"
                                        name="identificacion"
                                        className="form-control"
                                        required
                                        placeholder={languageTranslate.moduloRecuperarPassword.textoIdentificacion}
                                        value={props.form.values['identificacion']}
                                        onChange={(evt) => { props.onChange('identificacion', evt.target.value) }}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="row pt-5">
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary" style={{ width: '100%', backgroundColor: '#01397D' }}>
                                    {languageTranslate.moduloRecuperarPassword.textoAccion}
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="row pt-3">
                        <div className="col-12 text-center">
                            <div className="forgot-phone">
                                {
                                    // eslint-disable-next-line
                                    <a href="#" className="text-primary" onClick={() => props.onClickLogin()} style={{ fontWeight: 'bold' }}>{languageTranslate.moduloRecuperarPassword.textoRegresarLogin}</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
            <div className='login-block fw-semibold d-flex justify-content-end align-items-end' style={{top:'95%'}}>
                <span className="text-primary">Idioma:</span><span>Español</span>
            </div> 
        </div >
    )
};