// Domain
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntityMain } from "../Domain/EntityMain";
import { EntityRequestList } from "../Domain/EntityRequest";
import { KeyBDModule } from "../Domain/EntityTypeModule";
import { RepositoryMain } from "../Domain/RepositoryMain";

// Infraestructure
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityTareoPersonal } from "../../../shared/Domain/Tareo/EntityTareoPersonal";
import { EntityAusencia } from "../../../shared/Domain/Tareo/EntityAusencia";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public formatToGenericList<T>(payload: any[], module: KeyBDModule): EntityMain<T>[] {

        switch(module) {
            case 'TareoPersonal':
                return payload.map((row: EntityTareoPersonal) => ({
                    Codigo: row.Codigo,
                    FechaRegistro: row.Dates?.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date(),
                    isPendingSend: row.isPendingSend,
                    dataComplete: row as T
                })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
            case 'TareoGrupal':
                return payload.map((row: EntityTareoPersonal) => ({
                    Codigo: row.Codigo,
                    FechaRegistro: row.Bitacora.filter(row => row.Action === 'RENDICION EN REVISION').pop()?.Date.Date ?
                        AdapterGenerico.convertStringToDate(row.Bitacora.filter(row => row.Action === 'RENDICION EN REVISION').pop()?.Date.Date)
                        :
                        (row.Dates?.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date()),
                    isPendingSend: row.isPendingSend,
                    dataComplete: row as T
                })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
            default:
                return [];
        }
    }

    public async getList(user: EntityDataUsuario, keyModule: KeyBDModule, filters: any = null): Promise<{ dataFormatted: EntityMain<any>[], data: any[] } | null> {
        return this._getList(user, keyModule, filters);
    }

    private async _getList(user: EntityDataUsuario, keyModule: KeyBDModule, filters: any = null): Promise<{ dataFormatted: EntityMain<any>[], data: any[] } | null> {
        let url: string;
        let params: EntityRequestList = { fields: {}, filter: {} };
        let result: EntityMain<any>[];

        switch(keyModule) {
            case 'TareoPersonal':
                url = `${this.urlBaseGastos}/Tareo/Informe/find`;
                params.filter = { "Personal.IdUser": user.IdUser,
                    "Status.IdStatus": {
                        $in: filters ? 
                            (filters.value === 'pending' ? [2, 3, 5] : [1])
                            :
                            [2, 3, 5]
                    }
                };
                Object.assign(params, { limit: 1 })
                break;
            case 'TareoGrupal':
                url = `${this.urlBaseGastos}/Navision/Solicitudes/findAnticipo`;
                params.filter = {
                    "Users.Registry.User": user.User,
                    "DatosPersonal.Identificacion": user.Identificacion,
                    "Status.Solicitud.IdStatus": { "$in": [0, 2, 3] },
                    "Modulo": "RENDICIONES"
                }
                if (filters) {
                    params.filter = {
                        ...params.filter,
                        "Status.Solicitud.IdStatus": {  $in: filters.value === 'pending' ? [0, 2, 3] : [1] }
                    }
                }
                break;
            default: url = "";
        }

        let response = await this.service.bgCall<any>("POST", url, JSON.stringify(params), "bearer", "json", 'json', { "request-decrypt-response": true }, 0);

        switch(keyModule) {
            case 'TareoGrupal':
                response = Array.isArray(response) ? response : [];
                result = this.formatToGenericList<EntityTareoPersonal>(response, keyModule);
                break;
            case 'TareoPersonal':
                await this.callDataDepends(user, response);
                result = this.formatToGenericList<EntityTareoPersonal>(response, keyModule);
                break;
            default: result = [];
        }

        return { data: response, dataFormatted: result };
    }

    private async callDataDepends(user: EntityDataUsuario, listTareo: EntityTareoPersonal[]): Promise<void>{

        if (listTareo.length === 0) return;
        const minFechaInicio = listTareo.reduce((prev, current) => {
            if (!prev) return current.Periodo.FechaInicio;
            let minDate = AdapterGenerico.convertStringToDate(prev);
            let currentDate = AdapterGenerico.convertStringToDate(current.Periodo.FechaInicio);
            return minDate > currentDate ? current.Periodo.FechaInicio : prev;
        }, "");

        const url = `${this.urlBaseGastos}/Tareo/Ausencia/find`;
        let payload = {
            filter: {
                "Personal.Identificacion": user.Identificacion,
                "FechaInicio.DateString": { $gte: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(minFechaInicio), 6) },
                "Status.IdStatus": { $in: [1, 2, 3] }
            },
            fields: {}
        }
        const response = await this.service.bgCall<EntityAusencia[]>("POST", url, JSON.stringify(payload), "bearer", "json", "json", { "request-decrypt-response": true }, 0);
        await this.dbLocal.clearStore('CTAusencia');
        await this.dbLocal.insertDataStore({ nameStore: 'CTAusencia', data: Array.isArray(response) ? response : [response] });
    }
}