import { EntityDataUsuario } from "../../../../shared/Domain/EntityDataUsuario";
import { EntityResponseInitialData } from "../Domain/EntityResponse";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseLoadInitialData {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(user: EntityDataUsuario): Promise<EntityResponseInitialData> {
        return await this._exec(user);
    }

    private async _exec(user: EntityDataUsuario): Promise<EntityResponseInitialData> {
        return await this.repository.loadInitialData(user);
    }
}