import { InputTextArea } from "../../../../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../../../../shared/Components/ElementModalCostume";
import { EntityLanguage } from "../../../../../../shared/Domain/EntityLanguage";
import { AdapterGenerico } from "../../../../../../shared/Infraestructure/AdapterGenerico";
import { EntityConfigModalApproval } from "../../Domain/EntityUtils";

interface ContainerProps {
    configModalApproval: EntityConfigModalApproval;
    languageTranslate: EntityLanguage;
    closeModalApproval(): void;
    onSubmitMA(evt: Event): Promise<any>;
    onChangeMA(value: string): void;
}

export const ViewModalApproval = (props: ContainerProps) => {
    if (!props.configModalApproval.show) return null;

    return (
        <>
            <ElementModalCostume
                title={props.languageTranslate.moduloGastoAprobacion.generic.textConfirmacion}
                closeModal={props.closeModalApproval}
                show={props.configModalApproval.show}
                height={"500px"}
            >
                <div className="ViewnModalApproval">
                    <div className={`TextDescription ${props.configModalApproval.type === 'approve' ? 'TextDescriptionApprove' : 'TextDescriptionReject'}`}>
                        <span>{props.configModalApproval.description}</span>
                    </div>
                    <div>
                        {
                            props.configModalApproval.items.map(row =>
                                <div className="GroupItems">
                                    <div className="d-flex">
                                        <span>{props.languageTranslate.moduloGastoAprobacion.generic.textProveedor}:</span>
                                        <span className="text-muted">&nbsp;{row.DatosEmpresa.RazonSocial}</span>
                                    </div>
                                    <div className="d-flex">
                                        <span>{props.languageTranslate.moduloGastoAprobacion.generic.textImpTotal}:</span>
                                        <span className="text-muted">&nbsp;{AdapterGenerico.formatoDinero(row.DatosImporte.Total)} {row.DatosImporte.Moneda.Codigo}</span>
                                    </div>
                                    <hr/>
                                </div>
                            )
                        }
                    </div>
                    <InputTextArea
                        label={props.languageTranslate.moduloGastoAprobacion.generic.textComentario}
                        name="Comentario"
                        onChange={(_, value) => props.onChangeMA(value)}
                        values={{ Comentario: props.configModalApproval.comentario }}
                        isRequired={props.configModalApproval.type === 'reject'}
                    />
                </div>
                <div className='group-button d-flex flex-column' style={{ gap: 10, marginTop: 10 }}>
                    <button className={`btn btn-${props.configModalApproval.type === 'approve' ? 'success' : 'danger'} w-100`} style={{ color: 'white' }} onClick={(evt: any) => props.onSubmitMA(evt)}> {props.configModalApproval.type === 'approve' ? props.languageTranslate.btnAprobar : props.languageTranslate.moduloGenericDetail.btnTextDevolver} </button>
                    <button className='btn btn-dark w-100' style={{ color: 'white' }} onClick={props.closeModalApproval}> {props.languageTranslate.btnCancelar} </button>
                </div>
            </ElementModalCostume>
        </>
    )
}