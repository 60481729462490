import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => {
        }
        // eslint-disable-next-line
    }, []);

    return (<ViewMain
        informationDataInitial={controller.informationDataInitial}
        onClickLoadCatalogo={controller.onClickLoadCatalogo}
        onClickLoadAll={controller.onClickLoadAll}
    />);
};

export default ManagerEntity;