import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoRequestLogin } from "../Domain/DtoRequestLogin";
import { DtoResponseLogin } from "../Domain/DtoResponseLogin";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { initEntityMenu, initEntityMenuDocucobra } from "../../../shared/Domain/EntityMenu";
import { EntityResponseFiles } from "../../../shared/Domain/EntityResponseFile";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";

const languageTranslate = LanguageTranslate();
export class RepositoryImplMain
    extends RepositoryImplGenerico<any>
    implements RepositoryMain {

    public async login(params: DtoRequestLogin): Promise<DtoResponseLogin | null> {
        return this._login(params);
    }

    private async _login(params: DtoRequestLogin): Promise<DtoResponseLogin | null> {
        if (!window.navigator.onLine) {
            this.dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoSinConexion, languageTranslate.textoDescripcionSinConexion, "warning");
            return null;
        }
        let response: DtoResponseLogin = await this.service.call<any>("POST", `${this.urlBase}/public/login`, JSON.stringify(params), "basic", "json", "json", {}, 0);
        response = typeof response === "string" ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;

        let listRoles: any[] = [];
        let paises: any[] = [];
        let filiales: any[] = [];
        let opciones1: any[] = [];

        let existeDocuCobra: boolean = false

        try {
            // throw new Error('prueba')
            let responseSisacce: any = await this.service.call<any>("POST", `${process.env?.REACT_APP_URL_MASTER || ""}/Sisacce/Acceso/Permiso/obtenerPermisos`, JSON.stringify({ sistema: "DOCUCOBRA", ambiente: "PROD" }), "basic", "json", "json", { Authorization: `Bearer ${response.token}`, "request-decrypt-response": "yes", }, 0);

            listRoles = this.generarRoles(parseInt(responseSisacce.rol.codigo));
            paises = await this.getPaisesPermisos(responseSisacce.grupo);
            filiales = await this.getFilialPermisos(responseSisacce.grupo);
            opciones1 = await this.getOpcionesFolder(responseSisacce.opciones, "", true, 0);
            existeDocuCobra = true;
        } catch (error) {
        }

        response.permisos = {
            menu: existeDocuCobra ? initEntityMenuDocucobra : initEntityMenu,
            rolesSisacce: listRoles,
            paisesSisacce: paises,
            filialesSisacce: filiales,
            opcionesSisacce: opciones1,
        };

        return response;
    }

    private generarRoles(codigoRol: number): Array<string> {
        let list: string[] = [];

        for (let i = 0; i < codigoRol; i++) {
            if (i !== 0) list.push(i.toString());
        }
        list.push(codigoRol.toString());
        return list;
    }

    private async getPaisesPermisos(dataGrupos: any[]) {
        const paises: Array<{ _id: string; codigo: string; nombre: string }> = [];

        for (let g of dataGrupos || []) {
            for (let p of g?.pais || []) {
                if (p) {
                    const i = paises.map((row) => row.codigo).indexOf(p.codigo);
                    if (i === -1)
                        paises.push({ _id: p._id, codigo: p.codigo, nombre: p.nombre });
                }
            }
        }

        return paises;
    }

    private async getFilialPermisos(dataGrupos: any[]) {
        const filiales: Array<{ _id: string; codigo: string; nombre: string }> = [];

        for (let g of dataGrupos || []) {
            for (let p of g?.pais || []) {
                for (let d of p?.delegacion || []) {
                    if (d.filial !== null) {
                        const p = filiales
                            .map((row) => row.codigo)
                            .indexOf(d.filial.codigo);
                        if (p === -1)
                            filiales.push({ _id: d._id, codigo: d.codigo, nombre: d.nombre });
                    }
                }
            }
        }

        return filiales;
    }

    private async getOpcionesFolder(data: any[], idPadre: any = null, option: boolean, nivel: number) {
        const listOpciones: EntityResponseFiles[] = [];
        try {
            for (const o of data) {
                if (option) {
                    const items = this.structureData(o, option, nivel);
                    listOpciones.push(items);
                } else {
                    if (o.Padre === idPadre) {
                        const items = this.structureData(o, option, nivel);
                        listOpciones.push(items);
                    }
                }
            }
            return listOpciones;
        } catch (error) {
            return listOpciones;
        }
    }

    private structureData = (
        row: any,
        option: boolean,
        nivel: number
    ): EntityResponseFiles => {
        let validate = "Name",
            Permiso = { Code: "", _id: "", Nombre: "" };
        if (option) {
            validate = "descripcion";
            Permiso = row.padre
                ? { Code: row.padre.codigo, _id: row._id, Nombre: row.padre.nombre }
                : { ...Permiso };
        } else {
            Permiso = row.Permiso ?? { ...Permiso };
        }
        return {
            _id: row._id,
            Code: "",
            Padre: row.padre ? row.padre._id : "",
            Name: row[`${validate}`],
            Extension: "FOLDER",
            Nivel: nivel,
            Parents: [],
            Permiso: Permiso,
            Description: "",
            DateRegistry: "null",
            Resumen: "",
            Status: 1,
            StatusWorkflow: { IdStatus: 1, Status: "Aprobado" },
            Type: "FOLDER",
            VersionFileName: "",
            ArchivosRelacionados: [],
            Publicado: 1,
        };
    };
}
