import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import ElementModalPDF from '../../shared/Components/ElementModalPDF';
import { ViewModal } from './Components/module/ViewModal';


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        // eslint-disable-next-line
    }, []);

    return (
    <>
        <ViewMain
            listContent={controller.listContent}
            confirmDetail={controller.confirmDetail}
            modeTypeModule={controller.modeTypeModule}
            viewDocument={controller.viewDocument}
            openModal={controller.openModal}
            copyCodeItem={controller.copyCodeItem}
        />
        <ElementModalPDF
            onCloseModal={controller.closeViewDocument}
            show={controller.viewOptionsPDF.show}
            src={controller.viewOptionsPDF.src}
            title={controller.viewOptionsPDF.title}
            downloadFile={controller.downloadFile}
        />
        <ViewModal
            configModal={controller.configModal}
            closeModal={controller.closeModal}
            onChange={controller.onChange}
            onSubmit={controller.onSubmitM}
        />
    </>
    );
};

export default ManagerEntity;