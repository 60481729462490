import { InputTextArea } from "../../../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../../../shared/Components/ElementModalCostume";
import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";
import { EntityConfigModalMessageReject } from "../../Domain/EntityUtils";

interface ContainerProps {
    configModalApproval: EntityConfigModalMessageReject;
    closeModalMessageReject(): void;
    onSubmitMMS(evt: Event): Promise<any>;
    onChangeMMS(name: string, value: string): void;
}

const languageTranslate = LanguageTranslate();

export const ModalMessageReject = (props: ContainerProps) => {
    if (!props.configModalApproval.show) return null;

    return (
        <>
            <ElementModalCostume
                title={'Devolver'}
                closeModal={props.closeModalMessageReject}
                show={props.configModalApproval.show}
                height={"500px"}
            >
                <div className="ModalMessageReject">
                    <div className={`TextDescription TextDescriptionReject mb-3`}>
                        <span>{props.configModalApproval.description}</span>
                    </div>
                    <InputTextArea
                        label={languageTranslate.moduloGastoAprobacion.generic.textComentario}
                        name="comment"
                        onChange={(_, value) => props.onChangeMMS(_, value)}
                        values={{ Comentario: props.configModalApproval.comment }}
                        isRequired
                    />
                </div>
                <div className='group-button d-flex flex-column' style={{ gap: 10, marginTop: 10 }}>
                    <button
                        className={`btn btn-danger w-100`}
                        style={{ color: 'white' }}
                        onClick={(evt: any) => props.onSubmitMMS(evt)}
                    >
                        {languageTranslate.moduloGenericDetail.btnTextDevolver}
                    </button>
                    <button
                        className='btn btn-dark w-100'
                        style={{ color: 'white' }}
                        onClick={props.closeModalMessageReject}
                    >
                        {languageTranslate.btnCancelar}
                    </button>
                </div>
            </ElementModalCostume>
        </>
    )
}