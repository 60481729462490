import { EntityOT } from "../../../../../shared/Domain/Catalogos/EntityOT";
import { EntitySelectBase } from "../../../../../shared/Domain/Catalogos/EntitySelectBase";
import { EntityAnticipo } from "../../../../../shared/Domain/EntityAnticipo";
import { EntityGasto } from "../../../../../shared/Domain/EntityGasto";

export type EntityRequestGenericForm =  {
  Codigo: string;
  OT: EntitySelectBase<EntityOT>;
  Empresa: {
    CDEmpresa: string;
    Empresa: string;
    Grupo: string;
  };
  Delegacion: {
    Code: string;
    Codigo: string;
    Name: string;
  };
  Pais: {
    CDPais: string;
    Code: string;
    Name: string;
  };
  Personal: {
    ApellidoMaterno: string;
    ApellidoPaterno: string;
    Identificacion: string;
    Nombres: string;
    TipoDocumento: string;
  };
  ActivarAnticipo?: boolean;
  Anticipo?: EntitySelectBase<EntityAnticipo>;
  Banco: string;
  CuentaBancaria: string;
  CuentaInterbancaria: string;
  Fecha: string;
  Descripcion: string;
  GastoAsociado: EntityGasto[];
  GastoAsociadoInit: EntityGasto[];
  User: {
    User: string;
    LastName: string;
    Name: string;
    IdUser: number;
    Perfil: string;
  };
  Status: {
    IdStatus: number;
    Status: string;
  };
  Aprobacion: {
    Nivel: number
  }
  Flujo?: {
    Id: number
    Code: string
    Codigo: string
    Name: string
    Descripcion: string
    Aprobacion: Array<{
      IdNivel: number
      Nivel: number
      Monto: number
      Status: {
        IdStatus: number
        Status: string
      }
      Perfil: {
        IdPerfil: number
        Perfil: string
        Initial: string
      }
      Aprobador: {
        LastNameFather: string
        LastNameMother: string
        Name: string
        Identificacion: string
        Status: {
          IdStatus: number
          Status: string
        }
        name: string
        FullName: string
      }
    }>
  };
  Notas?: any[];
  Bitacora?: any[];
}

export const initEntityRequestGenericForm: EntityRequestGenericForm = {
  Codigo: '',
  OT: undefined as any,
  Empresa: {
    CDEmpresa: "",
    Empresa: "",
    Grupo: ""
  },
  Delegacion: {
    Code: "",
    Codigo: "",
    Name: ""
  },
  Pais: {
    CDPais: "",
    Code: "",
    Name: ""
  },
  Personal: {
    ApellidoMaterno: '',
    ApellidoPaterno: '',
    Identificacion: '',
    Nombres: '',
    TipoDocumento: ''
  },
  Anticipo: undefined,
  Banco: "",
  CuentaBancaria: "",
  CuentaInterbancaria: "",
  Fecha: "",
  Descripcion: "",
  GastoAsociado: [],
  GastoAsociadoInit: [],
  User: {
    LastName: '',
    Name: '',
    User: '',
    IdUser: 0,
    Perfil: ''
  },
  Status: {
    IdStatus: 0,
    Status: ''
  },
  Aprobacion: {
    Nivel: 0
  }
}

export type EntityRequestFormService = {
  Codigo: string
  isPending?: boolean
  DatosPersonal: {
    TipoDocumento: string
    Identificacion: string
    ApellidoPaterno: string
    ApellidoMaterno: string
    Nombres: string
  }
  DatosBanco: {
    Banco: string
    TipoCuenta: string
    CuentaBancaria: string
    CuentaInterbancaria: string
  }
  DatosEmpresa: {
    Codigo: string
    Empresa: string
    Grupo: string
  }
  DatosImporte: {
    Moneda: {
      Codigo: string
      Moneda: string
      Nombre: string
      Descripcion: string
    }
    Importe: number
    MontoIVA: number
    Total: number
  }
  DatosTrabajo: {
    Delegacion: {
      Code: string
      Codigo: string
      Delegacion: string
    }
    OT: {
      Code: string
      Codigo: string
      OT: string
    }
  }
  Anticipo?: {
    Codigo: string;
    Nav: string;
  }
  DatosPais: {
    CDPais: string
    Codigo: string
    Nombre: string
  }
  FechaEmision: {
    IdDate: number
    Date: string
  }
  GastoAsociado: Array<string>
  User: {
    User: string;
    LastName: string;
    Name: string;
    IdUser: number;
    Perfil: string;
  }
  Descripcion: string;
}

export type EntityRequestFormServiceSendApproval = {
  codigo: string;
  flujo: {
    Id: number;
    Code: string;
    Codigo: string;
    Name: string;
    Descripcion: string;
    Aprobacion: any[];
  }
  user: {
    user: string;
    lastname: string;
    name: string;
  }
}

export type EntityRequestUpdateGasto = {
  GastoInit: EntityGasto[];
  GastoEnd: EntityGasto[];
}

export interface EntityRequestNote {
  codigo: string;
  nota: {
      titulo: string;
      descripcion: string;
  }
  user: {
      user: string;
      lastname: string;
      name: string;
  };
}