// React
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";

// Domain
import { ErrorCostume } from "../../../shared/Domain/ErrorCostume";
import { EntityTypeModule, KeyBDModule } from "../Domain/EntityTypeModule";
import { EntityMain } from "../Domain/EntityMain";

// Adapters
import { AdapterCopyText } from "../../../shared/Infraestructure/AdapterCopyText";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { AdapterConfigure } from "./AdapterConfigure";

// Infraestructure
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { addLoading, changeSaludo, removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { RepositoryImplMain } from "./RepositoryImplMain";

// Use Case
import { UseCaseReloadList } from "../Application/UseCaseReloadList";
import { EntityRendicionGasto } from "../../../shared/Domain/EntityRendicionGasto";
import { EntityModeMultipleSelect, EntityModeMultipleSelectInit } from "../Domain/EntityModeMultipleSelect";
import { UseCaseDeleteItem } from "../Application/UseCaseDeleteItem";
import { EntityAnticipo } from "../../../shared/Domain/EntityAnticipo";
import { EntityModalFiltro, initEntityModalFiltro } from "../Domain/EntityUtils";


let nameStore: KeyBDModule = '';

export const Controller = () => {
    const { generico: { websocket, dbLocal, countProcess }, auth: { user } } = useSelector((state: RootState) => state);
    const [data, setData] = useState<Array<EntityMain<any>>>([]);
    const [modeTypeModule, setModeTypeModule] = useState<EntityTypeModule>({ key: '', navBar: [] });
    const [modeMultipleSelect, setModeMultipleSelect] = useState<EntityModeMultipleSelect>(EntityModeMultipleSelectInit);
    const [modalFiltroConfig, setModalFiltroConfig] = useState<EntityModalFiltro>(initEntityModalFiltro);

    const languageTranslate = LanguageTranslate();
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const init = async () => {
        dispatch(changeSaludo(false));
        configModeType();
    };

    const configModeType = async () => {
        const arrPathname = window.location.pathname.split('/');
        const validationPathName = (module: string) => arrPathname.some(item => item === module);
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_RENDICION_GASTOS}`)) initModeRendicionGasto();
        else if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_SOLICITUD_ANTICIPO}`)) initModeSolicitudAnticipo();
        else if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_APROBADOR_GASTO_APROBACION}`)) initModeGastoAprobacion();
        else {};

        if (navigator.onLine) await loadData();
    }

    const initModeRendicionGasto = async () => {
        setModeTypeModule(() => ({ key: 'RendicionGasto', navBar: languageTranslate.moduloGenericList.navBarRendicionGastos }));
        nameStore = "RendicionGasto";
        if (!navigator.onLine) {
            const response: EntityRendicionGasto[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatToGenericList<EntityRendicionGasto>(response, 'RendicionGasto')));
        }
    }

    const initModeSolicitudAnticipo = async () => {
        setModeTypeModule(() => ({ key: 'SolicitudAnticipo', navBar: languageTranslate.moduloGenericList.navBarSolicitudAnticipo }));
        nameStore = "SolicitudAnticipo";
        if (!navigator.onLine) {
            const response: EntityAnticipo[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatToGenericList<EntityAnticipo>(response, 'SolicitudAnticipo')));
        }
    }

    const initModeGastoAprobacion = async () => {
        setModeTypeModule(() => ({ key: 'GastoAprobacion', navBar: languageTranslate.moduloGenericList.navBarGastoAprobacion }));
        nameStore = "GastoAprobacion";
        if (!navigator.onLine) {
            const response: EntityRendicionGasto[] = await dbLocal.selectAllStore(nameStore);
            setData(() => (repository.formatToGenericList<EntityRendicionGasto>(response, 'GastoAprobacion')));
        }
    }

    const loadData = async () => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const response = await (new UseCaseReloadList(repository).exec(user, nameStore));
            if (response === null) return;
            await dbLocal.clearStore(nameStore);
            await dbLocal.insertDataStore([{ nameStore, data: response.data }]);
            setData(() => (response.dataFormatted));
        } catch (err) {
            let error: ErrorCostume = new ErrorCostume((err as Error).message);
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    };

    const copyCodeItem = async (code: string) => {
        await AdapterCopyText.copyText(code);
        AdapterGenerico.createToast(`${languageTranslate.textoCopiar}`, 'success');
    }

    const onRedirectForm = (codigo: string) => {
        if (nameStore === 'SolicitudAnticipo') {
            const maximoAnticipoAbierto = 2;
            const countAnticipoAbierto = data.filter(row => row.dataComplete.Status?.Solicitud.IdStatus === 2).length;
            if (countAnticipoAbierto > maximoAnticipoAbierto) {
                AdapterGenerico.createMessage(languageTranslate.textoAlerta, `Tiene ${countAnticipoAbierto} anticipos en estado abierto, el máximo es de ${maximoAnticipoAbierto}`, 'warning', false);
                return;
            }
        }
        navigate(`${codigo}`)
    };

    // ================================== Modal Filtros ==========================================================
    const openModalFiltro = () => {
        setModalFiltroConfig((prev) => ({ ...initEntityModalFiltro, show: true }));
    }
    
    const closeModalFiltro = () => {
        setModalFiltroConfig((prev) => ({ ...initEntityModalFiltro }));
    }

    const onChangeModalFiltro = (name: string, value: any) => {
        setModalFiltroConfig((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    const onSubmitModalFiltro = async () => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            if (!modalFiltroConfig.statusSelected.value) throw new Error('Seleccione un estado');
            const response = await (new UseCaseReloadList(repository).exec(user, nameStore, modalFiltroConfig.statusSelected));
            if (response === null) return;
            await dbLocal.clearStore(nameStore);
            await dbLocal.insertDataStore([{ nameStore, data: response.data }]);
            setData(() => (response.dataFormatted));
            closeModalFiltro();
        } catch (err) {
            let error: ErrorCostume = new ErrorCostume((err as Error).message);
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    // ================================== Eliminación múltiple ==========================================================
    const activeModeMultipleSelect = (active: boolean = true) => setModeMultipleSelect(() => ({ active, listCode: [] }));

    const onSelectItem = (code: string) => {
        let listCode = modeMultipleSelect.listCode;
        let findedIndex = listCode.findIndex(row => row === code);
        findedIndex === -1 ? listCode.push(code) : listCode.splice(findedIndex, 1);
        setModeMultipleSelect(prev => ({...prev, listCode}));
    }

    const onSelectAllItem = () => {
        let listCode: string[] = [];
        if (nameStore === 'SolicitudAnticipo') {
            const _data = data.filter(row => row.dataComplete.Status.Solicitud.IdStatus === 2);
            listCode = modeMultipleSelect.listCode.length === _data.length ? [] : _data.map(row => row.Codigo);
        } else {
            listCode = modeMultipleSelect.listCode.length === data.length ? [] : data.map(row => row.Codigo);
        }
        setModeMultipleSelect(prev => ({...prev, listCode}));
    }

    const confirmDetail = async () => {
        const isAccept = await AdapterGenerico.createMessage('', languageTranslate.textoEliminar, 'info', true);
        if (!isAccept) return;

        dispatch(addLoading({ textLoading: languageTranslate.textoCargando }))
        const result = await new UseCaseDeleteItem(repository).exec(modeMultipleSelect.listCode, modeTypeModule.key, user)
        dispatch(removeLoading());

        if (result) {
            setModeMultipleSelect({active: false, listCode: []});
            AdapterGenerico.createToast(languageTranslate.moduloGenericDetail.successBatch, 'success');
            init();
        }
    }

    return {
        data,
        countProcess,
        modeTypeModule,
        modeMultipleSelect,
        modalFiltroConfig,

        init,
        onRedirectForm,
        loadData,
        copyCodeItem,

        activeModeMultipleSelect,
        onSelectItem,
        onSelectAllItem,
        confirmDetail,

        openModalFiltro,
        closeModalFiltro,
        onChangeModalFiltro,
        onSubmitModalFiltro,
    };
}