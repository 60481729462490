import { ElementTableCostume } from "../../../../shared/Components/ElementTableCostume";
import { EntityDocumentoContent } from "../../Domain/EntityListContent";
import { EntityRequestDownloadFile } from "../../Domain/EntityRequestDownloadFile";

interface containerProps {
    payload: EntityDocumentoContent[];
    viewDocument: (payload: EntityRequestDownloadFile, code: string) => Promise<any>;
}

const groupDocumentos = (arr: EntityDocumentoContent[]) => {
    return arr.reduce((prev, current) => {
        const indexFinded = prev.findIndex(row => row.keyGroup === current.keyGroup);
        indexFinded === -1 ? prev.push({...current, list: [current]}) : prev[indexFinded].list.push(current);
        return prev;
    }, [] as any[])
}

export const TableGenericDoc = (props: containerProps) => {
    const list = groupDocumentos(props.payload);
    return (
        <div>
            {
                list.map(row => 
                    <>
                        <label className="label-custom-modal">{ row.textGroup }</label>
                        <ElementTableCostume
                            listHeader={[
                                {
                                    label: 'Archivo',
                                    key: 'name',
                                    render: (text, data, index) => <>
                                        <i className="fa-solid fa-eye mx-2" style={{ fontSize: 10 }} onClick={() => props.viewDocument(data.path, data.code)} />
                                        { text }
                                    </>
                                },
                            ]}
                            listData={row.list}
                            className="mb-4"
                        />
                    </>
                )
            }
        </div>
    )
}