import { InputText } from "../../../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../../../shared/Components/ElementModalCostume"
import { ElementTableCostume } from "../../../../../shared/Components/ElementTableCostume"
import { AdapterGenerico } from "../../../../../shared/Infraestructure/AdapterGenerico";
import { EntityConfigModalDetalle } from "../../Domain/EntityUtils";

interface ContainerProps {
    closeModalDetalle: () => void;
    configModalDetalle: EntityConfigModalDetalle;
}

export const ModalDetalle = (props: ContainerProps) => {

    return (
        <ElementModalCostume
            closeModal={props.closeModalDetalle}
            show={props.configModalDetalle.show}
            title={props.configModalDetalle.title}
        >
            <div className="ModalDetalle">

                {
                    props.configModalDetalle.message ?
                        <div className='alert alert-warning' style={{ textAlign: 'center' }}>
                            <span style={{ fontSize: '0.8rem' }}>
                                { props.configModalDetalle.message }
                            </span>
                        </div>
                    : null
                }

                <ElementTableCostume
                    listData={props.configModalDetalle.ListDetail.filter(row => row.Horas > 0)}
                    listHeader={[
                        { key: 'OT', label: 'OT' },
                        { key: 'Disciplina', label: 'Disciplina' },
                        { key: 'Horas', label: '', render: (text, data, index) => 
                            <InputText
                                label=""
                                onChange={() => {}}
                                values={{ Horas: AdapterGenerico.formatNumberToHoras(data.Horas) }}
                                name="Horas"
                                type="time"
                                className="CustomInputModalDetalle"
                                key={index}
                                disabled
                            />
                        }
                    ]}
                    className='my-4'
                />

                <div className="w-100 d-flex" style={{ justifyContent: 'right' }}>
                    <span><strong>Total Horas: {
                        props.configModalDetalle.ListDetail.reduce((prev, current) => { prev += parseInt(`${current.Horas || 0}`); return prev; }, 0)    
                    }</strong></span>
                </div>
            </div>
        </ElementModalCostume>
    )
}