import { EntityDias } from "../../../../Tareo/Detail/Personal/Domain/EntityRequest"
import { ElementBreadCrumb } from "../../../../shared/Components/ElementBreadCrumb"
import { ElementCollapseCostume } from "../../../../shared/Components/ElementCollapseCostume"
import ElementStepperContent from "../../../../shared/Components/ElementStepperContent"
import ElementStepperHeader from "../../../../shared/Components/ElementStepperHeader"
import { ElementTableCostume } from "../../../../shared/Components/ElementTableCostume"
import { AdapterGenerico } from "../../../../shared/Infraestructure/AdapterGenerico"
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate"
import { EntityConfigForm } from "../Domain/EntityUtils"
import { AdapterConfigure } from "../Infraestructure/AdapterConfigure"
import './View.scss';

const languageTranslate = LanguageTranslate();

interface ContainerProps {
    configForm: EntityConfigForm;
    openModalDetalle: (payload: EntityDias) => void
    openModalMessageReject: () => void
    confirmDetail: () => Promise<void>;
    openModalNote: () => void;
}

export const ViewMain = (props: ContainerProps) => {

    return (
        <div className="ViewTareoAprobacion">
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={props.configForm.navBar} />

                {/** FLUJO DE APROBADORES */}
                {
                    (props.configForm.data.Niveles.length || 0) > 0 ?
                        <ElementStepperHeader
                            data={props.configForm.data.Niveles.map(row => ({ label: row.Aprobador.FullName, value: row.Aprobador.Identificacion })) || []}
                            onChange={() => {}}
                            stepPosition={props.configForm.data.Status.IdStatus === 1 ? 999 : (props.configForm.data.Nivel || 0) + 1}
                        />
                    : null
                }

                {/** Detalle General */}
                <ElementCollapseCostume
                    textTitle={'DETALLE GENERAL'}
                    className='my-3'
                    isOpenDefault
                    persistsOpen
                >
                    <div className='ContentItemCollapse'>
                        <div className='d-flex w-100'>
                            <span>OT:</span>
                            <span className='text-muted'>&nbsp;{props.configForm.data.DatosTrabajo.OT.Codigo} - {props.configForm.data.DatosTrabajo.OT.OT}</span>
                        </div>

                        <div className='d-flex w-100'>
                            <span>Periodo:</span>
                            <span className='text-muted'>&nbsp;{props.configForm.data.Periodo.Criterio}</span>
                        </div>

                        <div className='d-flex w-100'>
                            <span>Personal:</span>
                            <span className='text-muted'>&nbsp;{`${props.configForm.data.Personal.Name} ${props.configForm.data.Personal.LastFather} ${props.configForm.data.Personal.LastMother}`}</span>
                        </div>

                        <div className='d-flex w-100'>
                            <span>Cantidad Horas:</span>
                            <span className='text-muted'>&nbsp;{props.configForm.data.CantidadHoras}</span>
                        </div>
                    </div>
                </ElementCollapseCostume>

                {/** DETALLE NOTAS */}
                <div>
                    <ElementCollapseCostume
                        textTitle={languageTranslate.moduloGenericDetail.itemNotas.textTitle}
                        className='my-3'
                    >
                        <div className='ContentItemCollapse'>
                            <div className="w-100 mb-2" style={{ textAlign: 'end' }}>
                                <button className="btn btn-primary" onClick={props.openModalNote}>{languageTranslate.btnAgregar} </button>
                            </div>
                            <ElementTableCostume
                                listHeader={languageTranslate.moduloGenericDetail.itemNotas.headerTable}
                                listData={props.configForm.data.Notas}
                                className="CustomTableNota"
                            />
                        </div>
                    </ElementCollapseCostume>

                    <ElementCollapseCostume
                        textTitle={languageTranslate.moduloGenericDetail.itemBitacora.textTitle}
                        className='mt-3 mb-5'
                    >
                        <div className='ContentItemCollapse'>
                            <ElementTableCostume
                                listHeader={languageTranslate.moduloGenericDetail.itemBitacora.headerTable}
                                listData={props.configForm.data.Bitacora}
                            />
                        </div>
                    </ElementCollapseCostume>
                </div>


                {/** Detalle Tareo */}
                <ElementStepperContent
                    title="DETALLE TAREO"
                    currentStep={0}
                    step={0}
                    removeButtonSubmit
                    onSubmit={() => { }}
                >
                    <div className="p-1">
                    <ElementTableCostume
                            listData={props.configForm.data.Dias}
                            listHeader={
                                languageTranslate.moduloTareo.detail.table?.map(row => {
                                    // if (row.key === 'Nombre') row.render = (text: string, data: EntityDias) => <span className={`${data.Tipo === AdapterConfigure.IDENTIFIER_TIPO_FESTIVO ? 'text-danger' : ''}`}>{languageTranslate.moduloTareo.days[text]}</span>
                                    if (row.key === 'Nombre')
                                        row.render = (text: string, data: EntityDias) =>
                                            <span
                                                className={`${data.Tipo === AdapterConfigure.IDENTIFIER_TIPO_FESTIVO || (data.Ausencia || data.Bloqueado) ? 'text-danger' : ''}`}
                                            >
                                                {languageTranslate.moduloTareo.days[text]}{data.Ausencia ? ' - Ausencia' : ''}{data.Bloqueado ? ' - Bloqueado' : ''}
                                            </span>
                                    if (row.key === 'Preview') row.render = (text: string, data: EntityDias, index: number) => <i className='fa-solid fa-eye' onClick={() => props.openModalDetalle(data)} />
                                    if (row.key === 'HorasTrabajador') row.render = (text: string, data: EntityDias, index: number) => data.HorasTrabajador === 0 ? '-' : AdapterGenerico.formatNumberToHoras(data.HorasTrabajador); 
                                    return row;
                                })
                            }
                            className='my-4'
                        />
                    </div>
                </ElementStepperContent>

                {/** Botones de aprobación */}
                <div className='container_group_button shadow-lg'>
                    <div className='group_button'>
                        <button className='btn btn-danger' onClick={props.openModalMessageReject}>
                            <i className="fa-solid fa-arrow-rotate-left" style={{ fontSize: 14, marginRight: 12 }} />
                            {languageTranslate.moduloGenericDetail.btnTextDevolver}
                        </button>
                        <button className='btn btn-success' onClick={props.confirmDetail}>
                            <i className="fa-solid fa-check" style={{ fontSize: 14, marginRight: 12 }} />
                            {languageTranslate.moduloGenericDetail.btnTextAprobar}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}