import React from 'react';
import './ElementEmptyList.scss'

interface PropsView {
    text: string;
    className?: string;
    loading?: boolean;
};


export const ElementEmptyList = (props: PropsView) => {
    return (
        props.loading ? 
        (
            <div className={`ElementEmptyList ${props.className || ''}`}>
                <i className="fa-solid fa-magnifying-glass" style={{ fontSize: 35 }}/>
                <h4>Buscando registros...</h4>
            </div>
        ) : (
            <div className={`ElementEmptyList ${props.className || ''}`}>
                <i className="fa-regular fa-file" style={{ fontSize: 35 }}/>
                <h4>{props.text}</h4>
            </div>
        )
    );
};
