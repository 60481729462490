import * as React from 'react';
import { ViewMain } from "./Components/View";
import { Controller } from "./Infraestructure/Controller";


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => { }
        // eslint-disable-next-line
    }, []);

    return (<ViewMain
        listMenu={controller.menu}
        onClickMenu={controller.onClickMenu}
        languageTranslate={controller.languageTranslate}
    />);
};

export default ManagerEntity;